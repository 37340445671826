import './App.css';
import './theme.css'
import Router from './router';
import '@/utils/i18n';
import { Suspense, useEffect } from 'react';
import { uiStrore } from './store/ui';
import { ToastProvider } from './hooks/useGlobalToast';
import { GlobalScrollbar } from 'mac-scrollbar';
import 'mac-scrollbar/dist/mac-scrollbar.css';
import { Alert } from './components/Alert';
import { getProcessNodeEnv, ipcRendererInvoke, ipcRendererOn, isDesktop } from './utils/electron';
import { createStandaloneToast } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { zoomPlugin } from './utils/common';
import 'react-photo-view/dist/react-photo-view.css';

const { ToastContainer: Message } = createStandaloneToast();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0
    }
  }
});
function App() {
  //@ts-ignore
  document.body.style['zoom'] = (uiStrore.zoom || 0.9).toString();
  zoomPlugin();
  useEffect(() => {
    // 客户端代理地址
    if (getProcessNodeEnv()?.NODE_ENV === 'production') {
      window.proxyApi = 'https://www.wisetalker.cn'
    } else if (getProcessNodeEnv()?.NODE_ENV === 'development') {
      window.proxyApi = 'https://dev.wisetalker.tech' //'http://localhost:3000'
    }
    if (uiStrore.language) {
      uiStrore.setLanguage(uiStrore.language)
    } else if (navigator.language) {
      switch (navigator.language) {
        case 'zh-CN':
          uiStrore.setLanguage('zh')
          break;
        case 'en-US':
          uiStrore.setLanguage('en')
          break;
        default:
          uiStrore.setLanguage('zh')
          break;
      }
    }

    ipcRendererOn('updateDownloaded', (_event, data) => {
      //@ts-ignore
      if (data.downloaded) {
        uiStrore.setUpdateDownloaded(true);
        //@ts-ignore
        uiStrore.setReleaseNotes(data.releaseNotes.split('|__|'));
      }

    })

    isDesktop() && getVersion()
  }, [])

  const getVersion = async () => {
    const { isWindows, version, versionCheckUrl } = await ipcRendererInvoke('getVersion');
    if (isWindows) {
      try {
        const url = `https://www.wisetalker.cn/api/system/pcVersion?version=${version}&platform=win32&arch=x64`
        const res: any = await axios.get(url);
        if (res.status === 200) {
          uiStrore.setUpdateDownloaded(true);
          uiStrore.setUpdateIsWindow(true);
          uiStrore.setNewsetVersion(res.data?.version);
          uiStrore.setReleaseNotes(res.data.notes.split('|__|'));
        }
      } catch (error) {
        console.log('error', error)
      }
    } else {
      uiStrore.setVersion(version);
      const res = await axios.get(versionCheckUrl);
      uiStrore.setVersionCheckUrl(versionCheckUrl);
      uiStrore.setNewsetVersion(res.data?.version || '');
      uiStrore.setReleaseNotes(res.data?.notes?.split('|__|') || []);
      uiStrore.setIsMustUpdate(res.data?.isMustUpdate || false);
    }
  }
  return <Suspense>

    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <Router />
        <Alert />
        <Message />
      </ToastProvider>
    </QueryClientProvider>
    <GlobalScrollbar />
  </Suspense>
}

export default App;
