import { Box } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { PhoneInput, PwdInput } from "./LoginInput";
import { CButton } from "@/components/Button";
import { useMessage } from "@/hooks/useMessage";
import { globalStore } from "@/store/global";
import { userStore } from "@/store/user";
import { useLocation } from "react-router-dom"

import styles from './index.module.scss'

interface LoginByPwdProps {
  onForgetPwd: () => void
  onAgreen: () => void
}
const LoginByPwd: FC<LoginByPwdProps> = (props) => {
  const { onForgetPwd, onAgreen } = props

  const [pwdError, setPwdError] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [pwd, setPwd] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { loginByPwdAction } = userStore;
  const { setLoginIsOpen, loginPhone, setLoginPhone, loginIsAgreeProtocol, loginSuccess } = globalStore;
  const message = useMessage();
  const location = useLocation();

  const loginByPwd = async () => {
    if (!loginIsAgreeProtocol) {
      onAgreen();
      return
    }
    let errMsg = ''
    if (loading || isDisabled) return;
    if (!loginPhone) {
      errMsg = '请输入手机号';
    } else if (!/(^1[3456789]\d{9}$)/.test(loginPhone)) {
      errMsg = '手机号格式错误';
      setError(errMsg);
    } else if (!pwd) {
      errMsg = '请输入密码';
      setPwdError(errMsg)
    }
    if (errMsg) {
      message.error({ title: errMsg });
      return
    }
    try {
      setLoading(true)
      await loginByPwdAction(loginPhone, pwd);
      message.success({ status: 'success', title: '登录成功' });
      setLoginPhone('');
      loginSuccess(location.pathname);
      setLoading(false);
      setLoginIsOpen(false);
    } catch (err: unknown) {
      setPwdError((err as ErrorResponse).message);
      setLoading(false);
      message.error({ status: 'success', title: (err as ErrorResponse).message });
    }
  }

  const isDisabled = loginPhone === '' || pwd === '';
  return <Box className={styles.byPwd}>
    <PhoneInput value={loginPhone} err={error} onChange={value => {
      setLoginPhone(value);
      if (error) {
        setError('');
      }
    }} />
    <PwdInput error={pwdError} placeholder="密码" onChange={pwd => {
      setPwd(pwd);
      if (pwdError) {
        setPwdError('');
      }
    }} />
    <Box className={styles.underPwd}>
      <Box className={styles.toForgot} onClick={onForgetPwd}>忘记密码</Box>
    </Box>
    <CButton
      disabled={isDisabled}
      isLoading={loading}
      className={styles.loginBtn}
      theme='primary'
      onClick={loginByPwd}
    >
      登录
    </CButton>
  </Box >
}

export default observer(LoginByPwd)