import { Text, Box, BoxProps } from "@chakra-ui/react";
import { FC } from "react";

import styles from './index.module.scss'

interface CreationTitleProps {
  title: string
}
export const CreationTitle: FC<CreationTitleProps> = (props) => {
  const { title } = props;
  return <Text className={styles.title}>{title}</Text>
}

export const CreationTitleBox: FC<BoxProps> = (props) => {
  const { className, ...others } = props;
  return <Box className={`${styles.titleBox} ${className}`} {...others} />
}