import { observer } from "mobx-react-lite";
import { FC } from "react";
import { ChatHistory } from "./components/History";
import { Box, Icon } from "@chakra-ui/react";
import ChatHeader from "./components/Header";
import ChatContent from "./components/Content";
import InputArea from "./components/InputArea";
import { WiseImage } from "@/components/Image";
import { chatStore } from "@/store/chat";
import { FileRejection, useDropzone } from 'react-dropzone';
import { useMessage } from "@/hooks/useMessage";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { DragFile } from "@/components/DragFileMask";

import styles from './index.module.scss'

const imgMaxSize = 20 * 1024 * 1024 // 20MB
const docFileMaxSize = 10 * 1024 * 1024 // 10MB
const Chat: FC = () => {
  const { isHideHistoryNav, setIsHideHistoryNav, setUploading, chatFile, chatDocFile, setChatFile, setChatDocFile } = chatStore;
  const message = useMessage();
  const { t } = useTranslation();

  const onDrop = async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (acceptedFiles.length === 0 || fileRejections.length) return;
    if (chatFile?.length && chatFile.length >= 4) {
      message.warning({ title: t('MaxUploadImg') });
      return;
    } else if (chatDocFile?.length && chatDocFile.length >= 2) {
      message.warning({ title: t('MaxUploadFile') });
      return;
    }

    let firstFlieType: 'img' | 'doc' = acceptedFiles[0].type.split('/').indexOf('image') > -1 ? 'img' : 'doc';

    if (chatFile?.length && firstFlieType === 'doc') {
      message.warning({ title: t('AddedImgNotBoth') });
      return;
    } else if (chatDocFile?.length && firstFlieType === 'img') {
      message.warning({ title: t('AddedFileNotBoth') });
      return;
    }
    if (acceptedFiles.length === 2) {
      const lastFlieType: 'img' | 'doc' = acceptedFiles[1].type.split('/').indexOf('image') > -1 ? 'img' : 'doc';
      if (firstFlieType !== lastFlieType) {
        message.warning({ title: t('AddImgOrDoc') });
        return
      }
    }

    const maxSize = firstFlieType === 'img' ? imgMaxSize : docFileMaxSize;
    const afterInfo: ChatFileType[] = [];
    setUploading(true)
    for (const item of acceptedFiles) {
      if (item.size > maxSize) {
        message.warning({ title: `${item.name} ${firstFlieType === 'img' ? t('FileTooLarge20') : t('FileTooLarge10')}` });
        continue;
      }
      try {
        const res = await uploadFiles(item)
        afterInfo.push({
          type: item.type,
          name: item.name,
          url: res.data.data[0]
        })
      } catch (err) {
        message.error({ title: `${item.name} ${t('UploadFailed')}` });
      }
    }
    if (firstFlieType === 'img') {
      setChatFile(afterInfo)
    } else {
      setChatDocFile(afterInfo)
    }
    setUploading(false)
  }

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const firstReason = fileRejections[0].errors
    switch (firstReason[0].code) {
      case 'file-invalid-type':
        message.warning({ title: `${t('NotSupported')} ${fileRejections[0].file.type} ${t('FileType')}` });
        break;
      case 'file-too-large':
        message.warning({ title: t('FileTooLarge') });
        break;
      case 'too-many-files':
        message.warning({ title: t('MaxUploadFile') });
        break;
      default:
        message.warning({ title: t('UploadFailed') });
    }
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: {
      'image/*': [],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/plain': ['.txt'],
      'application/pdf': ['.pdf'],
    },
    maxFiles: 2,
    noClick: true,
    noKeyboard: true,
    onDrop,
    onDropRejected
  });

  const uploadFiles = (e: File) => {
    const formData = new FormData();
    formData.append('file', e)
    return axios.post(
      (window.proxyApi || '') + '/api/system/uploadToAliOss',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      }
    );
  }
  return <Box className={styles.chat}>
    <ChatHistory />
    <Box className={`${styles.nav}  ${isHideHistoryNav ? styles.hide : ''}`} onClick={() => setIsHideHistoryNav(!isHideHistoryNav)}>
      <Box className={styles.navBox}>
        <WiseImage className={styles.navOp} src={require('@/assets/png/nav_op_bg.png')} />
        <Icon className={styles.navOpIcon} as={require('@/assets/svg/right.svg').ReactComponent} />
      </Box>
    </Box>
    <Box className={styles.content} {...getRootProps()}>
      <input {...getInputProps()} style={{ display: 'none' }} />
      <ChatHeader />
      <ChatContent />
      <InputArea onScrollToBottom={() => { }} />
      {
        isDragActive && <DragFile />
      }
    </Box>
  </Box>
}

export default observer(Chat)
