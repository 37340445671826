import { FC } from "react";
import { Button, ButtonProps } from "@chakra-ui/react";

import styles from './index.module.scss'

interface CButtonProps extends ButtonProps {
  theme?: 'primary' | 'outline' | 'disabled' | 'warning' | 'custom' | 'error' | 'outline-error'
  size?: 'sm' | 'md' | 'lg'
}
export const CButton: FC<CButtonProps> = (props) => {
  const { children, disabled, className, size = 'lg', ...others } = props
  const cls = `${styles.button} ${styles[size]} ${styles[props?.theme || '']} ${disabled ? styles.disabled : ''} ${className}`
  return <Button className={cls} {...others}>{children}</Button>
}