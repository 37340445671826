import { Box, Flex, Icon, Text, Textarea, Image } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss"
import { CButton } from "@/components/Button";
import { useSelectFile } from "@/hooks/useSelectFile";
import axios from "axios";
import { readPdfContents, readTxtContent } from "@/utils/file";
import { useMessage } from "@/hooks/useMessage";
import RotatingCircles from "@/components/RotatingCircles";
import { truncateText } from "@/pages/creation/Tools/Components/ComFile";
interface IconMap {
  [key: string]: React.ComponentType<any>;
}
const icon_map: IconMap = {
  txt: require('@/assets/svg/txt.svg').ReactComponent,
  pdf: require('@/assets/svg/pdf.svg').ReactComponent,
  doc: require('@/assets/svg/docx.svg').ReactComponent,
  docx: require('@/assets/svg/docx.svg').ReactComponent, // 添加 `docx` 类型
  srt: require('@/assets/svg/srt.svg').ReactComponent,
};
const MAX_FILE_SIZE = 20 * 1024 * 1024;

export const TranslationTextarea: FC<any> = observer(({
  title,
  prompt,
  textvalue,
  textchange,
  maxCount,
  rows,
  finishdata,
  required,
  SetFilmname,
  SetTitle,
  fileExtension,
  beforelang_key
}: {
  title: string;
  prompt: string;
  textvalue: string;
  textchange: (e: string) => void;
  maxCount: number;
  rows: number;
  finishdata: string,
  required?: boolean;
  SetFilmname: (name: any) => void;
  SetTitle: (name: any) => void;
  fileExtension?: string;
  beforelang_key: string;
}) => {
  const { File, onOpen } = useSelectFile({
    fileType: fileExtension ? fileExtension : '.txt,.pdf,.log,.srt',
    multiple: true
  });
  const message = useMessage();
  const [loading, setLoading] = useState(false);
  const fileExtensions = fileExtension ? fileExtension : '.txt,.pdf,.log,.srt'
  const formattedExtensions = fileExtensions.replace(/,/g, "、");
  const [files, setFiles] = useState<{ filename: string; text: string }[]>([]);
  const [fileStatuses, setFileStatuses] = useState('上传文档');
  const [uploadError, setUploadError] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const onSelectFile = useCallback(
    async (files: File[]) => {
      try {
        setLoading(true)
        let promise = Promise.resolve();
        files.forEach((file) => {
          if (file.size > MAX_FILE_SIZE) {
            message.warning({ title: `文件 ${file.name} 超过最大限制 20MB。` });
            setLoading(false)
            return;
          }
          promise = promise.then(async () => {
            try {
              const formData = new FormData();
              formData.append('file', file, file.name)
              const res = await axios.post(
                '/api/system/uploadToAliOss',
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                }
              );
              const extension = file?.name?.split('.')?.pop()?.toLowerCase();
              let text = '';
              // 根据文件类型读取内容
              switch (extension) {
                case 'txt':
                case 'log':
                case 'srt':
                  text = await readTxtContent(file);
                  break;
                case 'pdf':
                  text = await readPdfContents(res.data.data[0], beforelang_key);
                  break;
                default:
                  text = '';
                  break;
              }
              // 如果成功读取
              if (text) {
                setFiles([]);
                setFiles((state) => [{ filename: file.name, text }].concat(state));
                SetTitle(res.data.data[0]);
                SetFilmname(file.name);
                const updateText = text.slice(0, maxCount);
                textchange(updateText);
                setLoading(false)
                // 更新文件状态为上传成功
              } else {
                message.warning({ title: '上传失败，请检查上传文件的类型' })
                setFiles([]);
                setUploadError('上传失败');
                // throw new Error('文件内容为空');
              }
            } catch (error: any) {
              // 在捕获到错误时更新文件状态为上传失败
              textchange('')
              setFiles((state) => [{ filename: file.name, text: '' }].concat(state));
              setLoading(false)
            }
            return; // 确保promise可以继续链式调用
          });
        });
        await promise;
      } catch (error: any) {
        console.log(error);
        message.error({ title: typeof error === 'string' ? error : '解析文件失败' })
      }
    },
    [message]
  );
  const getFileIcon = (filename: any) => {
    const ext = filename.split('.').pop().toLowerCase(); // 提取文件扩展名并转为小写
    return icon_map[ext] || icon_map['default']; // 返回相应的图标组件或默认图标
  };
  const [errorMessage, setErrorMessage] = useState('');
  const handleBlur = () => {
    if (required === true && textvalue.trim() === '') {
      setErrorMessage('请输入内容');
    } else {
      setErrorMessage('');
    }
  };
  let parts = [''];
  if (required === true) {
    if (!title.includes('*')) {
      title = title + '*';
      parts = title.split('*');
    } else {
      parts = title.split('*');
    }
  } else {
    parts = title.split('*');
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (finishdata.length > 1) {
          setFiles([{ filename: '', text: '' }])
          textchange(finishdata)
        }
      } catch (e) {
        console.error(e);
      }
    }
    fetchData()
  }, [finishdata]);
  return (
    <Flex className={styles.allbox}>
      <Box className={styles.titlebox}>
        {parts.map((part: any, index: any) => (
          <Text className={styles.title} key={index}>
            {part}{index < parts.length - 1 ? <span className={styles.titletips}>*</span> : null}
          </Text>
        ))}
      </Box>
      {(files.length > 0 && files[0].filename !== '') ? (
        files.map((file) => {
          return (
            <Box className={styles.uploadBoxx} key={file.text}>
              <Icon as={getFileIcon(file.filename)} className={styles.previewVideo} />
              <Box>
                <Text className={styles.textWithEllipsis}>{truncateText(file.filename)}</Text>
                <Flex className={styles.SucessBox}>
                  <Icon as={require('@/assets/svg/hdrSucess.svg').ReactComponent} />
                  <Text className={styles.uploadSucess}>上传成功</Text>
                </Flex>
              </Box>
              <Icon className={styles.uploadBoxicon} onClick={() => { setUploadError(''); textchange(''); setFiles([]) }}
                as={require(`@/assets/svg/personalClosed.svg`).ReactComponent} />
            </Box>
          )
        })
      ) : (uploadError ?
        <Box className={styles.uploadBoxx}>
          <Flex className={styles.defaultbox}>
            <Icon as={require('@/assets/svg/errorVideo.svg').ReactComponent} className={styles.uploadIcon} />
            <Text className={styles.errplusIcon} >上传失败</Text>
          </Flex>
          <Flex className={styles.SucessBox}>
            <Icon className={styles.errsvg} as={require('@/assets/svg/toolFileRegenerate.svg').ReactComponent} />
            <Text className={styles.againText} onClick={onOpen}>重新上传</Text>
          </Flex>
          <Icon className={styles.uploadBoxicon} onClick={() => { setUploadError(''); textchange(''); setFiles([]); setLoading(false) }}
            as={require(`@/assets/svg/personalClosed.svg`).ReactComponent} />
        </Box>
        : loading ?
          <Box className={styles.uploadBoxx}>
            <Box className={styles.loader}></Box>
            <Text className={styles.textWithEllipsis}>
              文件上传中...
            </Text>
            <Icon className={styles.uploadBoxicon} onClick={() => { setLoading(false); setUploadError(''); textchange(''); setFiles([]) }}
              as={require(`@/assets/svg/personalClosed.svg`).ReactComponent} />
          </Box>
          :
          <Box className={styles.uploadBox} onClick={onOpen}>
            <input
              type="file"
              accept=".txt,.pdf,.doc,.docx,.srt"
              onChange={onOpen}
              className={styles.fileUploadInput}
              id="file-input"
            />
            <Icon as={require('@/assets/svg/uploadVideo.svg').ReactComponent} className={styles.uploadIcon} />
            <Text className={styles.plusIcon} >上传文件</Text>
            <Flex className={styles.titletextBox}>
              <Text className={styles.titleText}>
                [支持以{fileExtensions}结尾的文件]
              </Text>
              <Text className={styles.titleText}>
                文件大小限制为20MB
              </Text>
            </Flex>
          </Box>
      )}
      {(files.length > 0 && files[0].filename !== '' || finishdata.length > 0) &&
        <Box className={`${styles.textareabox} ${isFocused ? styles.focused : ''}`}>
          {loading && (
            <Flex className={styles.loadingbox}>
              <RotatingCircles />
              <Text>上传中，请稍等...</Text>
            </Flex>
          )}
          <Textarea
            className={styles.textarea}
            placeholder={prompt}
            value={textvalue}
            onChange={(e) => textchange(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => { handleBlur(); setIsFocused(false) }}
            maxLength={maxCount}
          />
          {errorMessage && (
            <Flex className={styles.tipstext}>
              <Icon />
              <p>{errorMessage}</p>
            </Flex>
          )}
        </Box>
      }
      <File onSelect={onSelectFile} />
    </Flex>
  );
})