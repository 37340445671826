import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Box,
  Text,
  Flex,
  Image,
  useOutsideClick,
  Icon,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/react';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { CModal } from '@/components/Modal';
import { CButton } from '@/components/Button';
import { WiseImage } from '@/components/Image';
import axios from 'axios';
import { useMessage } from '@/hooks/useMessage';

const Characteristic: any = [
  {
    key: 'Person',
    Image: require('@/assets/svg/homeperson.svg').ReactComponent,
    Title: '面向个人：智能工具平台',
    Content: '我们希望利用先进的Al技术，开发出能够提高效率和生产力的智能工具和软件平台。这些解决方案旨在帮助用户简化复杂的工作流程，优化决策过程，并提高整体的工作与学习效率。'
  },
  {
    key: 'Enterprise',
    Image: require('@/assets/svg/homeenterprise.svg').ReactComponent,
    Title: '面向企业：定制化服务',
    Content: '同时，我们也会为企业客户提供定制服务，以满足特定客户的独特需求。公司与客户紧密合作，确保解决方案能够精准地解决客户面临的挑战，并帮助企业实现业务目标。'
  },
  {
    key: 'Company',
    Image: require('@/assets/svg/homecompany.svg').ReactComponent,
    Title: '公司愿景',
    Content: '我们希望继续扩展服务范围，探索新的应用场景，并与更多行业领域的企业建立合作，以推动智能化转型和创新发展。公司致力于成为个人和企业数字化转型旅程中的可靠伙伴，为客户创造更多的价值和商业可能性。'
  }
]

const wapHomes: any[] = [
  {
    img: require('@/assets/png/waphomechat.png'),
    title: `免费大语言模型+多模态对话`,
    content: `支持使用当前主流的大语言模型进行内容生成，帮助用户高效获取信息；支持多模态对话，具有联网搜索、图像识别、天气查询、文档上传等功能来为用户提供多样化的服务；移动端用户还可以享受语音对话功能，实现在多种场景下进行沟通。`,
    bgimg: require('@/assets/png/homeChatBanner.png'),
    height: '240px'
  },
  {
    img: require('@/assets/png/waphomedraw.png'),
    title: `完整云端AI绘画功能`,
    content: `同时接入Midjourney、Stablediffusion、Dall·E3、Gen3、Suno等创作工具让人工智能来丰富您的艺术生活。只需要选择需要的风格，再输入描述文字即可一键进行图片、视频、音乐创作，还可以进行部分参数调优来获得更加高质量的结果。`,
    bgimg: require('@/assets/png/homeDrawBanner.png'),
    height: '280px'
  },
  {
    img: require('@/assets/png/homeTool.png'),
    title: `内置多款AI加持工具`,
    content: `具有在线视频总结、电影字幕翻译、文档多语翻译、AI高级写作、PPT生成等多款热门智能工具。同时办公、学习、生活、电商、自媒体、娱乐等各类工具可以让您的创作过程更加高效。`,
    bgimg: require('@/assets/png/homeToolBanner.png'),
    height: '230px'
  },
  {
    img: require('@/assets/png/waphomeagent.png'),
    title: `定制化智能体助手`,
    content: `具有多款预设的智能体对话，能够针对不同的工作和学习场景进行高效沟通，从而满足用户的多样化需求；用户也可以根据自身需求，简单便捷地创建或调整智能体，设计专属的对话智能体，来解决特定问题；在未来还会支持用户上传知识库并进行关联。实现更精准的回答服务。`,
    bgimg: require('@/assets/png/homeDrawBanner.png'),
    height: '280px'
  },
  {
    img: require('@/assets/png/waphomeim.png'),
    title: `便捷分享与协作`,
    content: `通过聊天界面快速分享生成的文本、图片、音乐等内容，与好友进行作品交流和讨论；用户可以创建群聊，与多个好友共同分享与讨论生成的内容，增强合作与创意碰撞的机会。用`,
    bgimg: require('@/assets/png/homeToolBanner.png'),
    height: '230px'
  }
];

const getVersion = async (platform: any) => {
  const version = '0.2.7';
  const arch = 'x64';
  try {
    const url = `https://www.wisetalker.cn/api/system/pcVersion?version=${version}&platform=${platform}&arch=${arch}`;
    const res = await axios.get(url);
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log('error', error);
  }
  return undefined;
};

const getapkRes = async () => {
  try {
    const url = `https://www.wisetalker.cn/api/system/checkupdateVersion`;
    const res = await axios.get(url);
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log('error', error);
  }
  return undefined;
};

const Home: FC = () => {
  const [hydrated, setHydrated] = React.useState(true);
  const navigate = useNavigate();
  const [macVersion, setMacVersion] = useState('');
  const [winVersion, setWinVersion] = useState('');
  // const [apkUrl, setApkUrl] = useState('https://www.wisetalker.cn/downloads/android-apk.html');
  useEffect(() => {
    const fetch = async () => {
      const macres = await getVersion('darwin');
      const winres = await getVersion('win32');
      // const apkRes = await getapkRes();
      if (macres && macres.status === 200) {
        setMacVersion(macres.data.version)
      }
      if (winres && winres.status === 200) {
        setWinVersion(winres.data.version)
      }
      // if (apkRes && apkRes.status === 200) {
      //   setApkUrl(apkRes.data.data.ver_url)
      // }
    };
    fetch();
  }, [])

  const handleDownload = (downloadUrl: any) => {
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `文思逸言-${macVersion}`); // 可选，指定下载文件名

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadapk = () => {
    // const link = document.createElement('a');
    // const apkUrl = 'https://www.wisetalker.cn/downloads/android-apk.html';
    // link.href = apkUrl;
    // link.setAttribute('download', `android-apk`); // 可选，指定下载文件名

    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    const apkUrl = 'https://www.wisetalker.cn/downloads/android-apk.html';
    window.location.href = apkUrl; // 使用 window.location.href 进行跳转
  };

  const chathomes: any[] = [
    {
      img: require('@/assets/svg/home1.svg').ReactComponent,
      backcolor: '#10CDF6',
      title: `多款模型即刻调用`,
      content: `支持GPT、文心一言、通义千问、智谱清言。等多款大语言模型`,
      classname: styles.pchometags1
    },
    {
      img: require('@/assets/svg/home2.svg').ReactComponent,
      backcolor: '#FF7B00',
      title: `体验前沿AI技术`,
      content: `整合最新的AI算法，定期更新引入新的人工智能应用`,
      classname: styles.pchometags2
    },
    {
      img: require('@/assets/svg/home3.svg').ReactComponent,
      backcolor: '#FA5F68',
      title: `高效稳定的服务`,
      content: `系统在长时间运行中保持稳定的高效运行。支持大量用户同时使用`,
      classname: styles.pchometags3
    },
    {
      img: require('@/assets/svg/home4.svg').ReactComponent,
      backcolor: '#333333',
      title: `优秀的用户体验`,
      content: `界面直观对新手友好、操作简便学习成本低、交互流畅`,
      classname: styles.pchometags4
    }
  ];
  const [homedownload, SetHomeDownload] = useState('');
  const nodeRef = useRef<HTMLDivElement>(null); // 用于CSSTransition的ref

  const handleClickOutside = (event: any) => {
    if (nodeRef.current && !nodeRef.current.contains(event.target)) {
      SetHomeDownload('');
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [macopen, setMacOpen] = useState(false)
  const [iOSopen, setiOSOpen] = useState(false)
  const [androidopen, setAndroidOpen] = useState(false)
  const downloadhomes: any[] = [
    {
      img: require('@/assets/svg/homeMac.svg').ReactComponent,
      title: 'Mac桌面端',
      onClick: () => setMacOpen(true)
    },
    {
      img: require('@/assets/svg/homeWin.svg').ReactComponent,
      title: 'Windows桌面端', 
      // onClick: () => { handleDownload(`https://wisetalker.cn/downloads/文思逸言 Setup ${winVersion}.exe`) }
      onClick: () => { handleDownload(`https://wisetalker-res.oss-accelerate.aliyuncs.com/download/文思逸言 Setup ${winVersion}.exe`) }
    },
    {
      img: require('@/assets/svg/homeApple.svg').ReactComponent,
      title: 'iOS版',
      onClick: () => setiOSOpen(true)
    },
    {
      img: require('@/assets/svg/homeAnd.svg').ReactComponent,
      title: 'Android版',
      onClick: () => {
        setAndroidOpen(true);
      }
    }
  ];
  const downloadPhoneHomes: any[] = [
    {
      img: require('@/assets/svg/homeApple.svg').ReactComponent,
      title: 'iOS下载',
      onClick: () => message.error({ title: '版本更新中，敬请期待...' })
    },
    {
      img: require('@/assets/svg/waphomeAnd.svg').ReactComponent,
      title: 'android下载',
      onClick: () => handleDownloadapk() //handleDownload(apkUrl)
    }
  ];

  // const [hoverimg, setHoverimg] = React.useState('')
  const [modalHeight, setModalHeight] = useState(100);
  const [isPopupVisible, setIsPopupVisible] = useState(false);  //控制弹窗是否出现
  const [isheight, setIsHeight] = useState(false); //控制自动调整高度
  const message = useMessage();
  useEffect(() => {
    let animationFrameId: any;
    const animateOpen = () => {
      if (modalHeight < window.innerHeight) {
        setModalHeight((prevHeight) => prevHeight + 10);
        animationFrameId = requestAnimationFrame(animateOpen);
      }
    };

    const animateClose = () => {
      if (modalHeight > 100) {
        setModalHeight((prevHeight) => prevHeight - 10);
        animationFrameId = requestAnimationFrame(animateClose);
      } else {
        setIsPopupVisible(false);
      }
    };
    if (isheight === true) {
      setIsPopupVisible(true)
      animateOpen();
    } else if (isheight === false) {
      animateClose();
      setIsPopupVisible(false);
    }
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [isheight]);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isHovered, setIsHovered] = useState(0);
  const homeRef = useRef<HTMLDivElement>(null);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (homeRef.current) {
        const position = homeRef.current.scrollTop
        setScrollPosition(position);
      }
    };
    const homeElement = homeRef.current;
    if (homeElement) {
      homeElement.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (homeElement) {
        homeElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [homeRef.current]);
  const ContextMenuRef = useRef(null);
  const onclickContext = useRef(false);
  // close contextMenu
  useOutsideClick({
    ref: ContextMenuRef,
    handler: () => {
      setTimeout(() => {
        if (contextMenuData && !onclickContext.current) {
          setContextMenuData(undefined);
        }
      }, 10);
      setTimeout(() => {
        onclickContext.current = false;
      }, 10);
    }
  });
  const [contextMenuData, setContextMenuData] = useState<{
    left: number;
    top: number;
  }>();

  if (!hydrated) {
    return null;
  }

  const Titlebox = ({ title, span, entitle }: { title: any, span?: any, entitle: any }) => {
    return (
      <Box className={styles.titlebox}>
        <Box className={styles.stylebox}>
          <Box className={styles.topbox}></Box>
          <Box className={styles.bottombox}></Box>
        </Box>
        <Box>
          <Text className={styles.spantext}>
            {title}
            {span &&
              <span>{span}</span>
            }
          </Text>
          <Text className={styles.colortext}>{entitle}</Text>
        </Box>
      </Box>
    )
  }

  const isMobile = window.innerWidth <= 768
  return (
    <>
      {isMobile ?
        <Box className={styles.phoneHome}>
          <Flex className={styles.titleNav}>
            <Box
              className={styles.iconbox}
            >
              <Flex className={styles.logoboxs}>
                <WiseImage w={'22px'} h={'22px'} src={require('@/assets/logo.png')} />
              </Flex>
              <Text>文思逸言</Text>
            </Box>
            <Icon onClick={() => setIsHeight(true)} as={require('@/assets/svg/phoneHome.svg').ReactComponent} />
          </Flex>
          <Flex className={styles.logobox}>
            <Text>文思逸言</Text>
            <Text>未来已来</Text>
            <Image className={styles.logoimg} src={require('@/assets/png/phoneHomeBanner.png')} />
            <Box className={styles.bgBox}></Box>
            <Box
              className={styles.hometitle}
              onClick={() => {
                navigate('/clientPrompt');
              }}
            >
              立即使用网页版 →
            </Box>
          </Flex>
          <Flex className={styles.introducebox}>
            <Text className={styles.introduce}>
              文思逸言是一款集合多种服务的人工智能创作平台。支持与当前市面上的多款通用大语言模型对话，适应多样化的问题场景和需求；支持自媒体文案创作；支持云端进行创作，包括绘图、音乐创作、文生视频，让您的艺术和创意得到实践；内置多款AI工具，帮助您在多个领域中提升生产力表现。具有智能体对话，能够针对不同场景进行设置，满足多样化需求。
            </Text >
            <Text className={styles.titleintroduce}>下载客户端：</Text>
            <Box className={styles.dowbloadboxs}>
              {downloadPhoneHomes.map((item) => (
                <Box className={styles.dowbloadbox} onClick={item.onClick}>
                  <Icon as={item.img} w={'38px'} h={'34px'} />
                  <Text>{item.title}</Text>
                </Box>
              ))}
            </Box>
          </Flex>
          <Flex className={styles.chooseBoxs}>
            <Titlebox title={'为什么选择'} span={'文思逸言'} entitle={'Why choose Wisetalker'} />
            {chathomes.map((item) => (
              <Box bg={item.backcolor} className={styles.backcolor}>
                <Icon as={item.img} />
                <Box>
                  <Text className={styles.title}>{item.title}</Text>
                  <Text className={styles.content}>{item.content}</Text>
                </Box>
              </Box>
            ))}
          </Flex>
          <Flex className={styles.chooseBoxs}>
            <Titlebox title={'多功能'} span={'AI软件'} entitle={'Multi functional AI software'} />
            {wapHomes.map((item) => (
              <Box className={styles.waphomebox}>
                <Box>
                  <Text className={styles.title}>{item.title}</Text>
                  <Text className={styles.content}>{item.content}</Text>
                </Box>
                <Box className={styles.bgbnox} backgroundImage={item.bgimg} h={item.height}></Box>
                <Image className={styles.img} src={item.img} />
              </Box>
            ))}
          </Flex>
          <Flex className={styles.chooseBoxs}>
            <Titlebox title={'关于我们'} entitle={'About Us'} />
            <Text className={styles.cnContents}>成都足不出户教育科技有限公司，成立于2019年，是一家位于中国成都的创新科技企业，专注于人工智能(AI)智能软件的研发和落地应用。面向个人和各类企业，提供广泛的智能化解决方案。</Text>
            {Characteristic.map((value: any) => (
              <Box key={value.key} className={styles.aboutBox_box}>
                <Box className={styles.iconbox}>
                  <Box w={'40px'} ></Box>
                  <Icon as={value.Image} />
                </Box>
                <Box className={styles.textbox}>
                  <Text className={styles.aboutBox_title}>{value.Title}</Text>
                  <Text className={styles.aboutBox_contents}>{value.Content}</Text>
                </Box>
              </Box>
            ))}
          </Flex>
          <Flex className={styles.bottomBoxs}>
            <Box display={'flex'} flexDirection={'column'} mb={'20px'}>
              <Text className={styles.bottomBox_title}>
                快速访问
              </Text>
              <Flex>
                {/* <Text className={styles.bottomBox_contents} onClick={() => navigate('/chat')}>对话</Text>*/}
                <Text className={styles.bottomBox_contents} onClick={() => navigate('/clientPrompt')}>对话</Text>
                <Text className={styles.bottomBox_contents} onClick={() => navigate('/clientPrompt')}>创作</Text>
                <Text className={styles.bottomBox_contents} onClick={() => navigate('/clientPrompt')}>工具</Text>
                <Text className={styles.bottomBox_contents} onClick={() => navigate('/clientPrompt')}>智能体</Text>
                <Text className={styles.bottomBox_contents} onClick={() => navigate('/clientPrompt')}>聊天</Text>
              </Flex>
            </Box>
            <Box display={'flex'} flexDirection={'column'} mb={'30px'}>
              <Text className={styles.bottomBox_title}>
                联系我们
              </Text>
              <Text className={styles.bottomBox_contents}>
                公司名称：成都足不出户教育科技有限公司
              </Text>
              <Text className={styles.bottomBox_contents}>
                地址：成都天府大道茂业中心B座1601
              </Text>
              <Text className={styles.bottomBox_contents}>
                联系电话：18782285825
              </Text>
            </Box>
            <Box className={styles.recordBox}>
              备案号：蜀ICP备2023035098号-3
            </Box>
          </Flex >
          <CModal isOpen={isPopupVisible} onClose={() => { setIsPopupVisible(false); setIsHeight(false) }}>
            <ModalOverlay w={'100%'} h={'100%'} onClick={() => { setIsPopupVisible(false); setIsHeight(false) }} />
            <ModalContent pos={'absolute'} w={'100%'} maxH={`${modalHeight}px`} p={'10px 20px'} bg={'#ffffff'} marginTop={'0px'}
              mx={'0px'} borderTopRadius={0} borderBottomRadius={'20px'}
              transition={'max-height 1s ease-out'} overflow={'hidden'}>
              <Flex w={'100%'} justifyContent={'space-between'} mb={'20px'}>
                <Flex justify={'center'} alignItems={'center'}>
                  <Flex className={styles.logoboxs}>
                    <WiseImage w={'22px'} h={'22px'} src={require('@/assets/logo.png')} />
                  </Flex>
                  <Text fontWeight={'b'}>文思逸言</Text>
                </Flex>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} onClick={() => { setIsPopupVisible(false); setIsHeight(false) }}>
                  <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} />
                </Box>
              </Flex>
              <Flex flexDir={'column'}>
                <Flex w={'calc( 100% - 40px)'} borderTop={'1px solid rgba(221, 221, 221, 1)'} p={'20px'} onClick={() => navigate('/clientPrompt')}>
                  <Text className={styles.phonehometags1}>对话</Text>
                </Flex>
                <Flex w={'calc( 100% - 40px)'} borderTop={'1px solid rgba(221, 221, 221, 1)'} p={'20px'} onClick={() => navigate('/clientPrompt')}>
                  <Text className={styles.phonehometags2}>创作</Text>
                </Flex>
                <Flex w={'calc( 100% - 40px)'} borderTop={'1px solid rgba(221, 221, 221, 1)'} p={'20px'} onClick={() => navigate('/clientPrompt')}>
                  <Text className={styles.phonehometags3}>工具</Text>
                </Flex>
                <Flex w={'calc( 100% - 40px)'} borderTop={'1px solid rgba(221, 221, 221, 1)'} p={'20px'} onClick={() => navigate('/clientPrompt')}>
                  <Text className={styles.phonehometags4}>智能体</Text>
                </Flex>
                <Flex w={'calc( 100% - 40px)'} borderTop={'1px solid rgba(221, 221, 221, 1)'} p={'20px 20px 10px'} onClick={() => navigate('/clientPrompt')}>
                  <Text className={styles.phonehometags5}>聊天</Text>
                </Flex>
              </Flex>
            </ModalContent>
          </CModal>
        </Box >
        :
        <Flex className={styles.home}>
          <Box flexDirection={'column'} w={'100%'} h={'100%'} overflowY={'auto'} ref={homeRef}>
            <Box
              zIndex={999}
              position={scrollPosition < 10 ? 'absolute' : 'fixed'}
              bg={scrollPosition < 10 ? 'transparent' : 'rgba(255,255,255,0.3)'}
              boxShadow={scrollPosition < 10 ? '0px 0px 0px 0px' : '0px 4px 4px 0px rgba(9,110,154,0.2)'}
              py={'26px'}
              px={windowWidth > 1470 ? '10%' : `161px`}
              w={'80%'}
              minW={'1200px'}
              display={'flex'}
              justifyContent={'space-between'}
              backdropFilter={scrollPosition < 10 ? 'blur(0px)' : 'blur(8px)'}
            >
              <Box
                textAlign={'left'}
                fontSize={'20px'}
                display={'flex'}
                alignItems={'center'}
              >
                <Flex className={styles.logobox}>
                  <WiseImage w={'40px'} h={'40px'} src={require('@/assets/logo.png')} />
                </Flex>
                <Text as={'b'}>文思逸言</Text>
              </Box>
              <Box className={styles.titleNav}>
                <Box onClick={() => navigate('/chat')} _hover={{ color: '#565CFA' }}>对话</Box>
                <Box onClick={() => navigate('/creation')} _hover={{ color: '#565CFA' }}>创作</Box>
                <Box onClick={() => navigate('/tools')} _hover={{ color: '#565CFA' }}>工具</Box>
                <Box onClick={() => navigate('/agents')} _hover={{ color: '#565CFA' }}>智能体</Box>
                <Box onClick={() => navigate('/im')} _hover={{ color: '#565CFA' }}>聊天</Box>
              </Box>
            </Box>
            <Flex className={styles.titleBox} px={windowWidth > 1400 ? '0%' : `161px`} mx={windowWidth > 1400 ? 'auto' : `0px`}>
              <Box w={'50%'} h={'100%'} mt={'150px'} zIndex={2}>
                <Text className={styles.title}>
                  文思逸言，未来已来
                </Text>
                <Text className={styles.synopsis}>
                  文思逸言是一款集合多种服务的人工智能创作平台。支持与当前市面上的多款通用大语言模型对话，适应多样化的问题场景和需求；支持自媒体文案创作；支持云端进行创作，包括绘图、音乐创作、文生视频，让您的艺术和创意得到实践；内置多款AI工具，帮助您在多个领域中提升生产力表现。具有智能体对话，能够针对不同场景进行设置，满足多样化需求。
                </Text>
                <Box
                  className={styles.hometitle}
                  onClick={() => {
                    navigate('/chat');
                  }}
                >
                  立即使用网页版
                  <Box className={styles.homearrowbox}>
                    <Text className={styles.homearrow}> → </Text>
                  </Box>
                </Box>
              </Box>
              <Box className={styles.bannerBox}>
                <Image src={require('@/assets/png/homepageBg.png')} />
                <Box className={styles.bgBox}></Box>
              </Box>
            </Flex>

            <Flex className={styles.downloadbox} my={'140px'} px={windowWidth > 1350 ? '0%' : `101px`} mx={windowWidth > 1350 ? 'auto' : `0px`}>
              {downloadhomes.map((item) => {
                if (item.title === 'iOS版' && iOSopen) {
                  return (
                    <Flex className={styles.comingSoon} key={'PC' + item.title} onClick={() => setiOSOpen(false)}>
                      <Box>
                        <Icon as={require('@/assets/svg/comingSoon.svg').ReactComponent} w={'38px'} h={'34px'} />
                      </Box>
                      <Text className={styles.texta}>敬请期待</Text>
                      <Text className={styles.textb}>版本正在更新中...</Text>
                    </Flex>
                  )
                } else {
                  return (
                    <Flex className={styles.download} key={'PC' + item.title} onClick={item.onClick}>
                      <Box>
                        <Icon as={item.img} w={'38px'} h={'34px'} />
                      </Box>
                      <Text>{item.title}</Text>
                    </Flex>
                  )
                }
              })}
            </Flex>

            <Box w={'1200px'} mb={'200px'} px={windowWidth > 1400 ? '0%' : `161px`} mx={windowWidth > 1400 ? 'auto' : `0px`}>
              <Box className={styles.contTitleBox}>
                <Text className={styles.cntitle}> 为什么要选择文思逸言？</Text>
                <Text className={styles.entitle}>Why choose Wisetalker</Text>
              </Box>
              <Box mt={'70px'} display={'flex'} justifyContent={'space-between'}>
                {chathomes.map((item) => (
                  <Flex bg={item.backcolor} flexDir={'column'} className={scrollPosition > 350 ? item.classname : styles.pchometags} key={item.title + 'key'}>
                    <Icon as={item.img} w={'60px'} h={'60px'} />
                    <Text className={styles.titleText}>
                      {item.title}
                    </Text>
                    <Text className={styles.tipsText}>
                      {item.content}
                    </Text>
                    <Box bg={item.backcolor} className={styles.shadowBox}></Box>
                  </Flex>
                ))}
              </Box>
            </Box>

            <Box w={'1200px'} px={windowWidth > 1400 ? '0%' : `161px`} mx={windowWidth > 1400 ? 'auto' : `0px`}
              mb={'200px'} display={'flex'} flexDir={'column'}>
              <Box className={styles.contTitleBox}>
                <Text className={styles.cntitle}> 多功能 <span>AI 软件</span></Text>
                <Text className={styles.entitle}>Multi functional AI software</Text>
              </Box>

              <Flex className={styles.introductionBox}>
                <div className={`${styles.introductionBoxImage} ${isHovered === 1 ? styles.hovered : ''}`}
                  onMouseEnter={() => setIsHovered(1)}
                  onMouseLeave={() => setIsHovered(0)}>
                  <Image src={require('@/assets/png/homeChatwap.png')}
                    w={'150px'} h={'300px'} zIndex={3} left={'10px'} top={'120px'}
                    transform={'rotateX(20deg) rotateY(10deg)'}
                  />
                  <Image src={require('@/assets/png/homeChatPC.png')}
                    w={'700px'} h={'400px'} left={0}
                    transform={'rotateX(-10deg) rotateY(-20deg)'}
                  />
                </div>
                <Box className={styles.bgBox} left={'-120px'} bgImage={require('@/assets/png/homeChatBanner.png')}></Box>
                <Box className={styles.introductionBox_title}>
                  <Text className={styles.title}>免费大语言模型+多模态对话</Text>
                  <Text className={styles.contents}>
                    支持使用当前主流的大语言模型进行内容生成，帮助用户高效获取信息；支持多模态对话，具有联网搜索、图像识别、天气查询、文档上传等功能来为用户提供多样化的服务；移动端用户还可以享受语音对话功能，实现在多种场景下进行沟通。
                  </Text>
                </Box>
              </Flex>

              <Flex className={`${styles.introductionBox} ${styles.reverse}`}>
                <Box className={styles.bgBox} right={'-100px'} bgImage={require('@/assets/png/homeChatBanner.png')}></Box>
                <Box className={styles.introductionBox_title}>
                  <Text className={styles.title}>完整云端AI绘画功能</Text>
                  <Text className={styles.contents}>
                    同时接入Midjourney、Stablediffusion、Dall·E3、Gen3、Suno等创作工具让人工智能来丰富您的艺术生活。只需要选择需要的风格，再输入描述文字即可一键进行图片、视频、音乐创作，还可以进行部分参数调优来获得更加高质量的结果。
                  </Text>
                </Box>
                <div className={`${styles.introductionBoxImage} ${isHovered === 2 ? styles.hovered : ''}`}
                  onMouseEnter={() => setIsHovered(2)}
                  onMouseLeave={() => setIsHovered(0)}>
                  <Image src={require('@/assets/png/homeDrawPC.png')} w={'700px'} h={'400px'} right={0}
                    transform={'rotateX(-10deg) rotateY(20deg)'} />
                  <Image src={require('@/assets/png/homeDrawwap.png')}
                    w={'220px'} h={'300px'} zIndex={3} right={'-30px'} top={'160px'}
                    transform={'rotateX(10deg) rotateY(0deg)'} />
                </div>
              </Flex>

              <Flex className={styles.introductionBox}>
                <div className={`${styles.introductionBoxImage} ${isHovered === 3 ? styles.hovered : ''}`}
                  onMouseEnter={() => setIsHovered(3)}
                  onMouseLeave={() => setIsHovered(0)}>
                  <Image className={styles.intImg} src={require('@/assets/png/homeTool.png')} transform={'rotateX(15deg)'} />
                </div>
                <Box className={styles.bgBox} left={'-120px'} bgImage={require('@/assets/png/homeChatBanner.png')}></Box>
                <Box className={styles.introductionBox_title}>
                  <Text className={styles.title}>内置多款AI加持工具</Text>
                  <Text className={styles.contents}>
                    具有在线视频总结、电影字幕翻译、文档多语翻译、AI高级写作、PPT生成等多款热门智能工具。同时办公、学习、生活、电商、自媒体、娱乐等各类工具可以让您的创作过程更加高效。
                  </Text>
                </Box>
              </Flex>

              <Flex className={`${styles.introductionBox} ${styles.reverse}`}>
                <Box className={styles.bgBox} right={'-100px'} bgImage={require('@/assets/png/homeChatBanner.png')}></Box>
                <Box className={styles.introductionBox_title}>
                  <Text className={styles.title}>定制化智能体助手</Text>
                  <Text className={styles.contents}>
                    具有多款预设的智能体对话，能够针对不同的工作和学习场景进行高效沟通，从而满足用户的多样化需求；用户也可以根据自身需求，简单便捷地创建或调整智能体，设计专属的对话智能体，来解决特定问题；在未来还会支持用户上传知识库并进行关联。实现更精准的回答服务。
                  </Text>
                </Box>
                <div className={`${styles.introductionBoxImage} ${isHovered === 4 ? styles.hovered : ''}`}
                  onMouseEnter={() => setIsHovered(4)}
                  onMouseLeave={() => setIsHovered(0)}>
                  <Image className={styles.intImg} src={require('@/assets/png/homeAgent.png')} right={0}
                    transform={'rotateX(15deg)'} />
                </div>
              </Flex>

              <Flex className={styles.introductionBox}>
                <div className={`${styles.introductionBoxImage} ${isHovered === 5 ? styles.hovered : ''}`}
                  onMouseEnter={() => setIsHovered(5)}
                  onMouseLeave={() => setIsHovered(0)}>
                  <Image src={require('@/assets/png/homeImPC.png')} w={'600px'} h={'400px'}
                    transform={'rotateX(10deg) rotateY(20deg)'} />
                  <Image src={require('@/assets/png/homeImwap.png')} w={'230px'} h={'300px'} left={'420px'} top={'80px'}
                    transform={'rotateX(-10deg) rotateY(-10deg)'} />
                </div>
                <Box className={styles.bgBox} left={'-120px'} bgImage={require('@/assets/png/homeChatBanner.png')}></Box>
                <Box className={styles.introductionBox_title}>
                  <Text className={styles.title}>便捷分享与协作</Text>
                  <Text className={styles.contents}>
                    通过聊天界面快速分享生成的文本、图片、音乐等内容，方便与好友进行作品交流和讨论；用户可以创建群聊，与多个好友共同分享与讨论生成的内容，增强合作与创意碰撞的机会。
                  </Text>
                </Box>
              </Flex>
            </Box>

            <Box w={'1200px'} px={windowWidth > 1400 ? '0%' : `161px`} mx={windowWidth > 1400 ? 'auto' : `0px`} mb={'150px'}>
              <Box className={styles.contTitleBox}>
                <Text className={styles.cntitle}> 关于我们</Text>
                <Text className={styles.entitle}>About Us</Text>
                <Text className={styles.cnContents}>成都足不出户教育科技有限公司，成立于2019年，是一家位于中国成都的创新科技企业，专注于人工智能(AI)智能软件的研发和落地应用。面向个人和各类企业，提供广泛的智能化解决方案。</Text>
              </Box>
              <Box className={styles.aboutBox}>
                {Characteristic.map((value: any) => (
                  <Flex key={value.key} className={styles.aboutBox_box}>
                    <Box className={styles.iconbox}>
                      <Icon as={value.Image} />
                    </Box>
                    <Text className={styles.aboutBox_title}>{value.Title}</Text>
                    <Text className={styles.aboutBox_contents}>{value.Content}</Text>
                  </Flex>
                ))}
              </Box>
            </Box>

            <Flex className={styles.bottomBoxs} >
              <Box className={styles.bottomBox} py={'50px'} px={windowWidth > 1400 ? '0px' : `161px`} mx={windowWidth > 1400 ? 'auto' : `0px`}>
                <Box display={'flex'} flexDirection={'column'} mx={['auto', 0]}>
                  <Text className={styles.bottomBox_title}>
                    联系我们
                  </Text>
                  <Text className={styles.bottomBox_contents}>
                    公司名称：成都足不出户教育科技有限公司
                  </Text>
                  <Text className={styles.bottomBox_contents}>
                    地址：成都天府大道茂业中心B座1601
                  </Text>
                  <Text className={styles.bottomBox_contents}>
                    联系电话：18782285825
                  </Text>
                </Box>

                <Box display={'flex'} flexDirection={'column'} mx={['auto', 0]}>
                  <Text className={styles.bottomBox_title}>
                    快速访问
                  </Text>
                  <Flex>
                    <Text className={styles.bottomBox_contents} cursor={'pointer'} onClick={() => navigate('/chat')}>对话</Text>
                    <Text className={styles.bottomBox_contents} cursor={'pointer'} onClick={() => navigate('/creation')}>创作</Text>
                    <Text className={styles.bottomBox_contents} cursor={'pointer'} onClick={() => navigate('/tools')}>工具</Text>
                    <Text className={styles.bottomBox_contents} cursor={'pointer'} onClick={() => navigate('/agents')}>智能体</Text>
                    <Text className={styles.bottomBox_contents} cursor={'pointer'} onClick={() => navigate('/im')}>聊天</Text>
                  </Flex>
                </Box>

                <Box display={'flex'} flexDirection={'column'} mx={['auto', 0]}>
                  <Text className={styles.bottomBox_title}>
                    关注我们
                  </Text>
                  <Flex flexDir={'column'} h={'140px'}>
                    <Image
                      src={'https://cachefile.wisetalker.cn/file/config/gzh.jpeg'}
                      width={'110px'}
                      height={'110px'}
                      mb={'10px'}
                      alt=""
                    />
                    <Text className={styles.bottomBox_contents}>微信公众号</Text>
                  </Flex>
                </Box>
              </Box>
              <Box className={styles.recordBox}>
                <Text >
                  <a href='https://beian.miit.gov.cn/'>
                    备案号：蜀ICP备2023035098号-3
                  </a>
                </Text>
              </Box>
            </Flex>
          </Box>
          <CModal isOpen={macopen} onClose={() => setMacOpen(false)}>
            <Flex className={styles.cmodelbox}>
              <Flex className={styles.cmodelTitleBox}>
                <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setMacOpen(false)} />
              </Flex>
              <Box className={styles.macboxbut}>
                <Text className={styles.macboxbut_tip}>下载Mac桌面端</Text>
                <Box>
                  <CButton className={styles.macboxbutton} onClick={() => {
                    // handleDownload(`https://wisetalker.cn/downloads/文思逸言-${macVersion}-arm64.dmg`)
                    handleDownload(`https://wisetalker-res.oss-accelerate.aliyuncs.com/download/文思逸言-${macVersion}-arm64.dmg`)
                  }}>下载Apple芯片版</CButton>
                  <CButton className={styles.macboxbutton} onClick={() => {
                    // handleDownload(`https://wisetalker.cn/downloads/文思逸言-${macVersion}.dmg`)
                    handleDownload(`https://wisetalker-res.oss-accelerate.aliyuncs.com/download/文思逸言-${macVersion}.dmg`) 
                  }}>下载Intel芯片版</CButton>
                </Box>
              </Box>
              <Box className={styles.macBoxTips}>
                <Flex className={styles.macBoxTips_box}>
                  <Text className={styles.texta}>如何确认芯片类型</Text>
                  <Box className={styles.boxa}>
                    <Box>1</Box>
                    <Text>在左上角，点击<span>  — 关于本机。</span></Text>
                  </Box>
                  <Box className={styles.boxa}>
                    <Box>2</Box>
                    <Text>找到<span>芯片</span>或<span>处理器</span>一行，查看后面是<span>Apple</span>还是<span>Intel。</span></Text>
                  </Box>
                </Flex>
                <Image src={require('@/assets/png/macDownload.png')} />
              </Box>
            </Flex>
          </CModal>
          <CModal isOpen={androidopen} onClose={() => setAndroidOpen(false)}>
            <Flex className={styles.cmodelbox}>
              <Flex className={styles.cmodelTitleBox}>
                <Icon onClick={() => setAndroidOpen(false)} as={require('@/assets/svg/cModelClose.svg').ReactComponent} />
              </Flex>
              <Box className={styles.macboxbut}>
                <Text className={styles.macboxbut_tips}>下载Android版</Text>
                <Text className={styles.macboxbut_text}>可直接下载APK文件 或 使用手机扫描二维码下载</Text>
                <CButton className={styles.macboxbutton}
                  onClick={() => { handleDownloadapk() }}//handleDownload(apkUrl) }}
                >下载Apk包</CButton>
              </Box>
              <Box className={styles.andBoxTip}>
                <Image src={require('@/assets/img/androiddow.png')} />
                <Text >扫描二维码下载</Text>
              </Box>
            </Flex>
          </CModal >
        </Flex >
      }
    </>
  );
};

export default observer(Home);
