
import { LocalDB } from ".";

class PersonalDB extends LocalDB {

  /** 设置点数列表 */
  setBills(bills: BillsType[], mineAgents: MineAgentsType) {
    this.db.setItem('bills', { bills, mineAgents })
  }

  /** 获取点数列表 */
  getBills(): Promise<{ bills: BillsType[], mineAgents: MineAgentsType } | null> {
    return this.db.getItem('bills')
  }

  setPromotionData(data: PromotionData, mineAgents: MineAgentsType) {
    this.db.setItem('promotionData', { data, mineAgents })
  }

  /** 获取邀请好友数据 */
  getPromotionData(): Promise<{ data: PromotionData, mineAgents: MineAgentsType } | null> {
    return this.db.getItem('promotionData')
  }

  /** 设置佣金列表 */
  setPromotions(promotions: PromotionsType[], mineAgents: MineAgentsType) {
    this.db.setItem('promotions', { promotions, mineAgents })
  }

  /** 获取佣金列表 */
  getPromotions(): Promise<{ promotions: PromotionsType[], mineAgents: MineAgentsType } | null> {
    return this.db.getItem('promotions')
  }

  /** 设置充值列表 */
  setPayOrders(payOrders: PayOrdersType[], mineAgents: MineAgentsType) {
    this.db.setItem('payOrders', { payOrders, mineAgents })
  }

  /** 获取充值列表 */
  getPayOrders(): Promise<{ payOrders: PayOrdersType[], mineAgents: MineAgentsType } | null> {
    return this.db.getItem('payOrders')
  }

  /** 设置充值列表 */
  setVipPayOrders(payVipOrders: PayOrdersType[], mineAgents: MineAgentsType) {
    this.db.setItem('payVipOrders', { payVipOrders, mineAgents })
  }

  /** 获取充值列表 */
  getVipPayOrders(): Promise<{ payVipOrders: PayOrdersType[], mineAgents: MineAgentsType } | null> {
    return this.db.getItem('payVipOrders')
  }

  /** 设置累计点数 */
  setMonthBills(AccumulatePoints: AccumulatePoints, mineAgents: MineAgentsType) {
    this.db.setItem('AccumulatePoints', { AccumulatePoints, mineAgents })
  }

  /** 获取累计点数 */
  getMonthBills(): Promise<{ AccumulatePoints: AccumulatePoints, mineAgents: MineAgentsType } | null> {
    return this.db.getItem('AccumulatePoints')
  }

  /** 设置收藏工具 */
  setAllWorktool(worktool: WorktoolSchema[]) {
    this.db.setItem('worktool', { worktool })
  }

  /** 获取收藏工具 */
  getAllWorktool(): Promise<{ worktool: WorktoolSchema[] } | null> {
    return this.db.getItem('worktool')
  }

  /** 设置点数列表 */
  setInforms(informs: informSchema[], mineAgents: MineAgentsType) {
    this.db.setItem('informs', { informs, mineAgents })
  }

  /** 获取点数列表 */
  getInforms(): Promise<{ informs: informSchema[], mineAgents: MineAgentsType } | null> {
    return this.db.getItem('informs')
  }

  /** 设置累计签到 */
  setCumulativeSignIn(cumulativeSignIn: CumulativeSignInSchema) {
    this.db.setItem('cumulativeSignIn', cumulativeSignIn)
  }

  /** 获取累计签到 */
  getCumulativeSignIn(): Promise<{ cumulativeSignIn: CumulativeSignInSchema } | null> {
    return this.db.getItem('cumulativeSignIn')
  }
}

export const personalDb = new PersonalDB('personal');