import React, { FC, useMemo } from 'react'
import { Menu } from 'antd'
import { CrudeAvatar, useTranslation } from '../../common'
import { NimKitCoreTypes } from '@xkit-yx/core-kit'
import { ConversationItem } from './ConversationItem'
import { Box } from '@chakra-ui/react'

export interface GroupItemProps extends NimKitCoreTypes.TeamSession {
  isSelected: boolean
  onStickTopChange: (isTop: boolean) => void
  onDeleteClick: () => void
  onItemClick: () => void
  beMentioned?: boolean
  avatarRenderer?: JSX.Element | null
  sessionNameRenderer?: JSX.Element | null
  sessionMsgRenderer?: JSX.Element | null
  prefix?: string
  commonPrefix?: string
  isMute: boolean
  onMuteChange: (mute: boolean) => void
}

export const GroupItem: FC<GroupItemProps> = ({
  onStickTopChange,
  onDeleteClick,
  teamId,
  name,
  avatar,
  unread,
  lastMsg,
  isMute,
  beMentioned,
  stickTopInfo,
  updateTime,
  isSelected,
  onItemClick,
  onMuteChange,
  avatarRenderer,
  sessionNameRenderer,
  sessionMsgRenderer,
  prefix = 'conversation',
  commonPrefix = 'common',
  ext,
}) => {
  const { t } = useTranslation()

  const menuRenderer = useMemo(() => {
    const items = [
      {
        label: stickTopInfo?.isStickOnTop
          ? t('deleteStickTopText')
          : t('addStickTopText'),
        icon: <Box as={require('@/assets/svg/top.svg').ReactComponent} type="icon-zhiding" />,
        key: 'stickTop',
      },
      {
        label: isMute ? t('unmuteSessionText') : t('muteSessionText'),
        icon: <Box as={require('@/assets/svg/ignore_message.svg').ReactComponent} />,
        key: 'muteSession',
      },
      {
        label: <span style={{ color: '#FF5852' }}>{t('deleteSessionText')}</span>,
        icon: <Box as={require('@/assets/svg/delete.svg').ReactComponent} />,
        key: 'deleteSession',
      },
    ] as any

    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation()
          switch (key) {
            case 'stickTop':
              onStickTopChange(!stickTopInfo?.isStickOnTop)
              break
            case 'deleteSession':
              onDeleteClick()
              break
            case 'muteSession':
              onMuteChange(!isMute)
              break
            default:
              break
          }
        }}
        items={items}
      ></Menu>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stickTopInfo?.isStickOnTop, onStickTopChange, onDeleteClick, t])

  return (
    <ConversationItem
      isTop={!!stickTopInfo?.isStickOnTop}
      isMute={isMute}
      sessionName={name || teamId}
      time={lastMsg?.time || updateTime}
      lastMsg={lastMsg}
      beMentioned={beMentioned}
      isSelected={isSelected}
      onItemClick={onItemClick}
      prefix={prefix}
      commonPrefix={commonPrefix}
      menuRenderer={menuRenderer}
      sessionMsgRenderer={sessionMsgRenderer}
      sessionNameRenderer={sessionNameRenderer}
      count={isSelected ? 0 : unread}
      showCount={isSelected ? false : !isMute && unread > 0}
      showGroupAvatar
      avatarRenderer={
        avatarRenderer ?? (
          <CrudeAvatar
            nick={name}
            account={teamId}
            avatar={avatar}
          />
        )
      }
    />
  )
}
