import { WiseImage } from "@/components/Image";
import { Box, Icon, Text } from "@chakra-ui/react";
import { FC, memo, MouseEventHandler } from "react";
import { CreationType, DrawImageStatusEnum } from "@/store/creation";
// import Loading from "@/components/Loading";
import { CButton } from "@/components/Button";
import i18n from "@/utils/i18n";

import styles from './index.module.scss'
import { useTranslation } from "react-i18next";

interface CreationImageItemProps {
  url: string;
  type?: `${CreationType}`;
  isFavorite: boolean;
  onFavorite?: () => void;
  favoriteLoading?: boolean;
  onMore?: MouseEventHandler<SVGElement>;
  onView?: () => void;
  isVideo?: boolean;
}
export const CreationImageItem: FC<CreationImageItemProps> = (props) => {
  const { url, type, onMore, onView, isVideo } = props;//isFavorite, favoriteLoading, onFavorite,
  return <Box className={`${styles.creationImgItem}  ${type ? styles[type] : ''}`}>
    <Box className={styles.imgBox} bg={`url(${require('@/assets/png/transparent_bg.png')})`}>
      {
        isVideo ? <video src={url} /> : <WiseImage src={url} />
      }
    </Box>
    <Box className={styles.mask}>
      <Box visibility='hidden'></Box>
      {/* <Box className={styles.favorite} onClick={onFavorite}>
        {
          favoriteLoading && <Loading.Icon />
        }
        {
          isFavorite ? <Icon as={require('@/assets/svg/heart_red.svg').ReactComponent} />
            : <Icon as={require('@/assets/svg/heart_hollow.svg').ReactComponent} />
        }
        <Box className={`${styles.heart} ${isFavorite ? styles.active : ''}`} />
      </Box> */}
      <Icon as={require('@/assets/svg/more.svg').ReactComponent} className={styles.more} onClick={onMore} />
    </Box>
    <Box className={styles.view} onClick={onView}>
      <Icon as={require('@/assets/svg/view_icon.svg').ReactComponent} />
    </Box>
  </Box>
}


interface RenderImgProps {
  item: HistoryItem
  favorites?: string[]
  type: `${CreationType}`
  sizeScale?: string
  onDelete?: () => void
  onReDraw?: () => void
  onMore?: (e: React.MouseEvent<SVGElement, MouseEvent>, url: string) => void;
  onFavorite?: (id: string, url: string) => void;
  onView?: (index: number) => void;
}
export const RenderImg: FC<RenderImgProps> = memo((props) => {
  const { item, favorites, type = CreationType.MJ, onMore, onFavorite, onReDraw, onView, onDelete } = props;
  const { t } = useTranslation();
  const { imageUrl } = item;
  const status = Number(item.status) || 0;
  const scales = (item.sizeScal || item.extends?.sd3DrawParam?.aspect_ratio)?.split(':') || [1, 1];

  const _baseWidth = 280;
  const scalesHeight = Math.ceil(_baseWidth / Number(scales[0]) * Number(scales[1]));

  if (status === DrawImageStatusEnum.FAIL) {
    return <Box className={`${styles.fail} ${styles[type]}`}>
      <Icon as={require('@/assets/svg/creation_fail.svg').ReactComponent} />
      <Text>{t('creation.drawFaild')}</Text>
      <Text>{t('creation.autoRetrunPoints')}</Text>
      {
        item.type !== CreationType.MJ && <Box className={styles.btns}>
          <CButton className={styles.btn} theme='outline' onClick={onReDraw}>{t('creation.Redraw')}</CButton>
          <CButton className={styles.btn} theme='outline' onClick={onDelete}>{t('delete')}</CButton>
        </Box>
      }
    </Box>
  } else if (status === DrawImageStatusEnum.DEAWRISK) {
    return <Box className={`${styles.risk} ${styles[type]}`}>
      <Icon as={require('@/assets/svg/risk_red.svg').ReactComponent} />
      <Box className={styles.riskText}>
        <Text>{t('creation.imgMayContain')} {item.contentCheck?.labels?.split(',').map(str => i18n.t('riskContent.' + str)).join(',')}{t('creation.TemporarilyUnavailable')}</Text>
      </Box>
    </Box>
  } else if (status === DrawImageStatusEnum.PROCESSING || status === DrawImageStatusEnum.WAIT || status === DrawImageStatusEnum.QUEUE) {
    return <Box className={`${styles.process} ${styles[type]}`}>
      <Icon as={require('@/assets/svg/creation_loading.svg').ReactComponent} />
      <Text>{t('creation.imgGenerating')}</Text>
    </Box>
  } else {
    return <Box className={styles.imgContent}>
      {
        (item.extends.extendsType === 'magnify' ? [imageUrl[0]] : imageUrl).map((url, index) => {
          return url && <CreationImageItem
            isFavorite={favorites?.includes(url) || false}
            key={url}
            url={url + `?x-oss-process=image/resize,w_${_baseWidth},h_${scalesHeight}`}
            type={type}
            onFavorite={() => onFavorite && onFavorite(item._id, url)}
            onMore={e => onMore && onMore(e, url)}
            onView={() => onView && onView(index)}
          />
        })
      }
    </Box>
  }
})

interface RenderVideoProps extends Omit<RenderImgProps, 'item' | 'type'> {
  item: DrawVideo
}
export const RenderVideo: FC<RenderVideoProps> = memo((props) => {
  const { item, onMore, onFavorite, onReDraw, onView, onDelete } = props;
  const { result_url } = item;
  const status = Number(item.status) || 0;
  const { t } = useTranslation();

  if (status === DrawImageStatusEnum.FAIL) {
    return <Box className={`${styles.fail} ${styles[CreationType.GEN3]}`}>
      <Icon as={require('@/assets/svg/creation_fail.svg').ReactComponent} />
      <Text>{t('creation.videoDrawFaild')}</Text>
      <Text>{t('creation.autoRetrunPoints')}</Text>
      <Box className={styles.btns}>
        <CButton className={styles.btn} theme='outline' onClick={onReDraw}>{t('creation.Redraw')}</CButton>
        <CButton className={styles.btn} theme='outline' onClick={onDelete}>{t('delete')}</CButton>
      </Box>
    </Box>
  } else if (status === DrawImageStatusEnum.PROCESSING || status === DrawImageStatusEnum.WAIT || status === DrawImageStatusEnum.QUEUE) {
    return <Box className={`${styles.process} ${styles[CreationType.GEN3]}`}>
      <Icon as={require('@/assets/svg/creation_loading.svg').ReactComponent} />
      <Text>{t('creation.videoGenerating')}</Text>
    </Box>
  } else {
    return <Box className={styles.imgContent}>
      <CreationImageItem url={result_url || ''}
        isFavorite={false}
        isVideo
        type={CreationType.GEN3}
        onFavorite={() => onFavorite && onFavorite(item._id, result_url || '')}
        onMore={e => onMore && onMore(e, result_url || '')}
        onView={() => onView && onView(0)}
      />
    </Box>
  }
})