import { Box, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, MenuOptionGroup, Text, Tooltip } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss"
import { ScrollBarBox } from "@/components/ScrollBox";
import { CModal } from "@/components/Modal";
import { CButton } from "@/components/Button";
import { RobotIcon } from "../../WechatRobot";

export const SpeechBoBox: FC<any> = observer(({
  title,
  langs,
  selectLanguage,
  setlang_key,
  SetSelectLanguage,
  type,
  width,
  readonly,
  isaction
}: {
  title: string;
  langs: any[];
  selectLanguage: any;
  setlang_key?: (e: string) => void;
  SetSelectLanguage: (e: string) => void;
  type?: string
  width: string
  readonly?: boolean
  isaction?: boolean
}) => {

  const AudioWave = () => {
    return (
      <div className={styles.audioWave}>
        <div className={styles.bar}></div>
        <div className={styles.bar}></div>
        <div className={styles.bar}></div>
        <div className={styles.bar}></div>
      </div>
    );
  };

  useEffect(() => {
    if (type) {
      SetSelectLanguage(langs[0].name);
    } else {
      if (isaction) { return }
      SetSelectLanguage(langs[0]);
    }

  }, [])
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const [isHovered, setIsHovered] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const [isPlay, setIsPlay] = useState(-1);
  const [open, setOpen] = useState<boolean>(false);
  const handleMenuToggle = (isOpen: any) => {
    setIsOpen(isOpen);
  };
  const handlePlay = (index: any) => {
    if (isPlay !== null) {
      const audio = document.getElementById(`audio-${isPlay}`) as HTMLAudioElement;
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    }
    const newAudio = document.getElementById(`audio-${index}`) as HTMLAudioElement;
    if (newAudio) {
      newAudio.play();
    }
    setIsPlay(index);
  };
  return (
    <Flex className={styles.allbox}>
      <Box className={styles.titlebox}>
        <Text className={styles.title}>
          {title}
        </Text>
        {readonly && ( //跟在标题后的提示文字
          <Tooltip>
            <Icon className={styles.titletipsicon} onClick={() => setOpen(true)} />
          </Tooltip>
        )}
      </Box>
      <Box w={'100%'}>
        < Menu onOpen={() => handleMenuToggle(true)} onClose={() => { setIsPlay(-1); handleMenuToggle(false) }}>
          <MenuButton ref={menuButtonRef} className={`${styles.selectmenu} ${readonly ? styles.disabled : ""}`} disabled={readonly ? true : false}>
            <Flex>
              <Box>{selectLanguage}</Box>
              <Icon className={isOpen ? styles.top : styles.bottom} as={require(`@/assets/svg/personalRight.svg`).ReactComponent} />
            </Flex>
          </MenuButton>
          <MenuList w={width} zIndex={999} className={styles.menuList}>
            <MenuOptionGroup defaultValue={selectLanguage} type="radio">
              <ScrollBarBox className={styles.scrollbar}>
                {langs && langs.map((it, index) => {
                  if (type) {
                    return (<MenuItem
                      className={styles.menuitem}
                      key={index}
                      value={it.name}
                      onClick={() => {
                        SetSelectLanguage(it.name);
                        if (setlang_key) {
                          setlang_key(it.name)
                        }
                      }}
                      onMouseEnter={() => setIsHovered(index)}
                      onMouseLeave={() => setIsHovered(-1)}
                    >
                      <Text color={isHovered === index ? '#565CFA' : '#333'}>{it.name}</Text>
                      {selectLanguage === it.name &&
                        <Icon as={require('@/assets/svg/Comselected.svg').ReactComponent} className={styles.uploadIcon} />
                      }
                      <Flex className={styles.playbox}>
                        {isHovered === index ? isPlay === index ?
                          AudioWave()
                          :
                          <Icon as={require('@/assets/svg/soundcheckPauseHover.svg').ReactComponent} className={styles.playicon}
                            onClick={(e) => { e.stopPropagation(); setIsPlay(index); handlePlay(index) }} />
                          :
                          <Icon as={require('@/assets/svg/soundcheckPause.svg').ReactComponent} className={styles.playicon} />
                        }
                      </Flex>
                      <audio id={`audio-${index}`} src={it.url} />
                    </MenuItem>
                    )
                  } else {
                    return (<MenuItem
                      className={styles.menuitem}
                      key={index}
                      value={it}
                      onClick={() => {
                        SetSelectLanguage(it);
                        if (setlang_key) {
                          setlang_key(it)
                        }
                      }}
                    >
                      <Text>{it.replace(/,简体/g, '')}</Text>
                      {selectLanguage === it &&
                        <Icon as={require('@/assets/svg/Comselected.svg').ReactComponent} className={styles.uploadIcon} />
                      }
                    </MenuItem>
                    )
                  }
                })}
              </ScrollBarBox>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Box>
      <CModal isOpen={open} onClose={() => setOpen(false)}>
        <Flex className={styles.notbalancebox}>
          <Flex className={styles.notbalanceTitleBox}>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <RobotIcon />
              <Text>语言</Text>
            </Flex>
            <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setOpen(false)} />
          </Flex>
          <Text className={styles.texttips}>当选择文思GPT作为模型时，其具备自动识别转语音文本中不同语种的能力，能够对各种语言实现兼容和转化为对应的语音。</Text>
          <Flex className={styles.modelbuttonbox}>
            <CButton className={styles.Savebutton} onClick={() => setOpen(false)}>
              我知道了
            </CButton>
          </Flex>
        </Flex>
      </CModal>
    </Flex >
  );
})