import { Box, Drawer, DrawerContent, DrawerOverlay, Flex, Icon, Image, Text, useDisclosure } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useCallback } from "react";
import styles from "./index.module.scss";
import { ScrollBarBox } from "@/components/ScrollBox";
import React from "react";
import { userStore } from "@/store/user";

export const TranslationTitle: FC<any> = observer(({
  icon,
  title,
  historytitle,
  setopen,
  data
}: {
  icon: string;
  title: string;
  historytitle?: string;
  setopen?: () => void
  data?: string
}) => {
  let parentField = '';
  let childField = '';
  const { userInfo } = userStore;
  if (historytitle && historytitle.includes('.')) {
    const fields = historytitle.split('.');
    // 假设 historytitle 总是 "parent.child" 这样的格式
    parentField = fields[0];
    childField = fields[1];
  } else {
    parentField = historytitle!
  }
  return (
    <Flex className={styles.toolbox}>
      <Flex className={styles.databox}>
        <Image className={styles.avatar} src={icon} />
        <Text className={styles.title}
          color={title === '微信机器人' ? userInfo?.wxRobotVip ? '#FF8A00' : '#999' : '#333'}>{title}</Text>
        {(!userInfo?.wxRobotVip && title === '微信机器人') && <Flex className={styles.notvip} onClick={setopen} >
          <Icon as={require('@/assets/svg/robotlock.svg').ReactComponent} />
          未解锁
        </Flex>}
        {(userInfo?.wxRobotVip && title === '微信机器人' && setopen) && <Flex className={styles.isvip} onClick={setopen}>
          <Icon as={require('@/assets/svg/robotUnlock.svg').ReactComponent} />
          续费
        </Flex>}
        {(userInfo?.wxRobotVip && title === '微信机器人' && setopen) && <Text className={styles.vipdata}>
          {data}
        </Text>}
      </Flex>
    </Flex>
  )
})