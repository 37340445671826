import { FC } from "react";
import { ChatContainer, ContactInfoContainer, useStateContext } from "../components";
import { imStore } from "@/store/im";
import { observer } from "mobx-react-lite";

const IMContent: FC = observer(() => {
  const { type, setType } = imStore;
  const { store } = useStateContext();
  return type === 'chat' ? <ChatContainer /> : <ContactInfoContainer
    onFriendItemClick={async (account) => {
      await store.sessionStore.insertSessionActive('p2p', account, true)
      setType('chat')
    }}
    onGroupItemClick={() => { setType('chat') }}
    afterSendMsgClick={() => setType('chat')}
  />
})

export default IMContent