import { Box, Flex, Icon, Table, Tbody, Td, Text, Th, Thead, Tr, Image } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import styles from './index.module.scss';
import { ScrollBarBox } from "@/components/ScrollBox";
import { ComTextarea } from "../components/ComTextarea";
import { Contentpreview } from "../components/ContentPreview";
import { MultipleTextarea } from "../components/MultipleTextarea";
import { toolStore } from "@/store/tool";
import { ToolSchema } from "@/@types/tools";
import { TranslationTitle } from "../components/ComTarnsTitle";
import { ComRobotButton } from "../components/ComRobotButton";
import { userStore } from "@/store/user";
import { CButton } from "@/components/Button";
import { uiStrore } from "@/store/ui";
import { CModal } from "@/components/Modal";
import { useMessage } from "@/hooks/useMessage";
import { createPayVipOrder, getVipPrice, getWeChatAI, postCreatWeChatAI, postSignOutWeChatAI } from "@/api/tools";
import { useQuery } from "@tanstack/react-query";
import Loading from "@/components/Loading";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export const PayRobot = (
  { open, setOpen, okopen }:
    { open: boolean, setOpen: (value: boolean) => void, okopen: () => void }
) => {
  const { userInfo } = userStore;
  let vipinfo: any;
  let brforeinfo: any;
  if (userInfo && userInfo.wxRobotVip) {
    let date = new Date(userInfo.limit.wxRobotMonPayList[userInfo.limit.wxRobotMonPayList.length - 1].endTime)
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1

    let day = String(date.getDate()).padStart(2, '0');
    // let hours = String(date.getHours()).padStart(2, '0');
    // let minutes = String(date.getMinutes()).padStart(2, '0');
    // let seconds = String(date.getSeconds()).padStart(2, '0');
    // vipinfo = `（${year}.${month}.${day} ${hours}:${minutes}:${seconds} 到期）`;
    vipinfo = `${year}-${month}-${day} 到期`;
    let years = date.getFullYear();
    let months = date.getMonth() + 1;
    months += 1;
    if (months > 12) {
      months = 1;
      years += 1;
    }
    let formattedYear = String(years).padStart(2, '0');
    let formattedMonth = String(months).padStart(2, '0');
    brforeinfo = `${formattedYear}-${formattedMonth}-${day} 到期`;
  }

  return (
    <CModal isOpen={open} onClose={() => setOpen(false)}>
      <Flex className={styles.vipPaybox}>
        <Flex className={styles.vipPayTitleBox}>
          <Text>{(userInfo && userInfo.wxRobotVip) ? '续费微信机器人' : '解锁微信机器人'}</Text>
          <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setOpen(false)} />
        </Flex>
        {userInfo && userInfo.wxRobotVip ?
          <Box w={'100%'}>
            <Text className={styles.paytips}>您确认续费微信机器人的一个月使用权益吗？</Text>
            <Text className={styles.vippaytips}>当前到期时间:{vipinfo}</Text>
            <Text className={styles.vippaytips}>续费后：{brforeinfo}</Text>
          </Box>
          :
          <Text w={'100%'} className={styles.paytips}>您确认解锁微信机器人的一个月使用权益吗？</Text>
        }
        <Flex className={styles.buttonBox}>
          <Text>需支付：5800</Text>
          <Icon as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
          {/* <Text>/月</Text> */}
          <CButton onClick={okopen} className={styles.button}>
            {userInfo && !userInfo.wxRobotVip &&
              <Icon as={require('@/assets/svg/robotviplock.svg').ReactComponent} />
            }
            {(userInfo && userInfo.wxRobotVip) ? '立即续费' : '立即解锁'}
          </CButton>
        </Flex>
      </Flex>
    </CModal>
  )
}

export const RobotIcon = () => {
  return <Flex fontSize={'16px'} mb={'1px'} color={'#fff'} bg={'#FF7B00'} w={'22px'} height={'22px'} mr={'5px'} borderRadius={'50%'} justifyContent={'center'} alignItems={'center'}>
    ?
  </Flex>
}

const images = [
  require('@/assets/png/robotExample1.png'),
  require('@/assets/png/robotExample2.png'),
  require('@/assets/png/robotExample3.png')
]

export const WechatRobot: FC = observer(() => {
  const [tool, SetTool] = useState<ToolSchema>()
  const { openAlert, closeAlert } = uiStrore;
  const { userInfo, setUserInfo } = userStore;
  const bindGenPrompt = useRef<HTMLTextAreaElement>(null);
  const [loading, setLoading] = useState(false);
  const [single_chat_reply_prefix, Setsingle_chat_reply_prefix] = useState('[文思逸言小助手]：')
  const [single_chat_prefix, Setsingle_chat_prefix] = useState('')
  const [private_chat_prefix, Setprivate_chat_prefix] = useState('')
  const [group_welcome_msg, Setgroup_welcome_msg] = useState('欢迎入群！🌟 我是文思逸言小助手，随时陪您文字对话、语音互动，还可以提供在线资讯查询等小功能哦！🗣️✨ 想了解如何使用？请发送：“@我 使用教程”')
  const webPageUrl = 'https://dev.wisetalker.tech/article?title=%E5%BE%AE%E4%BF%A1%E6%9C%BA%E5%99%A8%E4%BA%BA%E4%BD%BF%E7%94%A8%E6%95%99%E7%A8%8B'
  const [character_desc, Setcharacter_desc] = useState('你是由文思逸言开发的AI全能助手，你旨在回答并解决人们的任何问题，并且可以使用多种语言与人交流。')
  const [selectModelID, SetSelectModelID] = useState('');
  const [selectModelType, SetSelectModelType] = useState('');
  const [datalist, SetDataList] = useState<any>([])
  const { getToolByLink } = toolStore;
  const message = useMessage();
  const [open, setOpen] = useState<boolean>(false);
  const [payopen, setPayOpen] = useState<boolean>(false);
  const [wxopen, setWxOpen] = useState<boolean>(false);
  const [choosePay, setChoosePay] = useState<{ discount: string; payPrice: number; price: number; month: number }>()
  const [banopen, setBanOpen] = useState<boolean>(false);
  const [wsmopen, setWsmOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getToolByLink({ toollink: 'wechatRobot' });
      SetTool(data[0]);
      const res = await getVipPrice('wxrobot')
      setChoosePay(res.expand.price[0])
      const ret = await getWeChatAI();
      Setsingle_chat_reply_prefix(ret[0].dataJson.chat_reply_prefix);
      Setprivate_chat_prefix(ret[0].dataJson.chat_prefix);
      Setsingle_chat_prefix(ret[0].dataJson.private_chat_prefix);
      const originalTextValues = ret[0].dataJson.group_name_white_list.split('/');
      const originalBoxes = originalTextValues.map(textvalue => ({
        textvalue: textvalue,
        maxCount: 15, // 或者其他你需要的值
      }));
      setBoxes(originalBoxes);
      Setcharacter_desc(ret[0].dataJson.character_desc);
      Setgroup_welcome_msg(ret[0].dataJson.group_welcome_msg);
    };
    fetchData();
  }, []);
  const onclicklang = (e: any, id: any) => {
    SetSelectModelType(e);
    SetSelectModelID(id)
  };
  const [boxes, setBoxes] = useState([{
    textvalue: 'AI对话聊天群',
    maxCount: 15,
  }]);

  const signOut = async (reGen: boolean) => {
    try {
      const ret = await postSignOutWeChatAI({
        isReGen: reGen,
        toolid: tool ? tool._id : ''
      })
      if (!reGen) {
        message.success({ title: '退出成功' })
      }
      setLoading(false)
    } catch (err: any) {
      message.success({ title: typeof err === 'string' ? err : err?.message || '出错了~' })
      setLoading(false);
      setWxOpen(false)
    }
  }

  const handleGPTGenInfo = async (reGen: boolean) => {
    // 拼接字符串
    const textValuesJoined = boxes
      .filter(box => box.textvalue)
      .map(box => box.textvalue)
      .join('/');
    setLoading(true)
    try {
      let per_setting = '1'
      const rdval = localStorage.getItem('radioValue')
      if (rdval) {
        per_setting = rdval
      }
      const ret = await postCreatWeChatAI({
        chat_reply_prefix: single_chat_reply_prefix,
        chat_prefix: single_chat_prefix,
        private_chat_prefix: private_chat_prefix,
        group_name_white_list: textValuesJoined,
        character_desc: character_desc,
        group_welcome_msg: group_welcome_msg,
        chatgpt_key: selectModelID,
        personality_setting: per_setting,
        isReGen: reGen,
        toolid: tool ? tool._id : ''
      });
      if (ret) {
        message.success({ title: '保存成功' })
      }
      setLoading(false)
    } catch (err: any) {
      message.success({ title: typeof err === 'string' ? err : err?.message || '出错了~' })
      setLoading(false);
    }
    // setQrStatus(4);
  };

  const restoresdefault = async () => {
    try {
      const ret = await postCreatWeChatAI({
        chat_reply_prefix: '[文思逸言小助手]：',
        chat_prefix: '',
        private_chat_prefix: "",
        group_name_white_list: 'AI对话聊天群',
        character_desc: '你是由文思逸言开发的AI全能助手，你旨在回答并解决人们的任何问题，并且可以使用多种语言与人交流。',
        group_welcome_msg: '欢迎入群！🌟 我是文思逸言小助手，随时陪您文字对话、语音互动，还可以提供在线资讯查询等小功能哦！🗣️✨ 想了解如何使用？请发送：“@我 使用教程”',
        chatgpt_key: selectModelID ? selectModelID : '',
        personality_setting: '1', //默认自定义人格
        isReGen: false,
        toolid: tool ? tool._id : ''
      });
      Setsingle_chat_reply_prefix(ret.dataJson.chat_reply_prefix); // 如果返回的值不为空，更新状态
      Setsingle_chat_prefix(ret.dataJson.chat_prefix);
      Setprivate_chat_prefix(ret.dataJson.private_chat_prefix)
      const originalTextValues = ret.dataJson.group_name_white_list.split('/');
      const originalBoxes = originalTextValues.map(textvalue => ({
        textvalue: textvalue,
        maxCount: 15, // 或者其他你需要的值
      }));
      setBoxes(originalBoxes);
      Setcharacter_desc(ret.dataJson.character_desc);
      Setgroup_welcome_msg(ret.dataJson.group_welcome_msg);
      if (ret) {
        message.success({ title: '恢复默认成功' })
      }
    } catch (err: any) {
      message.success({ title: typeof err === 'string' ? err : err?.message || '出错了~' })
      setLoading(false);
    }
  };

  const [qrURL, setQrURL] = useState('');
  const [lastUpdateTime, setLastUpdateTime] = useState(null)
  const [scaned, Setscaned] = useState('');
  const [logined, Setlogined] = useState('');
  const [nickname, Setnickname] = useState('');
  const [wxmessage, SetWxmessage] = useState('');
  const [timeLeft, setTimeLeft] = useState<any>(0);
  const [qrStatus, setQrStatus] = useState(0);
  useQuery(
    ['getWeChatAI'],
    () => {
      return getWeChatAI();
    },
    {
      refetchInterval: 3000,
      onSuccess(res: any) {
        if (res.length > 0) {
          // console.log('res:', res[0].wechatUrl)
          Setscaned(res[0].scan ? res[0].scan.status : '')
          Setlogined(res[0].wxlogin ? res[0].wxlogin.status : '')
          Setnickname(res[0].wxlogin && res[0].wxlogin.nickname ? res[0].wxlogin.nickname : "")
          SetWxmessage(res[0].wxlogin && res[0].wxlogin.message ? res[0].wxlogin.message : "")
          setQrURL(res[0].wechatUrl)
          setLastUpdateTime(res[0].last_update)
          setQrStatus(res[0].status)

          const currentTime = (new Date()).toISOString();
          const lastUpdateTimes = new Date(lastUpdateTime!).getTime(); // 将 ISO 8601 字符串转换为时间戳
          const expirationTime = new Date(currentTime).getTime();
          const timeDifference = Math.abs(expirationTime - (lastUpdateTimes - 8 * 60 * 60 * 1000));
          if (timeDifference < (5 * 60 * 1000)) {
            setTimeLeft(300 * 1000 - timeDifference);
          } else {
            setTimeLeft(0);
          }

          if (document.getElementById('bindQRCode')) {
            // 清除旧的二维码
            const qrCodeElement = document.getElementById('bindQRCode');
            if (qrCodeElement) {
              qrCodeElement.innerHTML = '';
            }
            new QRCode(document.getElementById('bindQRCode'), {
              text: res[0].wechatUrl,
              width: 170,
              height: 170,
              colorDark: '#000000',
              colorLight: '#ffffff',
              correctLevel: QRCode.CorrectLevel.H
            });
          }
        } else {
          setQrURL('')
        }
      }
    }
  );

  // const [payConfirmShow, setPayConfirmShow] = useState(false)
  const handleClickPay = async () => {
    if (userInfo && userInfo?.balance < 58) {
      setBanOpen(true);
      setPayOpen(false);
      return
    }
    // if (isLoading || !choosePay) return
    try {
      // setIsLoading(true)
      // setPayConfirmShow(false)
      const res = await createPayVipOrder('wxrobot', Number(choosePay?.month))
      setUserInfo(res.user)
      // setIsLoading(false)
      message.success({ title: '支付成功' })
      setPayOpen(false)
    } catch (err: any) {
      console.log(err)
      // setIsLoading(false)
      message.error({ title: err.message })
    }
  }

  const [currentIndex, setCurrentIndex] = useState(0);
  const nextSlide = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  let vipinfo: any;
  if (userInfo && userInfo.wxRobotVip) {
    let date = new Date(userInfo.limit.wxRobotMonPayList[userInfo.limit.wxRobotMonPayList.length - 1].endTime)
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
    let day = String(date.getDate()).padStart(2, '0');
    vipinfo = `${year}.${month}.${day}日到期`;
  }

  useEffect(() => {
    if (scaned === 'scaned' && logined === 'logined') {
      setWxOpen(false)
    }
    if (scaned === 'scaned' && wxmessage.indexOf('未实名') >= 0) {
      setWsmOpen(true)
      setWxOpen(false)
    }
  }, [scaned, logined])
  return (
    <Flex key={'weChatRobot'} className={styles.toollistBox}>
      <Flex className={styles.listBox}>
        {(!userInfo?.wxRobotVip && !open) && (
          <Flex className={styles.notVipBox}>
            <Box className={styles.robotimgbox}>
              <Icon onClick={prevSlide} className={styles.robotleftsvg} as={require('@/assets/svg/robotLeft.svg').ReactComponent} />
              <Image className={styles.avataricon} src={images[currentIndex]} />
              <Icon onClick={nextSlide} className={styles.robotrightsvg} as={require('@/assets/svg/robotRight.svg').ReactComponent} />
              <Flex className={styles.indicators}>
                {images.map((_, index) => (
                  <span
                    key={index}
                    className={index === currentIndex ? styles.active : ''}
                  ></span>
                ))}
              </Flex>
            </Box>
            <Box w={'340px'}>
              <Box className={styles.titleText}>
                解锁微信机器人您将获取以下权益
              </Box>
              <Box>
                <ul className={styles.ulbox}>
                  <li>24小时随问随答的专属微信助手</li>
                  <li>最先进的Gpt模型调用</li>
                  <li>分享至多5个群聊用于娱乐或客服</li>
                  <li>快速获取早报，星座等在线咨询</li>
                </ul>
              </Box>
              <Flex className={styles.priceText}>
                <Text>5800</Text>
                <Icon as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
                <Text>/月</Text>
              </Flex>
              <Flex>
                <CButton className={styles.unlockBox} onClick={() => setPayOpen(true)}>
                  <Icon as={require('@/assets/svg/robotUnlock.svg').ReactComponent} />
                  <Text>解锁机器人</Text>
                </CButton>
                <CButton className={styles.previewBox} onClick={() => setOpen(true)}>
                  <Icon as={require('@/assets/svg/robotPreview.svg').ReactComponent} />
                  <Text>视频预览</Text>
                </CButton>
              </Flex>
            </Box>
          </Flex>
        )}
        <TranslationTitle
          icon={tool ? tool.icon.toString() : ''}
          title={tool ? tool.name.toString() : ''}
          setopen={() => setPayOpen(true)}
          historytitle='language'
          data={vipinfo}
        />
        <Box className={styles.contentsBox}>
          <ScrollBarBox>
            {scaned === 'scaned' && logined === 'logined' && qrStatus !== 13 ?
              <Box w={'556px'} m={'30px auto 10px'}>
                <Flex className={styles.loginbox}>
                  <Box className={styles.loginstatus}>
                    <Box className={styles.loginedbox}>
                      <Icon as={require('@/assets/svg/wx.svg').ReactComponent} />
                      <Text>当前微信登陆状态:</Text>
                    </Box>
                    <Text className={styles.wxname}>微信名：{nickname}</Text>
                  </Box>
                  <Box className={styles.statusbox}>
                    <Icon as={require('@/assets/svg/hdrSucess.svg').ReactComponent} />
                    <Text>已登陆</Text>
                  </Box>
                </Flex>
              </Box>
              :
              <Box w={'556px'} m={'30px auto 10px'}>
                <Flex className={styles.Unloginbox}>
                  <Box className={styles.Unloginedbox}>
                    <Icon as={require('@/assets/svg/wx.svg').ReactComponent} />
                    <Text>当前微信登陆状态:</Text>
                  </Box>
                  <Box className={styles.Unstatusbox}>
                    <Icon as={require('@/assets/svg/unLoginWechat.svg').ReactComponent} />
                    <Text>未登陆</Text>
                  </Box>
                </Flex>
              </Box>
            }
            <ComTextarea
              title={'机器人回复前缀'}
              prompt={''}
              textvalue={single_chat_reply_prefix}
              textchange={Setsingle_chat_reply_prefix}
              maxCount={10}
              rows={1}
              systemPromptTip={'私聊或群聊时,微信机器人自动回复的前缀,用于区分真人,可以留空。'}
            />
            <ComTextarea
              title={'私聊对话触发词'}
              prompt={''}
              textvalue={private_chat_prefix}
              textchange={Setprivate_chat_prefix}
              maxCount={10}
              rows={1}
              systemPromptTip={'留空时给机器人发送任意消息都会回复，设置了触发词，则发送的消息包含触发词才会回复。建议留空！'}
            />
            <ComTextarea
              title={'群组对话触发词'}
              prompt={''}
              textvalue={single_chat_prefix}
              textchange={Setsingle_chat_prefix}
              maxCount={10}
              rows={1}
              systemPromptTip={'群聊时，输入的文本内容需要包含触发词机器人才会回复，留空时需要@机器人，机器人才会回复。建议填写！'}
            />
            <MultipleTextarea
              title={'微信群名单'}
              prompt={''}
              boxes={boxes}
              setBoxes={setBoxes}
              rows={1}
              systemPromptTip={'填入开启微信机器人回复的群名称列表,最多填入5个群,可以留空。留空时则不会在任何微信群内触发机器人。'}
            />
            <ComTextarea
              title={'请输入机器人人格设定'}
              prompt={'请输入机器人人格设定'}
              textvalue={character_desc}
              textchange={Setcharacter_desc}
              maxCount={300}
              rows={3}
            />
            <ComTextarea
              title={'微信群新人入群欢迎语'}
              prompt={'请输入微信群新人入群欢迎语'}
              textvalue={group_welcome_msg}
              textchange={Setgroup_welcome_msg}
              maxCount={300}
              rows={3}
              systemPromptTip={'填入后若微信群内有机器人,且有新人入群时,则会触发机器人发送新人入群欢迎语。'}
            />
          </ScrollBarBox>
        </Box>
        <ComRobotButton
          saveDataOnClick={() => { handleGPTGenInfo(false) }}
          loadDefaulOnClick={restoresdefault}
          genQRcodeOnClick={() => { signOut(true); setWxOpen(true) }}
          ginOutOnClick={() => { signOut(true); message.success({ title: '退出成功' }) }}
          logined={scaned === 'scaned' && logined === 'logined' && qrStatus !== 13} />
      </Flex>
      <Flex className={styles.previewBox}>
        <Flex className={styles.flexColumn}>
          <Box className={styles.centerItemsbox}>
            <Text className={styles.title}>使用教程</Text>
            <Text className={styles.prompt}>新手指南</Text>
          </Box>
          <Box className={styles.iframeBox}>
            <iframe
              title="Embedded Web Page"
              width="100%"
              height="100%"
              src={webPageUrl}
              frameBorder="0"
            />
          </Box>
        </Flex>
      </Flex>
      {/* 未解锁的视频预览 */}
      <CModal isOpen={open} onClose={() => setOpen(false)}>
        <Flex className={styles.cmodelbox}>
          <Flex className={styles.cmodelTitleBox}>
            <Text>视频预览</Text>
            <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setOpen(false)} />
          </Flex>
          <video controls style={{ width: '308px', height: '667px', borderRadius: '30px', border: '4px solid #333' }}>
            <source src={'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/video/system/wechatrobot_video.mp4'}
              type="video/mp4" />
          </video>
        </Flex>
      </CModal>
      {/* 开通弹窗 */}
      <PayRobot open={payopen} setOpen={setPayOpen} okopen={handleClickPay} />
      {/* 微信扫码 */}
      <CModal isOpen={wxopen} onClose={() => setWxOpen(false)}>
        <Flex className={styles.wxewmbox}>
          <Flex className={styles.wxewmTitleBox}>
            <Text>微信机器人</Text>
            <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setWxOpen(false)} />
          </Flex>
          <Box className={styles.wxtitleText}>
            {qrURL === '' &&
              < Text color={'#FF7B00'}>正在获取登录二维码，请稍等</Text>
            }
            {(scaned !== 'scaned' && logined !== 'logined' && qrURL !== '') &&
              <Text color={'#09AD19'}>
                登录后的微信将开启AI回复功能
              </Text>
            }
          </Box>
          <Box pos={'relative'}>
            <Box className={styles.wxewm} id={'bindQRCode'} userSelect={'none'}></Box>
            {!(scaned === 'scaned' && qrURL !== "") && (qrURL !== "" && timeLeft <= 0) && (
              <Box className={styles.limitBox} border={'1px solid rgba(153, 153, 153, 0.5)'}>
                二维码已失效
              </Box>
            )}
            {qrURL === '' && <Flex className={styles.limitBox} border={'0px'}>
              <Loading.Icon className={styles.loadingicon} w={'40px'} height={'40px'} />
            </Flex>}
          </Box>
          {!(scaned === 'scaned' && qrURL !== "") && (qrURL !== "" && timeLeft <= 0) && (
            <Box cursor={'pointer'} className={styles.refreshEwm}
              onClick={() => {
                setQrURL('')
                handleGPTGenInfo(true)
              }}>
              刷新
              <Icon as={require('@/assets/svg/refreshEwm.svg').ReactComponent} />
            </Box>
          )}
          <Box className={styles.tipstext}>
            提示：请使用微信“扫一扫”进行登录
          </Box>
        </Flex>
      </CModal >
      <CModal isOpen={banopen} onClose={() => setBanOpen(false)}>
        <Flex className={styles.notbalancebox}>
          <Flex className={styles.notbalanceTitleBox}>
            <Text>余额不足</Text>
            <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setBanOpen(false)} />
          </Flex>
          <Text className={styles.texttips}>哎呀！您的点数余额不足！请充值点数后再次尝试哦～</Text>
          <Flex className={styles.modelbuttonbox}>
            <CButton className={styles.Savebutton} onClick={() => navigate(`/personal?selectedIndex=${t('Point_records')}`)}>
              前往充值
            </CButton>
          </Flex>
        </Flex>
      </CModal>
      <CModal isOpen={wsmopen} onClose={() => setWsmOpen(false)}>
        <Flex className={styles.notbalancebox}>
          <Flex className={styles.notbalanceTitleBox}>
            <Text>登录失败</Text>
            <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setWsmOpen(false)} />
          </Flex>
          <Text className={styles.texttips}>由于微信官方的限制，未实名的微信暂不支持使用，请实名后尝试重新登录</Text>
          <Flex className={styles.modelbuttonbox}>
            <CButton className={styles.Savebutton} onClick={() => setWsmOpen(false)}>
              确定
            </CButton>
          </Flex>
        </Flex>
      </CModal>
    </Flex >
  )
})