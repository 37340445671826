import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { FriendSelectContainer, useTranslation } from '../../../common'
import { Box } from '@chakra-ui/react'
import { CButton } from '@/components/Button'
import { useMessage } from '@/hooks/useMessage'
// import { SearchInput } from '@x-kit-react/search-kit'

interface ChatAddMemebersProps {
  defaultAccounts?: string[]
  visible: boolean
  onCancel: () => void
  onGroupAddMembers: (selectedAccounts: string[]) => void

  prefix?: string
  commonPrefix?: string
}

const emptyArr: string[] = []

const ChatAddMemebers: React.FC<ChatAddMemebersProps> = ({
  defaultAccounts = emptyArr,
  visible,
  onCancel,
  onGroupAddMembers,

  prefix = 'chat',
  commonPrefix = 'common',
}) => {
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([])
  const message = useMessage()
  // const [searchText, setSearchText] = useState('')

  useEffect(() => {
    if (visible) {
      setSelectedAccounts([...new Set(defaultAccounts)])
    }
  }, [defaultAccounts, visible])

  useEffect(() => {
    if (!visible) {
      resetState()
    }
  }, [visible])

  const { t } = useTranslation()
  const _prefix = `${prefix}-add-members`

  const handleOk = () => {
    if (!selectedAccounts.length) {
      message.error({ title: t('addTeamMemberConfirmText') })
      return
    }
    onGroupAddMembers(selectedAccounts)
  }

  const handleCancel = () => {
    onCancel()
  }

  const resetState = () => {
    setSelectedAccounts([])
  }

  return (
    <Modal
      className={`${_prefix}-wrap`}
      onOk={handleOk}
      onCancel={handleCancel}
      visible={visible}
      width={630}
      destroyOnClose={true}
      okText={t('addTeamMemberText')}
      closable={false}
      footer={null}
    >
      <Box
        onClick={handleCancel}
        as={require('@/assets/svg/close_x.svg').ReactComponent}
        className={`${_prefix}-header-close`}
      />
      <div className={`${_prefix}-title`}>{t('addTeamMemberText')} </div>
      {/* <SearchInput value={searchText} onChange={setSearchText} /> */}
      <div>
        <FriendSelectContainer
          prefix={commonPrefix}
          onSelect={(selectedList) =>
            setSelectedAccounts(selectedList.map((item) => item.account))
          }
          selectedAccounts={selectedAccounts}
        />
      </div>
      <div className={`${_prefix}-btns`}>
        <CButton onClick={handleCancel} size='md'>{t('cancelText')}</CButton>
        <CButton onClick={handleOk} size='md' theme='primary' ml='20px'>
          {t('addTeamMemberText')}
        </CButton>
      </div>
    </Modal>
  )
}

export default ChatAddMemebers
