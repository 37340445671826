import Lottie from 'react-lottie';
import { FC } from "react";
import { chatLottieJson } from '@/assets/lottie/chat'
import { creationLottieJson } from '@/assets/lottie/creation'
import { toolLottieJson } from '@/assets/lottie/tool';
import { agentsLottieJson } from '@/assets/lottie/agents';
import { imLottieJson } from '@/assets/lottie/im';


export const ChatAnimation: FC = () => {

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: chatLottieJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return <Lottie options={defaultOptions}
    height={50}
    width={50}
  />
}

export const CreationAnimation: FC = () => {

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: creationLottieJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
  };

  return <Lottie options={defaultOptions} height={50} width={50} />
}

export const ToolAnimation: FC = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: toolLottieJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
  };

  return <Lottie options={defaultOptions} height={50} width={50} />
}

const AgentAnimation: FC = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: agentsLottieJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
  };

  return <Lottie options={defaultOptions} height={50} width={50} />
}

const IMAnimation: FC = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: imLottieJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
  };

  return <Lottie options={defaultOptions} height={50} width={50} />
}
export const NavAnimation = {
  Chat: ChatAnimation,
  Creation: CreationAnimation,
  Tool: ToolAnimation,
  Agent: AgentAnimation,
  IM: IMAnimation
}