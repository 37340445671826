import { Box, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, MenuOptionGroup, Text, Tooltip } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss"
import { ScrollBarBox } from "@/components/ScrollBox";
import { CModal } from "@/components/Modal";
import { CButton } from "@/components/Button";
import { RobotIcon } from "@/pages/tools/WechatRobot";
import { useTranslation } from "react-i18next";

export const CreationSingStyle: FC<any> = observer(({
  title,
  langs,
  selectLanguage,
  setlang_key,
  SetSelectLanguage,
  systemPromptTip
}: {
  title: string;
  langs: any[];
  selectLanguage: any;
  setlang_key?: (e: string) => void;
  SetSelectLanguage: (e: string) => void;
  systemPromptTip?: string
}) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    SetSelectLanguage(langs[1].name);
  }, [])
  const { t } = useTranslation();
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleMenuToggle = (isOpen: any) => {
    setIsOpen(isOpen);
  };
  return (
    <Flex className={styles.allbox}>
      <Box className={styles.titlebox}>
        <Text className={styles.title}>
          {title}
        </Text>
        <Box w={'5px'}></Box>
        {systemPromptTip &&
          <Tooltip >
            <Icon className={styles.tipsicon} onClick={() => setOpen(true)} />
          </Tooltip>
        }
      </Box>
      <Box >
        <Menu onOpen={() => handleMenuToggle(true)} onClose={() => handleMenuToggle(false)}>
          <MenuButton ref={menuButtonRef} className={styles.selectmenu}>
            <Flex>
              <Box>{selectLanguage}</Box>
              <Icon className={isOpen ? styles.top : styles.bottom} as={require(`@/assets/svg/personalRight.svg`).ReactComponent} />
            </Flex>
          </MenuButton>
          <MenuList zIndex={999} className={styles.menuList}>
            <MenuOptionGroup defaultValue={selectLanguage} type="radio">
              <ScrollBarBox className={styles.scrollbar}>
                {langs.map((it, index) => {
                  return (<MenuItem
                    className={styles.menuitem}
                    key={it.name + index}
                    value={it.text}
                    onClick={() => {
                      SetSelectLanguage(it.name);
                      if (setlang_key) {
                        setlang_key(it.name)
                      }
                    }}
                  >
                    <Text >{it.name}</Text>
                    {selectLanguage === it.name &&
                      <Icon as={require('@/assets/svg/Comselected.svg').ReactComponent} className={styles.uploadIcon} />
                    }
                  </MenuItem>)
                })}
              </ScrollBarBox>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Box>
      <CModal isOpen={open} onClose={() => setOpen(false)}>
        <Flex className={styles.notbalancebox}>
          <Flex className={styles.notbalanceTitleBox}>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <RobotIcon />
              <Text>{title}</Text>
            </Flex>
            <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setOpen(false)} />
          </Flex>
          <Text className={styles.texttips}>{systemPromptTip}</Text>
          <Flex className={styles.modelbuttonbox}>
            <CButton className={styles.Savebutton} onClick={() => setOpen(false)}>
              {t('ISee')}
            </CButton>
          </Flex>
        </Flex>
      </CModal>
    </Flex>
  );
})