import { Box, BoxProps } from "@chakra-ui/react";
import { FC } from "react";

interface HorizontalBoxProps extends BoxProps { }
const HorizontalBox: FC<HorizontalBoxProps> = (props) => {
  return <Box display='flex' alignItems='center' {...props}>
    {props.children}
  </Box>
}

export default HorizontalBox