import { FC, useEffect, useState } from 'react';
import { Box, Icon, Image, ImageProps } from "@chakra-ui/react";
import heic2any from 'heic2any';

import styles from './index.module.scss'

interface WiseImageProps extends ImageProps { }
export const WiseImage: FC<WiseImageProps> = (props) => {
  const { src, ...others } = props;
  const [isError, setIsError] = useState(false);
  const [selfUrl, setSelfUrl] = useState(src || '');

  useEffect(() => {
    if (src !== selfUrl) {
      setSelfUrl(src || '');
    }
    // eslint-disable-next-line
  }, [src]);

  useEffect(() => {
    setIsError(false);
    if (!selfUrl) return;

    if (selfUrl?.endsWith('.heic') || selfUrl?.endsWith('.heif')) {
      fetch(selfUrl).then(res => res.blob()).then(blob => {
        heic2any({
          blob,
          toType: 'image/jpeg',
          quality: 0.5
        }).then((result) => {
          //@ts-ignore
          const heic_url = URL.createObjectURL(result)
          setSelfUrl(heic_url)
        })
      })
    }
  }, [selfUrl]);

  let finalUrl = '';
  if (src?.startsWith('/_next/') || src?.startsWith('/api/') || src === '/icon/human.png' || src === '/icon/logo.png') {
    finalUrl = (window.proxyApi || 'https://dev.wisetalker.tech') + src;
  } else if (src?.endsWith('.heic') || src?.endsWith('.heif')) {
    finalUrl = selfUrl || '';
  } else {
    finalUrl = src || '';
  }

  return isError ? <Box className={styles.errorImg}>
    <Icon as={require('@/assets/svg/img_err_default.svg').ReactComponent} />
  </Box>
    : <Image
      loading='lazy'
      onError={() => setIsError(true)}
      src={finalUrl || src}
      alt='' {...others} />
};
