export const chatLottieJson = {
  "v": "5.8.1",
  "fr": 60,
  "ip": 0,
  "op": 60,
  "w": 70,
  "h": 70,
  "nm": "选中——AI对话",
  "ddd": 0,
  "assets": [
    {
      "id": "chat_bg",
      "w": 150,
      "h": 151,
      "p": require('@/assets/lottie/chat_bg.png'),
      "e": 0
    },
    {
      "id": "image_1",
      "w": 24,
      "h": 24,
      "p": require('@/assets/lottie/chat_text.png'),
      "e": 0
    },
    {
      "id": "comp_0",
      "nm": "静态——AI对话",
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 2,
          "nm": "AI对话.png",
          "cl": "png",
          "td": 1,
          "refId": "chat_bg",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                26,
                26,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                75,
                75.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                34,
                34,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 120,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          "nm": "AI.png",
          "cl": "png",
          "tt": 1,
          "refId": "image_1",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 10,
                  "s": [
                    26,
                    60,
                    0
                  ],
                  "to": [
                    0,
                    -6.667,
                    0
                  ],
                  "ti": [
                    0,
                    5,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 20,
                  "s": [
                    26,
                    20,
                    0
                  ],
                  "to": [
                    0,
                    -5,
                    0
                  ],
                  "ti": [
                    0,
                    -0.5,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 30,
                  "s": [
                    26,
                    30,
                    0
                  ],
                  "to": [
                    0,
                    0.5,
                    0
                  ],
                  "ti": [
                    0,
                    1.167,
                    0
                  ]
                },
                {
                  "t": 40,
                  "s": [
                    26,
                    23,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                12,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 120,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 2,
          "nm": "AI对话.png",
          "cl": "png",
          "refId": "chat_bg",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                26,
                26,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                75,
                75.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                34,
                34,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 120,
          "st": 0,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 0,
      "nm": "AI对话——静态",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            35,
            61.025,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            26,
            52,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 0,
              "s": [
                0,
                0,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 10,
              "s": [
                110,
                110,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 20,
              "s": [
                80,
                80,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 30,
              "s": [
                105,
                105,
                100
              ]
            },
            {
              "t": 40,
              "s": [
                100,
                100,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 52,
      "h": 52,
      "ip": 0,
      "op": 120,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}