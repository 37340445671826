import { Box, Text, BoxProps, Icon, ModalProps, ModalOverlayProps, ModalContentProps } from "@chakra-ui/react";
import { FC, useCallback, useState } from "react";
import { CButton } from "../Button";
import { uiStrore } from "@/store/ui";
import { observer } from "mobx-react-lite";

import styles from './index.module.scss'
import { CModal } from "../Modal";
import { useTranslation } from "react-i18next";

export interface AlertProps {
  status?: 'warning' | 'error' | 'ask'
  title?: React.ReactNode
  onOk?: () => void | Promise<void>
  onCancel?: () => void
  onclose?: () => void
  open: boolean
  footer?: React.ReactNode
  cancelBtn?: React.ReactNode
  okBtn?: React.ReactNode
  content?: React.ReactNode
  boxsProps?: BoxProps
  okBtnText?: string
  cancelBtnText?: string
  modalProps?: ModalProps
  overlayProps?: ModalOverlayProps
  contentProps?: ModalContentProps
}
export const Alert: FC = observer(() => {
  const { status, okBtn, open, cancelBtn, title, content, footer, onOk, onCancel, onclose, okBtnText, cancelBtnText, modalProps,
    overlayProps, contentProps, ...boxsProps } = uiStrore.alert;
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const StatusIcon = useCallback(() => {
    switch (status) {
      case 'error':
        return <Icon as={require('@/assets/toasticon/error.svg').ReactComponent} className={styles.icon} />
      case 'warning':
        return <Icon as={require('@/assets/toasticon/warning.svg').ReactComponent} className={styles.icon} />
      case 'ask':
        return <Icon as={require('@/assets/toasticon/ask.svg').ReactComponent} className={styles.icon} />
      default:
        return <Icon as={require('@/assets/toasticon/warning.svg').ReactComponent} className={styles.icon} />
    }
  }, [status])

  return <CModal isOpen={open} onClose={() => {
    uiStrore.closeAlert();
    setLoading(false);
  }} overlayProps={overlayProps} contentProps={contentProps} {...modalProps}>
    <Box className={styles.modalbox} {...boxsProps}>
      <Box className={styles.titleheadbox}>
        <Box className={styles.titlebox}>
          {status &&
            <StatusIcon />
          }
          <Text className={styles.alertTitle}>{title}</Text>
        </Box>
        {onclose &&
          <Icon className={styles.titleClosebut} as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={onclose} />
        }
      </Box>
      {content && (
        <Box className={styles.content}>{content}</Box>
      )}
      {
        footer ? footer : <Box className={styles.footer}>
          {
            cancelBtn ? cancelBtn : <CButton theme='outline' className={styles.cancelbutton} onClick={() => {
              if (onCancel) {
                onCancel();
              } else {
                uiStrore.closeAlert();
                setLoading(false);
              }
            }}>
              {cancelBtnText || t('cancel')}
            </CButton>
          }
          {
            okBtn ? okBtn : <CButton isLoading={loading} theme='primary' className={styles.okbutton} onClick={async () => {
              if (loading) return;
              if (onOk) {
                setLoading(true);
                try {
                  await onOk();
                } catch (e) {
                  console.log(e);
                }
                setLoading(false);
              }
            }}>
              {okBtnText || t('confirm')}
            </CButton>
          }
        </Box>
      }
    </Box>
  </CModal>
})