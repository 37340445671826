import { Box, Flex, Icon, Image, Text, useOutsideClick } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import { chatStore } from "@/store/chat";
import { ScrollBarBox } from "@/components/ScrollBox";
import { useTranslation } from "react-i18next";
import { CButton } from "@/components/Button";
import { userStore } from "@/store/user";
import { uiStrore } from "@/store/ui";

import styles from './index.module.scss'
import { WiseImage } from "@/components/Image";

const Models: FC = () => {
  const { models, chooseModel, setChooseModel, getModels } = chatStore
  const { userInfo } = userStore;
  const { setOpenVip } = uiStrore;
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation();

  useEffect(() => {
    getModels();
    // eslint-disable-next-line
  }, [userInfo])

  useEffect(() => {
    if (open) {
      const scrollElement = listRef.current?.querySelector('.ms-container');
      const activeElement = listRef.current?.querySelector(`.${styles.active}`) as HTMLDivElement;
      if (!scrollElement || !activeElement) return;
      scrollElement?.scrollTo({ top: activeElement.offsetTop - 240, behavior: 'auto' });
    }
    // eslint-disable-next-line
  }, [open])

  useOutsideClick({
    ref: listRef,
    handler: e => {
      if (open && !(e.target as Element).classList.contains('no-click')) {
        setOpen(false);
      }
    }
  })
  return <Box className={styles.models}>
    <Box ref={ref} className={`${styles.currentModel} ${open ? styles.openChoose : ''} no-click`}
      onClick={() => setOpen(!open)}>
      <Box className={styles.modelImg}>
        <Image className={`${styles.icon} no-click`} src={chooseModel?.expand.group.avatar || ''} />
      </Box>
      <Box>
        <Text className={`${styles.name} no-click`}>
          {chooseModel?.name.replace('-', ' ')}
          <Icon className={`${styles.arrow} no-click`} as={require('@/assets/svg/arrow_r.svg').ReactComponent} />
        </Text>
        <Text className={`${styles.choose} no-click`}>
          {t('model.choose_generate_model')}
        </Text>
      </Box>
    </Box>
    {
      open && <Box className={styles.chooseModel} ref={listRef}>
        {userInfo?.monVip ?
          <Flex className={styles.vipbox}>
            <Icon as={require('@/assets/svg/chatisVip.svg').ReactComponent} />
            <Text>{t('VipAllModelFree')}</Text>
          </Flex>
          :
          <Flex className={styles.notvipbox}>
            <Icon as={require('@/assets/svg/chatisVip.svg').ReactComponent} />
            <Text>{t('VipEnjoyModelFree')}</Text>
            <CButton className={styles.notvipbut} onClick={() => { setOpen(false); setOpenVip(true) }}>{t('activation')}</CButton>
          </Flex>}
        <ScrollBarBox className={styles.lists}>
          {
            Object.keys(models).map((key) => {
              return <Box key={models[key].name} className={styles.groupItem}>
                <Box className={styles.modelGroup}>
                  <Image className={styles.logo} src={models[key].avatar} />
                  <Text className={styles.groupText}>{models[key].name}</Text>
                </Box>
                <Box className={styles.modelList}>
                  {
                    models[key].models.map(item => {
                      const isActive = chooseModel?._id === item._id;
                      const isFree = item.title.indexOf('免费') > -1 || item.params.single_price === 0;
                      return <Box key={item._id} className={`${styles.modelItem} ${isActive ? styles.active : ''} ${userInfo?.monVip ? styles.free : ''}`}
                        onClick={() => { setChooseModel(item); setOpen(false) }}>
                        <Box className={styles.title}>
                          <Box className={styles.top}>
                            <Text className={styles.name}>
                              {item && item.name && (item.name.slice(item.name.indexOf('-') + 1))}
                            </Text>
                            {
                              item.expand.tag ? <Text className={styles.recommend} bg={`${item.expand.tag.color} !important`}>
                                <WiseImage className={styles.tagIcon} src={item.expand.tag.icon} />
                                {t(item.expand.tag.title)}
                              </Text> : (item.key.indexOf('gpt') === -1 && <Text className={styles.tag}>
                                {item.expand.subtitle}
                              </Text>)
                            }
                          </Box>
                          {
                            isFree && <Text className={`${styles.tag} ${styles.exp}`}>{t('LIMF')}</Text>
                          }
                          {
                            item.params.single_price ? <Text className={styles.price}>
                              {item.params.single_price}
                              <Icon as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
                              /{t('model.per_time')}
                            </Text>
                              : ''
                          }
                        </Box>
                        {
                          <Text className={styles.desc}>{item.expand.titles.join('、')}</Text>
                        }
                      </Box>
                    })
                  }
                </Box>
              </Box>
            })
          }
        </ScrollBarBox>
      </Box>
    }
  </Box >
}

export default observer(Models)