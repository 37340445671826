
import { Box, Icon } from "@chakra-ui/react";
import { FC } from "react";

import styles from './index.module.scss'

export const DragFile: FC = () => {
  return <Box className={styles.DragBox}>
    <Icon className={styles.icon} as={require('@/assets/svg/drop_upload.svg').ReactComponent} />
    <Box>拖拽到此处上传</Box>
  </Box>
}