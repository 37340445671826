import { FC } from "react";
import styles from './index.module.scss'

interface ProgressBarProps {
  value: number;
}

export const ProgressBar: FC<ProgressBarProps> = ({ value }) => {
  return (
    <div className={styles.progressContainer}>
      <div className={styles.progressBar}>
        <div className={styles.progress} style={{ width: `${value}%` }}></div>
      </div>
      {/* <span className={styles.progressText}>{value ? value.toFixed(2) : 0}%</span> */}
    </div>
  );
}
