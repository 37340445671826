import { getAgentById } from "@/api/agents";
import { fetchMineAgents } from "@/api/chat";
import { agentsDb } from "@/db/agents";
import { makeAutoObservable } from "mobx";

class AgentsStore {
  list: MineAgentsType | undefined = undefined;
  activeInfo: MineAgentsType['myModels'][0] | undefined = undefined;
  activeDetail?: ModelSchema;
  showNav = true;
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async fetchList() {
    let isGet = false;
    agentsDb.getAgentsList().then(res => {
      if (res && !isGet) {
        this.setList(res);
      }
    })
    const res = await fetchMineAgents();
    isGet = true;
    if (this.activeInfo?._id) {
      const activeInfo = res.myModels.find(item => item._id === this.activeInfo?._id);
      this.setActiveInfo(activeInfo);
    }
    this.setList(res, true);
    return res;
  }

  async fetchDetail(id: string) {
    let isGet = false;
    agentsDb.getAgentDetail(id).then(res => {
      if (res && !isGet) {
        this.setActiveDetail(res);
      }
    })
    const res = await getAgentById(id);
    isGet = true;
    this.setActiveDetail(res, true);
  }

  setList(list: MineAgentsType, writeDb = false) {
    this.list = list;
    writeDb && agentsDb.setAgentsList(list)
  }


  setActiveInfo(info?: MineAgentsType['myModels'][0]) {
    this.activeInfo = info;
  }

  setActiveDetail(detail: ModelSchema, writeDb = false) {
    this.activeDetail = detail;
    writeDb && agentsDb.setAgentDetail(detail)
  }

  setShowNav(show: boolean) {
    this.showNav = show;
  }
}

export const agentsStore = new AgentsStore();