import { createHashPassword } from "@/utils/common"
import { GET, POST } from "./request"
import { UserAuthTypeEnum } from "@/store/user"

export const userLogin = (data: LoginByPasswordPoss) => {
  return POST<{ user: UserResult }>('/user/loginByPassword', {
    username: data.username,
    password: createHashPassword(data.password)
  })
}

export const userLoginByCode = async (data: LoginCodeType) => {
  return POST<{ user: UserResult, isRegister: boolean }>('/user/loginByCode', data);
}

export const sendAuthCode = (data: { username: string; type: `${UserAuthTypeEnum}`; googleToken: string; }) => {
  return POST('/user/sendAuthCode', data);
}

// 找回密码
export const postFindPassword = ({ username, code, password }: FindPwdParams) => {
  return POST<{ user: UserResult, isRegister: boolean }>('/user/updatePasswordByCode', {
    username,
    code,
    password: createHashPassword(password)
  });
}

//获取累计签到奖励
export const fetchMonthBills = () => GET<any>(`/user/getMonthBill`);

// 为用户增加签到
export const fetchSignIn = () => POST<any>('/user/addSignIn');

// 查询用户累计签到
export const fetchCumulativeSignIn = () => POST<any>('/user/getCumulativeSignIn');

// 用户领取累计签到奖励
export const updateCumulativeSignIn = (title: string) => POST<any>('/user/setCumulativeSignIn', { title });

//更新订单状态
export const fetchcheckPayResult = (payId: string) => GET<number>(`/user/checkPayResult?payId=${payId}`);

// export const fetchcheckPayResult = (payId: string) => GET<number>(`/user/updatePayStatus?payid=${payId}`);

// 解除微信绑定
export const fetchwxUnBind = () => POST<any>('/user/wxUnBind');

// 修改用户昵称
export const fetchUserNickname = ({ nickname }: { nickname: string }) => {
  return POST('/user/updatenickname', { nickname });
}

// 修改用户昵称
export const fetchUserAvatar = ({ avatar, openaiKey }: { avatar: string, openaiKey: string }) => {
  return POST('/user/update', { avatar, openaiKey });
}

// 通过token更新获取用户数据
export const fetchTokenLogin = () => { return GET('/user/tokenLogin'); }

// 修改用户绑定手机号
export const fetchUserPhone = ({ username, code }: { username: string, code: string }) => {
  return POST('/user/updatebindPhone', { username, code });
}

//
export const getJsPayCode = (amount: number, orderType: string, isWechat: boolean, vipCategoryId?: string, vipType?: string) =>
  GET<{
    codeUrl: string;
    payId: string;
    appId: string;
    timeStamp: string;
    nonceStr: string;
    paySign: string;
    payType: string;
    url?: string;
    h5Url?: string;
    partnerId?: string;
    packageValue?: string;
    prepayid?: string;
  }>(`/user/getJsPayCode?amount=${amount}&orderType=${orderType}&isWechat=${isWechat ? "1" : '0'}&vipCategoryId=${vipCategoryId}&vipType=${vipType}`);

export const getPayCode = (amount: number, orderType: string, vipCategoryId?: string, vipType?: string) =>
  GET<{
    codeUrl: string;
    payId: string;
    payType: string;
    url?: string;
  }>(`/user/getPayCode?amount=${amount}&orderType=${orderType}&vipCategoryId=${vipCategoryId}&vipType=${vipType}`);
// 获取登录关注微信公众号的二维码
export const getWechatQrCode = (key: string) => {
  return POST<string>('/user/wxGetQrcode', { key });
}

export const getWechatScanResult = (key: string) => {
  return GET<UserResult | 'scanWait'>('/user/wxScanResult?key=' + key);
}

//获取所有用户收藏的工作台
export const fetchAllWorktool = () => POST<WorktoolSchema[]>(`/user/worktool/list`);

//新增收藏的工作台
export const fetchallworktool = ({ label }: { label: String }) =>
  POST<WorktoolSchema[]>('/user/worktool/create', {
    label
  });

/* 取消删除收藏中的工作台 */
export const cancelcollectUserWorktool = ({ label }: { label: string }) =>
  POST<WorktoolSchema[]>(`/user/worktool/cancelcollect`, {
    label
  });

export const loginOut = () => GET('/user/loginout');

//微信扫码登陆，首次绑定手机
export const postWxBindPhone = ({ username, code, password, wxopenid, inviterId }: {
  username: string; code: string; password: string; wxopenid: string; inviterId?: string;
}) =>
  POST<{ user: UserResult }>('/user/wxbindPhone', {
    username,
    code,
    password: createHashPassword(password),
    wxopenid,
    inviterId
  });

export const updateWindowsClient = (version: string) => {
  return GET<{ notes: string }>('/system/pcVersion', { version, platform: 'win32', arch: 'x64' });
}