import { Box, Icon, Text, useOutsideClick } from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import { creationStore, CreationType } from "@/store/creation";
import { observer } from "mobx-react-lite";
import { CreationHeader, CreationPrompt, CreationScale, CreationStyle, NegativePrompt, CreationReference, CreationInfo, CreationPreview, CreationContent, CreationSeed, CreationQuality } from "../Components";
import { SliderProps } from "@mui/base/Slider";
import { CButton } from "@/components/Button";
import { ScrollBarBox } from "@/components/ScrollBox";
import { getOSSBlobResource, PRICE_SCALE, saveBlobToLocal, SHOW_PRICE_SCALE } from "@/utils/common";
import { useMessage } from "@/hooks/useMessage";
import { userStore } from "@/store/user";
import { globalStore } from "@/store/global";
import { getLangtranslate, postCreateDrawImage, retryDraw, shareDrawImg } from "@/api/creation";
import { useQuery } from "@tanstack/react-query";
import { RenderImg } from "../Components/ImageItem";
import { uiStrore } from "@/store/ui";
import { WisePage } from "@/components/Pagination";

import styles from '../SD/index.module.scss'
import { PartReDraw } from "../Components/PartReDraw";
import { useTranslation } from "react-i18next";

const pageType = CreationType.SD3;
const scaleList: ScalType[] = [
  { "key": "11", "name": "1:1", "w": "512", "h": "512", "cost_coef": 1, "enable": true },
  { "key": "169", "name": "16:9", "w": "965", "h": "543", "cost_coef": 1, "enable": true },
  { "key": "916", "name": "9:16", "w": "543", "h": "965", "cost_coef": 1, "enable": true },
  { "key": "32", "name": "3:2", "w": "888", "h": "592", "cost_coef": 1, "enable": true },
  { "key": "23", "name": "2:3", "w": "592", "h": "888", "cost_coef": 1, "enable": true },
  { "key": "45", "name": "4:5", "w": "362", "h": "724", "cost_coef": 1, "enable": true },
  { "key": "54", "name": "5:4", "w": "724", "h": "362", "cost_coef": 1, "enable": true },
  { "key": "219", "name": "21:9", "w": "836", "h": "627", "cost_coef": 1, "enable": true },
  { "key": "921", "name": "9:21", "w": "627", "h": "836", "cost_coef": 1, "enable": true }
]

export const WiseSD3: FC = observer(() => {
  const {
    getCreationHistory, setShouldUpdate, init,
    cnPrompt, setCnPrompt, negativeCnPrompt, setNegativeCnPrompt, prompt, negativePrompt,
    styleList, styleConfig, showStyles, setStyleConfig, sizeScal, setSizeScal, seed, setSeed,
    imgToImgUrl, imgToImgWeight, setImgToImgUrl, setImgToImgWeight,
    sd3Type, setSD3Type, sd3Model, setSD3Model
  } = creationStore[pageType];
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [sd3Info, setSD3Info] = useState<QualityType>();
  const contentRef = useRef<HTMLDivElement>(null);

  const { userInfo, updateUserBalance } = userStore;
  const { setLoginIsOpen } = globalStore;
  const message = useMessage();
  const styleTips = {
    title: t("creation.StyleReference"),
    tips: [
      t('creation.SDTips1'),
      t('creation.SDTips2')
    ]
  }

  const sd3Quality: QualityType[] = [
    { cost_coef: 35, enable: true, key: 'Core', name: t('creation.Clarity') },
    { cost_coef: 45, enable: true, key: 'SD3', name: t('creation.HD') },
    { cost_coef: 90, enable: true, key: 'Ultra', name: t('creation.UltraHD') },
  ]
  const marks: SliderProps['marks'] = [
    { value: 0, label: t('low') },
    { value: 1, label: t('high') },
  ]
  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!sizeScal) {
      setSizeScal('1:1')
    }
    if (sd3Type) {
      const obj = sd3Quality.find(item => item.key === sd3Type);
      obj && setSD3Info(obj);
    } else {
      setSD3Info(sd3Quality[0]);
      setSD3Type(sd3Quality[0].key as SD3Type);
    }
    // eslint-disable-next-line
  }, [])

  const createTask = async () => {
    if (loading || isDisable) return;
    setLoading(true);
    try {
      if (!userInfo) {
        message.warning({ title: t('LoginFirst') });
        setLoginIsOpen(true);
        return;
      }
      if (!userInfo.monVip && (userInfo.balance * SHOW_PRICE_SCALE) < Number(sd3Info?.cost_coef)) {
        message.warning({ title: t('creation.DrawFaildBalance') });
        return;
      }

      let ePrompt = '';
      if (/[\u4e00-\u9fa5]/.test(cnPrompt + '#' + negativeCnPrompt)) {
        //有中文，则翻译
        ePrompt = (await getLangtranslate(cnPrompt + '#' + negativeCnPrompt)).toString();
      }

      const isImgToImg = !!imgToImgUrl;
      const { userBalance, ret, message: resMessage } = await postCreateDrawImage({
        type: pageType,
        prompt: ePrompt.split('#').length > 0 ? (ePrompt.split('#')[0] || cnPrompt) : prompt,
        drawType: styleConfig.name,
        sd3DrawParam: {
          prompt: ePrompt.split('#').length > 0 ? (ePrompt.split('#')[0] || cnPrompt) : prompt,
          api_type: sd3Type,
          seed,
          aspect_ratio: sizeScal,
          mode: isImgToImg ? 'image-to-image' : 'text-to-image',
          negative_prompt: ePrompt.split('#').length > 1 ? (ePrompt.split('#')[1] || negativeCnPrompt) : negativePrompt,
          model: sd3Type === 'SD3' ? sd3Model : undefined,
          style_preset: styleConfig.name,
          ...(isImgToImg && { image: imgToImgUrl, strength: imgToImgWeight || 0 }),
        },
        cnPrompt: cnPrompt,
        negativePrompt: ePrompt.split('#').length > 1 ? (ePrompt.split('#')[1] || negativeCnPrompt) : negativePrompt,
        negativeCnPrompt: negativeCnPrompt,
        group: 'AIDraw',
        ...(isImgToImg && { extendsType: 'imgToImg', imgToImgUrl, imgToImgWeight: imgToImgWeight || 0 }),
      })

      if (ret === 'successd') {
        updateUserBalance(userBalance);
        setShouldUpdate(true);
        message.success({ title: t('creation.DrawTaskSuccess') });
        getCreationHistory();
      } else {
        message.error({ title: resMessage });
      }
    } catch (err: any) {
      console.log(err)
      if (err?.message === 'political_content') {
        message.error({ title: t('creation.InputContainsPolitical') });
      } else {
        message.error({ title: err.message });
      }
    } finally {
      setLoading(false);
    }
  }

  const isDisable = !cnPrompt || !styleConfig || !sizeScal;

  return <CreationContent.Content>
    <CreationContent.Inputs>
      <CreationHeader icon='draw_sd_000' text={t('wise') + 'SD3.0'} />
      <Box className={styles.container} ref={contentRef}>
        <ScrollBarBox className={styles.content}>
          <CreationStyle
            styleList={styleList}
            selectedStyle={styleConfig}
            onStyleChange={setStyleConfig}
            showStyles={showStyles}
          />
          <CreationPrompt onClear={() => { setCnPrompt(''); setNegativeCnPrompt(''); }}
            cnPrompt={cnPrompt}
            onInput={setCnPrompt}
            type={pageType}
          />
          <NegativePrompt negativeCNPrompt={negativeCnPrompt} onInput={setNegativeCnPrompt} />
          <CreationSeed value={seed} onSeed={setSeed} />
          <CreationScale scaleList={scaleList} onScaleChange={setSizeScal} value={sizeScal} />
          <CreationQuality qualityList={sd3Quality} value={sd3Info?.name} onQualityChange={(level) => {
            const obj = sd3Quality.find(item => item.name === level);
            obj && setSD3Type(obj.key as SD3Type);
            obj?.key === 'SD3' && setSD3Model('sd3-large-turbo');
            setSD3Info(obj);
            const scrollEle = contentRef.current?.querySelector(`.ms-container`);
            if (!scrollEle) return;
            // const isScrollBottom = scrollEle?.scrollHeight - scrollEle?.scrollTop === scrollEle?.clientHeight;

            setTimeout(() => {
              scrollEle?.scrollTo({ top: scrollEle.scrollHeight, behavior: 'smooth' });
            }, 30);
          }} />
          {
            sd3Type === 'SD3' && <CreationReference
              title={t('creation.StyleReference')}
              tips={styleTips}
              sliderProps={{ marks, min: 0, max: 1, step: 0.1, onChange: (_, value) => setImgToImgWeight(value as number) }}
              url={imgToImgUrl}
              onUrlChange={setImgToImgUrl}
            />
          }
        </ScrollBarBox>
      </Box>
      <Box className={styles.create}>
        {userInfo?.monVip ?
          <CButton className={styles.vipbtn} theme='primary' isLoading={loading} disabled={isDisable} onClick={createTask}>
            <Box className={styles.vipprice}>
              <Icon as={loading ? require('@/assets/svg/navisVipNull.svg').ReactComponent : require('@/assets/svg/navisVip.svg').ReactComponent} />
              <Text>{t('creation.FreeDrawing')}</Text>
            </Box>
          </CButton>
          :
          <CButton className={styles.btn} theme='primary' isLoading={loading} disabled={isDisable} onClick={createTask}>
            <Text className={styles.start}>{t('creation.StartDrawing')}</Text>
            <Box className={styles.price}>
              <Text>{sd3Info?.cost_coef}</Text>
              <Icon as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
            </Box>
          </CButton>
        }
      </Box>
    </CreationContent.Inputs>
    <WiseSDGenerate />
  </CreationContent.Content>
})

const WiseSDGenerate: FC = observer(() => {
  const {
    historyList, styleList, pageNum, pageSize, setPageNum, total, shouldUpdate, faverites,
    setSizeScal, setHrLevel, setHrPriceLevel, setPrompt, setCnPrompt, setNegativePrompt, setNegativeCnPrompt, setSeed,
    setStyleConfig, deleteCreationAction, getCreationHistory, setShouldUpdate, postCollection, setFaverites
  } = creationStore[pageType];
  const { userInfo } = userStore;
  const { openAlert, closeAlert } = uiStrore;

  const message = useMessage();
  const moreEdit = useRef<HistoryItem>();
  const editRef = useRef<HTMLDivElement>(null);
  const [morePosition, setMorePosition] = useState<undefined | { x: number, y: number, isFirstEdit: boolean, index: number }>();
  const [viewSwiper, setViewSwiper] = useState(false);
  const viewInfo = useRef<HistoryItem>();
  const [infoIndex, setInfoIndex] = useState(0);
  const [openPartReDraw, setOpenPartReDraw] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (shouldUpdate) {
      setShouldUpdate(false);
    }
    // eslint-disable-next-line
  }, [shouldUpdate])

  useOutsideClick({
    ref: editRef,
    handler: () => {
      setMorePosition(undefined);
    }
  })

  // 存在绘画任务轮询更新列表
  useQuery(
    ['refreshDrawList'],
    () => {
      getCreationHistory();
      return null
    },
    {
      refetchInterval: 30000,
      enabled: pageNum === 1 && !!userInfo?._id
    }
  )

  // 复用绘画参数
  const usePrompt = (item: HistoryItem) => {
    let _index = -1;
    const currentStyle = styleList.find((style, index) => {
      _index = index;
      return style.name === item.drawType
    });
    setPrompt(item.prompt.en);
    setCnPrompt(item.prompt.cn);
    setNegativePrompt(item.prompt.negativeEn);
    setNegativeCnPrompt(item.prompt.negativeCn);
    setSizeScal(item.sizeScal);
    setHrLevel(item.hrLevel);
    setHrPriceLevel(item.hrPriceLevel);
    currentStyle && setStyleConfig(currentStyle, _index); // 选中当前风格
    if (item.type === pageType) {
      item.extends?.sdSeed && setSeed(item.extends.sdSeed)
    }
    message.success({ title: t('creation.ReuseSuccess') })
  }

  // 下载单张图片
  const download = async (url: string) => {
    if (!url) return;
    try {
      const res = await getOSSBlobResource(url);
      const suggestedName = url.substring(url.lastIndexOf('/') + 1)

      await saveBlobToLocal(res, `${suggestedName}.png`);
      message.success({ title: t('ImgDownloadSuccess') })
    } catch (err: any) {
      if (err.message !== 'The user aborted a request.') {
        message.error({ title: t('ImgDownloadFaild') })
      }
    }
  }

  const onFavorite = async (id: string, url: string) => {
    const isFavorite = faverites.get(id)?.includes(url) || false;
    try {
      const res = await postCollection(id, url);
      setFaverites(id, res[id] || []);
      if (isFavorite) {
        message.success({ title: t('CancelCollectionSuccess') })
      } else {
        message.success({ title: t('collected') })
      }
    } catch (err: any) {
      message.error({ title: err.message })
    }
  }

  // 点击显示更多操作
  const onMore = (e: React.MouseEvent<SVGElement, MouseEvent>, url: string, item: HistoryItem) => {
    e.stopPropagation();
    //@ts-ignore
    let { x, y } = e.target.getBoundingClientRect();
    const index = item.imageUrl.findIndex(i => i === url);
    let isFirstEdit = false;
    if (item.imageUrl.filter(i => !!i).length === 1 || index === 0) {
      isFirstEdit = true;
    }
    const _BoxHeigh = 210, _BoxWidth = 140;
    if (e.nativeEvent.y + _BoxHeigh > window.innerHeight) {
      y = y - _BoxHeigh;
    } else {
      y = y + 15;
    }
    if (e.nativeEvent.x + _BoxWidth > window.innerWidth) {
      x = x - _BoxWidth;
    } else {
      x = x + 15;
    }
    setMorePosition({ x, y, isFirstEdit, index });
    moreEdit.current = item;
  }

  // 预览大图
  const onPreview = (item: HistoryItem, index: number) => {
    viewInfo.current = item;
    setInfoIndex(index);
    setViewSwiper(true);
  }

  const onDelete = async (id: string) => {
    openAlert({
      title: t('creation.deleteCreate'),
      content: t('creation.DeleteCantRecover'),
      status: 'warning',
      onOk: async () => {
        await deleteCreationAction(id || '')
        closeAlert();
        message.success({ title: t('deleteSuccess') });
        getCreationHistory();
      }
    })
  }

  const onRetry = async (item: HistoryItem) => {
    try {
      await retryDraw({ _id: item._id, type: item.type });
      getCreationHistory();
    } catch (err: any) {
      message.error({ title: err.message })
    }
  }

  const onShare = async () => {
    if (!viewInfo.current) return
    try {
      await shareDrawImg({ _id: viewInfo.current._id || '', url: viewInfo.current.imageUrl[infoIndex], type: CreationType.MJ });
      message.success({ title: t('ShareSuccess') });
    } catch (err: any) {
      message.error({ title: err.message })
    }
  }

  return <CreationContent.History>
    <Box className={styles.historyTitle}>
      <Text className={styles.text}>{t('history')}</Text>
    </Box>
    <ScrollBarBox className={styles.listBox}>
      {
        historyList.map((item, _index) => {
          return <Box key={item._id} className={styles.historyItem}>
            <CreationInfo.CreationTitle
              type={pageType}
            />
            <RenderImg
              type={pageType}
              sizeScale={item.sizeScal}
              favorites={faverites.get(item._id)}
              item={item}
              onFavorite={onFavorite}
              onMore={(e, url) => onMore(e, url, item)}
              onView={_index => onPreview(item, _index)}
              onReDraw={() => onRetry(item)}
              onDelete={() => onDelete(item._id)}
            />
            <Box className={styles.bottomInfo}>
              <CreationInfo.Calendar time={item.time} />
              {
                item.drawType && <CreationInfo.DrawType drawType={item.drawType} />
              }
              <CreationInfo.Price isVip={item.isVip} price={parseInt((item.extends.costPoint / PRICE_SCALE * SHOW_PRICE_SCALE).toFixed(1))} />
            </Box>
          </Box>
        })
      }
      {
        historyList.length % 3 !== 0 && <Box w='160px' visibility='hidden' className={styles.historyItem}></Box>
      }
      {
        total > pageSize && <WisePage pageNum={pageNum} pageSize={pageSize} total={total} onPageChange={setPageNum} />
      }
    </ScrollBarBox>
    {
      morePosition && <Box ref={editRef} className={styles.moreEdit} style={{ left: morePosition.x, top: morePosition.y }}>
        <Box className={styles.moreItem} onClick={() => {
          if (moreEdit.current) {
            usePrompt(moreEdit.current)
          }
        }}>{t('creation.Reuse')}</Box>
        <Box className={styles.moreItem} onClick={() => setOpenPartReDraw(true)}>{t('PartialRepaint')}</Box>
        <Box
          className={styles.moreItem}
          onClick={() => download(moreEdit.current?.imageUrl[morePosition?.index || 0] || '')}>
          {t('download')}
        </Box>
        <Box className={styles.moreItem} onClick={() => onDelete(moreEdit.current?._id || '')}>{t('delete')}</Box>
      </Box>
    }
    <CreationPreview
      imgList={viewInfo.current?.imageUrl || []}
      imgIndex={infoIndex || 0}
      isOpen={viewSwiper}
      onClose={() => setViewSwiper(false)}
      userAvatar={userInfo?.avatar || ''}
      userName={userInfo?.nickName || userInfo?.wiseNickName || ''}
      drayInfo={{
        id: viewInfo.current?._id || '',
        type: viewInfo.current?.drawType || '',
        avatar: styleList.find(style => style.name === viewInfo.current?.drawType)?.view_image || '',
      }}
      isFavorite={faverites.get(viewInfo.current?._id || '')?.includes(viewInfo.current?.imageUrl[infoIndex || 0] || '') || false}
      prompt={viewInfo.current?.prompt.cn || viewInfo.current?.prompt.en || ""}
      negativePrompt={viewInfo.current?.prompt.negativeCn || viewInfo.current?.prompt.negativeEn || ""}
      onDownload={download}
      sizeScal={viewInfo.current?.sizeScal || ''}
      hrLevel={viewInfo.current?.hrLevel || ''}
      imgToImg={{ url: viewInfo.current?.extends.imgToImgUrl || '', weight: viewInfo.current?.extends.imgToImgWeight || 0 }}
      createTime={viewInfo.current?.time}
      onChangeIndex={setInfoIndex}
      onFavorite={() => onFavorite(viewInfo.current?._id || '', viewInfo.current?.imageUrl[infoIndex || 0] || '')}
      onSameDraw={() => {
        viewInfo.current && usePrompt(viewInfo.current);
        setViewSwiper(false);
      }}
      onShare={onShare}
      type={pageType}
      extendsType={viewInfo.current?.extends.extendsType}
    />
    {
      moreEdit.current && <PartReDraw
        open={openPartReDraw}
        onClose={() => setOpenPartReDraw(false)}
        item={moreEdit.current}
        partReDrawUrl={moreEdit.current.imageUrl[morePosition?.index || 0]}
        styleList={styleList}
        shouldUpdate={() => setPageNum(1)}
        isVip={userInfo?.monVip || false}
      />
    }
  </CreationContent.History>
})

