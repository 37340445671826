import GlobalToast, { GlobalToastProps } from "@/components/Toast";
import { Context, createContext, useContext, useEffect, useState } from "react";

interface UseGlobalToastProps extends GlobalToastProps {
  openToast: (props: GlobalToastProps) => void
  closeToast: () => void
}
const ToastContext: Context<UseGlobalToastProps> = createContext({
  openToast: ({ isOpen }) => { },
  closeToast: () => { }
});

function useGlobalToast() {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
}

const ToastProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const [toastState, setToastState] = useState<GlobalToastProps>({ isOpen: false, })

  function openToast(info: GlobalToastProps) {
    setToastState({ isOpen: true, ...info });
  }

  function closeToast() {
    setToastState({ isOpen: false, });
  }

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (toastState.isOpen) {
      timer = setTimeout(() => {
        closeToast();
      }, 2000); // 3000 毫秒（3 秒）后关闭 Toast
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [toastState.isOpen]);

  const value: UseGlobalToastProps = {
    ...props,
    isOpen: toastState.isOpen,
    openToast,
    closeToast,
  };

  return <ToastContext.Provider value={value}>
    {children}
    {toastState.isOpen && <GlobalToast {...toastState} />}
  </ToastContext.Provider>
}

export { ToastProvider, useGlobalToast };