import { Box } from "@chakra-ui/react";
import { FC } from "react";
import styles from './index.module.scss';
import { observer } from "mobx-react-lite";
import VipHeader from './components/Header';
import VipContent from './components/Content'

export const Recharge: FC = observer(() => {

  return (
    <Box className={styles.content}>
      <VipHeader />
      <VipContent />
    </Box>
  )
})