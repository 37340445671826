import { Box, Flex, Text, Icon, Image, Tooltip, Grid, Stack, Card, Stepper, Step, StepSeparator, useSteps } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import styles from './index.module.scss';
import dayjs from 'dayjs';
import { CButton } from "@/components/Button";
import { useTranslation } from "react-i18next";
import { userStore } from "@/store/user";
import { personalStore } from "@/store/personal";
import { observer } from "mobx-react-lite";
import { useMessage } from "@/hooks/useMessage";
import { CModal } from "@/components/Modal";
import { uiStrore } from "@/store/ui";

interface Step {
  title: 'three' | 'seven' | 'fourteen' | 'twentyOne' | 'twentySeven';
  description: string;
  value: string;
  receive: boolean;
}

const steps: Step[] = [
  { title: 'three', description: '3', value: '20', receive: false },
  { title: 'seven', description: '7', value: '30', receive: false },
  { title: 'fourteen', description: '14', value: '40', receive: false },
  { title: 'twentyOne', description: '21', value: '60', receive: false },
  { title: 'twentySeven', description: '27', value: '80', receive: false },
]

export const SignIn: FC = observer(() => {
  const { t } = useTranslation();
  const { userInfo } = userStore;
  const { creatSignIn,
    AccumulatePoints,
    getMonthBills,
    cumulativeSignIn,
    getCumulativeSignIn,
    updateCumulativeSignIn
  } = personalStore;
  const message = useMessage();
  const [signInDates, setSignInDates] = useState<string[]>([]);
  const [thisMonth, setThisMonth] = useState('');
  const [allNums, setAllNums] = useState('');
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  const weekdays = [t('Sun'), t('Mon'), t('Tue'), t('Wed'), t('Thu'), t('Fri'), t('Sat')];
  const daysInMonth = [];
  const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
  const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  for (let day = monthStart; day <= monthEnd; day.setDate(day.getDate() + 1)) {
    daysInMonth.push(new Date(day));
  }
  const signStatus: { date: string; week: number; status: string; }[] = [];
  daysInMonth.forEach(day => {
    const formattedDay = dayjs(day).format('YYYY-MM-DD');
    const dayOfWeek = day.getDay();
    let tmpArray: { date: string; week: number; status: string; }[] = [];
    if (formattedDay.endsWith("01")) {
      if (dayOfWeek !== 0) {
        for (let i = 0; i < dayOfWeek; i++) {
          tmpArray.push({ date: ' ', week: 0, status: 'unsigned' });
        }
      }
    }
    const isSignedIn = signInDates.some(dateStr =>
      dayjs(dateStr).isSame(dayjs(day), 'day')
    );
    const isToday = dayjs(day).isSame(dayjs(today), 'day');
    const isExpired = day.getTime() < today.getTime();

    tmpArray.push({
      date: formattedDay,
      week: dayOfWeek,
      status: isSignedIn ? 'signedIn' : (isToday ? 'today' : (isExpired ? 'expired' : 'unsigned'))
    });
    signStatus.push(...tmpArray);
  });

  const [signedDays, setsignedDays] = useState(0)
  const [points, setpoints] = useState(0)
  const [remainingDays, setremainingDays] = useState(0)
  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  })
  const [leftpercent, setLeftpercent] = useState(0)
  const [open, setOpen] = useState<boolean>(false);
  const formattedDay = dayjs(today).format('YYYY-MM');

  useEffect(() => {
    const fetchSignInDates = async () => {
      if (userInfo) {
        const signInDates = userInfo.signindates.filter(dateStr => {
          const date = new Date(dateStr);
          return date.getMonth() + 1 === currentMonth && date.getFullYear() === currentYear;
        });
        setSignInDates(signInDates)
      }
      await getCumulativeSignIn()
      await getMonthBills()
      setThisMonth((Math.abs(AccumulatePoints.monthtotalSum) / 10000).toFixed(2));
      setAllNums((Math.abs(AccumulatePoints.alltotalSum) / 10000).toFixed(2));
    };
    fetchSignInDates();
  }, [userInfo]);
  useEffect(() => {
    let percent = 0;
    let point = 0;
    let remainingDay = 0;
    signInDates.map((item) => {
      const date = new Date(item);
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      if (formattedDate.toString().includes(formattedDay)) {
        percent += 1
      }
    })
    if (percent < 3) {
      setActiveStep(0); point = 20; remainingDay = 3 - percent;
    } else if (percent < 7) {
      setActiveStep(1); point = 30; remainingDay = 7 - percent;
    } else if (percent < 14) {
      setActiveStep(2); point = 40; remainingDay = 14 - percent;
    } else if (percent < 21) {
      setActiveStep(3); point = 60; remainingDay = 21 - percent;
    } else if (percent < 27) {
      setActiveStep(4); point = 80; remainingDay = 27 - percent;
    } else {
      setActiveStep(5); point = 0; remainingDay = 0
    }
    setsignedDays(percent); setpoints(point); setremainingDays(remainingDay);
  }, [signInDates])

  useEffect(() => {
    const newnumber = activeStep === 0 ? 1 / 10 : ((activeStep - 1) * 2) / (1 - 0) * 10;
    setLeftpercent(newnumber);
  }, [activeStep])


  const toSignIn = async () => {
    try {
      const res = await creatSignIn();
      if (res) {
        setOpen(true)
      } else {
        message.warning({ title: '签到失败，请联系管理员' })
      }
    } catch (err) {
      message.error({ title: '签到错误' })
    }
  }

  const dateOnlyArray = userInfo && userInfo.signindates ? Object.values(userInfo.signindates).map(entry => entry.split('T')[0]) : [];
  const inputDate = new Date(today);
  const formattedInputDate = inputDate.toISOString().split('T')[0];
  const isSignIn = dateOnlyArray.includes(formattedInputDate);

  const { openAlert, closeAlert } = uiStrore;
  const getStatusIcon = (status: any) => {
    switch (status) {
      case 'prohibit':
        return require('@/assets/svg/toBeClaimed.svg').ReactComponent;
      case 'claimed':
        return require('@/assets/svg/claimed.svg').ReactComponent;
      case 'unclaimed':
        return require('@/assets/svg/unClaimed.svg').ReactComponent;
      default:
        return require('@/assets/svg/toBeClaimed.svg').ReactComponent;
    }
  };
  const cumulativeStatus = {
    three: cumulativeSignIn.three,
    seven: cumulativeSignIn.seven,
    fourteen: cumulativeSignIn.fourteen,
    twentyOne: cumulativeSignIn.twentyOne,
    twentySeven: cumulativeSignIn.twentySeven,
  };
  return (
    <Box className={styles.parent}>
      <Box className={styles.child}>
        <Box className={styles.allbox}>
          <Text className={styles.boxtext}>{t('Daily_checkIn_rewards')}</Text>
          <Box className={styles.box}>
            <Text className={styles.text}>（{t('Cumulative_checkIns')}</Text>
            <Text className={styles.specialText}> {signedDays} </Text>
            <Text className={styles.text}>{t('day')}）</Text>
            {/* <Tooltip label='累计签到奖励'> */}
            <Icon className={styles.specialIcon} as={require(`@/assets/svg/signIntips.svg`).ReactComponent}
              onClick={() => {
                openAlert({
                  status: 'warning',
                  title: '签到规则说明',
                  onclose: closeAlert,
                  footer: <Box>
                    <ul>
                      <li className={styles.li}><Box>
                        每天签到可获得<Text className={styles.specialText}>10</Text>点数
                      </Box></li>
                      <li className={styles.li}><Box>
                        每月累计签到3天<span>{'\u3000'}</span>额外奖励<Text className={styles.specialText}>20</Text>点数
                      </Box></li>
                      <li className={styles.li}><Box>
                        每月累计签到7天<span>{'\u3000'}</span>额外奖励<Text className={styles.specialText}>30</Text>点数
                      </Box></li>
                      <li className={styles.li}><Box>
                        每月累计签到14天<span>{'\u3000'}</span>额外奖励<Text className={styles.specialText}>40</Text>点数
                      </Box></li>
                      <li className={styles.li}><Box>
                        每月累计签到21天<span>{'\u3000'}</span>额外奖励<Text className={styles.specialText}>60</Text>点数
                      </Box></li>
                      <li className={styles.li}><Box>
                        每月累计签到27天<span>{'\u3000'}</span>额外奖励<Text className={styles.specialText}>80</Text>点数
                      </Box></li>
                    </ul>
                    <Box className={styles.detailbox} onClick={closeAlert}>
                      <Text>我知道了</Text>
                    </Box>
                  </Box>
                })
              }} />
            {/* </Tooltip> */}
          </Box>
        </Box>
        <Image src={require('@/assets/png/personSignIn.png')} className={styles.signInboxpng} />
        <Flex className={styles.signInbox}>
          <Grid className={styles.calendarbox}>
            {weekdays.map((weekday) => (
              <Text key={weekday} className={styles.weekdays}>{weekday}</Text>
            ))}
            {signStatus.map((day, index) => {
              let content = day.date.substring(8, 10);
              return (
                <Box key={index} className={`${styles.statusbox} ${styles[day.status]}`}>
                  {content && <Text className={`${styles.statustext} ${styles[`${day.status}text`]}`}>{content}</Text>}
                  {day.status === 'today' && <Text className={styles[`${day.status}textok`]}>{t('signIn')}</Text>}
                  {day.status === 'expired' && <Text className={styles[`${day.status}textno`]}>{t('NotSignIn')}</Text>}
                  {day.status === 'signedIn' && <Icon as={require(`@/assets/svg/signInOK.svg`).ReactComponent} />}
                </Box>
              );
            })}
          </Grid>
          <CButton className={isSignIn ? styles.signInedbutton : styles.signInbutton} onClick={() => {
            if (!isSignIn) {
              toSignIn()
            } else {
              message.warning({ title: '您今天已经签到过了' })
            }
          }} >
            {isSignIn ?
              <Text className={styles.signInten}>已签到</Text>
              :
              <Text className={styles.signInten}>{t('signIn')}+10</Text>
            }
            {!isSignIn && <Icon as={require(`@/assets/svg/signInicon.svg`).ReactComponent} />}
          </CButton>
          <Flex className={styles.signInbuttonbox}>
            <Text className={styles.signInbuttontext}>{t('Obtained_this_month')}：</Text>
            <Text className={styles.signInbuttonbills}>{thisMonth}</Text>
            <Icon as={require(`@/assets/svg/signIniconBlue.svg`).ReactComponent} className={styles.signInbuttonicon} />
            <Text className={styles.signInbuttontext}>{t('Accumulative')}：</Text>
            <Text className={styles.signInbuttonbills}>{allNums}</Text>
            <Icon as={require(`@/assets/svg/signIniconBlue.svg`).ReactComponent} className={styles.signInbuttonicon} />
          </Flex>
        </Flex>

        <Flex className={styles.cumulative}>
          <Card >
            <Box className={styles.reward}>
              <Text className={styles.rewardtitle}>{t('Cumulative_checkIn_rewards')}</Text>
              <Text className={styles.rewardtips}>（{t('Cumulatives1')}
                <span className={styles.rewardays}> {remainingDays} </span>
                {t('Cumulatives2')}
                <span className={styles.rewardays}> {points} </span>
                {t('Cumulatives3')}）
              </Text>
            </Box>
            <Box >
              <Stack className={styles.starkbox}>
                <Box className={styles.filled_track}
                  style={{ width: `${leftpercent}%` }}>
                </Box>
                <Box className={styles.unfilled_track}
                  style={{ width: `calc(80% - ${leftpercent}%)` }}>
                </Box>
                <Stepper size={'sm'} index={activeStep} className={styles.stepper}>
                  {steps.map((step, index) => {
                    const status = cumulativeStatus[step.title];
                    const IconComponent = getStatusIcon(status);
                    const toReceive = async () => {
                      try {
                        if (status === 'unclaimed') {
                          const res = await updateCumulativeSignIn(step.title)
                          if (res) {
                            message.success({ title: '领取成功！' })
                          } else {
                            message.error({ title: '领取失败，请重试！' })
                          }
                        }
                      } catch (err) {
                        message.error({ title: '领取错误，请联系管理员' })
                      }
                    }
                    return (
                      <Step key={index}>
                        <Flex className={styles.stepsbox}>
                          <Box className={styles.signInIconBox}>
                            <Icon as={IconComponent} className={styles.signInBoxicon} onClick={toReceive} />
                            <Box>
                              <Text className={styles.signInBoxtext}>{step.description}{t('day')}</Text>
                            </Box>
                          </Box>
                          <Box >
                            <CButton className={styles.signInButton} onClick={toReceive}>
                              <Text className={styles.signInButtontext}>
                                {status === 'claimed' ? '已领取' :
                                  status === 'unclaimed' ? "待领取" :
                                    step.value}
                              </Text>
                              {signedDays < parseInt(step.description) &&
                                <Icon as={require(`@/assets/svg/signInicon.svg`).ReactComponent} className={styles.signInButtonicon} />
                              }
                            </CButton>
                          </Box>
                        </Flex>
                        <StepSeparator />
                      </Step>
                    )
                  })}
                </Stepper>
              </Stack>
            </Box>
          </Card>
        </Flex>

      </Box>
      {/* 签到成功 */}
      <CModal isOpen={open} onClose={() => setOpen(false)}>
        <Flex className={styles.cmodel}>
          <Flex className={styles.cmodelbox}>
            <Text className={styles.cmodeltitle}>签到成功</Text>
            <Box className={styles.cmodeltextbox}>
              <Text>今日签到</Text>
              <Text>+10</Text>
              <Text>点，已累计签到</Text>
              <Text>{signedDays}</Text>
              <Text>天</Text>
            </Box>
          </Flex>
          <Icon as={require(`@/assets/svg/closeSignIn.svg`).ReactComponent} className={styles.cmodelclose} onClick={() => setOpen(false)} />
        </Flex>
      </CModal>
    </Box>
  )
})