import { Box, Flex, Icon, Image, Text, Textarea, Tooltip } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import styles from "./index.module.scss"
import { CModal } from "@/components/Modal";
import { CButton } from "@/components/Button";

export const MultipleTextarea: FC<any> = observer(({
  title,
  prompt,
  boxes,
  setBoxes,
  rows,
  systemPromptTip
}: {
  title: string;
  prompt: any;
  boxes: any;
  setBoxes: (e: any) => void;
  rows: number;
  systemPromptTip?: string;
}) => {
  // 处理文本变化的函数
  const [open, setOpen] = useState<boolean>(false);
  const textChange = (index: any, value: any) => {
    const newBoxes = [...boxes];
    newBoxes[index].textvalue = value;
    setBoxes(newBoxes);
  };
  // 增加 Box 组件的函数
  const addBox = () => {
    if (boxes.length < 5) {
      setBoxes([...boxes, { textvalue: '', maxCount: 15 }]);
    }
  };
  // 删除指定 Box 组件的函数
  const removeBox = (index: any) => {
    const newBoxes = boxes.filter((_: any, i: any) => i !== index);
    setBoxes(newBoxes);
  };
  const [addActive, setAddActive] = useState(false)
  const [delActive, setDelActive] = useState(false)

  return (
    <Flex className={styles.allbox}>
      <Box className={styles.titlebox}>
        <Text className={styles.title}>{title}</Text>
        {systemPromptTip && ( //跟在标题后的提示文字
          <Tooltip>
            <Icon onClick={() => setOpen(true)} />
          </Tooltip>
        )}
      </Box>
      <Box>
        {boxes.map((box: any, index: any) => (
          <Box key={index} className={styles.textareabox}>
            <Box position={'relative'}>
              <Textarea
                className={styles.textarea}
                placeholder={prompt}
                value={box.textvalue}
                onChange={(e) => textChange(index, e.target.value)}
                maxLength={box.maxCount}
              />
              <Text className={styles.maxText}>
                {box.textvalue.length}/{box.maxCount}
              </Text>
            </Box>
            {index === 0 ? (
              <Tooltip label="最多可添加5个微信群名单" isDisabled={boxes.length < 5}>
                <Box className={styles.addbutton} onClick={() => addBox()}>
                  <Icon as={require('@/assets/svg/robotAdd.svg').ReactComponent} />
                </Box>
              </Tooltip>
            ) : (
              <Box className={styles.addbutton} onClick={() => removeBox(index)}
                onMouseEnter={() => { setDelActive(true) }} onMouseLeave={() => { setDelActive(false) }}>
                <Icon as={require('@/assets/svg/robotGroup.svg').ReactComponent} />
              </Box>
            )}
          </Box>
        ))}
      </Box>
      <CModal isOpen={open} onClose={() => setOpen(false)}>
        <Flex className={styles.notbalancebox}>
          <Flex className={styles.notbalanceTitleBox}>
            <Text>{title}</Text>
            <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setOpen(false)} />
          </Flex>
          <Text className={styles.texttips}>{systemPromptTip}</Text>
          <Flex className={styles.modelbuttonbox}>
            <CButton className={styles.Savebutton} onClick={() => setOpen(false)}>
              确定
            </CButton>
          </Flex>
        </Flex>
      </CModal>
    </Flex>
  );
})