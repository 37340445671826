import { Box, Icon } from "@chakra-ui/react"
import { FC, memo, useEffect, useRef, useState } from "react"
import { CreationHeader, CreationPreview } from "../Components"
import { CreationOfType, creationStore, CreationType } from "@/store/creation"
import { observer } from "mobx-react-lite"
import { WiseImage } from "@/components/Image"
import { ScrollBarBox } from "@/components/ScrollBox"

import styles from './index.module.scss'
import { userStore } from "@/store/user"
import { uiStrore } from "@/store/ui"
import { useMessage } from "@/hooks/useMessage"
import { getOSSBlobResource, saveBlobToLocal } from "@/utils/common"
import { useTranslation } from "react-i18next"
import Loading from "@/components/Loading"


let imgBaseWidth = 256;
const can_del_square_user_id = ['64e45ba25c22f78cb9fbe3fd', '65c345ff5027f292d217af0d']
export const CreationSquare: FC = observer(() => {
  const {
    getList, list, params, total, setParams, isInit, setIsInit, adminDelete, favorites, urlList, postCollection
  } = creationStore[CreationType.Square];
  const { styleList: mjStyleList, getStyleConfig: getMJStyleConfig } = creationStore[CreationType.MJ];
  const { styleList: sdStyleList, getStyleConfig: getSDStyleConfig } = creationStore[CreationType.SD];
  const { styleList: sd3StyleList, getStyleConfig: getSD3StyleConfig } = creationStore[CreationType.SD3];
  const { userInfo } = userStore;
  const { pageNum, pageSize, filterType } = params
  const { openAlert, closeAlert } = uiStrore;
  const message = useMessage();
  const { t } = useTranslation();
  const listContainerRef = useRef<HTMLDivElement>(null);
  const [viewInfo, setViewInfo] = useState<CreationShareType>();
  const [viewSwiper, setViewSwiper] = useState(false);
  const [infoIndex, setInfoIndex] = useState(0);

  const _filter: Record<SquareFilterType, string> = {
    'ALL': t('all'),
    'Wise_Draw': t('wise') + 'MJ',
    'SD_Draw': t('wise') + 'SD1.5',
    'SD3_Draw': t('wise') + 'SD3',
    'Dall3_Draw': t('wise') + 'E3',
    // 'WiseGEN2_Draw': '文思GEN2',
  }

  useEffect(() => {
    if (!mjStyleList.length) {
      getMJStyleConfig()
    }
    if (!sdStyleList.length) {
      getSDStyleConfig()
    }

    if (!sd3StyleList.length) {
      getSD3StyleConfig()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const observerElement = document.querySelector('#loadMore') as HTMLDivElement;

    if (!observerElement) {
      return;
    }
    // 监听可视dom 用于渲染更多
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if ((pageNum * pageSize) >= total) {
            observer.unobserve(entry.target);
          } else {
            observerElement.style.display = 'none';
            isInit && setParams({ pageNum: pageNum + 1 });
            setTimeout(() => {
              observerElement.style.display = 'block';
            }, 300)
          }
        }
      });
    });

    observer.observe(observerElement as Element);

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line
  }, [list])

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params])

  useEffect(() => {
    computedWidth();

    if (!listContainerRef.current) return
    window.addEventListener('resize', () => {
      computedWidth()
    })

    return () => {
      setParams({ pageNum: 1 });
      setIsInit(false);
    }
    // eslint-disable-next-line
  }, [])

  // 计算宽度
  const computedWidth = () => {
    const minWidth = 256 + (imgBaseWidth * 2) + 60;
    let colNum = 2;
    const itemWidth = imgBaseWidth;
    if (listContainerRef.current) {
      const width = listContainerRef.current.clientWidth; // 容器宽度
      const flowList = listContainerRef.current.querySelector('#flowList') as HTMLDivElement;
      colNum = Math.floor((width - 60) / itemWidth);
      flowList?.setAttribute('style', `grid-template-columns:repeat(${colNum},1fr)`);
      listContainerRef.current.style.minWidth = `${minWidth}px`
    }
  }

  const deleteShare = (id: string) => {
    openAlert({
      title: t('tip'),
      content: t('creation.deleteCreate'),
      status: 'warning',
      onOk: async () => {
        try {
          await adminDelete(id);
          message.success({ title: t('deleteSuccess') });
          closeAlert()
        } catch (err: any) {
          message.error({ title: err.message });
          console.log(err);
        }
      }
    })
  }

  // 下载单张图片
  const download = async (url: string) => {
    if (!url) return;
    try {
      const res = await getOSSBlobResource(url);
      const suggestedName = url.substring(url.lastIndexOf('/') + 1)

      await saveBlobToLocal(res, `${suggestedName}.png`);
      message.success({ title: t('ImgDownloadSuccess') })
    } catch (err: any) {
      if (err.message !== 'The user aborted a request.') {
        message.error({ title: t('ImgDownloadFaild') })
      }
    }
  }

  const onFavorite = async () => {
    if (!viewInfo) return;
    const { drawId, url, type } = viewInfo;
    try {
      const res = await postCollection(drawId, url, type as CreationType);
      if (res[drawId]) {
        message.success({ title: t('collected') });
      } else {
        message.success({ title: t('CancelCollectionSuccess') });
      }
      favorites.set(drawId, res[drawId] || []);
    } catch (err: any) {
      message.error({ title: err.message });
    }
  }

  // 复用绘画参数
  const usePrompt = (item: CreationShareType) => {
    let _index = -1;

    let styles: StyleConfig[] = [];
    let pageType: CreationOfType;
    switch (item.type) {
      case CreationType.MJ:
        styles = mjStyleList;
        pageType = creationStore[CreationType.MJ];
        break;
      case CreationType.SD:
        styles = sdStyleList;
        pageType = creationStore[CreationType.SD];
        break;
      case CreationType.SD3:
        styles = sd3StyleList;
        pageType = creationStore[CreationType.SD3];
        break;
      default:
        return;
    }
    const currentStyle = styles.find((style, index) => {
      _index = index;
      return style.name === item.drawType
    });
    pageType.setPrompt(item.prompt?.en || '');
    pageType.setCnPrompt(item.prompt?.cn || '');
    pageType.setNegativePrompt(item.prompt?.negativeEn || '');
    pageType.setNegativeCnPrompt(item.prompt?.negativeCn || '');
    pageType.setSizeScal(item.sizeScal);
    pageType.setHrLevel(item?.hrLevel || '');
    currentStyle && pageType.setStyleConfig(currentStyle, _index); // 选中当前风格
    message.success({ title: t('creation.ReuseSuccess') });
    creationStore.publicStore.setPageType(item.type);
  }

  let style: StyleConfig[] = []
  switch (viewInfo?.type) {
    case CreationType.MJ:
      style = mjStyleList;
      break;
    case CreationType.SD:
      style = sdStyleList;
      break;
  }

  return <Box className={styles.square}>
    <CreationHeader icon='square_000' text={t('square')} />
    <Box className={styles.filter}>
      {
        Object.keys(_filter).map((key) => {
          const _key = key as SquareFilterType
          return <Box key={_key} className={`${styles.tag} ${filterType === key ? styles.active : ''}`} onClick={() => {
            setIsInit(false);
            setParams({ pageNum: 1, filterType: _key });
          }}>
            {_filter[_key]}
          </Box>
        })
      }
    </Box>
    <Box ref={listContainerRef} className={styles.container}>
      <ScrollBarBox className={styles.flowList} id='flowList'>
        {
          list.map((item, index) => {
            return <ImgFlow key={item._id}
              url={item.url}
              canDel={can_del_square_user_id.includes(userInfo?._id || '')}
              onDelete={() => deleteShare(item._id)} sizeScal={item.sizeScal}
              onView={() => { setViewSwiper(true); setViewInfo(item); setInfoIndex(index) }}
            />
          })
        }
        <Box className={styles.loadMore} id='loadMore' />
        {
          list.length >= total && isInit ? <Box className={styles.noMore}>{t('noMore')}</Box> :
            <Box className={styles.noMore}> <Loading.Dot /></Box>
        }
      </ScrollBarBox>
    </Box>
    <CreationPreview
      imgList={urlList}
      imgIndex={infoIndex || 0}
      isOpen={viewSwiper}
      onClose={() => setViewSwiper(false)}
      userAvatar={viewInfo?.userInfo[0]?.avatar || ''}
      userName={viewInfo?.userInfo[0]?.nickName || viewInfo?.userInfo[0]?.wiseNickName || ''}
      drayInfo={{
        id: viewInfo?._id || '',
        type: viewInfo?.drawType || '',
        avatar: style.find(item => item.name === viewInfo?.drawType)?.view_image || '',
      }}
      isFavorite={favorites.get(viewInfo?.drawId || '')?.includes(viewInfo?.url || '') || false}
      prompt={viewInfo?.prompt?.cn || viewInfo?.prompt?.en || ""}
      negativePrompt={viewInfo?.prompt?.negativeCn || viewInfo?.prompt?.negativeEn || ""}
      onDownload={download}
      sizeScal={viewInfo?.sizeScal || ''}
      hrLevel={viewInfo?.hrLevel || ''}
      // imgToImg={{ url: viewInfo.current?.extends.imgToImgUrl || '', weight: viewInfo.current?.extends.imgToImgWeight || 0 }}
      createTime={viewInfo?.createTime}
      onChangeIndex={index => {
        setInfoIndex(index);
        setViewInfo(list[index]);
      }}
      showPush={false}
      onFavorite={onFavorite}
      onSameDraw={() => {
        viewInfo && usePrompt(viewInfo);
        setViewSwiper(false);
      }}
      type={viewInfo?.type as unknown as CreationType || CreationType.MJ}
      extendsType={viewInfo?.extendsType}
    />
  </Box>
})

interface ImgFlowProps {
  url: string
  sizeScal?: string
  onView?: () => void
  canDel: boolean
  onDelete: () => void
}
const ImgFlow: FC<ImgFlowProps> = memo((props) => {
  const { url, sizeScal = '1:1', canDel, onView, onDelete } = props

  const [isInit, setIsInit] = useState(false)
  const [loadImgError, setLoadImgError] = useState(false)
  const boxRef = useRef<HTMLDivElement>(null);
  const scalRef = useRef(1)

  useEffect(() => {
    const img = new Image();
    let isUnmount = false;
    if (url) {
      img.src = url

      img.onload = () => {
        scalRef.current = img.width / img.height
        setIsInit(true)
        isUnmount = true
      }
      img.onerror = (err) => {
        setLoadImgError(true)
      }
    } else {
      setIsInit(true);
      isUnmount = true
    }

    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const box = boxRef.current as HTMLDivElement;
      window.requestAnimationFrame((): void | undefined => {
        if (!Array.isArray(entries) || !entries.length || !isUnmount) {
          return;
        }
        const spanLine = Math.ceil((box.clientWidth) / (scalRef.current ? scalRef.current : scal)) + 30;
        box.style.gridRow = `auto / span ${spanLine || 2}`;
      });
    })
    resizeObserver.observe(boxRef.current as Element)

    return () => {
      resizeObserver.disconnect()
    }
    // eslint-disable-next-line
  }, [])

  const scals = sizeScal.split(':') as [string, string]
  const scal = Number(scals[0] || 1) / Number(scals[1] || 1)
  const spanLine = Math.ceil((boxRef.current?.clientWidth ? boxRef.current.clientWidth : imgBaseWidth) / (scalRef.current ? scalRef.current : scal)) + 30;

  return <Box
    visibility={isInit ? 'visible' : 'hidden'}
    display={loadImgError ? 'none' : 'flex'}
    gridRow={`auto / span ${spanLine || 2}`}
    ref={boxRef}
    className={styles.imgContent}
    onClick={onView}
  >
    <WiseImage src={url + `?x-oss-process=image/resize,w_${Math.round(imgBaseWidth)}`} />
    {
      canDel && <Box className={styles.delete} onClick={e => {
        e.stopPropagation(); onDelete()
      }}>
        <Icon as={require('@/assets/svg/close_circle.svg').ReactComponent} w={'30px'} h='30px' />
      </Box>
    }
  </Box>
})