import HorizontalBox from "@/components/HorizontalBox";
import { WiseImage } from "@/components/Image";
import { Box, Flex, Icon, Image, Text } from "@chakra-ui/react";
import styles from './index.module.scss';
import { FC, useEffect, useState } from "react";
import { useMessage } from "@/hooks/useMessage";
import axios from "axios";
import { useTranslation } from "react-i18next";

const getVersion = async (platform: any) => {
  const version = '0.2.7';
  const arch = 'x64';
  try {
    const url = `https://www.wisetalker.cn/api/system/pcVersion?version=${version}&platform=${platform}&arch=${arch}`;
    const res = await axios.get(url);
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log('error', error);
  }
  return undefined;
};

const getapkRes = async () => {
  try {
    const url = `https://www.wisetalker.cn/api/system/checkupdateVersion`;
    const res = await axios.get(url);
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log('error', error);
  }
  return undefined;
};

const ClientPrompt: FC = () => {
  const message = useMessage();
  const [macVersion, setMacVersion] = useState('');
  const [apkUrl, setApkUrl] = useState('');
  const { t } = useTranslation();
  useEffect(() => {
    const fetch = async () => {
      const macres = await getVersion('darwin');
      const apkRes = await getapkRes();
      if (macres && macres.status === 200) {
        setMacVersion(macres.data.version)
      }
      if (apkRes && apkRes.status === 200) {
        setApkUrl(apkRes.data.data.ver_url)
      }
    };
    fetch();
  }, [])
  const handleDownload = (downloadUrl: any) => {
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `文思逸言-${macVersion}-arm64.dmg`); // 可选，指定下载文件名

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return <Flex flexDir='column' h='100%' bg='linear-gradient( 180deg, #F3F6FF 0%, #FFFFFF 100%)'>
    <HorizontalBox bg='#fff' h='38px' justifyContent='center' fontSize='18px' color='#333'>{t('WSYY')}</HorizontalBox>
    <Box flex={1} pos='relative'>
      <Box pos='relative' zIndex={1}>
        <HorizontalBox justifyContent='center' mt='50px'>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} w='80px' h='80px' p={'15px'} borderRadius='20px' bg='#fff' boxShadow='0px 6px 20px 0px rgba(0,7,70,0.1)'>
            <WiseImage w={'70px'} h={'70px'} src={require('@/assets/logo.png')} />
          </Box>
        </HorizontalBox>
        <Box textAlign='center' color='#999999' mt='20px' fontSize='16px'>
          <Text>{t('FullFunctionality')}</Text>
          <Text>{t('UsePCWeb')}</Text>
        </Box>
        <Text fontSize='18px' color='#333333' mt='50px' mb='30px' fontWeight='bold' textAlign='center'>
          {t('MoreServiceDownload')}
        </Text>
        <HorizontalBox color='#fff' fontSize='16px' justifyContent='center' flexDir='column'>
          <HorizontalBox bg='#333333' borderRadius='16px' boxShadow='0px 6px 20px 0px rgba(0,7,70,0.1)' p={'10px 20px'} className={styles.flexbox}
            w='152px' mb='30px' onClick={() => message.error({ title: '版本更新中，敬请期待...' })}>
            <Icon as={require('@/assets/svg/homeApple.svg').ReactComponent} w='32px' h='32px' mr='8px' className={styles.icon} />
            <Text>{t('iPhone')}</Text>
          </HorizontalBox>
          <HorizontalBox bg='#0E00BA' borderRadius='16px' boxShadow='0px 6px 20px 0px rgba(0,7,70,0.1)' p={'10px 20px'} className={styles.flexbox}
            w='152px' onClick={() => handleDownload(apkUrl)}>
            <Icon as={require('@/assets/svg/waphomeAnd.svg').ReactComponent} w='32px' h='32px' mr='8px' className={styles.icon} />
            <Text>{t('Android')}</Text>
          </HorizontalBox>
        </HorizontalBox>
      </Box>
      <Box pos='absolute' bottom={0} left={0} width='100%' zIndex={0}>
        <Image w={'100%'}
          src={require('@/assets/png/use_app_bg.png')}
          backgroundSize='contain'
          backgroundRepeat='no-repeat'
          backgroundPosition='center' />
      </Box>
    </Box>
  </Flex>
}

export default ClientPrompt