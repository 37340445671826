import { Modal } from 'antd'
import {
  useTranslation,
  useStateContext,
} from '../../../../common'
import React, { useState } from 'react'
import { CrudeAvatar } from '../../../../common'
import { observer } from 'mobx-react'
import { IMSearch } from '@/pages/im/components/IMSearch'
import { Box } from '@chakra-ui/react'
import { CButton } from '@/components/Button'
import { ScrollBarBox } from '@/components/ScrollBox'
import { useMessage } from '@/hooks/useMessage'

export interface AddFriendModalProps {
  visible: boolean
  onCancel: () => void
  onChat: (account: string) => void
  prefix?: string
  commonPrefix?: string
}

interface GetUserResultType {
  accid: string
  name: string
  icon: string
  sign: string
  email: string
  birth: string
  mobile: string
  gender: string
  ex: string
  valid: boolean
  mute: boolean
  muteP2P: boolean
  muteQChat: boolean
  muteTeam: boolean
  muteRoom: boolean
}

const AddFriendModal: React.FC<AddFriendModalProps> = observer(
  ({
    visible,
    onCancel,
    onChat,
    prefix = 'search',
  }) => {
    const _prefix = `${prefix}-add-modal`

    const { store, localOptions } = useStateContext()

    const message = useMessage()

    const { t } = useTranslation()

    const [searchValue, setSearchValue] = useState('')
    const [searchRes, setSearchRes] = useState<
      GetUserResultType[] | undefined
    >(undefined)
    const [searchResEmpty, setSearchResEmpty] = useState(false)
    const [searching, setSearching] = useState(false)
    const [adding, setAdding] = useState(false)

    const handleChange = (val: string) => {
      setSearchValue(val)
      setSearchResEmpty(false)
      setSearchRes(undefined)
    }

    const handleSearch = async () => {
      try {
        setSearching(true)
        const user = await searchUsers() // await store.userStore.getUserActive(searchValue)
        if (!user) {
          setSearchResEmpty(true)
        } else {
          setSearchRes(user)
        }
        setSearching(false)
      } catch (error) {
        setSearchResEmpty(true)
        setSearching(false)
      }
    }

    const searchUsers = async (): Promise<GetUserResultType[]> => {
      const res = await fetch((window.proxyApi || '') + '/api/im/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ value: searchValue })
      })
      return (await res.json()).data as GetUserResultType[]
    }

    const handleAdd = async (item: GetUserResultType) => {
      try {
        if (searchRes) {
          setAdding(true)
          if (localOptions?.addFriendNeedVerify) {
            await store.friendStore.applyFriendActive(item.accid)
            message.success({ title: t('applyFriendSuccessText') })
          } else {
            await store.friendStore.addFriendActive(item.accid)
            message.success({ title: t('addFriendSuccessText') })
          }
          // 发送申请或添加好友成功后解除黑名单
          await store.relationStore.setBlackActive({
            account: item.accid,
            isAdd: false,
          })
        }
        setAdding(false)
      } catch (error) {
        setAdding(false)
      }
    }

    const handleChat = async (item: GetUserResultType) => {
      if (searchRes) {
        await store.sessionStore.insertSessionActive('p2p', item.accid)
        onChat(item.accid)
        resetState()
      }
    }

    const handleCancel = () => {
      onCancel()
      resetState()
    }

    const resetState = () => {
      setSearchValue('')
      setSearchRes(undefined)
      setSearchResEmpty(false)
      setSearching(false)
      setAdding(false)
    }

    const renderFooter = () => (
      <div className={`${_prefix}-footer`}>
        <CButton onClick={handleCancel} size='md' theme='outline'>{t('cancelText')}</CButton>
        <CButton ml={'20px'} isLoading={searching} onClick={handleSearch} theme="primary" size='md'>
          {t('searchButtonText')}
        </CButton>
      </div>
    )

    return (
      <Modal
        className={_prefix}
        onCancel={handleCancel}
        visible={visible}
        width={460}
        footer={null}
        closable={false}
      >
        <Box
          onClick={handleCancel}
          as={require('@/assets/svg/close_x.svg').ReactComponent}
          className={`${_prefix}-header-close`}
        />
        <div className={`${_prefix}-title`}>{t('searchInputPlaceholder')} </div>
        <IMSearch placeholder={t('accountPlaceholder')} value={searchValue} onSearch={handleChange} />
        {searchResEmpty ? (
          <div className={`${_prefix}-empty-content`}>
            {t('accountNotMatchText')}
          </div>
        ) : searchRes ? <ScrollBarBox className={`${_prefix}-list-content`}>
          {
            searchRes.map(item => {
              return <div className={`${_prefix}-content`}>
                <CrudeAvatar
                  avatar={item.icon}
                  nick={item.name}
                  account={item.accid}
                />
                <div className={`${_prefix}-info`}>
                  <div className={`${_prefix}-info-name`}>
                    {item.name || item.accid}
                  </div>
                  {/* <div className={`${_prefix}-info-account`}>
                    {item.accid}
                  </div> */}
                </div>
                {store.uiStore.getRelation(item.accid).relation !==
                  'stranger' ? (
                  <CButton theme="primary" size='md' onClick={() => handleChat(item)}>
                    {t('chatButtonText')}
                  </CButton>
                ) : (
                  <CButton isLoading={adding} theme="primary" size='md' onClick={() => handleAdd(item)}>
                    {t('addButtonText')}
                  </CButton>
                )}
              </div>
            })
          }
        </ScrollBarBox>
          : renderFooter()}
      </Modal>
    )
  }
)

export default AddFriendModal
