import { Box, Icon, Text } from "@chakra-ui/react";
import { FC, memo } from "react";
import dayjs from "dayjs";
import { CreationType } from "@/store/creation";
import Loading from "@/components/Loading";

import styles from './index.module.scss'
import { useTranslation } from "react-i18next";

interface DrawTypeProps {
  drawType: string;
}
const DrawType: FC<DrawTypeProps> = memo((props) => {
  const { drawType } = props;
  const { t } = useTranslation();
  return <Box className={styles.drawType}>
    <Icon className={styles.icon} as={require('@/assets/svg/creation_draw_type.svg').ReactComponent} />
    <Text className={styles.text}>{t(drawType)}</Text>
  </Box>
})

interface VideoLengthProps {
  length: string;
}
const VideoLength: FC<VideoLengthProps> = memo((props) => {
  const { length } = props;
  const { t } = useTranslation();
  return <Box className={styles.drawType}>
    <Icon className={styles.icon} as={require('@/assets/svg/time_gray_icon.svg').ReactComponent} />
    <Text className={styles.text}>{length}{t('seconds')}</Text>
  </Box>
})

interface CalendarProps {
  time: Date;
}
// 完整的时间
const Calendar: FC<CalendarProps> = memo((props) => {
  const { time } = props;
  return <Box className={styles.calendar}>
    <Icon className={styles.icon} as={require('@/assets/svg/calendar.svg').ReactComponent} />
    <Text className={styles.text}>{dayjs(time).format('MM-DD HH:mm')}</Text>
  </Box>
})


interface PriceProps {
  price: number;
  isVip?: boolean;
}
const Price: FC<PriceProps> = memo((props) => {
  const { t } = useTranslation();
  const { price, isVip } = props;
  return <Box className={styles.price}>
    <Icon className={styles.icon} as={require('@/assets/svg/money_gray_icon.svg').ReactComponent} />
    <Text className={`${styles.text} ${isVip ? styles.freeNum : ''}`}>{price}</Text>
    {
      isVip && <>
        <Icon as={require('@/assets/svg/chatisVip.svg').ReactComponent} w='20px' h='20px' ml='4px' />
        <Text className={styles.free}>{t('vipFree')}</Text>
      </>
    }
  </Box>
})

interface TimeProps {
  seconds: number;
}
// 消耗的时间 目前以秒为单位
const Time: FC<TimeProps> = memo((props) => {
  const { seconds } = props;
  return <Box className={styles.price}>
    <Icon className={styles.icon} as={require('@/assets/svg/time_gray_icon.svg').ReactComponent} />
    <Text className={styles.text}>
      {seconds ? `${seconds < 10 ? '0' + String(seconds) : seconds}:` : ''}
    </Text>
  </Box>
})

interface CreationTitleProps {
  type: CreationType
  extendsType?: ExtendsType
  isDownloading?: boolean
  isReDrawing?: boolean
  onDownload?: () => void
  onDelete?: () => void
  onReDraw?: () => void
  onUsePrompt?: () => void
}
const CreationTitle: FC<CreationTitleProps> = memo((props) => {
  const { type, extendsType, isDownloading, isReDrawing, onDownload, onDelete, onReDraw, onUsePrompt } = props;
  const { t } = useTranslation();
  let title = t('wise') + 'MJ·';
  let extendsTitle = t('textToImage');

  if (type === CreationType.MJ) {
    title = t('wise') + 'MJ·'
  } else if (type === CreationType.SD) {
    title = t('wise') + 'SD1.5·'
  } else if (type === CreationType.SD3) {
    title = t('wise') + 'SD3·'
  } else if (type === CreationType.GEN3) {
    title = t('wise') + 'GEN3·'
  } else if (type === CreationType.SUNO) {
    title = t('wise') + 'Suno·'
  }

  if (extendsType === 'imgToImg') {
    extendsTitle = t('ImageToImage')
  } else if (extendsType === 'magnify') {
    extendsTitle = t('HDZoom')
  } else if (extendsType === 'partReDraw') {
    extendsTitle = t('PartialRepaint')
  } else if (extendsType === 'trans') {
    extendsTitle = t('Transform')
  } else if (extendsType === 'textToVideo') {
    extendsTitle = t('textToVideo')
  } else if (extendsType === 'imgToVideo') {
    extendsTitle = t('imageToVideo')
  } else if (extendsType === 'custom_model') {
    extendsTitle = t('customMode')
  } else if (extendsType === 'simple_gen') {
    extendsTitle = t('InspirationMode')
  }

  return <Box className={styles.title}>
    <Text className={styles.text}>{title}{extendsTitle}</Text>
    <Box className={styles.op}>
      {
        onReDraw && <Box className={`${styles.opItem} ${isReDrawing ? styles.redrawIng : ''}`} onClick={onReDraw}>
          <Icon className={styles.icon} as={require('@/assets/svg/creation_redraw.svg').ReactComponent} />
          <Text className={styles.text}>{t('creation.Redraw')}</Text>
        </Box>
      }
      {
        onUsePrompt && <Box className={styles.opItem} onClick={onUsePrompt}>
          <Icon className={styles.icon} as={require('@/assets/svg/creation_use_prompt.svg').ReactComponent} />
          <Text className={styles.text}>{t("creation.Reuse")}</Text>
        </Box>
      }
      {
        onDownload && <Box className={styles.opItem} onClick={onDownload}>
          {
            isDownloading ? <Loading.Icon className={styles.loading} />
              : <Icon className={styles.icon} as={require('@/assets/svg/creation_download.svg').ReactComponent} />
          }
          <Text className={styles.text}>{t('downloadAll')}</Text>
        </Box>
      }
      {
        onDelete && <Box className={styles.opItem} onClick={onDelete}>
          <Icon className={styles.icon} as={require('@/assets/svg/delete_black.svg').ReactComponent} />
          <Text className={styles.text}>{t("delete")}</Text>
        </Box>
      }
    </Box>
  </Box>
})


export const CreationInfo = {
  DrawType,
  Calendar,
  Price,
  Time,
  CreationTitle,
  VideoLength
}