import { FC, useCallback, useEffect, useState } from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Image, Flex, Icon, Modal, ModalBody, ModalContent, ModalContentProps, ModalOverlay, ModalOverlayProps, ModalProps, Text } from "@chakra-ui/react";

import styles from './index.module.scss'
import { ScrollBarBox } from "../ScrollBox";
import { WiseImage } from "../Image";
import { userStore } from "@/store/user";
import { useMessage } from "@/hooks/useMessage";
import { getJsPayCode, getPayCode } from "@/api/user";
import { getAndroidSystemVipConfig, getRechargeData } from "@/api/personal";
import { personalStore } from "@/store/personal";
import { useQuery } from "@tanstack/react-query";
import e from "express";

interface CModalProps {
  overlayProps?: ModalOverlayProps;
  contentProps?: ModalContentProps;
  onClose: () => void;
  isOpen: boolean; // 假设你仍然需要控制 Modal 的打开状态
  onOpen: () => void;
}

const newdata = {
  "_id": "66c8a1b6b78ef5cd9e4400bd",
  "type": "DEVvipPay",
  "subType": "DEVvip",
  "key": "vip",
  "name": "VIP包月会员配置",
  "title": "VIP会员",
  "expand": {
    "showQrcode": "http://wwww.baidu.com",
    "showTitle": "请联系客服",
    "price": [
      {
        "price": 499,
        "payPrice": 499,
        "discount": "1",
        "type": "month",
        "month": 1,
        "productId": "com.wisetalker.aiapp.vip.1month",
        "tag": "",
        "name": "半月卡"
      },
      {
        "price": 1000,
        "payPrice": 1000,
        "discount": "1",
        "type": "season",
        "month": 3,
        "productId": "com.wisetalker.aiapp.vip.1season",
        "tag": "",
        "name": "月卡"
      },
      {
        "price": 2000,
        "payPrice": 2000,
        "discount": "1",
        "type": "year",
        "month": 12,
        "productId": "com.wisetalker.aiapp.vip.1year",
        "tag": "",
        "name": "两月卡"
      },
      {
        "price": 3000,
        "payPrice": 3000,
        "discount": "1",
        "type": "year",
        "month": 12,
        "productId": "com.wisetalker.aiapp.vip.1year",
        "tag": "",
        "name": "三月卡"
      },
      {
        "price": 4000,
        "payPrice": 4000,
        "discount": "1",
        "type": "year",
        "month": 12,
        "productId": "com.wisetalker.aiapp.vip.1year",
        "tag": "",
        "name": "四月卡"
      },
      {
        "price": 5000,
        "payPrice": 5000,
        "discount": "1",
        "type": "year",
        "month": 12,
        "productId": "com.wisetalker.aiapp.vip.1year",
        "tag": "",
        "name": "五月卡"
      },
      {
        "price": 8000,
        "payPrice": 8000,
        "discount": "1",
        "type": "year",
        "month": 12,
        "productId": "com.wisetalker.aiapp.vip.1year",
        "tag": "",
        "name": "年卡"
      }
    ]
  }
}

function transformData(data: any) {
  return data.map((categoryObj: any) => {
    const category = Object.keys(categoryObj)[0]; // 获取类别名
    const models = categoryObj[category]; // 获取模型数组
    const transformedModels = models.map((modelEntry: any) => {
      const modelName = Object.keys(modelEntry)[0];
      const memberStatus = modelEntry[modelName];
      return { name: modelName, member: memberStatus === "免费" ? "免费" : "消耗点数", nonMember: '消耗点数' };
    });
    return { category: category, models: transformedModels };
  });
}

export const MonthlyCard: FC<CModalProps> = ({ overlayProps, contentProps, onClose, isOpen, onOpen }) => {
  const { userInfo } = userStore;
  const [recharges, setrecharges] = useState<any>();
  const [whiteRecharges, setWhiteRecharges] = useState<any>();
  const [select, setSelect] = useState<any>();
  const [selectid, setSelectid] = useState<any>();
  const [transformedData, setTransformedData] = useState<any[]>([]);
  const [contentData, setContentData] = useState<any[]>([]);
  useEffect(() => {
    const fetchSignInDates = async () => {
      try {
        const data = await getAndroidSystemVipConfig();
        setContentData(data.content);
        setTransformedData(transformData(data.rights));
        const recharge = await getRechargeData();
        //调用新接口获取数据 此处为模拟数据   const recharge = await getRechargeData();
        setrecharges(recharge.expand.price);
        setWhiteRecharges(newdata.expand.price);
        // if (userInfo?.isWhiteList === undefined || userInfo?.isWhiteList !== 1) {
        if (recharge) {
          setSelectid(recharge._id)
          setSelect(recharge.expand.price[0] || null);
        }
        // } else {
        //   setSelectid(newdata._id)
        //   setSelect(newdata.expand.price[0] || null);
        // }
      } catch (error) {
        console.error('Failed to fetch recharge data:', error);
      }
    };

    fetchSignInDates();
  }, []);

  useEffect(() => {
    const fetchSignInDates = async () => {
      await new Promise(resolve => setTimeout(resolve, 500));
      if (userInfo && userInfo.isWhiteList === 1) {
        const qrCodeContainer = document.getElementById('whiteQRCode');
        if (!qrCodeContainer) return;
        qrCodeContainer.innerHTML = '';
        new QRCode(qrCodeContainer, {
          text: newdata.expand.showQrcode,
          width: 128,
          height: 128,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: 2
        });
      }
    }
    fetchSignInDates()
    // setTimeout(() => fetchSignInDates(), 2000)
  }, [isOpen, onOpen])

  const closeModel = () => {
    onClose();
    setPayId('')
  }
  // const [select, Setselect] = useState(recharges[0].payPrice);
  const { checkPayResult } = personalStore;
  const [inputOrderType, setInputOrderType] = useState('Vip');
  const [payId, setPayId] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const [loading, setLoading] = useState(false);
  const message = useMessage();
  const isWap = /mobile/i.test(navigator.userAgent)
    || /android/i.test(navigator.userAgent)
    || /iphone/i.test(navigator.userAgent)
    || /ipad/i.test(navigator.userAgent);

  const handleClickPay = useCallback(async (price: any, type: any) => {
    if (loading) {
      return;
    }
    if (!isChecked) {
      message.warning({ title: '请先勾选充值协议！' });
      setLoading(false);
      return;
    }
    try {
      // @ts-ignore
      const res = await getPayCode(price, inputOrderType, selectid, type);
      setPayId(res.payId);
      await new Promise(resolve => setTimeout(resolve, 500));
      const qrCodeContainer = document.getElementById('payQRCode');
      if (!qrCodeContainer) return;
      qrCodeContainer.innerHTML = '';
      new QRCode(qrCodeContainer, {
        text: res.codeUrl,
        width: 128,
        height: 128,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: 2
      });
    } catch (err: any) {
      console.log(err);
      message.error({ title: err?.message || '生成错误' });
    }
  }, [select, isWap, inputOrderType, payId]);
  useQuery(
    [payId, setLoading],
    () => {
      if (!payId) return null;
      return checkPayResult(payId);
    },
    {
      enabled: !!payId,
      refetchInterval: 3000,
      onSuccess(res) {
        if (!res) return;
        if (res.toString() === '订单已结算') {
          setLoading(false);
          message.success({ title: '充值成功' });
          closeModel();
        }
      }
    }
  );
  if (!recharges) {
    // 数据加载之前，可以展示一个loading状态或提示信息
    return <div></div>;
  }
  return (
    <Modal isCentered isOpen={isOpen} onClose={closeModel}>
      <ModalOverlay className={styles.overlay} zIndex={39} {...overlayProps} />
      <ModalBody>
        <ModalContent className={styles.content} onClick={closeModel} containerProps={{ zIndex: 40 }} {...contentProps}>
          <Flex className={styles.modelbox} onClick={e => e.stopPropagation()}>
            <Flex className={styles.contentbox}>
              <Flex className={styles.titlebox}>
                {/* <Icon className={styles.avatar} /> */}
                <Box className={styles.avatarBox} >
                  <WiseImage className={styles.avatar} src={userInfo?.avatar} />
                </Box>
                <Text className={styles.name}>
                  {userInfo && userInfo.wiseNickName ? userInfo.wiseNickName : '文思用户'}
                </Text>
                <Icon as={userInfo?.monVip ? require('@/assets/svg/viptags.svg').ReactComponent : require('@/assets/svg/notviptags.svg').ReactComponent} className={styles.vipbox} />
                <Text className={styles.vipdate}>
                  {userInfo?.monVip ?
                    `${new Date(userInfo?.limit.vipPayList[userInfo?.limit.vipPayList.length - 1]?.endTime).toLocaleDateString()}日到期`
                    : '会员权益尚未开通'}
                </Text>
              </Flex>
              <Flex className={styles.vipcontentBox}>
                <ScrollBarBox >
                  <Flex className={styles.rechargesBox}>
                    {/* {(userInfo?.isWhiteList === undefined || userInfo?.isWhiteList !== 1) ? */}
                    {recharges.map((recharge: any, index: any) => {
                      return (
                        <Flex mr={'20px'} key={index} className={`${styles.rechargebox} ${select && select.payPrice === recharge.payPrice ? styles.selected : ''}`}
                          onClick={() => { setSelect(recharge); handleClickPay(recharge.payPrice, recharge.type) }}>
                          <Text className={styles.rechargeTitle}>{recharge.name}</Text>
                          <Text className={styles.rechargeNewprice}>{recharge.payPrice}<span>元</span></Text>
                          <Text className={styles.rechargePrice}>￥{recharge.price}元</Text>
                          {recharge.tag && (
                            <Flex className={styles.rechargeTagBox}>
                              <Text>{recharge.tag}</Text>
                            </Flex>
                          )}
                        </Flex>
                      )
                    })}
                    {/* :
                      whiteRecharges.map((recharge: any, index: any) => {
                        return (
                          <Flex mr={'10px'} key={index} className={`${styles.rechargebox} ${select && select.payPrice === recharge.payPrice ? styles.selected : ''}`}
                            onClick={() => { setSelect(recharge); handleClickPay(recharge.payPrice, recharge.type) }}>
                            <Text className={styles.rechargeTitle}>{recharge.name}</Text>
                            <Text className={styles.rechargeNewprice}>{recharge.payPrice}<span>元</span></Text>
                            <Text className={styles.rechargePrice}>￥{recharge.price}元</Text>
                            {recharge.tag && (
                              <Flex className={styles.rechargeTagBox}>
                                <Text>{recharge.tag}</Text>
                              </Flex>
                            )}
                          </Flex>
                        )
                      }) 
                    }*/}
                  </Flex>
                  <Text className={styles.titles}>充值尊享权益</Text>
                  <Flex>
                    {contentData.map((item, index) => {
                      return (
                        <Flex key={index} className={styles.rightbox}>
                          <Flex className={styles.iconbox}>
                            <Image src={item.icon} />
                          </Flex>
                          <Flex className={styles.rightContentBox}>
                            <Text className={styles.rightTitles}>{item.title}</Text>
                            <Text className={styles.rightcontents}>{item.desc}</Text>
                          </Flex>
                        </Flex>
                      )
                    })}
                  </Flex>
                  <Text className={styles.titles}>会员权益说明</Text>
                  <Flex>
                    <Table className={styles.table}>
                      <Thead>
                        <Tr>
                          <Th colSpan={2}>模型/详情</Th>
                          <Th className={styles.viptext}>
                            会员
                            <Icon className={styles.vipBadge} as={userInfo?.monVip ? require('@/assets/svg/viptags.svg').ReactComponent : require('@/assets/svg/notviptags.svg').ReactComponent} />
                          </Th>
                          <Th>非会员</Th>
                        </Tr>
                      </Thead>
                      {transformedData && transformedData.map((category: any, index: any) => (
                        <Tbody key={index} className={styles.categoryRow}>
                          {category.models && category.models.map((model: any, index: any) => (
                            <Tr key={model.member + index}>
                              {index === 0 && <Td rowSpan={category.models.length + 1}>{category.category}</Td>}
                              <Td className={styles.notviptext}>{model.name}</Td>
                              <Td className={styles.viptext}>{model.member}</Td>
                              <Td className={styles.notviptext}>{model.nonMember}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                      ))}
                    </Table>
                  </Flex>
                </ScrollBarBox>
              </Flex>
            </Flex>
            <Flex className={styles.listbox}>
              <Text className={styles.listTitle}>扫码立即开通</Text>
              {/* {(userInfo?.isWhiteList === undefined || userInfo?.isWhiteList !== 1) && */}
              <Text className={styles.listPrice}><span>￥</span>{select ? select.payPrice : '0'}</Text>
              {/* } */}
              {
                // (userInfo?.isWhiteList === undefined || userInfo?.isWhiteList !== 1) ?
                payId === '' ?
                  <Flex className={styles.codeBox} onClick={() => handleClickPay(select.payPrice, select.type)}>
                    <Icon as={require('@/assets/svg/paymodelRefresh.svg').ReactComponent} />
                    <Text>点击刷新</Text>
                  </Flex>
                  :
                  <Flex className={styles.codeBox}>
                    <Box id="payQRCode" className={styles.payimage}></Box>
                  </Flex>
                // :
                // <Flex className={styles.codeBox} mt={'20px'}>
                //   <Box id="whiteQRCode" className={styles.payimage}></Box>
                // </Flex>
              }
              <Flex className={styles.listPay}>
                {/* <Icon as={require('@/assets/svg/paymodelzfb.svg').ReactComponent} /> */}
                {/* {(userInfo?.isWhiteList === undefined || userInfo?.isWhiteList !== 1) ?
                  <> */}
                <Icon as={require('@/assets/svg/paymodelwx.svg').ReactComponent} />
                <Text>扫码支付</Text>
                {/* </>
                  :
                  <Box textAlign={'center'}>
                    <Text>{newdata.expand.showTitle}</Text>
                  </Box>
                } */}
              </Flex>
              <Text className={styles.listTxt}>
                支付即同意
                <span onClick={onOpen} style={{ cursor: 'pointer' }}>《文思逸言会员服务协议》</span>
                {/* 和
                <span>《文思逸言会员自动续费服务协议》</span> */}
              </Text>
            </Flex>
            <Icon as={require('@/assets/svg/vipCloseBut.svg').ReactComponent} className={styles.closeicon} onClick={closeModel} />
          </Flex>
        </ModalContent>
      </ModalBody >
    </Modal >
  );
}
