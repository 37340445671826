import { Box, Flex, Icon } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import styles from './index.module.scss'

interface MessageProps {
  status?: 'warning' | 'tips' | 'success' | 'error'
  title?: React.ReactNode
  content?: React.ReactNode
}
const Message: FC<MessageProps> = (props) => {
  const { status, title, content } = props

  const StatusIcon = useCallback(() => {
    switch (status) {
      case 'error':
        return <Icon as={require('@/assets/toasticon/error.svg').ReactComponent} className={styles.icon} />
      case 'success':
        return <Icon as={require('@/assets/toasticon/success.svg').ReactComponent} className={styles.icon} />
      case 'tips':
        return <Icon as={require('@/assets/toasticon/tips.svg').ReactComponent} className={styles.icon} />
      case 'warning':
        return <Icon as={require('@/assets/toasticon/warning.svg').ReactComponent} className={styles.icon} />
      default:
        return <Icon as={require('@/assets/toasticon/warning.svg').ReactComponent} className={styles.icon} />
    }
  }, [status])

  const cls = `${styles.message} ${styles[props?.status || '']}`

  return <Box className={cls}>
    <Flex alignItems='center'>
      <StatusIcon />{title}
    </Flex>
    {content}
  </Box>
}

export default Message