import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import { AnimationData } from './data';

const ImageRemovebg = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={300} width={556} />
    </div>
  );
};
export default ImageRemovebg;