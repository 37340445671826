export const AnimationData = {
  "v": "5.12.2",
  "fr": 60,
  "ip": 0,
  "op": 345,
  "w": 556,
  "h": 300,
  "nm": "移除有圆角",
  "ddd": 0,
  "assets": [
    {
      "id": "image_0",
      "w": 708,
      "h": 600,
      "p": require('@/assets/lottie/toolsper.png'),
      "e": 0
    },
    {
      "id": "image_1",
      "w": 556,
      "h": 300,
      "p": require('@/assets/lottie/toolsbg.jpg'),
      "e": 0
    },
    {
      "id": "image_2",
      "w": 556,
      "h": 300,
      "p": require('@/assets/lottie/drawsTool_BG.jpg'),
      "e": 0
    },
    {
      "id": "comp_0",
      "nm": "无圆角动效",
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          "nm": "竖线",
          "refId": "comp_1",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.125,
                    "y": 0
                  },
                  "t": 0,
                  "s": [
                    556,
                    150,
                    0
                  ],
                  "to": [
                    -74.167,
                    0,
                    0
                  ],
                  "ti": [
                    74.167,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 0.667
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0.333
                  },
                  "t": 120,
                  "s": [
                    111,
                    150,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.147,
                    "y": 0
                  },
                  "t": 135,
                  "s": [
                    111,
                    150,
                    0
                  ],
                  "to": [
                    46.5,
                    0,
                    0
                  ],
                  "ti": [
                    -46.5,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 0.667
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0.333
                  },
                  "t": 240,
                  "s": [
                    390,
                    150,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.205,
                    "y": 0.002
                  },
                  "t": 255,
                  "s": [
                    390,
                    150,
                    0
                  ],
                  "to": [
                    -67,
                    0,
                    0
                  ],
                  "ti": [
                    67,
                    0,
                    0
                  ]
                },
                {
                  "t": 345,
                  "s": [
                    -12,
                    150,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                15,
                150,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 30,
          "h": 300,
          "ip": 0,
          "op": 600,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          "nm": "美女模特3.png",
          "cl": "png",
          "refId": "image_0",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                278,
                150,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                354,
                300,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    50,
                    50,
                    100
                  ]
                },
                {
                  "t": 90,
                  "s": [
                    55,
                    55,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 600,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 2,
          "nm": "演示图.png",
          "cl": "png",
          "tt": 1,
          "tp": 4,
          "refId": "image_1",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                278,
                150,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                278,
                150,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 600,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          "nm": "遮罩 2",
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                150,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                -336.057,
                -9.791,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 0,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 2,
                  "s": [
                    99.5,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 5,
                  "s": [
                    98,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 10,
                  "s": [
                    95,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 15,
                  "s": [
                    91,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 30,
                  "s": [
                    78,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 60,
                  "s": [
                    52,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 75,
                  "s": [
                    40,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 90,
                  "s": [
                    30,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 105,
                  "s": [
                    23,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 112,
                  "s": [
                    21,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 120,
                  "s": [
                    20,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 135,
                  "s": [
                    20,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 138,
                  "s": [
                    20.5,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 143,
                  "s": [
                    22.2,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 150,
                  "s": [
                    26,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 180,
                  "s": [
                    45,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 195,
                  "s": [
                    54,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 204,
                  "s": [
                    59,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 210,
                  "s": [
                    61.5,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 217,
                  "s": [
                    65,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 225,
                  "s": [
                    67.75,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 233,
                  "s": [
                    69.5,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 240,
                  "s": [
                    70,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 255,
                  "s": [
                    70,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 260,
                  "s": [
                    69,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 266,
                  "s": [
                    65,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 270,
                  "s": [
                    61,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 276,
                  "s": [
                    55.5,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 285,
                  "s": [
                    46,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 300,
                  "s": [
                    29,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 315,
                  "s": [
                    14,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 323,
                  "s": [
                    8,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 329,
                  "s": [
                    3,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 332,
                  "s": [
                    2,
                    100,
                    100
                  ]
                },
                {
                  "t": 345,
                  "s": [
                    -10,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "rc",
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      556,
                      300
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "nm": "矩形路径 1",
                  "mn": "ADBE Vector Shape - Rect",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.337254901961,
                      0.360784313725,
                      0.980392156863,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "填充 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      -58.057,
                      -9.791
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "矩形 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 2,
          "nm": "马赛克背景.png",
          "cl": "png",
          "refId": "image_2",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                278,
                150,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                278,
                150,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 600,
          "st": 0,
          "bm": 0
        }
      ]
    },
    {
      "id": "comp_1",
      "nm": "竖线",
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          "nm": "形状图层 1",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                15,
                150,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                -134.709,
                -280.836,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "d": 1,
                  "ty": "el",
                  "s": {
                    "a": 0,
                    "k": [
                      20,
                      20
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "nm": "椭圆路径 1",
                  "mn": "ADBE Vector Shape - Ellipse",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "描边 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.337254901961,
                      0.360784313725,
                      0.980392156863,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "填充 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      -134.709,
                      -280.836
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "椭圆 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          "nm": "形状图层 2",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                15,
                150,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                -82.371,
                -280,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "rc",
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      4,
                      300
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "nm": "矩形路径 1",
                  "mn": "ADBE Vector Shape - Rect",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "填充 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      -82.371,
                      -280
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "矩形 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 600,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 0,
      "nm": "无圆角动效",
      "tt": 1,
      "tp": 2,
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            278,
            150,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            278,
            150,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 556,
      "h": 300,
      "ip": 0,
      "op": 600,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "遮罩 2",
      "td": 1,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            0,
            150,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            -336.057,
            -9.791,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "rc",
              "d": 1,
              "s": {
                "a": 0,
                "k": [
                  556,
                  300
                ],
                "ix": 2
              },
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 30,
                "ix": 4
              },
              "nm": "矩形路径 1",
              "mn": "ADBE Vector Shape - Rect",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.337254901961,
                  0.360784313725,
                  0.980392156863,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "填充 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  -58.057,
                  -9.791
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "变换"
            }
          ],
          "nm": "矩形 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 600,
      "st": 0,
      "ct": 1,
      "bm": 0
    }
  ],
  "markers": [],
  "props": {}
}