import { Box, Icon, Text } from "@chakra-ui/react";
import { CodeInput, PhoneInput, PwdInput } from "./LoginInput";
import { useState } from "react";
import { userStore } from "@/store/user";
import { useGlobalToast } from "@/hooks/useGlobalToast";
import { observer } from "mobx-react-lite";
import { CButton } from "@/components/Button";

import styles from './index.module.scss'
import { globalStore } from "@/store/global";

interface ForgetPwdProps {
  onBack: () => void
}
const ForgetPwd: React.FC<ForgetPwdProps> = (props) => {
  const { onBack } = props
  const [phone, setPhone] = useState<string>('');
  const [pwd, setPwd] = useState<string>('');
  const [pwdAgian, setPwdAgian] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [pwdNotSame, setPwdNotSame] = useState<boolean>(false);
  const [codeLoading, setCodeLoading] = useState<boolean>(false);
  const { openToast } = useGlobalToast();

  const { findPwdAction, codeCountdown, getCode } = userStore;
  const { setLoginIsOpen } = globalStore;
  const isDisabled = phone === '' || pwd === '' || pwdAgian === '' || code === '';

  const sendGetCode = async () => {
    let errMsg = '';
    if (!phone) {
      errMsg = '请输入手机号';
    } else if (!/(^1[3456789]\d{9}$)/.test(phone)) {
      errMsg = '手机号格式错误';
    }
    if (errMsg) {
      openToast({ status: 'error', title: errMsg })
      return
    }

    setCodeLoading(true);
    try {
      await getCode(phone, 'findPassword');
      setCodeLoading(false);
    } catch (err: unknown) {
      console.log(err);
      setCodeLoading(false);
      // setError((err as ErrorResponse).message)
    }
  }

  const confirm = async () => {
    let errMsg = ''
    if (loading || isDisabled) return;
    if (!phone) {
      errMsg = '请输入手机号';
    } else if (!/(^1[3456789]\d{9}$)/.test(phone)) {
      errMsg = '手机号格式错误';
    } else if (!code) {
      errMsg = '请输入验证码';
    } else if (!pwd) {
      errMsg = '请输入密码';
    } else if (!pwdAgian) {
      errMsg = '请再次输入密码';
    } else if (pwd !== pwdAgian) {
      errMsg = '两次密码输入不一致';
    }
    if (errMsg) {
      openToast({ status: 'error', title: errMsg })
      return
    }

    setLoading(true);
    try {
      await findPwdAction(phone, pwd, code);
      openToast({ status: 'success', title: '密码重置成功，已为您自动登录' })
      setLoginIsOpen(false);
      setLoading(false);
    } catch (err: unknown) {
      console.log(err);
      setLoading(false);
      openToast({ status: 'error', title: (err as ErrorResponse).message })
    }
  }

  return <Box className={styles.forgot}>
    <Box className={styles.findTitle}>
      <Icon className={styles.icon} as={require('@/assets/svg/back.svg').ReactComponent} onClick={onBack} />
      <Box className={styles.title}>找回密码</Box>
    </Box>
    <PhoneInput onChange={value => {
      setPhone(value);
    }} />
    <CodeInput loading={codeLoading} cutdown={codeCountdown} onChange={setCode} onGetCode={sendGetCode} />
    <PwdInput
      placeholder={'请输入密码'}
      onChange={value => {
        setPwd(value);
        if (value && pwdAgian && value !== pwdAgian) {
          setPwdNotSame(true);
        } else {
          setPwdNotSame(false);
        }
      }}
    />
    <PwdInput
      onChange={value => {
        setPwdAgian(value);
        if (value && pwd && value !== pwd) {
          setPwdNotSame(true);
        } else {
          setPwdNotSame(false);
        }
      }}
      placeholder={'请再次输入密码'}
    />
    {
      pwdNotSame && <Box className={styles.pwdNotSame}>
        <Icon as={require('@/assets/svg/pwd_error.svg').ReactComponent} />
        <Text className={styles.text}>两次密码输入不一致</Text>
      </Box>
    }
    <CButton
      isLoading={loading}
      disabled={isDisabled}
      className={styles.forgetBtn}
      theme='primary'
      onClick={confirm}
    >
      确定
    </CButton>
  </Box>
}

export default observer(ForgetPwd)