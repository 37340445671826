import { Box, Icon, Input, Text } from "@chakra-ui/react";

import styles from './index.module.scss'
import { useTranslation } from "react-i18next";

interface IMSearchProps {
  disabled?: boolean
  placeholder?: string
  onSearch?: (value: string) => void
  value?: string
  maxLength?: number
}
export const IMSearch: React.FC<IMSearchProps> = (props) => {
  const { t } = useTranslation();
  const { disabled, placeholder = t('Search') || '搜索', onSearch, value = '', maxLength } = props;
  return <Box className={styles.search}>
    <Box className={styles.searchInput}>
      <Icon className={styles.icon} as={require('@/assets/svg/search.svg').ReactComponent} />
      <Box className={styles.text}>
        {
          disabled ? <Text>{value || placeholder}</Text> : <Input
            value={value}
            disabled={disabled}
            className={styles.input}
            placeholder={placeholder}
            maxLength={maxLength}
            onChange={e => {
              onSearch && onSearch(e.target.value)
            }}
          />
        }
      </Box>
    </Box>
  </Box>;
}
