import { Box, Icon, Text } from "@chakra-ui/react";
import { FC } from "react";
import { CModal } from "../Modal";
import { WiseImage } from "../Image";
import { ScrollBarBox } from "../ScrollBox";

import styles from './version.module.scss'
import Loading from "../Loading";

interface NewVersionProps {
  open: boolean
  releaseNotes: string[]
  onClose: () => void
  onUpdate: () => void
  isWindow?: boolean
  version?: string
  isMustUpdate?: boolean
  isDownloaded?: boolean
}
export const NewVersion: FC<NewVersionProps> = (props) => {
  const { open, releaseNotes, onClose, onUpdate, isWindow, version, isMustUpdate, isDownloaded } = props;

  return <CModal isOpen={open} onClose={() => {
    if (!isMustUpdate) onClose()
  }}>
    <Box className={styles.version}>
      <Box className={styles.topBg}>
        <WiseImage src={require('@/assets/png/version_bg.png')} />
      </Box>
      <Box className={styles.content}>
        <Box className={styles.versionText}>
          <Text>目标版本：{version}</Text>
          <ScrollBarBox>
            {
              releaseNotes?.map(note => {
                return <Box className={styles.version_item}> •{note}</Box>
              })
            }
          </ScrollBarBox>
        </Box>
        {
          <Box className={`${styles.tips} ${(isMustUpdate || isWindow) ? styles.hidden : ''}`}>
            <Icon as={require('@/assets/svg/tip_gray.svg').ReactComponent} />
            <Text>请点击“重启并更新”以体验最新版本。如果您暂时不想更新，可以选择忽略，客户端将在下次启动时自动更新。</Text>
          </Box>
        }

        <Box className={styles.btns}>
          {
            !isMustUpdate && < Box className={styles.ignore} onClick={() => {
              if (!isMustUpdate) onClose()
            }}>忽略</Box>
          }
          <Box className={styles.btn} onClick={onUpdate}>
            {
              !isWindow && !isDownloaded ? <Loading.Dot /> : null
            }
            {isWindow ? '前往下载' : isDownloaded ? '重启并更新' : '更新中'}
          </Box>
        </Box>
      </Box>
    </Box>
  </CModal >
}