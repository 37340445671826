import { Box, Flex, Text, CheckboxGroup, VStack, Grid, Textarea, Icon } from "@chakra-ui/react";
import { FC } from "react";
import styles from './index.module.scss';
import { CButton } from "@/components/Button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { personalStore } from "@/store/personal";
import { observer } from "mobx-react-lite";
import { useMessage } from "@/hooks/useMessage";

export interface ImageItem {
  id: number;
  src: string | ArrayBuffer | null;
}

export const Report: FC = observer(() => {
  const { t } = useTranslation();
  const { creatReport } = personalStore;
  const message = useMessage();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const options = [
    t('Harmful_Information'),
    t('Pornography_is_vulgar'), t('Uncomfortable'), t('Misleading_attacks'),
    t('Violations_of_laws_and_regulations'),
    t('infringe'), t('other')];
  const [text, setText] = useState('');
  const [textPhone, setTextPhone] = useState('');
  const texthandleChange = (event: any) => {
    setText(event);
  };
  const textPhoenhandleChange = (event: any) => {
    setTextPhone(event);
  };
  const handleChange = (event: any) => {
    const { value, checked } = event.target;
    setSelectedOptions((selectedOptions: any) => {
      if (checked) {
        if (!selectedOptions.includes(value)) {
          return [...selectedOptions, value];
        }
      } else {
        return selectedOptions.filter((option: any) => option !== value);
      }
      return selectedOptions;
    });
  };
  const [images, setImages] = useState<ImageItem[]>([]);
  const deleteImage = (id: any) => {
    setImages((images) => images.filter((image) => image.id !== id));
  };
  const handleFileChange = (event: any) => {
    if (images.length >= 3) {
      // toast({
      //   title: '提示！最多只能上传3张图片',
      //   status: 'warning'
      // });
      return;
    }
    // 通过File API获取到所有选择的图片
    const files = Array.from(event.target.files);
    files.forEach((file) => {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImages((prevState) => [
            ...prevState,
            { id: Date.now(), src: reader.result }
          ]);
        };
        reader.readAsDataURL(file as Blob); // 转换图片到Base64
      }
    });
  };
  const confirm = async () => {
    if (text === '' || selectedOptions.length < 1) {
      message.warning({ title: '请选择举报类型并填写举报内容！' })
      return;
    }
    try {
      const res = await creatReport(text, images, textPhone, selectedOptions)
      if (res) {
        message.success({ title: '感谢您的举报，我们将会马上处理！' })
      }
    } catch (err: unknown) {
      message.error({ title: (err as ErrorResponse).message })
    }
  }

  return (
    <Box className={styles.parent}>
      <Box className={styles.child}>
        <Flex className={styles.selectbox}>
          <Text className={styles.text}>
            {t('Report_Content_Issues')}
            <span>*</span>
            （{t('Multiple_choices')}）
          </Text>
          <VStack>
            <CheckboxGroup >
              <Grid templateColumns="repeat(4, 1fr)" gap={6} paddingY={4}>
                {options.map((option, index) => (
                  <div key={index} className={styles.checkbox_custom}>
                    <input id={`cb_${index}`} type="checkbox" value={option}
                      onChange={handleChange} />
                    <label htmlFor={`cb_${index}`}>{option}</label>
                  </div>
                ))}
              </Grid>
            </CheckboxGroup>
          </VStack>
        </Flex>
        <Flex className={styles.questionbox}>
          <Text className={styles.text}>{t('Problem_description')}</Text>
          <Textarea className={styles.questiontextarea} value={text} onChange={(e) => texthandleChange(e.target.value)} />
        </Flex>
        <Flex className={styles.uploadContainer}>
          <Text className={styles.text}>{t('Upload_a_screenshot_of_the_issue')}</Text>
          <Flex className={styles.imagesbox}>
            {images.length > 0 && (
              images.map((file) => (
                <Box className={styles.uploadBox} key={file.id}>
                  <img src={file.src as string} className={styles.previewImage} />
                  {/* <Icon onClick={() => deleteImage(file.id)} as={require(`@/assets/svg/personalClosed.svg`).ReactComponent} className={styles.uploadBoxicon} /> */}
                </Box>
              ))
            )}
            <Box className={styles.uploadBox} onClick={() => document.getElementById('file-input')!.click()}>
              <input
                type="file"
                accept="image/*" // 只接受图片类型的文件
                onChange={handleFileChange}
                className={styles.fileUploadInput}
                id="file-input"
              />
              <Text className={styles.plusIcon} >+</Text>
            </Box>
          </Flex>
        </Flex>
        <Flex className={styles.phonebox}>
          <Text className={styles.text}>{t('Contact')}</Text>
          <Textarea className={styles.phonetextarea} value={textPhone} onChange={(e) => textPhoenhandleChange(e.target.value)} />
        </Flex>
        <CButton className={styles.cbutton} onClick={confirm}>
          {t('submit')}
        </CButton>
      </Box>
    </Box>
  )
})