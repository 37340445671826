import { imDb } from "@/db/im";
import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

class IMStore {
  type: ContactType = 'chat';
  unread: number = 0;
  teamIgnores: TeamMute = new Map();
  viewImg?: boolean = false;
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'imStore',
      properties: [],
      storage: window.localStorage
    })
  }

  setType(type: ContactType) {
    this.type = type;
  }

  setUnread(unread: number) {
    this.unread = unread;
  }

  setTeamIgnores(teamIgnores: TeamMute) {
    this.teamIgnores = teamIgnores;
    imDb.setTeamIgnores(teamIgnores);
  }

  async getTeamIgnores() {
    const res = await imDb.getTeamIgnores();
    if (res) {
      this.teamIgnores = res;
    }
  }

  setViewImg(viewImg: boolean) {
    this.viewImg = viewImg;
  }
}

export const imStore = new IMStore();