import { Box, Text } from "@chakra-ui/react";
import { FC } from "react";
import { CreationTitle, CreationTitleBox } from "../";
import { useTranslation } from "react-i18next";

import styles from './index.module.scss'

interface CreationQualityProps {
  value?: string;
  qualityList?: QualityType[];
  onQualityChange?: (quality: string, priceLevel: string) => void;
}
export const CreationQuality: FC<CreationQualityProps> = (props) => {
  const { value, qualityList, onQualityChange } = props;
  const { t } = useTranslation();

  return <Box className={styles.quality}>
    <CreationTitleBox className={styles.title}>
      <CreationTitle title={t('creation.Quality')} />
    </CreationTitleBox>
    <Box className={styles.list}>
      {
        qualityList?.map((quality, index) => {
          const isSelect = value === quality.name;
          return <Box
            key={index}
            className={`${styles.item} ${isSelect ? styles.active : ''}`}
            onClick={() => onQualityChange?.(quality.name, quality.key.split('-')[1])}
          >
            <Box className={styles.radio}>
              {
                isSelect && <Box className={styles.selected} />
              }
            </Box>
            <Text>{t(quality.name)}</Text>
          </Box>
        })
      }
    </Box>
  </Box>
}

const lengthList = [5, 10]
interface CreationVideoLengthProps {
  length: number;
  onLengthChange: (length: number) => void;
}
export const CreationVideoLength: FC<CreationVideoLengthProps> = (props) => {
  const { length, onLengthChange } = props;
  const { t } = useTranslation();

  return <Box className={styles.quality} mt='30px'>
    <CreationTitleBox className={styles.title}>
      <CreationTitle title={t('creation.VideoLength')} />
    </CreationTitleBox>
    <Box className={styles.list}>
      {
        lengthList?.map((len, index) => {
          const isSelect = length === len;
          return <Box
            key={index}
            className={`${styles.item} ${isSelect ? styles.active : ''}`}
            onClick={() => onLengthChange(len)}
          >
            <Box className={styles.radio}>
              {
                isSelect && <Box className={styles.selected} />
              }
            </Box>
            <Text>{len}{t('seconds')}</Text>
          </Box>
        })
      }
    </Box>
  </Box>
}