import { ToolhistorySchema, ToolSchema, VideoSummaryModelSchema } from "@/@types/tools";
import { LocalDB } from ".";



class ToolDB extends LocalDB {

  /** 设置点数列表 */
  setTools(tools: any) {
    this.db.setItem('tools', tools)
  }

  /** 获取点数列表 */
  getTools(): Promise<ToolSchema[] | null> {
    return this.db.getItem('tools')
  }

  getAllToolsHistory(): Promise<ToolhistorySchema[] | null> {
    return this.db.getItem('allToolsHistory')
  }
  setAllToolsHistory(allToolsHistory: any) {
    this.db.setItem('allToolsHistory', allToolsHistory)
  }

}

export const toolDb = new ToolDB('tools');