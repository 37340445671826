import HorizontalBox from "@/components/HorizontalBox";
import { Box, Flex, Icon, Img, Input, Text, useOutsideClick } from "@chakra-ui/react";
import { ChangeEvent, FC, KeyboardEventHandler, MouseEventHandler, useEffect, useRef, useState } from "react";
import WritingModal, { Writing } from "./modal";
import { debounce, throttle } from "lodash";
import { useMessage } from "@/hooks/useMessage";
import { saveWrite, fetchGetToolByLink } from "@/api/tools";
import Docxtemplater from "docxtemplater";
import { CButton } from "@/components/Button";
import { ToolSchema } from "@/@types/tools";
import { saveBlobToLocal } from "@/utils/common";
import { uiStrore } from "@/store/ui";

import styles from './index.module.scss'
import { WiseImage } from "@/components/Image";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { toolStore } from "@/store/tool";
import { useNavigate } from "react-router";

const _Models = [
  { name: '文思GPT 3.5-16k', key: 'gpt-3.5-turbo-1106', tips: '(速度快，但生成质量一般)', tag: '限时免费' },
  { name: '文思GPT 4o-128k', key: 'gpt-4o', tips: '(生成质量好、理解能力优秀)', price: '20' }
]

export type OperatesType = 'Optimization' | 'Copy' | 'Summary' | 'Expand' | 'Brainstorm' | 'Rewrite' | 'Translate' | 'Continue' | 'Title' | 'Outline'
export const operates: Record<OperatesType, { name: string, icon: any, extends?: string[] }> = {
  Optimization: { name: '优化', icon: require(`@/assets/svg/Optimization.svg`).ReactComponent },
  Copy: { name: '复制', icon: require(`@/assets/svg/copy.svg`).ReactComponent },
  Summary: { name: '总结', icon: require(`@/assets/svg/Summary.svg`).ReactComponent },
  Expand: { name: '扩写', icon: require(`@/assets/svg/Expand.svg`).ReactComponent },
  Brainstorm: { name: '头脑风暴', icon: require(`@/assets/svg/Brainstorm.svg`).ReactComponent },
  Rewrite: { name: '重写', icon: require(`@/assets/svg/Rewrite.svg`).ReactComponent },
  Translate: {
    name: '翻译', icon: require(`@/assets/svg/Translate.svg`).ReactComponent,
    extends: ['中文（简体）', '中文（繁体）', '英文', '西班牙文', '韩文', '日文', '德语', '法语', '阿拉伯语', '俄语']
  },
  Continue: { name: '续写', icon: require(`@/assets/svg/Continue.svg`).ReactComponent },
  Outline: { name: '撰写大纲', icon: require(`@/assets/svg/edit.svg`).ReactComponent },
  Title: { name: '标题优化', icon: require(`@/assets/svg/Optimization.svg`).ReactComponent },
}
const WritingAssistant: FC = () => {
  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [hydrated, setHydrated] = useState(false);
  const container = useRef<HTMLDivElement>(null)
  const [editIndex, setEditIndex] = useState(-2) // -1指title
  const content_ref = useRef<HTMLDivElement>(null)
  const [menu, setMenu] = useState<OperatesType[] | false>(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const [menuType, setMenuType] = useState<OperatesType>()
  const [menuExtend, setMenuExtend] = useState('')
  const message = useMessage()
  const select_ele = useRef<{ ele: Element, start: number, end: number }>()
  const [menu_postion, set_menu_postion] = useState<{ left: number, top: number }>()
  const multi_select_ele = useRef<{
    start_ele: HTMLElement, start_str: number,
    end_ele: HTMLElement, end_str: number
  }>()
  const current_position = useRef<{ ele: Node, offset: number } | undefined>(undefined) // 无选中时光标的最后位置
  const [spend, setSpend] = useState(0)
  const spendEle = useRef<HTMLDivElement>(null)
  const [listType, setListType] = useState<'use' | 'collection'>('use')
  const [chooseModel, setChooseModel] = useState(false)
  const [modelKey, setModelKey] = useState<{ name: string, key: string, price?: string, tag?: string }>({ name: "文思GPT 4o-128k", key: 'gpt-4o', price: '20' })
  const modelMenuRef = useRef<HTMLDivElement>(null)
  const isInit = useRef(true)
  const [isFirst, setIsFirst] = useState(false)
  const [showWelcome, setShowWelcome] = useState(true)
  const [canNewDoc, setCanNewDoc] = useState(false)
  const { openAlert, closeAlert, zoom = 1 } = uiStrore;
  const [activeId, setActiveId] = useState<string | undefined>()
  const [listShouldUpdate, setListShouldUpdate] = useState(false)
  const { isModify, setIsModify, leavaWritingAssistant, setLeavaWritingAssistant, SetSelectTool } = toolStore;
  const navigate = useNavigate();

  const [tool, SetTool] = useState<ToolSchema>();

  useEffect(() => {
    if (leavaWritingAssistant) {
      const reset = async (should_save = false) => {
        if (should_save) {
          await save()
        }
        setActiveId('')
        closeAlert();
        setIsModify(false);
        setLeavaWritingAssistant(null)
        localStorage.setItem('writingAssistant', JSON.stringify({ type: 'list', spend: 0 }));
        if (leavaWritingAssistant.type === 'route') {
          navigate(leavaWritingAssistant.next)
        } else {
          SetSelectTool(leavaWritingAssistant.next)
        }
      }
      openAlert({
        status: 'warning',
        title: '提示',
        content: '您有未保存的内容，是否保存修改?',
        footer: <HorizontalBox justifyContent='flex-end' mt='20px'>
          <CButton theme='outline' size='md' mr='10px' onClick={_ => { reset(false) }}>放弃</CButton>
          <CButton theme='primary' size='md' w='80px' onClick={() => { reset(true) }}>保存</CButton>
        </HorizontalBox>
      })
    }
  }, [leavaWritingAssistant])


  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchGetToolByLink({ toollink: 'writingAssistant' });
      SetTool(data[0]);
    };
    fetchData();
    setHydrated(true);

    const isFirst = !localStorage.getItem('writingIsFirstOpen')
    setIsFirst(isFirst);
    if (isFirst) {
      localStorage.setItem('writingIsFirstOpen', 'false')
    }
  }, []);

  useEffect(() => {
    if (!content_ref.current || !isInit.current) return;
    content_ref.current!.innerHTML = ''
    content_ref.current.focus() //聚焦设置一次选区

    const info = JSON.parse(localStorage.getItem('writingAssistant') || '{}');
    isInit.current = false

    if (info?.content?.length) {
      (info?.content || []).forEach((item: string) => {
        const create_div = document.createElement("div")
        create_div.className = 'content_item'
        create_div.innerHTML = item.replace('\n', '');
        content_ref.current?.append(create_div)
      })
    } else {
      const create_div = document.createElement("div")
      create_div.className = 'content_item'
      create_div.innerHTML = ''
      create_div.setAttribute('placeholder', '请输入文本，或按“空格”开始写作')
      if (content_ref.current) {
        content_ref.current?.append(create_div)
      }
    }
    setTitle(info?.title || '');
    const title_ele = document.getElementById('writeTitle') as HTMLInputElement
    title_ele.value = info?.title || '';
    // eslint-disable-next-line
  }, [content_ref.current, activeId])

  useEffect(() => {
    if (container.current) {
      container.current.style.maxHeight = window.innerHeight - container.current?.getBoundingClientRect().top + 'px'
    }
    window.addEventListener('resize', () => {
      if (container.current) {
        container.current.style.maxHeight = window.innerHeight - container.current?.getBoundingClientRect().top + 'px'
      }
    });
    // eslint-disable-next-line
  }, [container.current])

  useEffect(() => {
    if (spendEle.current) {
      const writingAssistant = JSON.parse(localStorage.getItem('writingAssistant') || '{}')
      writingAssistant.spend = Number(writingAssistant.spend || 0) + spend;
      spendEle.current.innerText = ((writingAssistant.spend || 0) / 10000).toFixed(2)
      localStorage.setItem('writingAssistant', JSON.stringify(writingAssistant))
    }
    // eslint-disable-next-line
  }, [spend, spendEle.current])

  useOutsideClick({
    ref: modelMenuRef,
    handler: e => {
      setChooseModel(false)
    }
  })

  useOutsideClick({
    ref: menuRef,
    handler: e => {
      const _event = e as MouseEvent
      if (_event.button === 2) { // 鼠标右键
        return
      }
      set_menu_postion(undefined)
      setMenu(false);
    }
  })

  const get_edit_item = (node: HTMLElement): HTMLElement | 'ERROR_NODE' => {
    if (node?.classList && node?.classList.contains('content_item')) {
      return node
    }
    if (node?.parentElement) {
      return get_edit_item(node?.parentElement)
    } else {
      return 'ERROR_NODE'
    }
  }

  const changeTitle = debounce((val: string) => {
    setTitle(val)
    const writingAssistant = JSON.parse(localStorage.getItem('writingAssistant') || '{}')
    writingAssistant.title = val
    setIsModify(true);
    setShowWelcome(false)
    setCanNewDoc(true)
    localStorage.setItem('writingAssistant', JSON.stringify(writingAssistant))
  }, 100)

  const changeContent = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const eles = (e.target as HTMLDivElement).getElementsByClassName('content_item');
    const content_strs: string[] = []
    new Array(eles.length).fill('').forEach((_, index) => {
      content_strs.push((eles.item(index) as HTMLDivElement)?.innerText)
    })
    const writingAssistant = JSON.parse(localStorage.getItem('writingAssistant') || '{}')
    writingAssistant.content = content_strs
    setIsModify(true);
    setShowWelcome(false)
    setCanNewDoc(true)
    localStorage.setItem('writingAssistant', JSON.stringify(writingAssistant))
  }, 500)

  // 添加到句尾
  const addToEnd = (val: string) => {
    const selection = getSelection() as Selection
    // const range = selection.getRangeAt(0)
    if (multi_select_ele.current) {
      selection.setBaseAndExtent(
        multi_select_ele.current.start_ele, multi_select_ele.current.start_str,
        multi_select_ele.current.end_ele, multi_select_ele.current.end_str
      )
    } else if (select_ele.current) {
      if (select_ele.current.ele.nodeType === 3) {
        selection.setPosition(select_ele.current.ele, select_ele.current?.end)
      } else {
        selection.setPosition(select_ele.current.ele, select_ele.current?.ele.childNodes.length)
      }
    } else if (current_position.current) {
      selection.setPosition(current_position.current?.ele, current_position.current?.offset)
    }
    document.execCommand("insertText", false, val);

    const newest_all_ele = content_ref.current?.getElementsByClassName('content_item');
    const content_strs: string[] = []
    new Array(newest_all_ele?.length).fill('').forEach((_, index) => {
      content_strs.push((newest_all_ele?.item(index) as HTMLDivElement)?.innerText)
    })
    const writingAssistant = JSON.parse(localStorage.getItem('writingAssistant') || '{}')
    writingAssistant.content = content_strs;
    setIsModify(true);
    setShowWelcome(false);
    setCanNewDoc(true);
    localStorage.setItem('writingAssistant', JSON.stringify(writingAssistant))

    setOpen(false)
    setMenuType(undefined)
    current_ele.style.removeProperty('color')
    select_ele.current = undefined
    content_ref.current!.style.color = '#333333'
  }

  const use = (val: string, index: number) => {
    const selection = getSelection() as Selection
    if (index === -1 && menuType === 'Title') {
      changeTitle(val)
      const title_ele = document.getElementById('writeTitle') as HTMLInputElement
      title_ele.value = val
    } else if (index === -1) {
      selection.setPosition(content_ref.current!.firstElementChild, 0)
      document.execCommand("insertText", false, val);
    } else {
      if (multi_select_ele.current) {
        selection.setBaseAndExtent(
          multi_select_ele.current.start_ele, multi_select_ele.current.start_str,
          multi_select_ele.current.end_ele, multi_select_ele.current.end_str
        )
      } else if (select_ele.current) {
        const range = selection.getRangeAt(0)
        if (select_ele.current.ele.nodeType === 3) {
          range.setStart(select_ele.current.ele, select_ele.current.start)
          range.setEnd(select_ele.current.ele, select_ele.current.end)
        } else {
          range.setStart(select_ele.current.ele, select_ele.current.start)
          range.setEnd(select_ele.current.ele, select_ele.current.ele.childNodes.length)
        }
      } else if (current_position.current) {
        selection.setPosition(current_position.current?.ele, current_position.current?.offset)
      }
      document.execCommand("insertText", false, val);
    }
    const content_strs: string[] = []
    const newest_all_ele = content_ref.current?.getElementsByClassName('content_item');
    new Array(newest_all_ele?.length).fill('').forEach((_, index) => {
      content_strs.push((newest_all_ele?.item(index) as HTMLDivElement)?.innerText)
    })

    const writingAssistant = JSON.parse(localStorage.getItem('writingAssistant') || '{}')
    writingAssistant.content = content_strs;
    setIsModify(true);
    setShowWelcome(false);
    setCanNewDoc(true)
    localStorage.setItem('writingAssistant', JSON.stringify(writingAssistant))
    setOpen(false)
    setMenuType(undefined)
    multi_select_ele.current = undefined
    select_ele.current = undefined
    if (current_ele) {
      current_ele.style.removeProperty('color')
    }
    content_ref.current!.style.color = '#333333'
  }

  // 点击设置光标索引
  const toFocusIndex: MouseEventHandler<HTMLDivElement> = (e) => {
    const ele = e.target as HTMLDivElement
    const all_ele = document.getElementsByClassName('content_item')
    new Array(all_ele.length).fill('').forEach((_, index) => {
      if (all_ele.item(index)?.isSameNode(ele)) {
        setEditIndex(index)
        ele.setAttribute('placeholder', '请输入文本，或按“空格”开始写作')
      } else {
        all_ele.item(index)?.removeAttribute('placeholder')
      }
    })
  }

  /**键入改变光标：回车、方向键 */
  const keyDownFocusIndex: KeyboardEventHandler<HTMLDivElement> = debounce((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.shiftKey && e.key === 'Enter') {
      document.execCommand('insertParagraph')
      return
    }
    if (e.key.indexOf('Arrow') > -1 || e.key === 'Enter') {
      var selection = getSelection() as Selection
      if (selection.focusNode?.nodeName === '#text') {
        const edit_item = get_edit_item(selection.focusNode as HTMLElement)
        if (edit_item !== 'ERROR_NODE') {
          const eles = document.getElementsByClassName('content_item');
          new Array(eles.length).fill('').forEach((_, index) => {
            if (eles.item(index)?.isSameNode(edit_item)) {
              setEditIndex(index)
              if (e.key === 'Enter' && eles.item(index)?.innerHTML === '<br>') {
                eles.item(index)!.innerHTML = ''
              }
              eles.item(index)?.setAttribute('placeholder', '请输入文本，或按“空格”开始写作')
            } else {
              eles.item(index)?.removeAttribute('placeholder')
            }
          })
        }
      } else if (selection.focusNode?.nodeName === 'DIV') {
        const is_edit_item = (selection.focusNode as HTMLDivElement).classList.contains('content_item')
        if (!is_edit_item) return

        const eles = document.getElementsByClassName('content_item');
        new Array(eles.length).fill('').forEach((_, index) => {
          if (eles.item(index)?.isSameNode((selection.focusNode as HTMLDivElement))) {
            if (e.key === 'Enter' && eles.item(index)?.innerHTML === '<br>') {
              eles.item(index)!.innerHTML = ''
            }
            eles.item(index)?.setAttribute('placeholder', '请输入文本，或按“空格”开始写作')
            setEditIndex(index)
          } else {
            eles.item(index)?.removeAttribute('placeholder')
          }
        })
      }
    }
  }, 10)

  const debounceDelete = debounce(() => {
    const content_strs: string[] = []
    const newest_all_ele = content_ref.current?.getElementsByClassName('content_item');
    new Array(newest_all_ele?.length).fill('').forEach((_, index) => {
      content_strs.push((newest_all_ele?.item(index) as HTMLDivElement)?.innerText)
    })

    const writingAssistant = JSON.parse(localStorage.getItem('writingAssistant') || '{}')
    writingAssistant.content = content_strs
    setIsModify(true);
    setShowWelcome(false);
    setCanNewDoc(true)
    localStorage.setItem('writingAssistant', JSON.stringify(writingAssistant))
  }, 30)

  /**键入删除 */
  const deleteKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const selection = getSelection() as Selection
    let should_prevent_default = false
    const _focus_node = (selection?.focusNode as HTMLDivElement)
    if (!selection.isCollapsed) { // 存在选区
      const all_ele = content_ref.current?.getElementsByClassName('content_item');
      const start_parent_ele = get_edit_item(selection.anchorNode as HTMLElement)
      const end_parent_ele = get_edit_item(selection.focusNode as HTMLElement)

      let start = { node: -1, offset: selection.anchorOffset }
      const end = { node: -1, offset: selection.focusOffset }
      new Array(all_ele?.length).fill('').forEach((_, index) => {
        if (all_ele?.item(index)?.isSameNode(start_parent_ele as HTMLElement)) {
          start.node = index
        }
        if (all_ele?.item(index)?.isSameNode(end_parent_ele as HTMLElement)) {
          end.node = index
        }
      })

      const first = start.node > end.node ? end : start
      document.execCommand("insertText", false, '');
      if (all_ele?.item(first.node)?.innerHTML === '' || all_ele?.item(first.node)?.innerHTML === '<br>') {
        all_ele?.item(first.node)?.setAttribute('placeholder', '请输入文本，或按“空格”开始写作')
      }
      e.preventDefault()
      debounceDelete()
    } else if (_focus_node?.nodeType === 1
      && _focus_node.classList.contains('content_item')
      && _focus_node.innerText === ''
    ) {
      // 文章仅存在一个段落时，删除最后一个字符串时阻止默认事件：默认事件会删除最后一个dom子节点
      if (content_ref.current?.childNodes.length === 1 && _focus_node.innerText?.length <= 1) {
        _focus_node.setAttribute('placeholder', '请输入文本，或按“空格”开始写作')
        e.preventDefault()
        debounceDelete()
        return
      }
      const preNode = _focus_node.previousElementSibling as HTMLDivElement;
      selection.setBaseAndExtent(_focus_node, 0, preNode, preNode?.childNodes.length)
      document.execCommand("insertText", false, '');
      if (preNode?.innerHTML === '<br>' || preNode?.innerText === '') {
        preNode.setAttribute('placeholder', '请输入文本，或按“空格”开始写作')
      }
      selection.setPosition(preNode as HTMLDivElement, (preNode as HTMLDivElement)?.childNodes.length)
      should_prevent_default = true
    } else if (selection?.focusNode?.nodeType === 1
      && _focus_node.classList.contains('content_item')
      && _focus_node.innerText?.length === 1) { //设置选区后清空
      const range = selection.getRangeAt(0)
      range.setStart(_focus_node, 0)
      range.setEnd(_focus_node, _focus_node.childNodes.length)
      document.execCommand("insertText", false, '');
      _focus_node.setAttribute('placeholder', '请输入文本，或按“空格”开始写作')
      should_prevent_default = true
    } else if (selection?.focusNode?.nodeType === 3) { // 纯文本
      const range = selection.getRangeAt(0)
      const parent_node = get_edit_item(selection?.focusNode as HTMLElement)
      if (parent_node !== 'ERROR_NODE' && parent_node?.innerText.length === 1) {
        range.setStart(parent_node, 0)
        range.setEnd(parent_node, parent_node?.childNodes.length)
        document.execCommand("insertText", false, '');
        parent_node.setAttribute('placeholder', '请输入文本，或按“空格”开始写作')
        should_prevent_default = true
      }
    }
    if (should_prevent_default) {
      e.preventDefault()
      debounceDelete()
    }
  }

  const hoverToSetIndex = throttle((e: React.MouseEvent<HTMLDivElement>) => {
    const _ele = e.target as HTMLDivElement
    const all_ele = content_ref.current?.getElementsByClassName('content_item')
    let index = -2
    new Array(all_ele?.length).fill('').forEach((_, i) => {
      if (all_ele?.item(i)?.isSameNode(_ele)) {
        index = i;
      }
    })
    if (index > -2) {
      setEditIndex(index)
    }
  }, 30)

  const showMenu = (type: OperatesType, extend?: string) => {
    if (type === 'Translate' && !extend) {
      return
    }
    const selection = getSelection() as Selection
    const range = selection?.getRangeAt(0) as Range
    // 跨段落选择保存参数
    if (!selection.anchorNode?.isSameNode(selection.focusNode)) {
      multi_select_ele.current = {
        start_ele: selection.anchorNode as HTMLElement,
        start_str: selection.anchorOffset,
        end_ele: selection.focusNode as HTMLElement,
        end_str: selection.focusOffset
      }
      select_ele.current = undefined
    } else {
      multi_select_ele.current = undefined
      if (!selection?.isCollapsed) { //选中了文本
        const max = Math.max(selection.focusOffset, selection.anchorOffset)
        const min = Math.min(selection.focusOffset, selection.anchorOffset)
        select_ele.current = {
          ele: selection.focusNode as Element,
          start: min,
          end: max
        }
      } else { //没选中直接设置整段为选中文本
        // current_ele.style.color = '#333333'
        // content_ref.current!.style.color = '#999999'
        select_ele.current = {
          ele: current_ele,
          start: 0,
          end: current_ele.innerText.length
        }
        range.setStart(current_ele, 0)
        range.setEnd(current_ele, current_ele.childNodes.length)
      }
    }
    if (type === 'Copy') {
      document.execCommand('copy')
      message.success({ title: '复制成功' })
      return
    }
    setMenuType(type);
    setMenu(false);
  }

  const save = async () => {
    try {
      const writingAssistant = JSON.parse(localStorage.getItem('writingAssistant') || '{}')
      if (!content_ref.current?.innerText.trim().replace(/\n\s*/g, '\n')) {
        message.warning({ title: '请输入内容后进行保存' })
        return
      }
      const res = await saveWrite({ _id: writingAssistant.id, title, content: writingAssistant.content, toolid: tool ? tool._id : '' })
      writingAssistant.id = res._id
      setIsModify(false);
      localStorage.setItem('writingAssistant', JSON.stringify(writingAssistant))
      message.success({ title: '保存成功' })
      setListShouldUpdate(!listShouldUpdate)
    } catch (err: any) {
      message.error({ title: err.message })
    }
  }

  const download = async () => {
    if (!content_ref.current?.innerText.trim().replace(/\n\s*/g, '\n')) {
      message.warning({ title: '无内容，请写作后下载' })
      return
    }

    if (window) {
      const PizZip = (await import('pizzip')).default;
      const PizZipUtils = (await import('pizzip/utils/index.js')).default;
      PizZipUtils.getBinaryContent(
        './docs/template.docx',
        async function (error, content) {
          if (error) {
            throw error;
          }
          const zip = new PizZip(content);
          const doc = new Docxtemplater().loadZip(zip)
          doc.setOptions({
            nullGetter: function () {
              return "";
            },
            linebreaks: true
          })
          doc.setData({
            title: title || `[文思逸言]AI写作（高级版）${dayjs().format('YYYYMMDD_HHmm')}`,
            content: content_ref.current?.innerText
          });
          doc.render();
          const out = doc.getZip().generate({
            type: 'blob',
            mimeType:
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          }); //Output the document using Data-URI
          const suggestedName = `${title || `[文思逸言]AI写作（高级版）${dayjs().format('YYYYMMDD_HHmm')}`}.docx`;
          try {
            await saveBlobToLocal(out, suggestedName);
          } catch (err) {
            console.log(err)
          }
        }
      );
    }
  }

  // 当前聚焦的元素
  const all_ele = document.getElementsByClassName('content_item');
  let current_ele: HTMLDivElement | HTMLInputElement
  if (editIndex === -1) {
    current_ele = document.getElementById('writeTitle') as HTMLInputElement
  } else {
    current_ele = all_ele.item(editIndex) as HTMLDivElement
  }

  let select_text = ''
  if (multi_select_ele.current) {
    const selection = getSelection() as Selection
    select_text = selection.toString() //选区的文本
  } else if (select_ele.current) {
    select_text = select_ele.current?.ele.nodeValue?.substring(select_ele.current?.start, select_ele.current?.end) || ''
  }

  if (!hydrated) {
    // Returns null on first render, so the client and server match
    return null;
  }

  const reset = async (should_save = false) => {
    if (should_save) {
      await save()
      setListShouldUpdate(!listShouldUpdate)
    }
    setActiveId('')
    setIsModify(false);
    setShowWelcome(true);
    setCanNewDoc(false);
    closeAlert()
    setTitle('')
    content_ref.current!.innerHTML = '';
    const title_ele = document.getElementById('writeTitle') as HTMLInputElement
    title_ele.value = '';
    const create_div = document.createElement("div")
    create_div.className = 'content_item'
    create_div.innerHTML = ''
    create_div.setAttribute('placeholder', '请输入文本，或按“空格”开始写作')
    if (content_ref.current) {
      content_ref.current?.append(create_div)
    }
    localStorage.setItem('writingAssistant', JSON.stringify({ type: 'list', spend: 0 }));
  }

  const leave = async () => {
    if (isModify) {
      openAlert({
        status: 'warning',
        title: '提示',
        content: '您有未保存的内容，是否保存修改?',
        okBtnText: '保存',
        cancelBtnText: '放弃',
        onOk: async () => {
          await reset(true);
        },
        onCancel: async () => {
          await reset(false)
        },
        // footer: <HorizontalBox justifyContent='flex-end' mt='20px'>
        //   <CButton theme='outline' size='md' mr='10px' onClick={_ => { reset(false) }}>放弃</CButton>
        //   <CButton theme='primary' size='md' w='80px' onClick={() => { reset(true) }}>保存</CButton>
        // </HorizontalBox>
      })
      return
    }
    reset(false)
  }

  return <Box className={styles.writing}>
    <HorizontalBox className={styles.titleBox} >
      <HorizontalBox className={styles.subTitle}>
        <Img className={styles.icon} src={tool?.icon} />
        <Text fontSize='20px' mt='4px' ml={2}>{tool?.name} </Text>
        <HorizontalBox ml='auto' cursor='pointer' onClick={async () => {
          await leave()
        }}>
          <Icon as={require('@/assets/svg/new_doc.svg').ReactComponent} w='20px' h='20px'
            css={{ ' path': { stroke: canNewDoc ? '#333333' : '', fill: canNewDoc ? '#333333' : '' } }} />
          <Text color={!canNewDoc ? '#cccccc' : '#333333'} fontSize='16px' ml='4px'>新建文档</Text>
        </HorizontalBox>
      </HorizontalBox>
      <HorizontalBox p='30px 70px' fontSize='20px' lineHeight={1} className={styles.record}>
        <Text color={listType === 'use' ? '#333333' : '#999999'} fontWeight='bold' mr='70px' cursor='pointer' onClick={_ => setListType('use')}>最近使用</Text>
        <Text color={listType === 'collection' ? '#333333' : '#999999'} cursor='pointer' onClick={_ => setListType('collection')}>收藏</Text>
      </HorizontalBox>
    </HorizontalBox>
    <Box className={styles.containerBox}>
      <Box className={styles.content}>
        <Box className={styles.textContent} onClick={_ => {
          const selection = getSelection()
          selection?.empty()
          selection?.setPosition(content_ref.current, content_ref.current?.childElementCount)
          const ele = content_ref.current?.lastChild as HTMLDivElement;
          const all_ele = document.getElementsByClassName('content_item')
          new Array(all_ele.length).fill('').forEach((_, index) => {
            if (all_ele.item(index)?.isSameNode(ele)) {
              setEditIndex(index)
              ele.setAttribute('placeholder', '请输入文本，或按“空格”开始写作')
            } else {
              all_ele.item(index)?.removeAttribute('placeholder')
            }
          })
        }}>
          <Box className={styles.titleInputBox} onClick={e => {
            e.stopPropagation();
            ((e.target as HTMLDivElement).firstChild as HTMLInputElement).focus()
          }}>
            <Input border='none' id='writeTitle'
              className={`${styles.input} ${styles.titleInput}`}
              placeholder='无标题'
              onClick={e => e.stopPropagation()}
              onMouseMove={() => setEditIndex(-1)}
              onChange={e => changeTitle(e.target.value)}
              onDrop={e => {
                e.preventDefault()
              }}
              onKeyDown={e => {
                if (e.code === 'Enter') {
                  const selection = getSelection()
                  selection?.setPosition(content_ref.current, 0)
                  e.preventDefault()
                }
              }}
            />
          </Box>
          <Box overflow='overlay' className={styles.generateContent} ref={container} onClick={e => e.stopPropagation()}>
            <Box pos='relative' p='0 30px'>
              <Box contentEditable px='14px'
                ref={content_ref}
                id="contentRef"
                suppressContentEditableWarning
                cursor='text'
                onMouseMove={hoverToSetIndex}
                onDrop={e => {
                  e.preventDefault()
                }}
                onKeyDown={e => {
                  if (e.code === 'Backspace') {
                    deleteKey(e)
                  } else if (e.code === 'Space') {
                    const selection = getSelection() as Selection
                    let is_content = selection.focusNode?.isSameNode(content_ref.current);
                    let edit_item = is_content ? (content_ref.current?.lastChild as HTMLDivElement)
                      : get_edit_item(selection.focusNode as HTMLElement) as HTMLDivElement
                    if (selection.isCollapsed) {
                      if (edit_item.innerHTML.length === 0 || edit_item.innerHTML === '<br>') { // 空段落输入空格弹出ai
                        current_position.current = { ele: selection.focusNode as Node, offset: selection.focusOffset }
                        setOpen(true)
                        e.preventDefault()
                      }
                    }
                  } else {
                    keyDownFocusIndex(e)
                  }
                }}
                onClick={toFocusIndex}
                onInput={changeContent}
                onContextMenu={e => {
                  e.preventDefault()
                  const is_select = !(getSelection()?.isCollapsed)
                  if (is_select) {
                    set_menu_postion({
                      left: e.clientX + 5,
                      top: e.clientY + 5
                    })

                    e.preventDefault();
                    setMenu(['Brainstorm', 'Expand', 'Continue', 'Copy', 'Translate', 'Optimization', 'Rewrite', 'Summary'])
                  }
                }}
                onPaste={e => {
                  e.preventDefault();
                  const text = e.clipboardData.getData('text/plain')//.replaceAll('\n', ''); //仅提取文字，去除粘贴中的换行
                  document.execCommand("insertText", false, text);
                }}
                onBlur={e => {
                  const selection = getSelection() as Selection
                  e.preventDefault()
                  current_position.current = { ele: selection.focusNode as Node, offset: selection.focusOffset }
                }}
              />
              {
                // 只有空格或者回车不显示菜单按钮
                editIndex > -1 && (current_ele?.innerText || (current_ele as HTMLInputElement)?.value || '').trim().replace(/\n\s*/g, '\n') &&
                <Icon as={require('@/assets/svg/wirte_menu.svg').ReactComponent}
                  id='menuIcon'
                  css={{ ' path': { fill: '#565CFA' }, scale: '0.8', ' g': { opacity: 1 } }}
                  pos='absolute' w='26px' h='26px'
                  left='14px' top={current_ele?.offsetTop - 4 + 'px'} cursor='pointer'
                  onClick={e => {
                    e.stopPropagation()
                    const range = getSelection()?.getRangeAt(0) as Range
                    select_ele.current = {
                      ele: current_ele,
                      start: 0,
                      end: current_ele.innerText.length
                    }
                    range.setStart(current_ele, 0)
                    range.setEnd(current_ele, current_ele.childNodes.length)
                    const windowHeight = window.innerHeight;
                    const isTopHalf = e.clientY < windowHeight / 2;
                    if (isTopHalf) {
                      set_menu_postion({
                        left: e.clientX + 5,
                        top: e.clientY + 5
                      })
                    } else {
                      const menuHeight = 300;
                      set_menu_postion({
                        left: e.clientX + 5,
                        top: e.clientY - menuHeight - 5
                      });
                    }
                    setMenu(['Brainstorm', 'Expand', 'Continue', 'Copy', 'Translate', 'Optimization', 'Rewrite', 'Summary'])
                  }}
                />
              }
            </Box>
          </Box>
          {
            // 只有空格或者回车不显示菜单按钮
            editIndex === -1 && title &&
            <Icon as={require('@/assets/svg/wirte_menu.svg').ReactComponent}
              id='menuIcon'
              css={{ ' path': { fill: '#565CFA' }, scale: '1.2', ' g': { opacity: 1 } }}
              pos='absolute' w='26px' h='26px'
              left='34px' top={current_ele?.offsetTop + 10 + 'px'} cursor='pointer'
              onClick={e => {
                e.stopPropagation()
                set_menu_postion({
                  left: e.clientX + 5,
                  top: e.clientY + 5
                })
                setMenu(['Outline', 'Title'])
              }}
            />
          }
          {
            (menu && menu.length) ? <Box id='menuMask' onContextMenu={e => e.preventDefault()}
              pos='fixed' top={0} left={0} right={0} bottom={0} zIndex={9}></Box> : ''
          }
          {
            (menu && menu.length) ? <Box pos='fixed' bg='#fff'
              boxShadow='0px 6px 20px 0px rgba(0,7,70,0.1)'
              borderRadius='10px' px='10px'
              zIndex={10}
              left={Math.floor((menu_postion?.left || -100) / zoom) + 'px'}
              top={Math.floor((menu_postion?.top || (current_ele?.offsetTop + 26)) / zoom) + 'px'}
              ref={menuRef}
              onContextMenu={e => e.preventDefault()}
            >
              {
                menu.map((item, index) => {
                  return <Box key={item} display='flex' alignItems='center' pos='relative'
                    p='14px'
                    borderBottom={index !== menu.length - 1 ? '1px solid rgba(153,153,153,0.4)' : 'none'}
                    lineHeight={1}
                    _hover={{ color: '#565CFA', ' .extends': { display: 'block' }, ' path': { fill: '#565CFA' } }}
                    cursor='pointer'
                    onClick={e => { e.stopPropagation(); showMenu(item) }}>
                    <Icon as={operates[item].icon} w='18px' h='18px' mr='8px' />
                    {operates[item].name}
                    {
                      operates[item].extends && <Box ml='12px' mt='-6px'>
                        <Icon as={require('@/assets/svg/down.svg').ReactComponent} transform='rotate(-90deg)' w='18px' h='18px' />
                        <Box className='extends' pos='absolute'
                          display='none'
                          boxShadow='0px 6px 20px 0px rgba(0,7,70,0.1)'
                          borderRadius='10px' px='10px'
                          right='-128px'
                          bottom={0}
                          bg='#fff'
                          color='#333'>
                          {
                            operates[item].extends?.map((extend, eIndex) => {
                              const length = operates[item].extends?.length || 0
                              return <Box key={extend} p='14px'
                                borderBottom={eIndex !== length - 1 ? '1px solid rgba(153,153,153,0.4)' : 'none'}
                                _hover={{ color: '#565CFA' }}
                                onClick={e => {
                                  e.stopPropagation();
                                  showMenu(item, extend)
                                  setMenuExtend(extend)
                                }}
                              >
                                {extend}
                              </Box>
                            })
                          }
                        </Box>
                      </Box>
                    }
                  </Box>
                })
              }
            </Box> : ''
          }

        </Box>
        <HorizontalBox className={styles.footer}>
          <HorizontalBox cursor='pointer' _hover={{ color: '#565CFA', ' svg path': { fill: '#565CFA' } }}
            position='relative' id='modelChoose'
            onClick={e => setChooseModel(true)}
            css={{
              color: chooseModel ? '#565CFA' : '#333',
              '.text1': { color: chooseModel ? '#565CFA' : '#999' },
              '.text2': { color: chooseModel ? '#565CFA' : '#333' },
              ' svg path': { fill: chooseModel ? '#565CFA' : '#666' }
            }}
            border='1px solid rgba(153, 153, 153, 0.5)'
            w='200px'
            h='44px'
            p='10px 15px'
            borderRadius='14px'
            boxSizing='border-box'
          >
            <Icon as={require('@/assets/svg/write_model.svg').ReactComponent} w="22px" h="22px" mr='8px' mt='-2px' />
            <Flex flexDir={'column'}>
              <Flex alignItems={'center'}>
                <Text fontSize={'14px'} fontWeight={'500'} className="text2">{modelKey.name}</Text>
                <Icon as={require('@/assets/svg/right.svg').ReactComponent} w='14px' h='14px' ml='2px' />
              </Flex>
              <Text fontSize={'12px'} className="text1">
                {
                  modelKey.tag ? <Box className={styles.tagtext} bg='#FF7A00' color='#fff' fontSize='10px'
                    borderRadius='10px' w='52px' textAlign='center'
                  >
                    {modelKey.tag}
                  </Box> :
                    <Flex className={styles.selectprice} align='center'>
                      {modelKey.price}<Icon as={require(`@/assets/svg/notvipbalance.svg`).ReactComponent} />/次
                    </Flex >
                }
              </Text>
            </Flex>
            {
              chooseModel && <Box id='modelMenu' boxShadow='0px 6px 20px 0px rgba(0,7,70,0.1)' borderRadius='14px' bg='#fff'
                px='20px' pos='absolute' bottom={'30px'} right={0} zIndex={9} w='300px' ref={modelMenuRef} className={styles.selectmodels}
              >
                {_Models.map((item) => {
                  return <Flex key={item.key} className={styles.selectmodel}
                    onClick={e => {
                      e.stopPropagation()
                      setModelKey(item)
                      localStorage.setItem('writingModel', JSON.stringify({ name: item.name, key: item.key }))
                      setChooseModel(false)
                    }}
                  >
                    {modelKey.key === item.key && <Icon className={styles.Select} as={require('@/assets/svg/toolsicon/gou_white.svg').ReactComponent}
                    />}
                    <Box>
                      <Text className={modelKey.key === item.key ? styles.selectName : styles.selectname}>{item.name}</Text>
                      <Text className={modelKey.key === item.key ? styles.selectTips : styles.selecttips}>{item.tips}</Text>
                    </Box>
                    {
                      item.tag && <Box className={styles.tagtext}>{item.tag}</Box>
                    }
                    {
                      item.price && <Flex className={modelKey.key === item.key ? styles.selectprice : styles.pricetext}>
                        {item.price}<Icon as={require(`@/assets/svg/notvipbalance.svg`).ReactComponent} />/次
                      </Flex >
                    }
                  </Flex>
                })}
              </Box>
            }
          </HorizontalBox>
          <HorizontalBox>
            <HorizontalBox w='106px' h='40px'
              borderRadius='14px' border='1px solid #666666'
              justifyContent='center'
              color='#666666'
              cursor='pointer'
              bg='#fff'
              mx='20px'
              _hover={{ color: '#565CFA', borderColor: '#565CFA' }}
              onClick={download}
            >
              <Icon as={require('@/assets/svg/dowload.svg').ReactComponent} w='18px' h='18px' mr='4px' />
              下载
            </HorizontalBox>
            <HorizontalBox w='106px' h='40px'
              borderRadius='14px'
              justifyContent='center'
              color='#fff'
              bg='#565CFA'
              cursor='pointer'
              _hover={{ bg: '#433FE3' }}
              onClick={save}
            >
              <Icon as={require('@/assets/svg/save.svg').ReactComponent} w='18px' h='18px' mr='4px' css={{ ' path': { stroke: '#fff' } }} />
              保存
            </HorizontalBox>
          </HorizontalBox>
        </HorizontalBox>
        <Box className={styles.welcome} cursor='pointer' onClick={() => {
          setOpen(true)
        }}>
          <WiseImage src={require('@/assets/img/write_modal_animation.gif')} w='100%' />
        </Box>
        {
          showWelcome ? <Box className={styles.welcome_text} cursor='pointer' onClick={() => {
            setOpen(true)
          }}>
            <Box className={styles.welBox}>
              <Icon as={require('@/assets/svg/welcome_text_bg.svg').ReactComponent} w='100%' />
              <Text zIndex={1}>Hi，试试Ai写作吧</Text>
            </Box>
          </Box> : null
        }
      </Box>
      <Writing.WriteHistory activeId={activeId || ''} selfType={listType} listShoulUpdate={listShouldUpdate} onChangeInfo={(id, params) => {
        isInit.current = true;
        if (isModify) {
          openAlert({
            status: 'warning',
            title: '提示',
            content: '您有未保存的内容，是否保存修改?',
            okBtnText: '保存',
            cancelBtnText: '放弃',
            onOk: async () => {
              setIsModify(false);
              await save()
              setListShouldUpdate(!listShouldUpdate)
              closeAlert()
              setActiveId(id)
              setCanNewDoc(true);
              if (id === '') {
                reset(false)
              }
              if (params) {
                localStorage.setItem('writingAssistant', JSON.stringify(params))
              }
            },
            onCancel: async () => {
              setIsModify(false);
              closeAlert()
              setActiveId(id)
              setCanNewDoc(true);
              if (id === '') {
                reset(false)
              }
              if (params) {
                localStorage.setItem('writingAssistant', JSON.stringify(params))
              }
            }
          })
        } else {
          setActiveId(id)
          setCanNewDoc(true);
          if (id === '') {
            reset(false)
          }
          if (params) {
            localStorage.setItem('writingAssistant', JSON.stringify(params))
          }
        }
      }} />
    </Box>
    {
      open && <WritingModal isOpen={open} onClose={() => {
        setTimeout(() => {
          if (current_position.current) {
            const selection = getSelection() as Selection;
            selection.setPosition(current_position.current.ele, current_position.current.offset)
          };
        }, 10);
        setOpen(false)
      }}>
        <Writing.BaseGenerat
          model={modelKey.key}
          onClose={() => {
            if (current_position.current) {
              const selection = getSelection() as Selection;
              selection.setPosition(current_position.current.ele, current_position.current.offset)
            }
            setOpen(false)
          }}
          title={title}
          index={editIndex}
          onAddToEnd={addToEnd}
          onUse={use}
          onSpend={setSpend}
        />
      </WritingModal>
    }
    {
      menuType && <WritingModal isOpen={!!menuType} onClose={() => {
        setMenuType(undefined)
        current_ele.style.removeProperty('color')
        content_ref.current!.style.color = '#333333'
      }}>
        <Writing.OperatesModal
          model={modelKey.key}
          title={title}
          index={editIndex}
          text={select_text || current_ele?.innerText || ''}
          type={menuType}
          extend={menuExtend}
          onAddToEnd={addToEnd}
          onUse={use}
          onSpend={setSpend}
          onClose={() => {
            current_ele.style.removeProperty('color')
            content_ref.current!.style.color = '#333333'
            setMenuType(undefined)
          }} />
      </WritingModal>
    }
    {
      <Writing.FirstOpenModal open={isFirst} onClose={() => setIsFirst(false)} />
    }
  </Box >
}

export default observer(WritingAssistant)