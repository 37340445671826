import { FC, useEffect, useRef, useState } from "react";
import { Box, Icon, Text } from "@chakra-ui/react";
import { useMessage } from "@/hooks/useMessage";
import { useTranslation } from "react-i18next";

import styles from './index.module.scss';

interface CreationSwiperProps {
  audioSrc: string
  lyrics: string
  songlyric: boolean
  coverImage: string
}

const parseLyrics = (lyrics: any, baseTime = 0, increment = 4.5, leadTime = -7) => {
  const parts = lyrics.trim().split(/\n\s*(?=\[)/); // 分割段落
  let currentTime = baseTime;
  let result: any[] = [];

  parts.forEach((part: any) => {
    const lines = part.split('\n').slice(1); // 去除段的标签 [Verse], [Bridge] 等
    lines.forEach((line: any) => {
      if (line.trim() !== '') {
        result.push({ time: currentTime, text: line.trim() });
        currentTime += increment;
      }
    });
  });

  return result;
};
export const SongPlayer: FC<CreationSwiperProps> = (props) => {
  const { audioSrc, lyrics, songlyric, coverImage } = props;

  const audioRef = useRef<HTMLAudioElement>(null);
  const message = useMessage();

  const lyricsData = parseLyrics(lyrics);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const lyricsRef = useRef<HTMLDivElement>(null); // 用于歌词滚动
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1); // 初始音量为最大
  const { t } = useTranslation();

  const toggleMute = () => {
    if (audioRef.current) {
      audioRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleVolumeChange = (e: any) => {
    const newVolume = parseFloat(e.target.value);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
      setVolume(newVolume);
    }
  };

  const togglePlayPause = () => {
    if (audioRef.current && duration !== 0) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    } else {
      message.warning({ title: t('creation.MusicLoading') })
    }
  };

  const skipForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime += 15;
    }
  };

  const skipBackward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime -= 15;
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const time = (e.target.valueAsNumber / 100) * duration;
    if (audioRef.current) {
      audioRef.current.currentTime = time;
    }
    setCurrentTime(time);
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onloadedmetadata = () => {
        if (audioRef.current) {
          setDuration(audioRef.current.duration);
        }
      };
      audioRef.current.ontimeupdate = () => {
        if (audioRef.current) {
          setCurrentTime(audioRef.current.currentTime);
        }
      };
    }
  }, []);

  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
  const timerRef = useRef<any>(null);
  // 自动滚动歌词到当前
  const scrollToCurrentLyric = () => {
    if (lyricsRef.current && isAutoScrollEnabled) {
      const currentIndex = lyricsData.findIndex((lyric) => currentTime < lyric.time) - 1;
      const currentLyricElement = lyricsRef.current.children[currentIndex] as HTMLDivElement;
      if (currentLyricElement) {
        const offset = lyricsRef.current.clientHeight / 2 - currentLyricElement.clientHeight / 2;
        lyricsRef.current.scrollTop = currentLyricElement.offsetTop - offset;
      }
    }
  };

  // 监听歌词变化
  useEffect(() => {
    scrollToCurrentLyric();
  }, [currentTime, isAutoScrollEnabled]);
  // 处理滚动和延迟
  useEffect(() => {
    const handleScroll = () => {
      setIsAutoScrollEnabled(false);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      // 5秒后启用自动滚动
      timerRef.current = setTimeout(() => {
        setIsAutoScrollEnabled(true);
      }, 5000);
    };

    const lyricsElement = lyricsRef.current;
    if (lyricsElement) {
      lyricsElement.addEventListener('scroll', handleScroll);
      return () => {
        lyricsElement.removeEventListener('scroll', handleScroll);
        clearTimeout(timerRef.current);
      };
    }
  }, []);
  const [rotationAngle, setRotationAngle] = useState(0); // 跟踪旋转角度
  const [animationId, setAnimationId] = useState<any>(null); // 跟踪动画的请求 ID

  useEffect(() => {
    if (isPlaying) {
      const start = Date.now();
      const animate = () => {
        const now = Date.now();
        const elapsed = now - start;
        const newAngle = (rotationAngle + (elapsed / 20000) * 360) % 360; // 每 20s 完成一次 360 度旋转
        setRotationAngle(newAngle);
        const id = requestAnimationFrame(animate);
        setAnimationId(id);
      };

      const id = requestAnimationFrame(animate);
      setAnimationId(id);

      return () => {
        cancelAnimationFrame(id);
      };
    } else if (animationId) {
      // 在暂停时取消动画
      cancelAnimationFrame(animationId);
      setAnimationId(null);
    }
  }, [isPlaying, rotationAngle]);
  const sliderRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (sliderRef.current) {
      updateSliderBackground((currentTime / duration) * 100 || 0);
    }
  }, [currentTime, duration]);
  const updateSliderBackground = (value: any) => {
    const percentage = (value / 100) * 100;
    if (sliderRef.current) {
      sliderRef.current.style.background = `linear-gradient(to right, #fff 0%, #fff ${percentage}%, rgb(255,255,255,0.3) ${percentage}%, rgb(255,255,255,0.3) 100%)`;
    }
  };

  const onChange = (e: any) => {
    handleSliderChange(e);
    updateSliderBackground(e.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      if (duration > 0 && currentTime === duration) {
        setIsPlaying(false);
      }
    }, 500);
  }, [currentTime])
  return (
    <Box className={styles.swiper}>
      <Box className={styles.swiperbefore} style={{ backgroundImage: `url(${coverImage})` }}></Box>
      <div className={styles.musicPlayer}>
        <audio
          ref={audioRef}
          src={audioSrc}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
        />
        <div className={styles.content}>
          <div
            className={isPlaying ? `${styles.cover} ${styles.rotate}` : `${styles.cover} ${styles.norotate}`}
            style={{
              backgroundImage: `url(${coverImage})`,
              transform: `rotate(${rotationAngle}deg)`,
            }}
          ></div>
          <div ref={lyricsRef} className={styles.lyricsContainer}>
            {!songlyric ? lyricsData.length > 0 ? lyricsData.map((lyric, index) => {
              const currentIndex = lyricsData.findIndex((lyric) => currentTime < lyric.time) - 1;
              const targetIndex = currentIndex >= 0 ? currentIndex : lyricsData.length - 1;
              const distance = Math.abs(targetIndex - index);
              const minOpacity = 0.4; // 设定一个最低透明度值
              const opacity = 1 - Math.min(distance / 5, 1) * (1 - minOpacity);

              return (
                <div
                  key={index}
                  className={`
                ${currentIndex === index ? styles.activeLyric : ''}
              `}
                  style={{ opacity }}
                >
                  {lyric.text}
                </div>
              );
            })
              : <div style={{ marginTop: '100%' }}>
                {lyrics}
              </div>
              : <div style={{ marginTop: '100%' }}>
                {t('creation.InstrumentalMusic')}
              </div>
            }
          </div>
        </div>
        <div className={styles.audioControls}>
          <input
            type="range"
            ref={sliderRef}
            value={(currentTime / duration) * 100 || 0}
            onChange={onChange}
            className={styles.slider}
          />
          {/* <button onClick={toggleMute}>
            {isMuted ? '取消静音' : '静音'}
          </button>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
            className={styles.verticalSlider}
          /> */}
        </div>
        <div className={styles.time}>
          <span>
            {Math.floor(currentTime / 60)}:{Math.floor(currentTime % 60).toString().padStart(2, '0')}
          </span>
          <span>
            {Math.floor(duration / 60)}:{Math.floor(duration % 60).toString().padStart(2, '0')}
          </span>
        </div>
        <div className={styles.controls}>
          <Icon w={'32px'} h={'32px'} onClick={skipBackward} as={require('@/assets/svg/mathcurent.svg').ReactComponent} />
          {!audioRef.current || duration == 0 ?
            <Box className={styles.loader}></Box>
            :
            <Icon w={'40px'} h={'40px'} mx={'30px'} onClick={() => {
              if (currentTime === duration) {
                setCurrentTime(0)
                setIsPlaying(true)
                if (audioRef.current) {
                  audioRef.current.play();
                }
              } else {
                togglePlayPause()
              };
            }} as={!isPlaying ? require('@/assets/svg/songPause.svg').ReactComponent
              : require('@/assets/svg/songPlay.svg').ReactComponent
            } />
          }
          <Icon w={'32px'} h={'32px'} onClick={skipForward} as={require('@/assets/svg/mathduration.svg').ReactComponent} />
        </div>
      </div>
    </Box>
  );
};
