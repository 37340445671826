export const toolLottieJson = {
  "v": "5.8.1",
  "fr": 60,
  "ip": 0,
  "op": 60,
  "w": 70,
  "h": 70,
  "nm": "选中——工具",
  "ddd": 0,
  "assets": [{
    "id": "tool_bg",
    "w": 150,
    "h": 151,
    "p": require('@/assets/lottie/tool_bg.png'),
    "e": 0
  },
  {
    "id": "comp_0",
    "nm": "静态——工具",
    "fr": 60,
    "layers": [{
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "形状图层 5",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [14.083,
            36.879,
            0],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [-910.292,
          -270.246,
            0],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100,
            100,
            100],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "d": 1,
          "ty": "el",
          "s": {
            "a": 0,
            "k": [5,
              5],
            "ix": 2
          },
          "p": {
            "a": 0,
            "k": [0,
              0],
            "ix": 3
          },
          "nm": "椭圆路径 1",
          "mn": "ADBE Vector Shape - Ellipse",
          "hd": false
        },
        {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [1,
              1,
              1,
              1],
            "ix": 4
          },
          "o": {
            "a": 0,
            "k": 100,
            "ix": 5
          },
          "r": 1,
          "bm": 0,
          "nm": "填充 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        },
        {
          "ty": "tr",
          "p": {
            "a": 0,
            "k": [-910.167,
            -270.371],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [0,
              0],
            "ix": 1
          },
          "s": {
            "a": 0,
            "k": [100,
              100],
            "ix": 3
          },
          "r": {
            "a": 0,
            "k": 0,
            "ix": 6
          },
          "o": {
            "a": 0,
            "k": 100,
            "ix": 7
          },
          "sk": {
            "a": 0,
            "k": 0,
            "ix": 4
          },
          "sa": {
            "a": 0,
            "k": 0,
            "ix": 5
          },
          "nm": "变换"
        }],
        "nm": "椭圆 1",
        "np": 3,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }],
      "ip": 0,
      "op": 120,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 2,
      "nm": "工具.png",
      "cl": "png",
      "refId": "tool_bg",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [26,
            26,
            0],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [75,
            75.5,
            0],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [34,
            34,
            100],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 120,
      "st": 0,
      "bm": 0
    }]
  }],
  "layers": [{
    "ddd": 0,
    "ind": 1,
    "ty": 0,
    "nm": "静态——工具",
    "refId": "comp_0",
    "sr": 1,
    "ks": {
      "o": {
        "a": 0,
        "k": 100,
        "ix": 11
      },
      "r": {
        "a": 0,
        "k": 0,
        "ix": 10
      },
      "p": {
        "a": 0,
        "k": [35,
          61,
          0],
        "ix": 2,
        "l": 2
      },
      "a": {
        "a": 0,
        "k": [26,
          52,
          0],
        "ix": 1,
        "l": 2
      },
      "s": {
        "a": 1,
        "k": [{
          "i": {
            "x": [0.667,
              0.667,
              0.667],
            "y": [1,
              1,
              1]
          },
          "o": {
            "x": [0.333,
              0.333,
              0.333],
            "y": [0,
              0,
              0]
          },
          "t": 0,
          "s": [0,
            0,
            100]
        },
        {
          "i": {
            "x": [0.667,
              0.667,
              0.667],
            "y": [1,
              1,
              1]
          },
          "o": {
            "x": [0.333,
              0.333,
              0.333],
            "y": [0,
              0,
              0]
          },
          "t": 10,
          "s": [110,
            110,
            100]
        },
        {
          "i": {
            "x": [0.667,
              0.667,
              0.667],
            "y": [1,
              1,
              1]
          },
          "o": {
            "x": [0.333,
              0.333,
              0.333],
            "y": [0,
              0,
              0]
          },
          "t": 20,
          "s": [80,
            80,
            100]
        },
        {
          "i": {
            "x": [0.667,
              0.667,
              0.667],
            "y": [1,
              1,
              1]
          },
          "o": {
            "x": [0.333,
              0.333,
              0.333],
            "y": [0,
              0,
              0]
          },
          "t": 30,
          "s": [105,
            105,
            100]
        },
        {
          "t": 40,
          "s": [100,
            100,
            100]
        }],
        "ix": 6,
        "l": 2
      }
    },
    "ao": 0,
    "w": 52,
    "h": 52,
    "ip": 0,
    "op": 120,
    "st": 0,
    "bm": 0
  }],
  "markers": []
}