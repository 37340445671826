
import { LocalDB } from ".";

class AgentsDB extends LocalDB {
  getAgentsList(): Promise<MineAgentsType | null> {
    return this.db.getItem('agentsList')
  }

  setAgentsList(list: MineAgentsType) {
    this.db.setItem('agentsList', list)
  }

  getAgentInfo(id: string): Promise<MineAgentsType['myModels'][0] | null> {
    return this.db.getItem(id)
  }

  setAgentInfo(info: MineAgentsType['myModels'][0]) {
    this.db.setItem(info._id, info)
  }


  getAgentDetail(id: string): Promise<ModelSchema | null> {
    return this.db.getItem(`agent_detail${id}`)
  }

  setAgentDetail(detail: ModelSchema) {
    this.db.setItem(`agent_detail${detail._id}`, detail)
  }
}

export const agentsDb = new AgentsDB('agents');