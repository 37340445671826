import { Flex, Box, Text, Textarea, Image, MenuList, Menu, MenuItem, useDisclosure, Icon, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { useMessage } from "@/hooks/useMessage";
import { CButton } from "@/components/Button";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { chatStore } from "@/store/chat";
import { voiceBroadcast } from "@/utils/chat";
import { userStore } from "@/store/user";
import { CModal } from "@/components/Modal";
import Markdown from "@/components/Markdown";
import { ScrollBarBox } from "@/components/ScrollBox";
import RotatingCircles from "@/components/RotatingCircles";
import i18n from '@/utils/i18n';
import { useTranslation } from "react-i18next";

export type ExportChatType = 'md' | 'pdf' | 'html' | 'word';
export const Contentpreview: FC<any> = observer(({
  title,
  titlePrompt,
  bindprompt,
  download,
  downloadtitle,
  loading,
  webPageUrl,
  onclose,
  isContent,
  buttonpd,
  isPlugin,
  riskInfo
}: {
  title: string;
  bindprompt: any;
  download?: boolean;
  downloadtitle?: string,
  loading?: boolean;
  titlePrompt?: string;
  webPageUrl?: string;
  onclose?: () => void;
  isContent: boolean;
  buttonpd: boolean;
  isPlugin?: boolean
  riskInfo?: ChatInfoHistory['contentCheck']
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userInfo } = userStore;
  const message = useMessage();
  const { setPlayingId, playingId } = chatStore;
  const androidRef = useRef<{ ttsWS: WebSocket; audioPlayer: any; }>();
  const {
    isOpen: isOpenDownload,
    onClose: onCloseDownload,
    onOpen: onOpenDownload
  } = useDisclosure();
  const { t } = useTranslation();

  const icons = [
    {
      key: '清空',
      icon: require('@/assets/svg/toolsicon/delete.svg').ReactComponent,
      hovericon: require('@/assets/svg/toolsicon/deletenot.svg').ReactComponent,
      selecticon: require('@/assets/svg/toolsicon/deleteed.svg').ReactComponent,
    },
    {
      key: '复制',
      icon: require('@/assets/svg/toolsicon/copy.svg').ReactComponent,
      hovericon: require('@/assets/svg/toolsicon/copynot.svg').ReactComponent,
      selecticon: require('@/assets/svg/toolsicon/copyed.svg').ReactComponent,
    },
    {
      key: '朗读',
      icon: require('@/assets/svg/toolsicon/play.svg').ReactComponent,
      hovericon: require('@/assets/svg/toolsicon/playnot.svg').ReactComponent,
      selecticon: require('@/assets/svg/toolsicon/played.svg').ReactComponent
    },
    {
      key: '下载',
      icon: require('@/assets/svg/toolsicon/download.svg').ReactComponent,
      hovericon: require('@/assets/svg/toolsicon/downloadnot.svg').ReactComponent,
      selecticon: require('@/assets/svg/toolsicon/downloaded.svg').ReactComponent
    }
  ];
  // 复制内容
  const onclickCopy = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      message.success({ title: '复制成功' })
    } catch (err) {
      message.error({ title: '复制失败' })
    }
  };
  const play = (id: any) => {
    // console.log('playid', playingId)
    if (playingId === id) {
      if (window['speechSynthesis'] === undefined) {
        androidRef.current?.ttsWS.close()
        androidRef.current?.audioPlayer.reset()
      } else {
        window.speechSynthesis.cancel();
      }
      setPlayingId('');
    } else {
      if (window['speechSynthesis'] === undefined) {
        // androidPlayAudio(value) // 安卓播放音频 暂时不支持
      } else {
        voiceBroadcast({
          text: bindprompt.current.value,
          onEndCallback: () => {
            setPlayingId(''); // 朗读结束时更新状态
          }
        });
      }
      setPlayingId(id);
    }
  }
  //删除内容
  const delChatRecord = () => {
    if (bindprompt.current !== null) {
      bindprompt.current.value = '';
    }
    setIsModalOpen(false)
  };

  // export chat data
  const onclickExportChat = useCallback(
    (type: ExportChatType) => {
      const getHistoryHtml = () => {
        const historyText = bindprompt.current.value;
        if (!historyText) return '';

        const encodedText = historyText.replace(/ /g, '&nbsp;').replace(/\n/g, '<br>');

        return encodedText;
      };

      const getHistoryPlainText = () => {
        const historyText = bindprompt.current.value;
        if (!historyText) return '';
        const plainText = historyText.replace(/<[^>]+>/g, ''); // 去除所有 HTML 标签
        return plainText;
      };

      let today = new Date();
      let year = today.getFullYear();
      let month = String(today.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
      let day = String(today.getDate()).padStart(2, '0');
      let date = `${year}${month}${day}`;

      let min = 1000;
      let max = 9999;
      let randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      const anyWindow: any = window;
      const map: Record<ExportChatType, () => void> = {
        md: async () => {
          const blob = new Blob([bindprompt.current.value], { type: 'text/markdown' });
          try {
            const fileName = `[文思逸言]${downloadtitle}_${date}_${randomNumber}.md`;
            if (anyWindow.showSaveFilePicker) {
              //@ts-ignore
              const handle = await window.showSaveFilePicker({
                suggestedName: fileName,
                types: [{
                  description: 'Markdown file',
                  accept: { 'text/markdown': ['.md'] }
                }]
              });
              const writableStream = await (handle as any).createWritable();
              await writableStream.write(blob);
              await writableStream.close();
            } else {
              // Safari 浏览器不支持 showSaveFilePicker，使用 a 元素替代
              const url = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
              setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
              }, 0);
            }
            message.success({ title: '保存成功' })
          } catch (err) {
            console.log('下载错误', err);
            message.warning({ title: '取消导出' })
          }
        },
        html: async () => {
          let htmlContent = getHistoryHtml();
          if (htmlContent === undefined) {
            return; // 终止操作
          }
          if (!htmlContent.includes('<meta charset="UTF-8">')) {
            htmlContent = '<meta charset="UTF-8">' + htmlContent;
          }
          const blob = new Blob([htmlContent], { type: 'text/html;charset=utf-8' });
          const fileName = `[文思逸言]${downloadtitle}_${date}_${randomNumber}.html`
          try {
            if (anyWindow.showSaveFilePicker) {
              // @ts-ignore
              const handle = await window.showSaveFilePicker({
                suggestedName: fileName,
                types: [{
                  description: 'HTML file',
                  accept: { 'text/html': ['.html'] },
                }],
              });
              const writableStream = await (handle as any).createWritable();
              await writableStream.write(blob);
              await writableStream.close();
            } else {
              const url = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
              setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
              }, 0);
            }
            message.success({ title: '保存成功' })
          } catch (err) {
            message.warning({ title: '取消导出' })
          }
        },
        pdf: async () => {
          const pdfContent = getHistoryHtml();
          if (pdfContent === undefined) {
            return; // 终止操作
          }
          if (pdfContent) {
            const element = document.createElement("div");
            element.innerHTML = pdfContent;
            document.body.appendChild(element);
            const scaleFactor = 0.7;
            const canvas = await html2canvas(element, { scale: scaleFactor });
            // const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL("image/png", 0.7);
            // 在渲染后移除元素
            document.body.removeChild(element);
            const pdf = new jsPDF("p", "mm", "a4");
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
            const blob = pdf.output("blob");
            const fileName = `[文思逸言]${downloadtitle}_${date}_${randomNumber}.pdf`
            try {
              if (anyWindow.showSaveFilePicker) {
                // @ts-ignore
                const handle = await window.showSaveFilePicker({
                  suggestedName: fileName,
                  types: [{
                    description: 'PDF file',
                    accept: { 'application/pdf': ['.pdf'] },
                  }],
                });
                const writableStream = await (handle as any).createWritable();
                await writableStream.write(blob);
                await writableStream.close();
              } else {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                setTimeout(() => {
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);
                }, 0);
              }
              message.success({ title: '保存成功' })
            } catch (err) {
              message.warning({ title: '取消导出' })
            }
          }
        },
        word: async () => {
          const wordContent = getHistoryPlainText();
          if (wordContent === undefined) {
            return; // 终止操作
          }
          const blob = new Blob([wordContent], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          const fileName = `[文思逸言]${downloadtitle}_${date}_${randomNumber}.docx`
          try {
            if (anyWindow.showSaveFilePicker) {
              // @ts-ignore
              const handle = await window.showSaveFilePicker({
                suggestedName: fileName,
                types: [{
                  description: 'Microsoft Word document',
                  accept: { 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'] },
                }],
              });
              const writableStream = await (handle as any).createWritable();
              await writableStream.write(blob);
              await writableStream.close();
            } else {
              const url = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
              setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
              }, 0);
            }
            message.success({ title: '保存成功' })
          } catch (err) {
            message.warning({ title: '取消导出' })
          }
        },
      };
      map[type]();

    },
    [bindprompt]
  );
  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleOutsideClick(event: any) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onCloseDownload();
      }
    }

    if (isOpenDownload) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    // 在组件卸载时移除事件监听器
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpenDownload]);

  const ButtonWithIcon = ({ keyName, onClick, isDisabled }: { keyName: any, onClick: any, isDisabled: any }) => {
    const [isHovered, setIsHovered] = useState(false);

    const iconData = icons.find(icon => icon.key === keyName)!;

    let IconComponent = iconData.icon;
    if (isDisabled) {
      IconComponent = iconData.hovericon;
    } else if (isHovered) {
      IconComponent = iconData.selecticon;
    }
    return (
      <Flex
        className={`${styles.button} ${isDisabled ? styles.disabled : ''}`}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <IconComponent />
        <Text whiteSpace="nowrap">{t(keyName)}</Text>
      </Flex>
    );
  };

  const value = bindprompt.current ? bindprompt.current.value : ''
  const isFunctionCall = Array.isArray(value);
  let valStr = ''
  if (isFunctionCall) {
    value.forEach(str => {
      const noText = str.startsWith('\n\n```function_call') || str.endsWith('```\n\n') || str.startsWith('\n\n```finish_reason')
      if (!noText) {
        valStr = str;
      }
    })
  }
  const val = (isPlugin || isFunctionCall) ? valStr : value;
  const riskValue = riskInfo?.status !== false ? val : (`${t("ContentMayContains")}:${riskInfo.labels?.split(',').map(str => i18n.t('riskContent.' + str)).join(',')} ${t('creation.TemporarilyUnavailable')} ` || t('ContentRiskBlocked'))
  return (
    <Flex className={styles.flexColumn}>
      <Box className={styles.centerItemsbox}>
        <Text className={styles.title}>{title}</Text>
        <Text className={styles.prompt}>{titlePrompt ? `（ ${titlePrompt} ）` : ''}</Text>
      </Box>
      <Box className={styles.fullHeightbox}>
        <Flex className={buttonpd ? styles.fullbox : styles.fullnobox}>
          <Icon as={require(`@/assets/svg/toolsicon/nofound.svg`).ReactComponent} />
          <Text className={styles.prompt}>{t('NoGenResult')}</Text>
        </Flex>
        <Textarea ref={bindprompt} className={buttonpd ? styles.notextarea : loading ? isContent ? styles.notextarea : styles.textarea : styles.notextarea} />
        {!loading &&
          <ScrollBarBox className={buttonpd ? styles.nomarkdownBox : styles.markdownBox}>
            <Flex>
              {(riskValue.includes(t("creation.TextMayContain")) || riskValue.includes(t("ContentMayContains"))) &&
                <Icon as={require('@/assets/svg/toolHisError.svg').ReactComponent} />
              }
              {/* <Markdown source={(bindprompt.current && bindprompt.current.value.length > 1) ? bindprompt.current.value : ''} /> */}
              <Box color={(riskValue.includes(t("creation.TextMayContain")) || riskValue.includes(t("ContentMayContains"))) ? '#FF5852' : '#333'}>
                <Markdown source={riskValue} />
              </Box>
            </Flex>
          </ScrollBarBox>
        }
        {(loading && !buttonpd && isContent) &&
          <Flex className={styles.loadingBox}>
            <RotatingCircles />
            <Text>{t('AIGenerating')}...</Text>
          </Flex>
        }
      </Box>
      {webPageUrl || (
        <Flex className={styles.footBox}>
          <Flex className={styles.footTextBox}>
            {/* <Text>本次生成消耗100.00点</Text> */}
          </Flex>
          <Box className={styles.buttonBox}>
            <ButtonWithIcon
              keyName="清空"
              onClick={() => setIsModalOpen(true)}
              // onClick={() => delChatRecord()}
              isDisabled={buttonpd}
            />
            <ButtonWithIcon
              keyName="复制"
              onClick={() => onclickCopy(bindprompt.current.value)}
              isDisabled={buttonpd}
            />
            <ButtonWithIcon
              keyName="朗读"
              onClick={() => { play(userInfo?._id) }}
              isDisabled={buttonpd}
            />
            <CModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <Flex className={styles.avatarcmodelbox}>
                <Flex className={styles.cmodelTitleBox}>
                  <Text>清空内容</Text>
                  <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setIsModalOpen(false)} />
                </Flex>
                <Flex className={styles.avatar_group}>
                  您确定要清空当前内容吗？
                </Flex>
                <Flex className={styles.modelbuttonbox}>
                  <CButton className={styles.cancelButton} mr={3} onClick={() => setIsModalOpen(false)}>
                    {t('cancel')}
                  </CButton>
                  <CButton className={styles.confirmButton} onClick={() => delChatRecord()}>
                    {t('confirm')}
                  </CButton>
                </Flex>
              </Flex>
            </CModal>
            {download && (
              <Box position="relative">
                <ButtonWithIcon
                  keyName="下载"
                  onClick={onOpenDownload}
                  isDisabled={buttonpd}
                />
                <Box className={styles.download} ref={menuRef}>
                  <Menu isOpen={isOpenDownload} >
                    <MenuList className={styles.downloadlist}>
                      <MenuItem className={styles.op} onClick={() => { onCloseDownload(); onclickExportChat('html') }}>导出HTML格式</MenuItem>
                      <MenuItem className={styles.op} onClick={() => { onCloseDownload(); onclickExportChat('pdf') }}>导出PDF格式</MenuItem>
                      <MenuItem className={styles.op} onClick={() => { onCloseDownload(); onclickExportChat('md') }}>导出Markdown格式</MenuItem>
                      <MenuItem className={styles.op} onClick={() => { onCloseDownload(); onclickExportChat('word') }}>导出Word格式</MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              </Box>
            )}
          </Box>
        </Flex>
      )}
    </Flex>
  )
})

