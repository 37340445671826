import { Box, Icon, Text } from "@chakra-ui/react"
import { CreationContent, CreationHeader, CreationPreview } from "../Components"
import { creationStore, CreationType } from "@/store/creation"
import { FC, useEffect, useRef, useState } from "react"
import { ScrollBarBox } from "@/components/ScrollBox"
import dayjs from "dayjs"
import { observer } from "mobx-react-lite"
import { WiseImage } from "@/components/Image"
import { userStore } from "@/store/user"
import { CButton } from "@/components/Button"
import { useMessage } from "@/hooks/useMessage"
import { ChatRoleEnum } from "@/store/chat"
import { cloneDeep } from "lodash"
import { uiStrore } from "@/store/ui"
import { getOSSBlobResource, saveBlobToLocal } from "@/utils/common"
import { WisePage } from "@/components/Pagination"
import { shareDrawImg } from "@/api/creation"
import { useTranslation } from "react-i18next"

import styles from './index.module.scss'

interface ViewProps {
  prompts: string[]
  urls: string[]
  ids: string[]
  id: string
  time: Date
  open: boolean
}
export const CreationDallE3 = observer(() => {
  const {
    getHistory, history, getInfo, info, favorite, prompt, pageNum, pageSize, total, setPageNum,
    setPrompt, setInfo, chatCreation, delDalle3, postCollection, setFavorite
  } = creationStore[CreationType.E3];
  const { userInfo } = userStore;

  const controller = useRef<AbortController>();
  const message = useMessage();
  const { openAlert, closeAlert } = uiStrore;
  const [viewInfo, setViewInfo] = useState<ViewProps>();
  const [infoIndex, setInfoIndex] = useState(0);
  const listRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    getHistory();
    // eslint-disable-next-line
  }, [])

  const send = async () => {
    const value = prompt
    const val = prompt.trim().replace(/\n\s*/g, '\n');

    if (!val) {
      message.warning({ title: t('ContentEmpty') });
      return;
    }

    const ele = document.getElementById('inputArea') as HTMLDivElement;
    setPrompt('');
    ele.innerText = '';
    const abortSignal = new AbortController();
    controller.current = abortSignal;
    let _info = cloneDeep(info);
    const originalInfo = cloneDeep(info);

    const sendContent: DrawDallE3Type['content'] = [
      { obj: ChatRoleEnum.Human, userInputPrompt: val, createTime: new Date(), aiDraw: [] },
      { obj: ChatRoleEnum.AI, userInputPrompt: val, aiDraw: [], createTime: new Date(), status: 'loading', }
    ]
    if (!info?._id) {
      _info = { _id: '', userId: '', content: sendContent, updateTime: new Date() }
    } else {
      _info = { ...info, content: [...info.content, ...sendContent] }
    }
    setInfo(_info)

    setTimeout(() => {
      // 将容器滚动到底部
      scrollToBottom();
    }, 100)

    try {
      const res = await chatCreation(val, info?._id || '');
      _info?.content.splice(_info?.content.length - 1, 1, {
        obj: ChatRoleEnum.AI,
        userInputPrompt: val,
        aiDraw: res.result,
        createTime: new Date(),
        status: 'finished',
        _id: res.drawId
      })
      setInfo({ ..._info, _id: res._id, content: [..._info?.content], contentCheck: res.contentCheck });
      getHistory();
      setTimeout(() => {
        // 将容器滚动到底部
        scrollToBottom();
      }, 100)
      getInfo(res._id);
    } catch (err: any) {
      if (err.name === 'AbortError') {
        return;
      }
      if (err.message === t('OpSoon') || err.message === t('creation.DrawFaildBalance')) {
        message.error({ title: err.message });
      } else {
        message.error({ title: t('creation.SensitiveFaild') });
      }
      _info && setInfo(originalInfo);
      setPrompt(value);
      ele.innerText = value;
    }
  }

  const scrollToBottom = () => {
    const scrollEle = listRef.current?.querySelector('.ms-container') as HTMLDivElement;
    if (scrollEle) {
      scrollEle.scrollTo({ top: scrollEle.scrollHeight, behavior: 'smooth' });
    }
  }

  // 删除
  const onDel = async (id: string) => {
    openAlert({
      status: 'warning',
      title: t('deleteConfirm'),
      content: t('ConfirmDeleteRecord'),
      onOk: async () => {
        await delDalle3(id);
        setInfo(undefined);
        message.success({ title: t('deleteSuccess') });
        getHistory();
        closeAlert();
      }
    })
  }

  const download = async (url: string) => {
    if (!url) return;
    try {
      const res = await getOSSBlobResource(url);
      const suggestedName = url.substring(url.lastIndexOf('/') + 1)

      await saveBlobToLocal(res, `${suggestedName}.png`);
      message.success({ title: t('ImgDownloadSuccess') })
    } catch (err: any) {
      console.error(err);
    }
  }

  const collection = async (url: string) => {
    if (!info?._id) return;
    try {
      const res = await postCollection(info._id, url);
      setFavorite(res[info._id]);
      if (res[info._id]) {
        message.success({ title: t('collected') })
      } else {
        message.success({ title: t('collectCancel') })
      }
    } catch (err: any) {
      message.error({ title: t('collectFaild') })
    }
  }

  const onView = (index: number) => {
    if (!info) return;
    const urls: string[] = [];
    const ids: string[] = [];
    const prompts: string[] = [];
    let time = new Date();
    let activeIndex = -1;
    info.content.forEach((con, i) => {
      if (con.obj === 'AI') {
        index >= i && activeIndex++;
        urls.push(con.aiDraw[0].url)
        ids.push(con._id || '');
      } else if (con.obj === 'Human') {
        prompts.push(con.userInputPrompt)
      }
      time = con.createTime;
    })
    setInfoIndex(activeIndex);
    setViewInfo({ prompts, urls, id: info._id, time, open: true, ids });
  }

  const onShare = async () => {
    if (!viewInfo) return
    try {
      await shareDrawImg({ _id: viewInfo.ids[infoIndex] || '', url: viewInfo?.urls[infoIndex], type: CreationType.E3 });
      message.success({ title: t('ShareSuccess') });
    } catch (err: any) {
      message.error({ title: err.message })
    }
  }

  const paste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  }
  return <CreationContent.Content>
    <CreationContent.Inputs>
      <CreationHeader icon='draw_e3_000' text={t('wise') + 'E3'} rightNode={<Box className={styles.delle3Op}>
        <Icon as={require('@/assets/svg/creation_chat.svg').ReactComponent} onClick={() => {
          message.success({ title: t('NewChatSuccess') });
          setInfo()
        }} />
        {
          info?._id && <Icon as={require('@/assets/svg/delete_black.svg').ReactComponent} onClick={() => info?._id && onDel(info?._id)} />
        }
      </Box>} />
      <Box className={styles.list} ref={listRef}>
        <ScrollBarBox className={styles.container}>
          <Box className={styles.aiCreation}>
            <LogoTime url={require('@/assets/logo.png')} time={new Date()} />
            <Text className={styles.creationTips}>{t('creation.E3Welcome')}</Text>
          </Box>
          {
            info && <Box className={styles.info}>
              {
                info.content.map((item, index) => {
                  return <Box key={index} className={`${styles.item} ${item.obj === 'Human' ? styles.user : ''}`}>
                    {
                      item.obj === 'AI' ? <AICreation time={item.createTime}
                        url={(item.aiDraw[0]?.url || '') + '?x-oss-process=image/resize,w_280,h_280'}
                        status={item.status}
                        onDownload={() => download(item.aiDraw[0]?.url || '')}
                        onFavorite={() => collection(item.aiDraw[0]?.url || '')}
                        isFavorite={favorite?.some(f => f === item.aiDraw[0]?.url)}
                        onPreview={() => onView(index)}
                        isRisk={item.isRisk || item.aiDraw[0]?.status === 'risk'}
                        riskLabels={info.contentCheck?.labels || ''}
                      />
                        : <>
                          <LogoTime isAI={false} url={userInfo?.avatar || ''} time={item.createTime} />
                          {
                            item.isRisk ? <Text className={styles.risk}>
                              <Icon as={require('@/assets/svg/risk_white.svg').ReactComponent} />
                              {t('creation.TextMayContain')} {info.contentCheck?.labels?.split(',').map(str => t('riskContent.' + str)).join(',')}{t('comma')} {t('creation')}{t('comma')} {t('creation.NoConsume')}{t('period')}
                            </Text>
                              : <Text className={styles.input}>
                                {item.userInputPrompt}
                              </Text>
                          }
                        </>
                    }
                  </Box>
                })
              }
            </Box>
          }
        </ScrollBarBox>
        <Box className={styles.inputArea}>
          <Box className={styles.areaBox}>
            <ScrollBarBox>
              <Box className={styles.area} id="inputArea" contentEditable content={t('creation.EnterUWantDraw')} onPaste={paste} onInput={e => {
                setPrompt(e.currentTarget.innerText || '')
              }} ></Box>
            </ScrollBarBox>
            {
              info?.isRisk ? <Box className={styles.riskDisable}>
                <Text>{t('IllegalNewChat')}</Text>
              </Box> : null
            }
          </Box>
          <CButton theme='primary' className={`${styles.submit} ${userInfo?.monVip ? styles.vip : ''}`}
            disabled={!prompt} onClick={send}
          >
            {
              userInfo?.monVip ? <>
                <Icon as={require('@/assets/svg/navisVip.svg').ReactComponent} />
                <Text>{t('creation.FreeDrawing')}</Text>
              </> : <>
                <Text>{t('Send')} 50</Text>
                <Icon as={require('@/assets/svg/personalBalanced.svg').ReactComponent} />
              </>
            }
          </CButton>
        </Box>
      </Box>
    </CreationContent.Inputs>
    <CreationContent.History>
      <Box className={styles.historyTitle}>
        <Text className={styles.text}>{t('history')}</Text>
      </Box>
      <Box className={styles.historyContent}>
        <ScrollBarBox className={styles.historyBox}>
          {history.map((item) => {
            return <Box key={item._id} className={styles.historyItem} onClick={async () => {
              await getInfo(item._id);
              scrollToBottom();
            }}>
              {
                item.isRisk ? <Box className={styles.risk}>
                  <Icon as={require('@/assets/svg/risk_red.svg').ReactComponent} />
                  <Text>{t('creation.ContentMayContain')}{item.contentCheck?.labels?.split(',').map(str => t('riskContent.' + str)).join(',')}{t('creation.TemporarilyUnavailable')}</Text>
                </Box>
                  : <Text className={styles.prompt}>{item.lastInputPrompt}</Text>
              }
              <Text className={styles.time}>{dayjs(item.updateTime).format('YYYY.MM.DD HH:mm')}</Text>
              <Text className={styles.count}>{t('creation.ContainsImages')}：{item.imgCount || 0}{t('Piece')}</Text>
              <Icon
                className={styles.del}
                as={require('@/assets/svg/delete_black.svg').ReactComponent}
                onClick={e => { e.stopPropagation(); onDel(item._id) }}
              />
            </Box>
          })}
          {
            total > pageSize && <WisePage total={total} pageNum={pageNum} pageSize={pageSize} onPageChange={setPageNum} />
          }
        </ScrollBarBox>
      </Box>
    </CreationContent.History>
    <CreationPreview
      imgList={viewInfo?.urls || []}
      imgIndex={infoIndex || 0}
      isOpen={!!viewInfo?.open}
      onClose={() => setViewInfo(undefined)}
      userAvatar={userInfo?.avatar || ''}
      userName={userInfo?.nickName || userInfo?.wiseNickName || ''}
      drayInfo={{
        id: viewInfo?.id || '',
        type: '',
        avatar: '',
      }}
      isFavorite={favorite?.some(f => f === viewInfo?.urls[infoIndex]) || false}
      prompt={viewInfo?.prompts || ""}
      negativePrompt={''}
      onDownload={download}
      createTime={viewInfo?.time || new Date()}
      onChangeIndex={setInfoIndex}
      onFavorite={() => collection(viewInfo?.urls[infoIndex || 0] || '')}
      onSameDraw={() => {
        setInfo();
        viewInfo && setPrompt(viewInfo?.prompts.join('\n') || '');
        const ele = document.getElementById('inputArea') as HTMLDivElement;
        ele.innerText = viewInfo?.prompts.join('\n') || '';
        setViewInfo(undefined);
      }}
      onShare={onShare}
      type={CreationType.E3}
    />
  </CreationContent.Content>
})

interface AICreationProps {
  time: Date
  url: string
  status?: "loading" | "finished"
  isFavorite?: boolean
  onFavorite?: () => void
  onDownload?: () => void
  onPreview?: () => void
  isRisk?: boolean
  riskLabels?: string
}
const AICreation: FC<AICreationProps> = (props) => {
  const { time, url, status, onDownload, onPreview, isRisk, riskLabels } = props; // isFavorite, onFavorite,
  const { t } = useTranslation();

  return <Box className={styles.aiCreation}>
    <LogoTime url={require('@/assets/logo.png')} time={time} />
    {
      status === 'loading' ? <Box className={styles.loading}>
        <Icon as={require('@/assets/svg/creation_loading.svg').ReactComponent} />
        <Text className={styles.loadingText}>{t('creation.imageGenerating')}…</Text>
      </Box>
        : <>
          {
            isRisk ? <Box className={styles.risk}>
              <Icon as={require('@/assets/svg/risk_white.svg').ReactComponent} />
              <Box className={styles.riskText}>
                {t('creation.imgMayContain')} {riskLabels?.split(',').map(str => t('riskContent.' + str)).join(',')}{t('creation.TemporarilyUnavailable')}
              </Box>
            </Box> :
              <Box className={styles.creationImg} onClick={onPreview}>
                <WiseImage src={url} className={styles.img} />
                {/* <Box className={`${styles.favorite} ${isFavorite ? styles.active : ''}`}
                onClick={e => { e.stopPropagation(); onFavorite && onFavorite() }}
              >
                <Icon as={require('@/assets/svg/heart_red.svg').ReactComponent} />
              </Box> */}
                <Box className={styles.download} onClick={e => { e.stopPropagation(); onDownload && onDownload() }}>
                  <Icon as={require('@/assets/svg/download_fill.svg').ReactComponent} />
                </Box>
              </Box>
          }
        </>
    }
  </Box>
}

interface LogoTimeProps {
  url: string
  time: Date
  isAI?: boolean
}
const LogoTime: FC<LogoTimeProps> = (props) => {
  const { url, time, isAI = true } = props;
  return <Box className={styles.logoTime}>
    {
      isAI && <Box className={styles.logo} order={isAI ? 0 : 1}>
        <WiseImage src={url} />
      </Box>
    }
    <Text className={`${styles.time} ${!isAI ? styles.human : ''}`}>{dayjs(time || new Date()).format('MM-DD HH:mm')}</Text>
  </Box>
}