import { Box, Flex } from '@chakra-ui/react';
import React, { Component, useEffect, useRef, useState } from 'react';
import { deriveOptions, Markmap } from 'markmap-view';
import { Transformer } from 'markmap-lib';
import { loadJS } from 'markmap-common';
import { icon_close, icon_fit, icon_zoomin, icon_zoomout } from '../icon';
import styles from '../../MarkmapClass/index.module.scss'

const clsToolbarItem = 'mm-toolbar-item';

const MMPreview = (props: { svgData: string; closeMe: () => void }) => {
  const bindSvg = useRef<SVGSVGElement>(null);
  const bindToolbar = useRef<HTMLDivElement>(null);

  let mm: Markmap;
  let closeMe: Function;
  let enabled: Record<string, boolean> = {};

  // this.setData(props.svgData);
  closeMe = props.closeMe;

  useEffect(() => {
    if (!bindSvg.current) return;

    mm = Markmap.create(bindSvg.current, {
      // pan: false
    });

    let text = props.svgData ? props.svgData.trim() : '';
    updateSvg(text);
  });

  const updateSvg = (text: string) => {
    const transformer = new Transformer();
    const result = transformer.transform(text);

    const keys = Object.keys(result.features).filter((key) => !enabled[key]);
    keys.forEach((key) => {
      enabled[key] = true;
    });
    const { styles, scripts } = transformer.getAssets(keys);
    if (scripts) loadJS(scripts);

    const markmapOptions = result.frontmatter?.markmap;
    const frontmatterOptions = deriveOptions(markmapOptions);

    mm.setData(result.root, frontmatterOptions);
    mm.fit();
  };

  const onClick_toolbar_zoomIn = () => {
    mm.rescale(1.25);
  };

  const onClick_toolbar_zoomOut = () => {
    mm.rescale(0.8);
  };

  const onClick_toolbar_fit = () => {
    mm.fit();
  };

  const onClick_toolbar_close = () => {
    if (closeMe) {
      closeMe();
    }
  };

  return (
    <Flex h={'100%'} position={'relative'} overflow={'hidden'} bg={'white'}>
      {/* eslint-disable-next-line react/jsx-no-undef */}
      <Box flex={1} h={'100%'} position={'relative'}>
        <svg className={styles.markmapclass} ref={bindSvg} />
        <div className={styles.markmapclasstoolbar_preview} ref={bindToolbar}>
          <div
            className={clsToolbarItem}
            title="Zoom in"
            onClick={onClick_toolbar_zoomIn.bind(this)}
          >
            {icon_zoomin(26)}
          </div>
          <div
            className={clsToolbarItem}
            title="Zoom out"
            onClick={onClick_toolbar_zoomOut.bind(this)}
          >
            {icon_zoomout(26)}
          </div>
          <div
            className={clsToolbarItem}
            title="Fit window size"
            onClick={onClick_toolbar_fit.bind(this)}
          >
            {icon_fit(26)}
          </div>
          <div
            className={clsToolbarItem}
            title="Close window"
            onClick={onClick_toolbar_close.bind(this)}
          >
            {icon_close(26)}
          </div>
        </div>
      </Box>
    </Flex>
  );
};

export default MMPreview;
