import { Box, Flex, Icon, Text, Textarea, Tooltip } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss"
import { CModal } from "@/components/Modal";
import { CButton } from "@/components/Button";
import { RobotIcon } from "../../WechatRobot";

export const ComBgTextarea: FC<any> = observer(({
  title,
  prompt,
  textvalue,
  textchange,
  maxCount,
  rows,
  required,
  systemPromptTip,
  isdatecl,
  istimecl
}: {
  title: string;
  prompt: string;
  textvalue: string;
  textchange: (e: string) => void;
  maxCount: number;
  rows: number;
  required?: boolean;
  systemPromptTip?: string;
  isdatecl?: boolean
  istimecl?: boolean
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [dateopen, setDateOpen] = useState<boolean>(false);
  const [timeopen, setTimeOpen] = useState<boolean>(false);
  const handleBlur = () => {
    setIsFocused(false)
    if (required === true && textvalue.trim() === '') {
      setErrorMessage('请输入内容');
    } else {
      setErrorMessage('');
    }
  };
  let parts = [''];
  if (required === true) {
    if (!title.includes('*')) {
      title = title + '*';
      parts = title.split('*');
    } else {
      parts = title.split('*');
    }
  } else {
    parts = title.split('*');
  }

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // 月份从0开始
  const currentDate = new Date().getDate();
  const currentHour = new Date().getHours();
  const currentMinute = new Date().getMinutes();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [selectedHour, setSelectedHour] = useState(currentHour);
  const [selectedMinute, setSelectedMinute] = useState(currentMinute);

  const years = Array.from({ length: 31 }, (_, index) => currentYear - 25 + index);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const dates = Array.from({ length: 31 }, (_, i) => i + 1);
  const hours = Array.from({ length: 24 }, (_, i) => i);
  const minutes = Array.from({ length: 60 }, (_, i) => i);

  const confirmSelection = () => {
    if (isdatecl) {
      textchange(`${selectedYear}-${selectedMonth}-${selectedDate}`)
    } else if (istimecl) {
      textchange(`${selectedHour}时 - ${selectedMinute}分`)
    }

    setDateOpen(false);
    setTimeOpen(false);
  };

  const renderOptions = (label: string, options: any, selected: any, setSelected: any) => {
    return (
      <div className={styles.selector}>
        {options.map((option: any, index: any) => {
          const displayValue = label === '年' ? option : String(option).padStart(2, '0');
          return (
            <div
              key={index}
              className={`${styles.option} ${displayValue === selected ? styles.selected : ''}`}
              onClick={() => setSelected(displayValue)}
            >
              {displayValue}{label}
            </div>
          )
        })}
      </div>
    );
  };

  return (
    <Flex className={styles.allbox}>
      <Box className={styles.titlebox}>
        {parts.map((part: any, index: any) => (
          <Text className={styles.title} key={index}>
            {part}{index < parts.length - 1 ? <span className={styles.titletips}>*</span> : null}
          </Text>
        ))}
        {systemPromptTip && (
          <Tooltip className={styles.titletipsbox} >
            <Icon color={'#fff'} onClick={() => setOpen(true)} />
          </Tooltip>
        )}
      </Box>
      <Box pb={'0px'} className={`${styles.textareabox} ${isFocused ? styles.focused : errorMessage ? styles.error : ''}`}>
        <Textarea
          className={styles.Onetextarea}
          rows={rows}
          placeholder={prompt}
          value={textvalue}
          onChange={(e) => textchange(e.target.value)}
          onBlur={handleBlur}
          onFocus={() => setIsFocused(true)}
          maxLength={maxCount}
          readOnly={isdatecl || istimecl}
          onClick={() => {
            if (isdatecl) {
              setDateOpen(true)
            }
            if (istimecl) {
              setTimeOpen(true)
            }
          }}
        />
        {maxCount > 100 &&
          <Text className={styles.maxText}>
            {textvalue ? textvalue.length : 0}{maxCount < 9999 && `/${maxCount}`}
          </Text>
        }
        {!isFocused && errorMessage && (
          <Flex className={maxCount > 100 ? styles.tipstext : styles.tipsOnetext}>
            <Icon as={require('@/assets/svg/errorIcon.svg').ReactComponent} />
            <p>{errorMessage}</p>
          </Flex>
        )}
      </Box>
      <CModal isOpen={open} onClose={() => setOpen(false)}>
        <Flex className={styles.notbalancebox}>
          <Flex className={styles.notbalanceTitleBox}>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <RobotIcon />
              <Text>{parts[0]}</Text>
            </Flex>
            <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setOpen(false)} />
          </Flex>
          <Text className={styles.texttips}>{systemPromptTip}</Text>
          <Flex className={styles.modelbuttonbox}>
            <CButton className={styles.Savebutton} onClick={() => setOpen(false)}>
              确定
            </CButton>
          </Flex>
        </Flex>
      </CModal>
      <CModal isOpen={dateopen} onClose={() => setDateOpen(false)}>
        <Flex className={styles.cmodelbox}>
          <Flex className={styles.cmodelTitleBox}>
            <Text>日期选择</Text>
            <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setDateOpen(false)} />
          </Flex>
          <Flex>
            <div className={styles.date_picker}>
              {renderOptions('年', years, selectedYear, setSelectedYear)}
              {renderOptions('月', months, selectedMonth, setSelectedMonth)}
              {renderOptions('日', dates, selectedDate, setSelectedDate)}
            </div>
          </Flex>
          <Flex className={styles.modelbuttonbox}>
            <CButton className={styles.Cancelbutton} onClick={() => setDateOpen(false)} >
              取消
            </CButton>
            <CButton className={styles.Savebutton} onClick={confirmSelection}>
              确认
            </CButton>
          </Flex>
        </Flex>
      </CModal>
      <CModal isOpen={timeopen} onClose={() => setTimeOpen(false)}>
        <Flex className={styles.cmodelbox}>
          <Flex className={styles.cmodelTitleBox}>
            <Text>时间选择</Text>
            <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setTimeOpen(false)} />
          </Flex>
          <Flex>
            <div className={styles.date_picker}>
              {renderOptions('时', hours, selectedHour, setSelectedHour)}
              {renderOptions('分', minutes, selectedMinute, setSelectedMinute)}
            </div>
          </Flex>
          <Flex className={styles.modelbuttonbox}>
            <CButton className={styles.Cancelbutton} onClick={() => setTimeOpen(false)} >
              取消
            </CButton>
            <CButton className={styles.Savebutton} onClick={confirmSelection}>
              确认
            </CButton>
          </Flex>
        </Flex>
      </CModal>
    </Flex>
  );
})