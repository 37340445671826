import { Box, Flex, Text, Icon, Image } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useState } from "react";
import styles from './index.module.scss';
import { CButton } from "@/components/Button";
import { useTranslation } from "react-i18next";
import { CModal } from "@/components/Modal";
import { userStore } from "@/store/user";
import { observer } from "mobx-react-lite";
import { useMessage } from "@/hooks/useMessage";
import { fetchTokenLogin, getJsPayCode, getPayCode } from "@/api/user";
import { isWechatOpen } from "@/utils/tools";
import { useQuery } from "@tanstack/react-query";
import { personalStore } from "@/store/personal";
import { CZFWXY } from "@/pages/login/LoginType";
// import { useRouter } from 'next/router';

export const BalanceTop_up: FC = observer(() => {
  const { t } = useTranslation();
  const { userInfo } = userStore;
  const { handleSuccess } = personalStore;
  const message = useMessage();
  const tools = [
    {
      icon: 'personalChat',
      title: t('tools.title1'),
      content: t('tools.content1')
    },
    {
      icon: 'personalDraw',
      title: t('tools.title2'),
      content: t('tools.content2')
    },
    {
      icon: 'personalTool',
      title: t('tools.title3'),
      content: t('tools.content3')
    },
    {
      icon: 'personalApp',
      title: t('tools.title4'),
      content: t('tools.content4')
    }
  ]
  const lines = [18, 38, 68, 128, 198, 648]
  const [open, setopen] = useState<boolean>(false)
  const [openRecharge, setOpenRecharge] = useState<boolean>(false)
  const newBalance = (userInfo!.balance * 100).toFixed(2)
  const [selectedIndex, setSelectedIndex] = useState(lines[0]);
  // const [selectPoint , SetSelectPoint] = useState()
  const [isChecked, setIsChecked] = useState(true);
  const [czfwxyOpen, setCzfwxyOpen] = useState(false); // 用户协议
  const [loading, setLoading] = useState(false);
  const handleCheckboxChange = (event: any) => {
    setIsChecked(event);
    // setIsChecked(event.target.checked);
  };
  const recharge = () => {
    if (!isChecked) {
      message.warning({ title: '请先阅读《文思逸言充值服务协议》！' })
      return
    }
    setEwmLoading(true)
    setOpenRecharge(true);
    handleClickPay()
  }
  useEffect(() => {
    if (openRecharge) {
      setPayId('');
      let pay = document.getElementById('payQRCode');
      if (pay) {
        pay.innerHTML = ''
      }
      if (!loading) {
        recharge()
      }
    }
  }, [selectedIndex])
  const [payId, setPayId] = useState('');
  const [inputOrderType, setInputOrderType] = useState('Balance');
  const [showQrCode, setShowQrCode] = useState(false);
  const [ewmloading, setEwmLoading] = useState(false);
  const isWechat = isWechatOpen();
  const { checkPayResult } = personalStore;
  // const router = useRouter();
  const isWap = /mobile/i.test(navigator.userAgent)
    || /android/i.test(navigator.userAgent)
    || /iphone/i.test(navigator.userAgent)
    || /ipad/i.test(navigator.userAgent);

  const handleClickPay = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (payId != '') {
      setPayId('')
    }

    if (!isChecked) {
      message.warning({ title: '请先勾选充值协议！' });
      setLoading(false);
      return;
    }

    try {
      // webclip也使用js支付
      // @ts-ignore
      const res = await getPayCode(selectedIndex, inputOrderType);
      new QRCode(document.getElementById('payQRCode')!, {
        text: res.codeUrl,
        width: 128,
        height: 128,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      });
      setPayId(res.payId);
    } catch (err: any) {
      console.log(err);
      message.error({ title: err?.message || '生成错误' });
    }
    setLoading(false);
    setEwmLoading(false)
  }, [selectedIndex, payId, isWap, inputOrderType]);
  useQuery(
    [payId, setLoading],
    () => {
      if (!payId) return null;
      return checkPayResult(payId);
    },
    {
      enabled: !!payId,
      refetchInterval: 3000,
      onSuccess(res) {
        if (!res) return;
        if (res.toString() === '订单已结算') {
          setLoading(false);
          message.success({ title: '充值成功' });
          handleSuccess();
          setopen(false);
          setOpenRecharge(false);
          setPayId('')
        }
      }
    }
  );
  return (
    <Box className={styles.parent}>
      <Flex className={styles.child}>
        <Flex className={styles.fatherbox}>
          <Text className={styles.boxtext}>{t('Balance_top_up')}</Text>
          <Flex className={styles.contentbox}>
            <Text className={styles.contenttext}>{t('Account_balance')}（{t('Points')}）</Text>
            <Flex className={styles.contentbalance}>
              <Flex className={styles.textAndicon}>
                <Text className={styles.balancetext}>{newBalance}</Text>
                <Icon className={styles.balanceicon} as={require(`@/assets/svg/personalBalance.svg`).ReactComponent} />
              </Flex>
              <CButton className={styles.balancebutton} onClick={() => { setopen(true) }}>{t('recharge')}</CButton>
            </Flex>
            <Text className={styles.contenttips}>{t('Introduction_to_points')}</Text>
          </Flex>
        </Flex>
        <Flex className={styles.fatherbox}>
          <Text className={styles.boxtext}>{t('benefits')}</Text>
          <Flex className={styles.listbox}>
            {tools.map((item, index) => {
              return (
                <Flex className={styles.listcontent} key={index}>
                  <Box className={styles.listcontentbox}>
                    <Icon className={styles.listcontenticon} as={require(`@/assets/svg/${item.icon}.svg`).ReactComponent} />
                  </Box>
                  <Text className={styles.listcontenttitle}>
                    {item.title}
                  </Text>
                  <Text className={styles.listcontentvalue}>{item.content}</Text>
                </Flex>
              )
            })}
          </Flex>
        </Flex>
      </Flex>
      <CModal isOpen={open} onClose={() => { setopen(false); setOpenRecharge(false) }}>
        <Flex className={styles.cmodelbox}>
          <Flex className={styles.cmodel_closebox}>
            <Text>余额充值</Text>
            <Icon className={styles.cmodel_close} onClick={() => { setopen(false); setOpenRecharge(false) }} as={require(`@/assets/svg/cModelClose.svg`).ReactComponent} />
          </Flex>
          <Flex className={styles.cmodel_group}>
            {lines.map((item, index) => (
              <div
                key={index}
                className={`${styles.cmodel_box} ${selectedIndex === item ? styles.selected : ''}`}
                onClick={() => { setSelectedIndex(item) }}>
                <Box className={styles.cmodeliconbox}>
                  {/* <Icon className={styles.cmodel_icon} as={require(`@/assets/svg/${selectedIndex === item ? 'personalBalanced' : 'personalBalance'}.svg`).ReactComponent} /> */}
                  <Icon className={styles.cmodel_icon} as={require(`@/assets/svg/personalBalance.svg`).ReactComponent} />
                  <Text className={styles.cmodel_point}
                  >{item}00</Text>
                </Box>
                <Text className={styles.cmodel_prices}>{item}元</Text>
                {selectedIndex === item && (
                  <Icon
                    className={styles.cmodel_checkmark_icon}
                    as={require('@/assets/svg/confirm.svg').ReactComponent}
                  />
                )}
                {selectedIndex === item &&
                  <div className={styles.corner}>
                    <svg width="100%" height="100%">
                      <path d="M 15 80 Q 55 55, 80 15 L 80 80 Z" fill="#4B6EFF" />
                    </svg>
                  </div>
                }
              </div>
            ))}
          </Flex>
          {!openRecharge ?
            <Flex className={styles.modelbuttonbox}>
              <CButton className={styles.Savebutton} onClick={recharge}>
                <Icon as={require('@/assets/svg/personalWechat.svg').ReactComponent} />
                获取微信支付二维码
              </CButton>
              <Box className={styles.checkbox_custom}>
                <Box onClick={() => handleCheckboxChange(!isChecked)} className={styles.checkbox_container}>
                  <Box className={`${styles.checkbox_customs} ${isChecked ? styles.checked : ''}`}>
                    <Icon as={require('@/assets/svg/confirm.svg').ReactComponent} />
                  </Box>
                </Box>
                <Text>我已阅读并同意</Text>
                <Text className={styles.agreement_text} onClick={() => { setCzfwxyOpen(true); setopen(false) }}>
                  《文思逸言充值服务协议》
                </Text>
              </Box>
            </Flex>
            :
            <Flex alignItems={'center'} pos={'relative'}>
              {ewmloading && (
                <Icon className={styles.loadicon} as={require('@/assets/svg/creation_loading.svg').ReactComponent} />
              )}
              <Box id="payQRCode" className={styles.payimage} display="inline-block"></Box>
              <Box>
                <Text className={styles.titletext}>{selectedIndex}.00<span>元</span></Text>
                <Box className={styles.payimgbox}>
                  <Icon as={require('@/assets/svg/wx_circle.svg').ReactComponent} />
                  <Text>请使用微信扫码支付</Text>
                </Box>
              </Box>
            </Flex>
          }
        </Flex>
      </CModal>
      <CZFWXY isOpen={czfwxyOpen} onClose={() => { setCzfwxyOpen(false); setopen(true) }} />
    </Box>
  )
})