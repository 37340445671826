import { chatStore } from "@/store/chat";
import { Box, Icon, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollBarBox } from "@/components/ScrollBox";
import { WiseImage } from '@/components/Image'
import { CModal } from "@/components/Modal";
import { useNavigate } from "react-router";

import styles from './index.module.scss'
import { agent_avatars } from "@/pages/agents/components/Nav";

type AgentType = 'hot' | 'mine'
const Agent: FC = (props: any) => {

  const { getAgents, agents, mineAgents, setCurrentChat, setChatInfo, agentLine } = chatStore;
  const { t } = useTranslation();
  const [type, setType] = useState<AgentType>('hot');
  const [allFilter, setAllFilter] = useState<string[]>(['agent.all', 'agent.hot', 'agent.mine']); // 所有筛选条件, 
  const [filter, setFilter] = useState<string>('agent.all');
  const [open, setOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (agents.length) {
      const clone = [...allFilter]
      agents.forEach(agent => {
        if (clone.indexOf(agent.chat.appType) === -1 && agent.chat.appType) {
          clone.push(agent.chat.appType)
        }
      })
      setAllFilter(clone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agents])

  useEffect(() => {
    getAgents()
  }, [getAgents])

  const changeCurrentChat = (modelId: string, avatar: string, intro: string, name: string) => {
    setCurrentChat({
      isPlugin: false,
      modelId,
      chatId: ''
    })
    setChatInfo({
      info: {
        modelId,
        chatId: '',
        history: [],
        model: {
          avatar,
          intro,
          name,
          canUse: false
        }
      }
    })
  }

  let chooseFilterLenth = 0;
  let modalAgents: AgentsType[] = []
  if (filter === 'agent.all') {
    chooseFilterLenth = mineAgents.myModels.length + agents.length;
    modalAgents = agents;
  } else if (filter === 'agent.mine') {
    chooseFilterLenth = mineAgents.myModels.length;
  } else if (filter === 'agent.hot') {
    modalAgents = agents.slice(0, agents.length >= (agentLine * 4) ? (agentLine * 4) : agents.length);
    chooseFilterLenth = modalAgents.length;
  } else {
    modalAgents = agents.filter(item => item.chat.appType === filter);
    chooseFilterLenth = modalAgents.length
  }

  // style空位补余
  const fix = chooseFilterLenth >= 4 ? (chooseFilterLenth + 1) % 4 : 4 % (chooseFilterLenth + 1);
  const mineFix = mineAgents.myModels.length >= (agentLine * 4) ? 0 : (mineAgents.myModels.length) % 4;
  return <Box className={styles.agents} id="agents" ref={containerRef}>
    <Box className={styles.header}>
      <Box className={styles.title}>
        <Box className={`${styles.text} ${type === 'hot' ? styles.active : ''}`} onClick={() => setType('hot')}>
          <Text>{t('agent.hot')}</Text>
          <Icon className={styles.icon} as={require('@/assets/svg/chat_header_active.svg').ReactComponent} />
        </Box>
        <Box className={`${styles.text}  ${type === 'mine' ? styles.active : ''}`} onClick={() => setType('mine')}>
          <Text> {t('agent.mine')}</Text>
          <Icon className={styles.icon} as={require('@/assets/svg/chat_header_active.svg').ReactComponent} />
        </Box>
      </Box>
      <Box className={styles.seeAll} onClick={() => {
        if (type === 'mine') {
          setFilter('agent.mine')
        } else if (type === 'hot') {
          setFilter('agent.hot')
        } else {
          setFilter('agent.all')
        }
        setOpen(true)
      }}>
        <Text>{t('agent.all')}</Text>
        <Icon className={styles.icon} as={require('@/assets/svg/arrow_r.svg').ReactComponent} />
      </Box>
    </Box>
    <Box className={`${styles.agentsList} ${type !== 'hot' ? styles.hidden : ''}`}>
      {agents.slice(0, agents.length >= (agentLine * 4) ? (agentLine * 4) : agents.length).map(item => {
        return <Box key={item._id} className={styles.item} onClick={() => {
          changeCurrentChat(item._id, item.avatar, item.intro, item.name)
        }}>
          <Box className={styles.avatar}>
            <WiseImage src={item.avatar} alt="" />
          </Box>
          <Text className={styles.name}>{item.name}</Text>
        </Box>
      })}
    </Box>
    <ScrollBarBox className={`${styles.agentsList} ${type !== 'mine' ? styles.hidden : ''}`}>
      {
        mineAgents.myModels.slice(0, mineAgents.myModels.length >= (agentLine * 4) ? (agentLine * 4) : mineAgents.myModels.length).map(item => {
          let cls = '';
          const avatar = item.avatar;
          Object.keys(agent_avatars).forEach((key) => {
            if (agent_avatars[key as keyof typeof agent_avatars] === avatar) {
              cls = styles[key];
            }
          });
          return <Box key={item._id} className={styles.item} onClick={() => {
            changeCurrentChat(item._id, item.avatar, item.intro, item.name)
          }}>
            <Box className={`${styles.avatar} ${cls}`}>
              <WiseImage src={item.avatar} alt="" />
            </Box>
            <Text className={styles.name}>{item.name}</Text>
          </Box>
        })
      }
      {
        /** 空位补余 */
        mineFix ? new Array(4 - (mineFix || 0)).fill(0).map((_, index) => {
          return <Box key={index} className={styles.item} visibility='hidden'></Box>
        }) : null
      }
      {
        mineAgents.myModels.length === 0 && <Box className={styles.noData}>
          <Icon className={styles.nodataIcon} as={require('@/assets/svg/mine_agents_nodata.svg').ReactComponent} />
          <Box className={styles.add} onClick={() => navigate('/agents')}>
            <Icon className={styles.addIcon} as={require('@/assets/svg/add_circle.svg').ReactComponent} />
            {t('agent.new_mine')}
          </Box>
        </Box>
      }
    </ScrollBarBox>
    <CModal isOpen={open} onClose={() => setOpen(false)}>
      <Box className={styles.allAgents}>
        <Box className={styles.title}>
          <Text className={styles.text}>{t('agent.choose')}</Text>
          <Icon onClick={() => setOpen(false)} className={styles.icon} as={require('@/assets/svg/close_x.svg').ReactComponent} />
        </Box>
        <Box className={styles.nav}>
          {
            allFilter.map(item => {
              return <Text key={item}
                className={`${styles.navItem} ${filter === item ? styles.active : ''}`}
                onClick={() => setFilter(item)}
              >
                {t(item)}
              </Text>
            })
          }
        </Box>
        <ScrollBarBox className={styles.content}>
          <Box className={`${styles.add} ${styles.item}`} onClick={() => navigate('/agents')}>
            <Icon className={styles.addIcon} as={require('@/assets/svg/add_circle.svg').ReactComponent} />
            {t('agent.new_mine')}
          </Box>
          {
            (filter === 'agent.all' || filter === 'agent.mine') && mineAgents.myModels.map(item => {
              let cls = '';
              const avatar = item.avatar;
              Object.keys(agent_avatars).forEach((key) => {
                if (agent_avatars[key as keyof typeof agent_avatars] === avatar) {
                  cls = styles[key];
                }
              });
              return <Box key={item._id} className={styles.item} onClick={() => {
                changeCurrentChat(item._id, item.avatar, item.intro, item.name)
              }}>
                <Box className={`${styles.avatar} ${cls}`}>
                  <WiseImage src={item.avatar} alt="" />
                </Box>
                <Text>{item.name}</Text>
              </Box>
            })
          }
          {
            modalAgents.map(item => {
              let cls = '';
              const avatar = item.avatar;
              Object.keys(agent_avatars).forEach((key) => {
                if (agent_avatars[key as keyof typeof agent_avatars] === avatar) {
                  cls = styles[key];
                }
              });
              return <Box key={item._id} className={styles.item} onClick={() =>
                changeCurrentChat(item._id, item.avatar, item.intro, item.name)
              }>
                <Box className={`${styles.avatar} ${cls}`}>
                  <WiseImage src={item.avatar} alt="" />
                </Box>
                <Text>{item.name}</Text>
              </Box>
            })
          }
          {
            /** 空位补余 */
            chooseFilterLenth && new Array(4 - (fix || 0)).fill(0).map((_, index) => {
              return <Box key={index} className={styles.item} visibility='hidden'></Box>
            })
          }
        </ScrollBarBox>
      </Box>
    </CModal >
  </Box >
}

export default observer(Agent);