import React, { FC } from 'react'
import { BlackItem } from './BlackItem'
import { useTranslation } from '../../../common'
import { Spin, Empty } from 'antd'
import { ScrollBarBox } from '@/components/ScrollBox'

export interface BlackListProps {
  list: string[]
  loading?: boolean
  onItemClick?: (account: string) => void
  afterSendMsgClick?: () => void
  renderBlackListHeader?: () => JSX.Element
  renderBlackListEmpty?: () => JSX.Element
  prefix?: string
  commonPrefix?: string
}

export const BlackList: FC<BlackListProps> = ({
  list,
  loading = false,
  onItemClick,
  afterSendMsgClick,
  renderBlackListEmpty,
  renderBlackListHeader,
  prefix = 'contact',
  commonPrefix = 'common',
}) => {
  const _prefix = `${prefix}-black`

  const { t } = useTranslation()

  return (
    <div className={`${_prefix}-wrapper`}>
      <div className={`${_prefix}-title`}>
        {renderBlackListHeader ? (
          renderBlackListHeader()
        ) : (
          <>
            {t('blackListTitle')}
            <span className={`${_prefix}-title-remark`}>
              {t('blackListDesc')}
            </span>
          </>
        )}
      </div>
      <div className={`${_prefix}-content`}>
        <ScrollBarBox>
          {loading ? (
            <Spin />
          ) : !list.length ? (
            renderBlackListEmpty ? (
              renderBlackListEmpty()
            ) : (
              <Empty image={require('@/assets/png/no_chat.png')} description={t('noBlacklistYet')} />
            )
          ) : (
            list.map((item) => (
              <BlackItem
                account={item}
                key={item}
                prefix={prefix}
                commonPrefix={commonPrefix}
                onItemClick={onItemClick}
                afterSendMsgClick={afterSendMsgClick}
              />
            ))
          )}
        </ScrollBarBox>
      </div>
    </div>
  )
}
