import zh from './zh'
import en from './en'

export enum Lang {
  zh = 'zh',
  en = 'en'
}

export type LangValues = `${Lang}`

export const resources = {
  [Lang.zh]: {
    translation: zh
  },
  [Lang.en]: {
    translation: en
  }
}
