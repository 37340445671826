import { CButton } from "@/components/Button";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useRef, useState } from "react";
import styles from "./index.module.scss"
import { _Models } from "../..";
import { userStore } from "@/store/user";

export const ComButton: FC<any> = observer(({
  loading,
  casebut,
  startbut,
  stopbut,
  startClick,
  stopClick,
  setLoading,
  caseButtonClick,
  caseStop,
  redius,
  chooseModel,
  setChooseModel,
  modelkey,
  setModelsKey,
  isnull,
  color,
  _Models
}: {
  loading: any;
  casebut?: string;
  startbut?: string;
  stopbut?: string
  startClick: () => void;
  stopClick: () => void;
  setLoading: (value: boolean) => void;
  caseButtonClick?: () => void;
  caseStop: boolean;
  isnull: boolean;
  redius?: string;
  chooseModel?: boolean;
  setChooseModel?: (value: boolean) => void;
  modelkey?: any;
  setModelsKey?: (vlaue: any) => void;
  color?: boolean;
  _Models: any[]
}) => {
  const [hover, setHover] = useState(false);
  const { userInfo } = userStore;
  const modelMenuRef = useRef<HTMLDivElement>(null)
  // useOutsideClick({
  //   ref: modelMenuRef,
  //   handler: e => {
  //     if (setChooseModel) {
  //       setChooseModel(false);
  //     }
  //   }
  // })
  return (
    <Flex className={styles.allbox}>
      {(modelkey && setChooseModel && setModelsKey) && (
        <Box className={styles.modalicon}
          onClick={e => setChooseModel(!chooseModel)}
        >
          <Flex background={color ? 'transparent' : '#fff'} className={`${styles.modalbox} ${chooseModel && !color ? styles.selectmodalbox : ''}`}>
            <Icon className={color ? styles.modalboxiconbl : styles.modalboxicon} as={require('@/assets/svg/toolsicon/selectmodel.svg').ReactComponent} />
            <Box>
              <Text color={color ? '#fff' : '#000'}>{modelkey.name}</Text>
              {modelkey.price ?
                <Flex >
                  <Flex textDecoration={userInfo?.monVip ? 'line-through' : ''} className={styles.pricetext}>
                    {modelkey.price}
                    <Icon as={require(`@/assets/svg/notvipbalance.svg`).ReactComponent} />
                    /次
                  </Flex >
                  {userInfo?.monVip && <Text className={styles.Vippricetext}>会员免费</Text>}
                </Flex>
                :
                <Flex className={styles.freetext}>
                  限时免费
                </Flex>
              }
            </Box>
            <Icon transform={chooseModel ? 'rotate(270deg)' : 'rotate(90deg)'} className={color ? styles.modalboxopenbl : styles.modalboxopen} as={require('@/assets/svg/toolsicon/openmodels.svg').ReactComponent} />
          </Flex>

          {chooseModel && <Box id='modelMenu' ref={modelMenuRef} className={styles.selectmodels}>
            {_Models.map((item, index) => {
              return <Flex key={item.key} className={styles.selectmodel}
                onClick={e => {
                  e.stopPropagation()
                  setModelsKey(item)
                  localStorage.setItem('writingModel', JSON.stringify({ name: item.name, key: item.key }))
                  setChooseModel(false)
                }}
              >
                {modelkey.key == item.key && <Icon className={styles.Select} as={require('@/assets/svg/toolsicon/gou_white.svg').ReactComponent}
                />}
                <Box>
                  <Text className={modelkey.key == item.key ? styles.selectName : styles.selectname}>{item.name}</Text>
                  <Text className={modelkey.key == item.key ? styles.selectTips : styles.selecttips}>{item.tips}</Text>
                </Box>
                {
                  item.tag && <Box className={styles.tagtext}>{item.tag}</Box>
                }
                {
                  item.price && <Flex className={modelkey.key == item.key ? styles.selectprice : styles.pricetext}>
                    {item.price}<Icon as={require(`@/assets/svg/notvipbalance.svg`).ReactComponent} />/次
                  </Flex >
                }
              </Flex>
            })}
          </Box>
          }
        </Box>
      )}
      <Flex flexDir={'row'}>
        {/* 插入示例按钮 */}
        {caseButtonClick && (
          <CButton className={color ? styles.casebuttonbl : styles.casebutton}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            onClick={() => caseButtonClick()}
          >
            <Icon as={color ? require('@/assets/svg/casebl.svg').ReactComponent : require('@/assets/svg/toolsicon/case.svg').ReactComponent} />
            {casebut}
          </CButton>
        )}
        {/* 开始生成按钮 */}
        {userInfo?.monVip ?
          <CButton className={`${styles.vipstartbutton} ${isnull ? styles.vipstartbutton_disabled : ''} `} //${loading ? styles.vipstartbutton_disabled : ''} disabled={loading}
            onClick={() => {
              if (isnull) { return }
              if (loading) { stopClick() } else { startClick() }
            }} disabled={isnull}>
            {loading ?
              <Box className={styles.vipstopicon}></Box>
              :
              <Icon as={isnull ? require('@/assets/svg/navisVipNull.svg').ReactComponent : require('@/assets/svg/navisVip.svg').ReactComponent} />
            }
            {loading ? '生成中' : '免费生成'}
          </CButton>
          :
          <CButton className={`${styles.startbutton} ${isnull ? styles.startbutton_disabled : ''} `}
            onClick={() => {
              if (isnull) { return }
              if (loading) { stopClick() } else { startClick() }
            }} disabled={isnull}>
            {loading ?
              <Box className={styles.stopicon}></Box>
              :
              <Icon as={require('@/assets/svg/toolsicon/action.svg').ReactComponent} />
            }
            {loading ? '生成中' : startbut ? startbut : '开始'}
            {/* {stopbut ? stopbut : '生成中'} */}
          </CButton>
        }
      </Flex>
    </Flex>
  );
})