import { cancelcollectUserWorktool, fetchTokenLogin, fetchUserAvatar, fetchUserNickname, fetchUserPhone, fetchallworktool, fetchwxUnBind, getWechatQrCode, loginOut, postFindPassword, sendAuthCode, userLogin, userLoginByCode } from "@/api/user";
import { formatPrice } from "@/utils/common";
import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

let timer: NodeJS.Timer;

export enum UserAuthTypeEnum {
  register = 'register',
  findPassword = 'findPassword',
  login = 'login',
  bindPhone = 'bindPhone'
}

const getRandStr = (len: number) => {
  let outString: string = '';
  let inOptions: string = 'abcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < len; i++) {
    outString += inOptions.charAt(Math.floor(Math.random() * inOptions.length));
  }
  return outString;
};

class UserStore {
  userInfo?: UserResult;
  codeCountdown = 60;
  qrRandKey = '';
  nextRefreshTime = 0;
  lgSuccess = false;
  wxUnbindUserInfo?: UserResult;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'userStore',
      properties: ['userInfo'],
      storage: window.localStorage
    })
  }

  genQrRandKey() {
    if (this.nextRefreshTime === 0 || this.nextRefreshTime < Date.now()) {
      this.nextRefreshTime = Date.now() + 1000 * 120;
      this.lgSuccess = false;

      let timestamp = Math.floor(Date.now() / 1000) % 10000;
      this.qrRandKey = getRandStr(10) + timestamp.toString();
    }
    return this.qrRandKey;
  }

  async loginByCodeAction(phone: string, code: string) {
    const res = await userLoginByCode({ username: phone, code });
    this.setUserInfo(res.user);
  }

  async loginByPwdAction(phone: string, password: string) {
    const res = await userLogin({ username: phone, password });
    this.setUserInfo(res.user);
  }

  async getCode(phone: string, type: `${UserAuthTypeEnum}` = UserAuthTypeEnum.login) {
    await sendAuthCode({
      username: phone,
      type,
      googleToken: ''//这个参数不需要了 传空字符串
    });
    if (timer) {
      clearInterval(timer)
    }
    this.setCodeCountdown(59);
    const self = this;
    timer = setInterval(() => {
      if (self.codeCountdown <= 0) {
        clearInterval(timer);
        self.setCodeCountdown(60);
        return;
      }
      self.setCodeCountdown(self.codeCountdown - 1);
    }, 1000)
  }

  async findPwdAction(phone: string, password: string, code: string) {
    const res = await postFindPassword({ username: phone, password, code });
    this.setUserInfo(res.user);
  }

  async getWxQrCode(key: string) {
    return await getWechatQrCode(key);
  }
  setUserInfo(userInfo?: UserResult) {
    if (userInfo) {
      const userDB = userInfo as unknown as UserResult;
      userInfo.wxRobotVip = false;
      userInfo.novelVip = false;
      userInfo.videoReVip = false
      if (userDB.limit && userDB.limit.novelMonPayList) {
        userDB.limit.novelMonPayList.forEach((i) => {
          if (new Date(i.endTime).getTime() >= Date.now()) {
            userInfo.novelVip = true;
          }
        });
      }
      if (userDB.limit && userDB.limit.videoMonPayList) {
        userDB.limit.videoMonPayList.forEach((i) => {
          if (new Date(i.endTime).getTime() >= Date.now()) {
            userInfo.videoReVip = true;
          }
        });
      }
      if (userDB.limit && userDB.limit.wxRobotMonPayList) {
        userDB.limit.wxRobotMonPayList.forEach((i) => {
          if (new Date(i.endTime).getTime() >= Date.now()) {
            userInfo.wxRobotVip = true;
          }
        });
      }
      if (userDB.limit && userDB.limit.vipPayList) {
        userDB.limit.vipPayList.forEach((i) => {
          if (new Date(i.endTime).getTime() >= Date.now()) {
            userInfo.monVip = true;
          }
        });
      }
    }
    this.userInfo = userInfo && { ...userInfo, balance: formatPrice(userInfo.balance) };
  }
  updateUserBalance(balance: number) {
    this.userInfo = this.userInfo && { ...this.userInfo, balance: balance };
  }
  setCodeCountdown(countdown: number) {
    this.codeCountdown = countdown;
  }
  async Logout() {
    try {
      await loginOut();
      this.userInfo = undefined;
    } catch (e) {
      console.log(e)
    }
  }

  async updateUserinfo() {
    const userdata = await fetchTokenLogin() as UserResult;
    this.setUserInfo(userdata);
  }

  async updateUserNickname(nickname: string) {
    await fetchUserNickname({ nickname: nickname })
    this.updateUserinfo();
  }

  async updateUserAvatar(avatar: string, openaiKey: string) {
    await fetchUserAvatar({ avatar, openaiKey })
    this.updateUserinfo();
  }

  async updateUserPhone(username: string, code: string) {
    await fetchUserPhone({ username: username, code: code })
    this.updateUserinfo();
  }

  async postwxUnBind() {
    await fetchwxUnBind();
    this.updateUserinfo();
  }

  async postWorkTool(label: string) {
    await fetchallworktool({ label: label })
    this.updateUserinfo();
  }

  async cancelWorkTool(label: string) {
    await cancelcollectUserWorktool({ label: label })
    this.updateUserinfo();
  }

  setWxUnbindUserInfo(user: UserResult) {
    this.wxUnbindUserInfo = user;
  }
}

export const userStore = new UserStore();