import Message from '@/components/Message';
import { useToast as uToast, UseToastOptions } from '@chakra-ui/react';

export const useMessage = (props?: UseToastOptions) => {
  const sameToast = (type: 'warning' | 'tips' | 'success' | 'error') => {
    return uToast({
      containerStyle: {
        border: '10px'
      },
      position: 'top',
      duration: 2000,
      isClosable: true,
      render: (props) => {
        return <Message status={type} title={props?.title} />
      },
      ...props,
    });
  }

  const success = sameToast('success')
  const warning = sameToast('warning')
  const tips = sameToast('tips')
  const error = sameToast('error')

  return {
    success,
    warning,
    tips,
    error
  };
};
