import HorizontalBox from "@/components/HorizontalBox";
import { Box, Icon, Text, Input, useOutsideClick } from "@chakra-ui/react";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useMessage } from "@/hooks/useMessage";
import { useCopyData } from "@/utils/tools";
import { OperatesType, operates } from "../WritingAssistant";
import { debounce, throttle } from "lodash";
import dayjs from "dayjs";
import { deleteWrite, getCollWrites, getWrites, updateWriteCollection } from "@/api/tools";
import { useGlobalToast } from "@/hooks/useGlobalToast";
import { WisePage } from "@/components/Pagination";
import { CButton } from "@/components/Button";
import { streamFetch } from "@/utils/stream";
import { WriteModelSchema } from "@/@types/tools";
import { saveBlobToLocal } from "@/utils/common";
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { CModal } from "@/components/Modal";
import { Pagination } from 'swiper/modules';
import { WiseImage } from "@/components/Image";
import { ScrollBarBox } from "@/components/ScrollBox";

import styles from './index.module.scss'
import { uiStrore } from "@/store/ui";
import Docxtemplater from "docxtemplater";

const defalutGPTModel = 'gpt-4o'

const _case = [
  {
    name: '述职报告',
    prompt: '请根据${input}，帮助我撰写一份述职报告。报告中需要突出${input}。报告应简洁明了，条理清晰，便于读者快速把握重点。',
    inputTips: ['项目概念、目标、预期成果、以及可能面临的挑战和解决策略', '我在过去一年中的工作成绩、面临的挑战、解决问题的方式以及未来的工作计划']
  },
  {
    name: '学术论文',
    prompt: '请根据我${input}，帮助我撰写一份学术论文，论文需包括${input}等关键部分。确保内容逻辑清晰、结构严谨，展现研究的新颖性和深度。',
    inputTips: ['研究主题、假设、研究方法、数据分析、以及实验结果', '摘要、引言、文献综述、方法论、结果分析、讨论、结论以及参考文献']
  },
  {
    name: '商业计划书',
    prompt: '请根据${input}等信息，帮我撰写一份商业计划书。确保内容详细、逻辑清晰，包括${input}等关键部分。商业计划书应突出商业模式的可行性、市场竞争力和盈利潜力，吸引潜在投资者或合作伙伴的关注。',
    inputTips: ['业务理念、市场分析、营销策略、运营计划、财务预测以及团队介绍', '执行摘要、公司描述、市场分析、组织结构和管理团队、产品或服务描述、营销与销售策略、资金需求和财务预测']
  },
  {
    name: '新闻稿',
    prompt: '请根据${input}等信息，帮我撰写一份新闻稿。新闻稿应包括${input}。请确保内容客观、准确，语言简洁明了，旨在快速传达新闻要点，适合媒体发布。',
    inputTips: ['事件背景、主要内容、参与人物、发生时间和地点', '紧凑的开头段落概述重点、详细的事件描述、相关人物的声明或评论，以及结尾的公司或组织信息']
  },
  {
    name: '小说',
    prompt: '请根据${input}等信息，帮我撰写一部小说。小说应包括${input}的主题寓意。确保故事流畅、情节合理，同时在叙述中巧妙融入文化、情感或哲思元素，以丰富故事层次，触动读者心弦。',
    inputTips: ['故事背景、角色设定、情节发展和主题思想', '引人入胜的开场、复杂多变的情节、鲜明个性的角色以及深刻']
  },
]

const base_prompt: Record<OperatesType, { prompt: string, tips?: string }> = {
  Optimization: {
    prompt: `假定你是一位AI写作导师，请根据我提供的原始文本，执行深度的文本优化工作。要求在优化过程中，提升文本的可读性、流畅性和吸引力，同时确保核心信息和主题不变。请通过调整句式结构、使用更加生动的语言、增加吸引人的细节或删除冗余内容等方式，全面提升文本质量。仅需提供优化后的文本即可，避免添加其他解释或指导。仅以纯文本格式发送给我，不需要富文本或Markdown及其他格式。 `,
    tips: '请输入优化要求'
  },
  Copy: { prompt: '', tips: '' },
  Summary: {
    prompt: `你是一名AI写作导师，请根据我提供的文本或段落，撰写一个精炼、有力的总结。要求总结要点鲜明，语言简洁明了，能够概括文章的中心思想和重要信息。仅需提供总结段落即可，无需附加其他指导或解释。仅以纯文本格式发送给我，不需要富文本或Markdown及其他格式。`,
    tips: '请输入总结要求'
  },
  Expand: {
    prompt: `你是一名AI写作导师，请根据我提供的文本或段落，进行内容扩写。要求扩写后的内容应该逻辑清晰、信息丰富，同时保持原有信息的核心不变。请确保文本扩展后更加详细、生动，能够吸引读者深入阅读。仅需提供扩写后的文本即可，无需附加其他指导或解释。仅以纯文本格式发送给我，不需要富文本或Markdown及其他格式。`,
    tips: '请输入扩写要求'
  },
  Brainstorm: {
    prompt: `假定你是一位AI写作导师，请根据我提供的主题或问题，进行头脑风暴。要求提出一系列创意点子、概念或解决方案，这些想法应该是创新的、多元的，并且能够从不同角度探讨主题或问题。请确保你的头脑风暴结果能够为进一步的讨论、研究或创作提供丰富的灵感来源。仅需提供头脑风暴的结果即可，无需附加其他解释或指导。仅以纯文本格式发送给我，不需要富文本或Markdown及其他格式。`,
    tips: '请输入头脑风暴要求'
  },
  Rewrite: {
    prompt: `你是一位AI写作导师，请根据我提供的句子或段落，进行内容的重写，保持原核心内容不变，只是换一种表达方式。切记与原有信息保持一致性，不偏离主题。仅需提供重写后的内容即可，无需附加其他指导或解释。仅以纯文本格式发送给我，不需要富文本或Markdown及其他格式。`,
    tips: '请输入重写要求'
  },
  Translate: {
    prompt: `你是一名AI翻译专家，请根据我提供的文本，进行精准而流畅的翻译。确保翻译准确传达原文意思，同时注重语言的细微差别和文化差异。仅需提供翻译的文本内容，无需附加指导或解释。仅以纯文本格式发送给我，不需要富文本或Markdown及其他格式。`,
    tips: ''
  },
  Continue: {
    prompt: `你是一位AI写作导师，请根据我提供的文本或段落内容，继续进行创意性的续写。要求在续写过程中，内容应保持与原文风格和主题的一致性，同时引入新的情节、角色或想法，以丰富和扩展故事。请确保续写部分能够自然衔接原文，为读者带来意想不到的发展或满意的结局。仅需提供续写后的内容即可，无需附加其他指导或解释。仅以纯文本格式发送给我，不需要富文本或Markdown及其他格式。`,
    tips: '请输入续写要求'
  },
  Title: {
    prompt: `你是一名AI写作导师，请对我的标题《{user_input_title}》进行优化。请发挥你的创造力和想象力，生成引人注目、风格独特的标题，以吸引读者的眼球。仅需提供标题即可，无需附加其他指导或解释。仅以纯文本格式发送给我，不需要富文本或Markdown及其他格式。`,
  },
  Outline: {
    prompt: `你是一名AI写作导师，请跟据我的标题《{user_input_title}》生成大纲，要求逻辑清晰，分条表述。仅需提供大纲即可，返回标题或其他的内容，切记不要附加其他指导或解释。仅以纯文本格式发送给我，不需要富文本或Markdown及其他格式。`
  }
}

interface WritingModalProps {
  isOpen: boolean
  onClose: () => void
  children?: React.ReactNode
}
const WritingModal: FC<WritingModalProps> = (props) => {
  const { children, isOpen, onClose } = props

  return <CModal isOpen={isOpen} onClose={onClose} >
    <Box pos='absolute' left={0} top={0} w='100%' h='100%' cursor='pointer' onClick={e => e.stopPropagation()}>
      <Box w='100%' h='100%' bg='transparent' boxShadow='none' pos='relative'>
        {children}
      </Box>
    </Box>
  </CModal>
}

interface StartWriteProps {
  onClose: () => void
  onSpend: (num: number) => void
  onStartUse: (data: { title: string, outline: string }) => void
  model: string
}
const StartWrite: FC<StartWriteProps> = (props) => {
  const { onClose, onSpend, onStartUse, model } = props

  const [generateStatus, setGenerateStatus] = useState<Status>('init')
  const [lang, setLang] = useState('中文（简体）')
  const content_ref = useRef<HTMLDivElement>(null)
  const controller = useRef(new AbortController())
  const [title, setTitle] = useState('')
  const message = useMessage()
  const { copyData } = useCopyData()

  const inputTitle = debounce((val: string) => {
    setTitle(val)
  }, 100)

  const generatingMessage = useCallback(
    throttle(() => {
      if (!content_ref.current) return;
      const box = content_ref.current as HTMLDivElement
      const isBottom = box.scrollTop + box.clientHeight + 150 >= box.scrollHeight;
      if (isBottom) {
        box.scrollTo({
          top: box.scrollHeight,
          behavior: 'smooth'
        });
      }
    }, 100),
    []
  );

  const generateInfo = async () => {
    if (generateStatus === 'generating') {
      controller.current.abort()
      setGenerateStatus('done')
      return
    }

    if (!title) {
      message.tips({ title: '请输入标题' })
      return
    }

    const prompt = `你是一名AI写作导师，请跟据我的标题《${title}》生成大纲，要求逻辑清晰，分条表述。仅需提供大纲即可，返回标题或其他的内容，切记不要附加其他指导或解释。
    仅以纯文本格式发送给我，不需要富文本或Markdown及其他格式。
    返回给我的语言请使用：《${lang}》`

    const abortSignal = new AbortController();
    controller.current = abortSignal;
    content_ref.current!.innerHTML = ''

    try {
      let is_init = true
      setGenerateStatus('generate_start')
      await streamFetch({
        data: {
          messages: [
            { content: prompt, role: 'user' },
            { content: '', role: 'assistant' },
          ],
          model: model || defalutGPTModel,
          type: 'toolWriting',
          stream: true
        },
        onMessage: text => {
          if (is_init) {
            is_init = false
            setGenerateStatus('generating')
          }
          if (text.startsWith('```__PRICE__:') && text.endsWith('__PRICE__END__```')) {
            let tokens = 0
            try {
              tokens = JSON.parse(text.replace('```__PRICE__: ', '').replace('__PRICE__END__```', '')).tokens
            } catch (err) {
              tokens = 0
            }
            onSpend(tokens)
          } else {
            content_ref.current!.innerHTML += text.replace('\n', '</br>')
          }
          generatingMessage()
        },
        abortSignal
      })
    } catch (err: any) {
      controller.current.abort()
      message.warning({ title: err.message || '已取消' })
    }
    setGenerateStatus('done')
  }

  return <Box
    w='660px'
    margin='0 auto'
    mt='40px'
    borderRadius='30px'
    p='30px'
    border='2px solid #FFFFFF'
    boxShadow='20px 30px 60px 0px rgba(2,22,205,0.12)'
    zIndex={1}
    pos='relative'
    bg='#fff'
  >
    <HorizontalBox mb='30px' w='100%' justifyContent='space-between'>
      <Box mr='60px' cursor='pointer' fontWeight='bold' color='#333333' fontSize='18px'>开始写作</Box>
      <Icon as={require('@/assets/svg/close_gray.svg').ReactComponent} w='24px' h='24px' cursor='pointer' onClick={onClose} />
    </HorizontalBox>
    <Box bg='#F7F7F7' borderRadius='20px' p='15px 20px'>
      <Input
        className={styles.input}
        pl='0'
        border='none'
        placeholder='请输入您想要写作的标题'
        _focusVisible={{ border: 'none' }}
        _focus={{ border: 'none' }}
        fontSize='16px'
        onChange={e => inputTitle(e.target.value)}
      />
      <Box mt={generateStatus != 'init' ? '10px' : 0} maxH='300px' overflow='overlay' ref={content_ref}></Box>
    </Box>
    <HorizontalBox justifyContent='space-between' mt='20px'>
      {
        generateStatus === 'init' && <Language lang={lang} changeLange={setLang} />
      }
      <HorizontalBox>
        {
          generateStatus === 'init' && <HorizontalBox color='#565CFA' borderRadius='16px' border='1px solid rgba(86,92,250,0.5)'
            mr='20px' cursor='pointer' onClick={onClose} h='44px' w='92px' justifyContent='center'
            _hover={{ color: '#433FE3' }}
          >
            随便写点
          </HorizontalBox>
        }
        {
          generateStatus === 'done' ? <GenerateBtns
            hideAddToEnd
            onUse={() => onStartUse({ title, outline: content_ref.current!.innerText })}
            onDontUse={() => {
              content_ref.current!.innerHTML = '';
              setGenerateStatus('init')
            }}
            onCopy={() => copyData(content_ref.current!.innerText)}
            onReGenerate={generateInfo}
          />
            : <HorizontalBox
              className={styles.generateBtn}
              justifyContent='center'
              onClick={() => generateInfo()}>
              {
                (generateStatus === 'generating' || generateStatus === 'generate_start') ? <Box w='14px' h='14px' bg='#fff' borderRadius='2px' mr='8px' className={styles.stopIcon}></Box>
                  : '生成大纲'
              }
              {(generateStatus === 'generating' || generateStatus === 'generate_start') && '停止'}
            </HorizontalBox>
        }
      </HorizontalBox>
    </HorizontalBox>
  </Box>
}

interface BaseGeneratProps {
  title?: string
  index: number
  model: string
  onClose: () => void
  onAddToEnd: (val: string, index: number) => void
  onUse: (val: string, index: number) => void
  onSpend: (num: number) => void
}
type Status = 'generate_start' | 'generating' | 'init' | 'done'
/** 写作输入空格后的弹窗 */
const BaseGenerat: FC<BaseGeneratProps> = (props) => {
  const { title, index, model, onClose, onAddToEnd, onUse, onSpend } = props

  const editBox = useRef<HTMLDivElement>(null)
  const editBox_outline = useRef<HTMLDivElement>(null)
  const [chooseCase, setChooseCase] = useState(-1)
  const [type, setType] = useState<1 | 2>(1) //1.直接写作 2.撰写大纲
  const [lang, setLang] = useState('中文（简体）')
  const caseInput = useRef<{ [key: number]: { value: string, placeholder: string } }>({})
  const [caseText, setCaseText] = useState<string[]>([])
  const message = useMessage()
  const controller = useRef(new AbortController());
  const [generateStatus, setGenerateStatus] = useState<{
    1: { status: Status, prompt: string }, 2: { status: Status, prompt: string }
  }>({ 1: { status: 'init', prompt: '' }, 2: { status: 'init', prompt: '' } })
  const { copyData } = useCopyData();
  const isDown = useRef(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const offsetPosition = useRef({ x: 0, y: 0 });

  useEffect(() => {

    window.addEventListener('resize', resizeInputHeight, false);

    return () => {
      window.removeEventListener('resize', resizeInputHeight, false);
    }
  }, [])

  useEffect(() => {
    if (editBox.current && chooseCase > -1) {
      const empty_arr: string[] = []
      caseInput.current = {}
      let input_index = -1
      _case[chooseCase].prompt.split('${input}').forEach((item, i) => {
        if (i > 0) {
          input_index++
          caseInput.current[input_index] = { placeholder: _case[chooseCase].inputTips[input_index], value: '' }
          empty_arr.push('${input}')
          empty_arr.push(item)
        } else {
          empty_arr.push(item)
        }
      })
      setCaseText(empty_arr)
    }
  }, [chooseCase])

  const resizeInputHeight = () => {
    if (window.innerHeight < 740) {
      if (window.innerHeight > 690) {
        editBox.current!.style.height = '210px'
      } else if (window.innerHeight > 640) {
        editBox.current!.style.height = '180px'
      } else {
        editBox.current!.style.height = '150px'
      }
    } else {
      editBox.current!.style.height = '240px'
    }
  }

  const generatingMessage = useCallback(
    throttle(() => {
      if (!editBox.current && !editBox_outline.current) return;
      const box = (type === 1 ? editBox.current : editBox_outline.current) as HTMLDivElement
      const isBottom = box.scrollTop + box.clientHeight + 150 >= box.scrollHeight;
      if (isBottom) {
        box.scrollTo({
          top: box.scrollHeight,
          behavior: 'smooth'
        });
      }
    }, 100),
    [type]
  );
  const generateInfo = async (rePrompt?: string) => {
    if (generateStatus[1].status == 'generating' || generateStatus[2].status == 'generating') {
      // message.warning({ title: '有内容正在生成中' })
      controller.current.abort()
      return
    }

    const _type = type;

    const val = rePrompt || (_type == 1 ? editBox.current?.innerText : editBox_outline.current?.innerText);

    if (type == 1 && caseText.length) {
      const is_empty = Object.keys(caseInput.current).some((_, i) => {
        return caseInput.current[i].value == ''
      })

      if (is_empty) {
        message.warning({ title: '请输入文本' })
        return
      }
    }

    const origin_html = rePrompt || _type == 1 ? editBox.current?.innerHTML : editBox_outline.current?.innerHTML
    if (!val?.trim().replaceAll(' ', '').replace(/\n\s*/g, '\n')) {
      message.warning({ title: '请输入文本' })
      return
    }

    let gptPrompt = ''
    if (_type == 1) {
      gptPrompt = `你是一名AI写作导师，请帮助我撰写文章正文, 请确保作品紧扣主题，表达清晰，风格一致。根据需要，你可能会撰写故事、文章、报告等各种形式的文本。仅需提供创作的正文内容。切记不要附加其他指导或解释。
      ${title ? '我的文章标题为：《' + title + '》' : ''}
      我想要撰写的内容或要求为：${val}
      返回给我的语言请使用：《${lang}》。
      仅以纯文本格式发送给我，不需要富文本或Markdown及其他格式。`

      editBox.current!.contentEditable = 'false'
      editBox.current!.innerHTML = ' '
    } else {
      gptPrompt = `你是一名AI写作导师，帮助我撰写一份文章大纲, 请遵循逻辑清晰，分条表述。仅需提供大纲即可，返回标题或其他的内容，切记不要附加其他指导或解释。
      ${title ? '我的文章标题为：《' + title + '》' : ''}
      我想要撰写的内容或要求为：${val}
      返回给我的语言请使用：《${lang}》。
      仅以纯文本格式发送给我，不需要富文本或Markdown及其他格式。`

      editBox_outline.current!.contentEditable = 'false'
      editBox_outline.current!.innerHTML = ' '
    }

    const abortSignal = new AbortController();
    controller.current = abortSignal;
    try {
      setGenerateStatus(pre => ({
        ...pre,
        [_type]: { status: 'generate_start', prompt: val }
      }))
      let _is_init = true
      await streamFetch({
        data: {
          messages: [
            { content: gptPrompt, role: 'user' },
            { content: '', role: 'assistant' },
          ],
          model: model || defalutGPTModel,
          type: 'toolWriting',
          stream: true
        },
        onMessage: text => {
          if (_is_init) {
            _is_init = false
            setGenerateStatus(pre => ({
              ...pre,
              [_type]: { status: 'generating', prompt: val }
            }))
          }
          if (text.startsWith('```__PRICE__:') && text.endsWith('__PRICE__END__```')) {
            let tokens = 0
            try {
              tokens = JSON.parse(text.replace('```__PRICE__: ', '').replace('__PRICE__END__```', '')).tokens
            } catch (err) {
              tokens = 0
            }
            onSpend(tokens)
          } else {
            if (_type == 1) {
              editBox.current!.innerHTML += text.replace('\n', '</br>')
            } else {
              editBox_outline.current!.innerHTML += text.replace('\n', '</br>')
            }
          }
          generatingMessage()
        },
        abortSignal
      })
      setGenerateStatus(pre => ({
        ...pre,
        [_type]: { status: 'done', prompt: val }
      }))
    } catch (err: any) {
      editBox.current!.innerHTML = origin_html || ''
      setGenerateStatus(pre => ({
        ...pre,
        [_type]: { status: 'init', prompt: '' }
      }))
      message.error({ title: err.message || '已取消' })
    } finally {
      editBox_outline.current!.contentEditable = 'true'
    }
  }

  const dontUse = () => {
    if (type == 1) {
      editBox.current!.contentEditable = 'true'
      editBox.current!.innerText = ''
    } else {
      editBox_outline.current!.contentEditable = 'true'
      editBox_outline.current!.innerText = ''
    }
    setGenerateStatus(pre => ({
      ...pre,
      [type]: { status: 'init', prompt: '' }
    }))
  }

  const copy = () => {
    if (type == 1) {
      copyData(editBox.current!.innerText)
    } else {
      copyData(editBox_outline.current!.innerText)
    }
  }

  const reGenerate = () => {
    generateInfo(generateStatus[type].prompt)
  }

  const addToEnd = () => {
    const value = type == 1 ? editBox.current?.innerText : editBox_outline.current?.innerText
    onAddToEnd(value || '', index > 0 ? index - 1 : 0)
  }

  const use = () => {
    const value = type == 1 ? editBox.current?.innerText : editBox_outline.current?.innerText
    onUse(value || '', index)
  }

  let edit_index = -1;

  const start = (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    if (event.target !== event.currentTarget) return
    isDown.current = true;

    //@ts-ignore
    const { left, top } = event.target.getBoundingClientRect()
    const offsetX = (event as React.MouseEvent<HTMLDivElement>).pageX - (left * 0.9) - 50;
    const offsetY = (event as React.MouseEvent<HTMLDivElement>).pageY - (top * 0.9) - 30;
    offsetPosition.current = { x: offsetX, y: offsetY }
  }
  const move = (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (!isDown.current) return
    let x: number = 0, y: number = 0;
    if (event.type === 'touchstart') {
      x = (event as React.TouchEvent<HTMLDivElement>).touches[0].pageX;
      y = (event as React.TouchEvent<HTMLDivElement>).touches[0].pageY;
    } else {
      const { clientX, clientY } = (event as React.MouseEvent<HTMLDivElement>).nativeEvent
      x = clientX; y = clientY;
    }

    boxRef.current?.style.setProperty('left', `${x - offsetPosition.current.x}px`);
    boxRef.current?.style.setProperty('top', `${y - offsetPosition.current.y}px`);
    boxRef.current?.style.setProperty('transform', `none`);
    boxRef.current?.style.setProperty('-webkit-transform', `none`);
  }

  const stop = () => {
    isDown.current = false;
  }
  return <Box
    w='720px'
    boxShadow='20px 30px 60px 0px rgba(2,22,205,0.12)'
    zIndex={1}
    pos='absolute'
    boxSizing='border-box'
    left='50%'
    top='50%'
    transform='translate(-50%, -50%)'
    cursor='all-scroll'
    ref={boxRef}
  >
    <Box pos='absolute' top='-90px' zIndex={-1} left='36px' w='120px' cursor='auto'>
      <WiseImage src={require('@/assets/img/write_modal_animation.gif')} maxH='100%' maxW='100%' />
    </Box>
    <Box pos='absolute' bg='#fff' borderRadius='16px' left='156px' top='-70px' cursor='auto'>
      <Box pos='relative' p='17px 30px' css={{
        ':after': {
          content: '""', position: 'absolute', borderWidth: '12px 11px 12px 0', left: '-10px', top: '50%',
          transform: 'translateY(-50%)', borderColor: 'transparent #ffffff transparent transparent;', borderStyle: 'solid'
        }
      }}>
        <Text>Hi～欢迎使用Ai写作</Text>
      </Box>
    </Box>
    <Box bg='#fff' p='30px' w='720px' border='2px solid #FFFFFF' borderRadius='30px' boxSizing='border-box'
      onMouseDown={start}
      onMouseUp={stop}
      onMouseMove={move}
    >
      <Box w='100%' h='100%' cursor='auto'>
        <HorizontalBox mb='30px' w='100%' justifyContent='space-between' cursor='auto'>
          <HorizontalBox fontSize='18px' fontWeight='Regular'>
            <Box mr='60px' cursor='pointer' {...(type == 1 && { color: '#565CFA', fontWeight: 'bold' })} onClick={() => setType(1)}>直接写作</Box>
            <Box cursor='pointer' {...(type == 2 && { color: '#565CFA', fontWeight: 'bold' })} onClick={() => setType(2)}>撰写大纲</Box>
          </HorizontalBox>
          <Icon as={require('@/assets/svg/close_gray.svg').ReactComponent} w='24px' h='24px' cursor='pointer' onClick={onClose} />
        </HorizontalBox>
        {
          type === 1 && generateStatus[1].status === 'init' && <HorizontalBox borderRadius='5px' mb='20px' cursor='auto'>
            <Text color='#333333'>您可以试试：</Text>
            {
              _case.map((item, index) => {
                return <HorizontalBox key={item.name} w='86px' h='38px' justifyContent='center' opacity={.9}
                  {...(chooseCase === index ? { background: 'rgba(86,92,250,0.1)', border: '1px solid #fff', color: '#565CFA' }
                    : { color: '#999999', border: '1px solid #99999980' })}
                  borderRadius='14px' ml='10px' cursor='pointer' onClick={() => setChooseCase(index)}>
                  {item.name}
                </HorizontalBox>
              })
            }
          </HorizontalBox>
        }
        <Box
          borderRadius='20px'
          bg='#F7F7F7'
          p='4px'
          mb='24px'
          pos='relative'
          overflow='overlay'
          className={styles.generateContent}
        >
          <Box
            ref={editBox}
            contentEditable={!caseText.length}
            suppressContentEditableWarning
            display={type === 1 ? 'block' : 'none'}
            content='告诉AI您想要写什么'
            color='#333333'
            lineHeight='2'
            h='240px'
            cursor='text'
            boxSizing='border-box'
            css={{ ':empty::before': { content: 'attr(content)', color: '#797979' } }}
            p='12px 20px 10px 20px'
            onPaste={e => {
              e.preventDefault();
              const text = e.clipboardData.getData('text/plain').replaceAll('\n', ''); //仅提取文字，去除粘贴中的换行
              document.execCommand("insertText", false, text);
            }}
          >
            {
              caseText?.map((text, i) => {
                let new_index = -1
                if (text == '${input}') {
                  edit_index++
                  new_index = edit_index
                }
                return text == '${input}' ? <span contentEditable={false} key={i}>
                  <span
                    contentEditable
                    suppressContentEditableWarning
                    content={caseInput.current[edit_index].placeholder}
                    className={styles.editInput}
                    color='#333333'
                    onBlur={e => {
                      const ele = e.target as HTMLSpanElement
                      if (!ele.innerHTML.trim().replace(/\n\s*/g, '\n')) {
                        ele.innerHTML = ''
                      }
                    }}
                    onInput={e => {
                      const ele = e.target as HTMLSpanElement
                      caseInput.current[new_index] = { ...caseInput.current[new_index], value: ele.innerText }
                    }}
                    onFocus={e => {
                      const ele = e.target as HTMLSpanElement
                      if (ele.innerHTML === '') {
                        const selection = getSelection()
                        ele.innerText = ' '
                        selection?.setPosition(ele, 0)
                      }
                    }}
                    style={{
                      borderBottom: '1px solid #333333', display: 'inline',
                    }}
                  />
                </span>
                  : <span className={styles.spanBox} key={i}>{text}</span>
              })
            }
          </Box>
          <Box
            ref={editBox_outline}
            contentEditable
            suppressContentEditableWarning
            display={type === 2 ? 'block' : 'none'}
            content='告诉AI您想要写什么大纲'
            color='#333333'
            lineHeight='2'
            cursor='text'
            h='240px'
            overflow='overlay'
            boxSizing='border-box'
            p='12px 20px 10px 20px'
            css={{ ':empty::before': { content: 'attr(content)', color: '#797979' } }}
            onKeyDown={e => {
              //不允许回车换行
              if ((e.target as HTMLDivElement).classList.contains('div-editable') && e.code == 'Enter') {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            onPaste={e => {
              e.preventDefault();
              const text = e.clipboardData.getData('text/plain').replaceAll('\n', ''); //仅提取文字，去除粘贴中的换行
              document.execCommand("insertText", false, text);
            }}
          />
          {
            generateStatus[type].status == 'generate_start' && <Box color='#565CFA' pos='absolute' top='20px' left='24px' zIndex={1}>AI正在生成中</Box>
          }
        </Box>
        {
          generateStatus[type].status !== 'done' && <HorizontalBox flexDir='column'>
            {
              generateStatus[type].status == 'init' && <Language lang={lang} changeLange={setLang} />
            }
            <HorizontalBox mt='30px'>
              {
                caseText?.length && type == 1 && generateStatus[type].status == 'init' ? <HorizontalBox
                  bg='#ffffff' borderRadius='16px' justifyContent='center' border='1px solid #ccc' fontSize='16px'
                  mr='20px' color='#333333' h='50px' w='110px' cursor='pointer' onClick={() => {
                    caseInput.current = {};
                    setCaseText([])
                    setChooseCase(-1)
                  }}
                >
                  <Icon as={require('@/assets/svg/dasao.svg').ReactComponent} mr='4px' mt='2px' w='20px' h='20px' />
                  清空
                </HorizontalBox> : ''
              }
              <CButton
                className={styles.generateBtn}
                theme='primary'
                onClick={() => generateInfo()}>
                {
                  (generateStatus[type].status == 'generating' || generateStatus[type].status == 'generate_start') ? <Box w='14px' h='14px' bg='#fff' borderRadius='2px' mr='8px' className={styles.stopIcon}></Box>
                    : <>
                      <Icon as={require('@/assets/svg/ai.svg').ReactComponent} w='24px' h='24px' mt='-2px' ml='4px' />
                      生成
                    </>
                }
                {(generateStatus[type].status == 'generating' || generateStatus[type].status == 'generate_start') && '停止'}
              </CButton>
            </HorizontalBox>
          </HorizontalBox>
        }
        {
          generateStatus[type].status === 'done' && <GenerateBtns
            onUse={use}
            onDontUse={dontUse}
            onCopy={copy}
            onReGenerate={reGenerate}
            onAddToEnd={addToEnd}
          />
        }
      </Box>
    </Box>
  </Box>
}

interface OperatesModalProps {
  type: OperatesType
  index: number
  title?: string
  text: string
  extend?: string
  model: string
  onClose: () => void
  onAddToEnd: (val: string, index: number) => void
  onUse: (val: string, index: number) => void
  onSpend: (num: number) => void
}
const OperatesModal: FC<OperatesModalProps> = (props) => {
  const { type, title, text, extend, index, model, onClose, onAddToEnd, onUse, onSpend } = props
  const controller = useRef(new AbortController())

  const [generateStatus, setGenerateStatus] = useState<'init' | 'done' | 'generating' | 'generate_start'>('init')
  const content_ref = useRef<HTMLDivElement>(null)
  const message = useMessage()
  const { copyData } = useCopyData()
  const lastInput = useRef('')
  const [isInput, setIsInput] = useState(false)
  const boxRef = useRef<HTMLDivElement>(null)
  const isDown = useRef(false);
  const offsetPosition = useRef({ x: 0, y: 0 });

  useEffect(() => {
    if ((type === 'Title' || type === 'Outline') && title) {
      generateText()
    } else if (type === 'Translate') {
      generateText()
    }
  }, [])

  const generatingMessage = useCallback(
    throttle(() => {
      if (!content_ref.current) return;
      const box = content_ref.current as HTMLDivElement
      const isBottom = box.scrollTop + box.clientHeight + 150 >= box.scrollHeight;
      if (isBottom) {
        box.scrollTo({
          top: box.scrollHeight,
          behavior: 'smooth'
        });
      }
    }, 100),
    [type]
  );

  const generateText = async (reGenerateText?: string) => {
    if (generateStatus === 'generating') {
      controller.current.abort()
      setGenerateStatus('done')
      return
    }

    let prompt = base_prompt[type].prompt

    const val = reGenerateText || content_ref.current?.innerText || '';

    if (!prompt) {
      message.warning({ 'title': '未知的prompt' })
      return
    }

    lastInput.current = val
    if (type === 'Title' || type == 'Outline') {
      prompt = prompt.replace('{user_input_title}', title!)
    } else if (type === 'Translate') {
      prompt = `${prompt}\n所需翻译的内容为：${text}\n所需要翻译的语言为：${extend}`
    } else {
      prompt = `${prompt}\n${title ? '我的文章标题为：《' + title + '》\n' : ''}我的需要优化的原始文本为：${text}\n我希望优化时中可以做到：${val}`
    }

    const abortSignal = new AbortController();
    controller.current = abortSignal;

    setGenerateStatus('generate_start')
    content_ref.current!.contentEditable = 'false'
    content_ref.current!.innerHTML = ''
    try {
      let is_init = true
      const res = await streamFetch({
        data: {
          messages: [
            { content: prompt, role: 'user' },
            { content: '', role: 'assistant' },
          ],
          model: model || defalutGPTModel,
          type: 'toolWriting',
          stream: true
        },
        onMessage: text => {
          if (is_init) {
            is_init = false
            setGenerateStatus('generating')
          }
          if (text.startsWith('```__PRICE__:') && text.endsWith('__PRICE__END__```')) {
            let tokens = 0
            try {
              tokens = JSON.parse(text.replace('```__PRICE__: ', '').replace('__PRICE__END__```', '')).tokens
            } catch (err) {
              tokens = 0
            }
            onSpend(tokens)
          } else {
            content_ref.current!.innerHTML += text.replace('\n', '</br>')
          }
          generatingMessage()
        },
        abortSignal
      })
    } catch (err: any) {
      message.warning({ title: err.message || '已取消' })
    }
    setGenerateStatus('done')
  }


  const start = (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    if (event.target !== event.currentTarget) return
    isDown.current = true;

    //@ts-ignore
    const { left, top } = event.target.getBoundingClientRect()
    const offsetX = (event as React.MouseEvent<HTMLDivElement>).pageX - (left * 0.9) - 50;
    const offsetY = (event as React.MouseEvent<HTMLDivElement>).pageY - (top * 0.9) - 30;
    offsetPosition.current = { x: offsetX, y: offsetY }
  }
  const move = (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (!isDown.current) return
    let x: number = 0, y: number = 0;
    if (event.type === 'touchstart') {
      x = (event as React.TouchEvent<HTMLDivElement>).touches[0].pageX;
      y = (event as React.TouchEvent<HTMLDivElement>).touches[0].pageY;
    } else {
      const { clientX, clientY } = (event as React.MouseEvent<HTMLDivElement>).nativeEvent
      x = clientX; y = clientY;
    }

    boxRef.current?.style.setProperty('left', `${x - offsetPosition.current.x}px`);
    boxRef.current?.style.setProperty('top', `${y - offsetPosition.current.y}px`);
    boxRef.current?.style.setProperty('transform', `none`);
    boxRef.current?.style.setProperty('-webkit-transform', `none`);
  }

  const stop = () => {
    isDown.current = false;
  }
  return <Box
    w={'720px'}
    borderRadius='30px'
    p='30px'
    zIndex={1}
    boxSizing='border-box'
    pos='absolute'
    left='50%'
    top='50%'
    transform='translate(-50%, -50%)'
    bg={'#fff'}
    cursor='all-scroll'
    onMouseDown={start}
    onMouseUp={stop}
    onMouseMove={move}
    ref={boxRef}
  >
    <Box h='100%' w='100%' cursor='auto'>
      <HorizontalBox mb='30px' w='100%' justifyContent='space-between'>
        {
          generateStatus == 'init' ? <HorizontalBox>
            <Box fontSize='18px' color={isInput ? '#999999' : '#333333'} fontWeight={isInput ? 'normal' : 'bold'} onClick={() => {
              setIsInput(false)
            }} >AI{operates[type].name}</Box>
            <Box fontSize='18px' color={isInput ? '#333333' : '#999999'} fontWeight={isInput ? 'bold' : 'normal'} ml='60px' onClick={() => {
              setIsInput(true)
            }} >{operates[type].name}要求</Box>
          </HorizontalBox> : <Box fontSize='18px' color='#333333' fontWeight='bold'>{operates[type].name}内容</Box>
        }

        <Icon as={require('@/assets/svg/close_gray.svg').ReactComponent} w='24px' h='24px' cursor='pointer' onClick={onClose} />
      </HorizontalBox>
      {
        generateStatus == 'init' && !isInput ? <Box my='120px'>
          <HorizontalBox bg='linear-gradient( 90deg, #FF951A 0%, #FFAF52 100%)' borderRadius='16px' m='0 auto'
            boxShadow='0px 4px 10px 0px rgba(255,145,84,0.4)' w='92px' h='44px' justifyContent='center' cursor='pointer'
            _hover={{ bg: 'linear-gradient( 90deg, #F27B0D 0%, #FF961D 100%)' }}
            onClick={() => generateText()}
          >
            <Text fontSize='16px' color='#fff'>一键</Text>
            <Icon as={require('@/assets/svg/ai.svg').ReactComponent} w='24px' h='24px' mt='-2px' ml='4px' />
          </HorizontalBox>
          <Text color='#999999' fontSize='14px' mt='30px' textAlign={'center'}>通过AI一键{operates[type].name}</Text>
        </Box> : null
      }
      <Box
        borderRadius='20px'
        bg='#F7F7F7'
        p='4px'
        mb='24px'
        pos='relative'
        display={generateStatus == 'init' && !isInput ? 'none' : 'block'}
      >
        <Box contentEditable={index != -1}
          ref={content_ref}
          onPaste={e => {
            e.preventDefault();
            const text = e.clipboardData.getData('text/plain').replaceAll('\n', ''); //仅提取文字，去除粘贴中的换行
            document.execCommand("insertText", false, text);
          }}
          suppressContentEditableWarning
          content={generateStatus == 'generate_start' ? '' : base_prompt[type].tips}
          color='#333333'
          lineHeight='2'
          minH='210px'
          cursor='text'
          p='12px 20px 10px 20px'
          maxH='400px'
          overflow='overlay'
          css={{ ':empty::before': { content: 'attr(content)', color: '#797979' } }}
        />
        {
          generateStatus == 'generate_start' && <Box color='#565CFA' pos='absolute' top='20px' left='24px' zIndex={1}>AI正在生成中</Box>
        }
      </Box>
      {
        generateStatus != 'done' ? <HorizontalBox justifyContent={'center'}
          display={generateStatus == 'init' && !isInput ? 'none' : 'flex'}>
          <HorizontalBox
            className={styles.generateBtn}
            bg='rgb(var(--primary-color))'
            fontSize='16px'
            onClick={() => {
              if (generateStatus === 'generating' || generateStatus == 'generate_start') {
                controller.current.abort()
                setGenerateStatus('done')
              } else {
                const val = content_ref.current?.innerText || ''
                if (!val.trim().replace(/\n\s*/g, '\n')) {
                  message.warning({ title: '请输入' })
                  return
                }
                generateText()
              }
            }}
          >
            {
              generateStatus === 'generating' || generateStatus == 'generate_start' ? <Box w='14px' h='14px' bg='#fff' borderRadius='2px' mr='8px' className={styles.stopIcon}></Box>
                : '确定'//<Icon as={require('@/assets/svg/writing_send.svg').ReactComponent} w='26px' h='26px' />
            }
            {generateStatus === 'generating' || generateStatus == 'generate_start' && '停止'}
          </HorizontalBox>
        </HorizontalBox> : null
      }
      {
        generateStatus == 'done' && <GenerateBtns
          onUse={() => onUse(content_ref.current?.innerText || '', index)}
          onDontUse={() => { content_ref.current!.innerText = ''; setGenerateStatus('init'); content_ref.current!.contentEditable = 'true' }}
          onAddToEnd={() => onAddToEnd(content_ref.current?.innerText || '', index)}
          onReGenerate={() => generateText(lastInput.current)}
          onCopy={() => { copyData(content_ref.current?.innerText || '') }}
          hideAddToEnd={index <= -1}
        />
      }
    </Box>
  </Box>
}

interface GenerateBtnsProps {
  onUse: () => void
  onDontUse: () => void
  onAddToEnd?: () => void
  onReGenerate: () => void
  onCopy: () => void
  hideAddToEnd?: boolean
}
const GenerateBtns: FC<GenerateBtnsProps> = (props) => {
  const { onUse, onAddToEnd, onDontUse, onReGenerate, onCopy, hideAddToEnd } = props
  return <HorizontalBox justifyContent='flex-end' mt='30px'>
    <CButton theme='outline-error' className={styles.opBtn} mr='20px'
      onClick={onDontUse}>
      <Icon as={require('@/assets/svg/delete_gray.svg').ReactComponent} mr='6px' w='20px' h='20px' css={{ ' path': { stroke: '#F76560' } }} />
      弃用
    </CButton>
    <CButton theme='outline' mr='20px' onClick={onCopy} className={styles.opBtn}
    >
      <Icon as={require('@/assets/svg/copy.svg').ReactComponent} mr='6px' w='20px' h='20px' css={{ ' path': { fill: '#666666' } }} />
      复制
    </CButton>
    <CButton theme='outline' mr='20px' onClick={onReGenerate} className={styles.opBtn}>
      <Icon as={require('@/assets/svg/refresg.svg').ReactComponent} mr='6px' w='20px' h='20px' css={{ ' path': { fill: '#666666' } }} />
      重新生成
    </CButton>
    {
      !hideAddToEnd && <CButton theme='outline' mr='20px' onClick={onAddToEnd} className={styles.opBtn}
      >
        <Icon as={require('@/assets/svg/add_to_end.svg').ReactComponent} mr='6px' w='20px' h='20px' css={{ ' path': { fill: '#666666' } }} />
        加至句尾
      </CButton>
    }
    <CButton theme='primary' onClick={onUse} className={styles.opBtn}>
      <Icon as={require('@/assets/svg/write_use.svg').ReactComponent} mr='6px' w='20px' h='20px' css={{ ' path': { fill: '#fff' } }} />
      应用
    </CButton>
  </HorizontalBox>
}

interface WriteHistoryProps {
  onChangeInfo: (id: string, params?: any) => void
  activeId: string
  selfType: 'use' | 'collection'
  listShoulUpdate: boolean
}
const WriteHistory: FC<WriteHistoryProps> = (props) => {
  const { onChangeInfo, selfType, activeId, listShoulUpdate } = props

  const activeRef = useRef<{ _id: string }>()
  const [menuPos, setMenuPos] = useState<{ x: number, y: number, show: boolean }>()
  const menuRef = useRef<HTMLDivElement>(null)
  const [writeHistory, setWriteHistory] = useState<WriteModelSchema[]>([])
  const [writeCollHistory, setWriteCollHistory] = useState<WriteModelSchema[]>([])
  const [loading, setLoading] = useState(false)
  const { openAlert, closeAlert } = uiStrore
  const [page, setPage] = useState<{ pageNum: number, pageSize: number }>({ pageNum: 1, pageSize: 11 })
  const [total, setTotal] = useState<number>(0)
  const [isFavorite, setIsFavorite] = useState(false);

  const message = useMessage()

  useEffect(() => {
    setPage({ pageNum: 1, pageSize: 11 })
  }, [selfType])

  useEffect(() => {
    getList()
  }, [page, listShoulUpdate])

  const getList = async () => {
    const res = await getWrites({ isCollection: selfType === 'collection', ...page })
    setWriteHistory(res.list)
    const rescoll = await getCollWrites()
    setWriteCollHistory(rescoll.list)
    setTotal(res.total)
  }
  useEffect(() => {
    if (activeRef.current) {
      const exists = writeCollHistory.some(item => item._id === activeRef.current!._id);
      setIsFavorite(exists);
    }
  }, [writeCollHistory, activeRef.current]);
  useOutsideClick({
    ref: menuRef,
    handler: () => {
      setMenuPos(undefined)
    }
  })

  const onOpen = () => {
    if (activeRef.current?._id) {
      const info = writeHistory.find(item => item._id == activeRef.current!._id)
      if (!info) return
      const params = { content: info.content, title: info.title, id: info._id, type: 'content' }
      onChangeInfo(info._id, params)
    }
  }

  const toOpen = (info: WriteModelSchema) => {
    const params = { content: info.content, title: info.title, id: info._id, type: 'content' }
    onChangeInfo(info._id, params)
  }

  const onCollection = async () => {
    if (loading) {
      message.tips({ title: '处理中请稍后' })
      return
    }
    if (!activeRef.current?._id) {
      message.tips({ title: '未知的文章' })
      return
    }
    setLoading(true)
    try {
      const res = await updateWriteCollection(activeRef.current._id)
      res.isCollection ? message.success({ title: '收藏成功' }) : message.success({ title: '取消收藏' })
      setMenuPos(undefined)
      getList()
    } catch (err: any) {
      message.error({ title: err?.message || '未知错误' })
    }
    setLoading(false)
  }

  const download = async () => {
    if (activeRef.current?._id) {
      setMenuPos(undefined)
      const info = writeHistory.find(item => item._id === activeRef.current!._id)
      if (info && window) {
        if (window) {
          const PizZip = (await import('pizzip')).default;
          const PizZipUtils = (await import('pizzip/utils/index.js')).default;
          PizZipUtils.getBinaryContent(
            './docs/template.docx',
            async function (error, content) {
              if (error) {
                throw error;
              }
              const zip = new PizZip(content);
              const doc = new Docxtemplater().loadZip(zip)
              doc.setOptions({
                nullGetter: function () {
                  return "";
                },
                linebreaks: true
              })
              doc.setData({
                title: info.title || `[文思逸言]AI写作（高级版）${dayjs().format('YYYYMMDD_HHmm')}`,
                content: info.content.join('\n')
              });
              doc.render();
              const out = doc.getZip().generate({
                type: 'blob',
                mimeType:
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              }); //Output the document using Data-URI
              const suggestedName = `${info.title || `[文思逸言]AI写作（高级版）${dayjs().format('YYYYMMDD_HHmm')}`}.docx`;
              try {
                await saveBlobToLocal(out, suggestedName);
              } catch (err) {
                console.log(err)
              }
            }
          );
        }
      }
    }
  }

  const onDelete = async () => {
    if (loading) {
      message.tips({ title: '处理中请稍后' })
      return
    }
    if (!activeRef.current?._id) {
      message.tips({ title: '未知的文章' })
      return
    }
    setLoading(true)
    try {
      await deleteWrite(activeRef.current._id)
      message.success({ title: '删除成功' })
      getList()
    } catch (err: any) {
      message.error({ title: err?.message || '未知错误' })
    }
    setLoading(false)
    closeAlert()
    if (activeRef.current?._id === activeId) {
      onChangeInfo('')
    }
  }

  return <Box className={styles.history}>
    <ScrollBarBox>
      <HorizontalBox flex={1} flexWrap='wrap'>
        {
          writeHistory.map(item => {
            return <HorizontalBox className={styles.item} key={item._id} onClick={() => toOpen(item)}>
              <HorizontalBox className={styles.itemTitle} justifyContent='space-between'>
                {
                  item.title && <Text className={styles.title}>
                    {item.title}
                  </Text>
                }
                <Icon as={require('@/assets/svg/write_dot.svg').ReactComponent} w='24px' h='24px' onClick={e => {
                  e.stopPropagation();
                  setMenuPos({ x: e.pageX, y: e.pageY, show: true })
                  activeRef.current = { _id: item._id }
                }} />
              </HorizontalBox>
              <Box className={styles.textContent} w='100%' h='40px'>{item.content}</Box>
              <HorizontalBox className={styles.itemBottom}>
                <Text color='#999999'>{dayjs(item.updateTime).format('YYYY-MM-DD HH:mm')}</Text>
                {
                  item.isCollection ? <Icon as={require('@/assets/svg/star_yellow.svg').ReactComponent} w='20px' h='20px' mt='-4px' mr='8px' />
                    : <Icon as={require('@/assets/svg/star_hollow.svg').ReactComponent} w='20px' h='20px' mt='-4px' mr='8px' />
                }
              </HorizontalBox>
            </HorizontalBox>
          })
        }
        {
          menuPos?.show ? <Box id='listMenu' onContextMenu={e => e.preventDefault()}
            pos='fixed' top={0} left={0} right={0} bottom={0} zIndex={9}></Box> : ''
        }
        {
          menuPos?.show ? <Box className={styles.menus}
            left={menuPos.x + 'px'}
            top={menuPos.y + 'px'}
            ref={menuRef}
            onContextMenu={e => e.preventDefault()}
          >
            <HorizontalBox className={styles.menusItem} onClick={onOpen}>
              <Icon as={require('@/assets/svg/write_folder.svg').ReactComponent} className={styles.fill} />
              <Text>打开</Text>
            </HorizontalBox>
            <HorizontalBox className={styles.menusItem} onClick={onCollection}>
              <Icon as={require('@/assets/svg/star_hollow.svg').ReactComponent} className={styles.stroke} />
              <Text>{isFavorite ? '取消收藏' : '收藏'}</Text>
            </HorizontalBox>
            <HorizontalBox className={styles.menusItem} onClick={download}>
              <Icon as={require('@/assets/svg/write_download.svg').ReactComponent} className={styles.fill} />
              <Text>下载</Text>
            </HorizontalBox>
            <HorizontalBox className={styles.menusItem}
              onClick={() => {
                openAlert({
                  status: 'warning',
                  title: '提示',
                  content: '是否确认删除该文章?',
                  onOk: async () => {
                    await onDelete()
                  }
                })
              }}>
              <Icon as={require('@/assets/svg/write_delete.svg').ReactComponent} className={styles.fill} />
              <Text>删除</Text>
            </HorizontalBox>
          </Box> : ''
        }
      </HorizontalBox>
      {
        total > 11 && <HorizontalBox justifyContent='center'>
          <WisePage {...page} total={total} onPageChange={(page) => {
            if (page === 1) {
              setPage({ pageNum: 1, pageSize: 11 })
            } else {
              setPage({ pageNum: page, pageSize: 11 })
            }
          }} />
        </HorizontalBox>
      }
    </ScrollBarBox>
  </Box>
}

interface LanguageProps {
  lang: string
  changeLange: (lang: string) => void
}
const Language: FC<LanguageProps> = (props) => {
  const { lang, changeLange } = props

  const [show, setShow] = useState(false)
  const menu_ref = useRef<HTMLDivElement>(null)

  useOutsideClick({
    ref: menu_ref,
    handler: _ => {
      setShow(false)
    }
  })

  return <HorizontalBox color='#666666' w='100%'>
    <Text>语言偏好：</Text>
    <HorizontalBox flex={1} borderRadius='14px' border='1px solid rgba(153,153,153,0.5)' h='40px' color='#333333'
      justifyContent='space-between' px='16px' cursor='pointer' pos='relative' onClick={_ => setShow(true)}>
      <Text whiteSpace='nowrap'>
        {lang}
      </Text>
      <Icon as={require('@/assets/svg/select_arrow.svg').ReactComponent} />
      {
        show && <Box pos='absolute' bg='#fff'
          boxShadow='0px 6px 20px 0px rgba(0,7,70,0.1)'
          borderRadius='14px'
          zIndex={10}
          left={0}
          bottom={'50px'}
          w='100%'
          pl='20px'
          pr='3px'
          maxH='300px'
          overflow='hidden'
          flex={1}
          boxSizing='border-box'
          ref={menu_ref}
        >
          <ScrollBarBox>
            {
              operates.Translate?.extends?.map((lang, index) => {
                return <Box p='12px' mr='17px' whiteSpace='nowrap' key={lang}
                  borderBottom={index != (operates.Translate?.extends?.length || 0) - 1 ? '1px solid #ddd' : 'none'}
                  onClick={e => {
                    e.stopPropagation();
                    changeLange(lang);
                    setShow(false)
                  }}>{lang}</Box>
              })
            }
          </ScrollBarBox>
        </Box>
      }
    </HorizontalBox>
  </HorizontalBox >
}

const firstText = [
  {
    title: '多场景适应性',
    desc: '可以针对不同类型的写作需求（如学术论文、创意写作、商业 文案等）调整语气和风格，满足多样化的写作场景。'
  },
  {
    title: '高级编辑和文章优化等功能',
    desc: '提供扩写、总结、优化、重写、续写等功能，帮助用户提高文本的整体质量，确保内容准确、专业且易于理解。'
  },
  {
    title: '智能内容建议',
    desc: '根据用户输入的主题或大纲，提供相关的内容建议和结构化的写作框架，帮助用户有效组织思路和扩展内容。'
  }
]

interface FirstOpenModalProps {
  open: boolean
  onClose: () => void
}
const FirstOpenModal: FC<FirstOpenModalProps> = (props) => {
  const { open, onClose } = props;
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [activeIndex, setActiveIndex] = useState(0);

  const next = () => {
    swiperRef?.slideNext();
  }

  const pre = () => {
    swiperRef?.slidePrev();
  }

  return <CModal onClose={onClose} isOpen={open}>
    <Box className={styles.firstModal}>
      <Box className={styles.swiperBox}>
        <Swiper
          onSwiper={setSwiperRef}
          className={styles.swiper}
          onSlideChange={(swiper: SwiperClass) => {
            setActiveIndex(swiper.activeIndex);
          }}
          modules={[Pagination]}
          pagination={{
            dynamicBullets: true,
            clickable: true,
            bulletClass: `swiper-pagination-bullet ${styles.bullet}`,
            bulletActiveClass: `swiper-pagination-bullet-active`
          }}
        >
          {
            new Array(3).fill(0).map((_, index) => {
              return <SwiperSlide key={index}>
                <WiseImage src={require(`@/assets/png/write_bg_${index + 1}.png`)} />
              </SwiperSlide>
            })
          }
        </Swiper>
        <Icon as={require('@/assets/svg/write_pre.svg').ReactComponent} className={styles.pre} onClick={pre} />
        <Icon as={require('@/assets/svg/write_next.svg').ReactComponent} className={styles.next} onClick={next} />
      </Box>
      <Box className={styles.content}>
        <Text className={styles.title}>{firstText[activeIndex].title}</Text>
        <Text className={styles.desc} color='#666666' fontSize='16px' lineHeight='24px'>{firstText[activeIndex].desc}</Text>
      </Box>
      <Box className={styles.footer}>
        <CButton theme='primary' onClick={onClose} className={styles.start}>立即写作</CButton>
      </Box>
    </Box>
  </CModal>
}


export const Writing = {
  /** 写作输入空格后的弹窗 */
  BaseGenerat: BaseGenerat,
  OperatesModal: OperatesModal,
  WriteHistory: WriteHistory,
  StartWrite: StartWrite,
  FirstOpenModal: FirstOpenModal
}

export default WritingModal