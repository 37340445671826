import { Box, Icon, Input } from "@chakra-ui/react";
import { FC, useState } from "react";

import styles from './index.module.scss'
import Loading from "@/components/Loading";

interface PhoneInputProps {
  err?: string
  value?: string
  onChange: (value: string) => void
}
export const PhoneInput: FC<PhoneInputProps> = (props) => {
  const { err, value, onChange } = props
  return <Box className={styles.phone}>
    <Box className={styles.before}>+86</Box>
    <Input placeholder='手机号' value={value} className={styles.input} onChange={e => onChange(e.target.value)} />
    {
      err && <Box className={styles.error}>{err}</Box>
    }
  </Box>
}

interface CodeInputProps {
  cutdown: number
  loading?: boolean
  onChange: (value: string) => void
  onGetCode: () => void
}
export const CodeInput: FC<CodeInputProps> = (props) => {
  const { cutdown, loading, onChange, onGetCode } = props

  return <Box className={styles.code}>
    <Input className={styles.inputCode} placeholder='短信验证码' onChange={e => onChange(e.target.value || '')} />
    <Box className={styles.getCode} onClick={onGetCode}>
      {
        loading ? <Loading.Icon /> : cutdown === 60 ? '获取验证码' : `${cutdown}s后重新获取`
      }
    </Box>
  </Box>
}

interface PwdInputProps {
  onChange: (value: string) => void
  placeholder: string
  error?: string
}
export const PwdInput: FC<PwdInputProps> = (props) => {
  const { onChange, placeholder, error } = props;

  const [type, setType] = useState<'text' | 'password'>('password')

  return <Box className={styles.pwd}>
    <Box className={styles.lock}>
      <Icon className={styles.icon} as={require('@/assets/svg/lock.svg').ReactComponent} />
    </Box>
    <Input type={type} className={styles.input} placeholder={placeholder} onChange={e => onChange(e.target.value || '')} />
    <Box className={styles.eyes}>
      <Icon
        className={styles.icon}
        as={require(`@/assets/svg/${type === 'text' ? 'open' : 'close'}_eyes.svg`).ReactComponent}
        onClick={() => setType(type === 'text' ? 'password' : 'text')}
      />
    </Box>
    {
      error && error.length < 10 && <Box className={styles.error}>{error}</Box>
    }
  </Box>
}