import React from 'react';
import ReactMarkdown from 'react-markdown';
import RemarkGfm from 'remark-gfm';
import RemarkMath from 'remark-math';
import RehypeKatex from 'rehype-katex';
import RemarkBreaks from 'remark-breaks';
//@ts-ignore
import MarkdownNavbar from 'markdown-navbar';

import 'katex/dist/katex.min.css';
import 'markdown-navbar/dist/navbar.css';
import styles from './index.module.scss';

import Link from './Link';
import CodeLight from './CodeLight';
import MermaidCodeBlock from './img/MermaidCodeBlock';
import MdImage from './img/Image';
import { PhotoProvider, PhotoView } from 'react-photo-view';

function Code({ inline, className, children, codeShowHeader }: any) {
  const match = /language-(\w+)/.exec(className || '');

  if (match?.[1] === 'mermaid') {
    return <MermaidCodeBlock code={String(children)} />;
  }

  return (
    <CodeLight className={className} inline={inline} match={match} codeShowHeader={codeShowHeader}>
      {children}
    </CodeLight>
  );
}

function Image({ src }: { src?: string }) {
  return <PhotoProvider maskOpacity={0.5}>
    <PhotoView src={src}>
      <div>
        <MdImage src={src} download={false} />
      </div>
    </PhotoView>
  </PhotoProvider>
}

function preprocessMathFormulas(source: string) {
  // console.log('原始文本为',source);
  const newsource = source.replace(/```latex([\s\S]*?)```/g, (match, p1) => {
    // 检查匹配内容是否包含\[\]或\(\)
    if (/\[.*?\]|\\\(.*?\\\)/.test(p1)) {
      // 如果有，则仅移除```latex...```标记
      return p1;
    } else {
      // 如果没有，则将整个块替换为$$...$$
      return `$$${p1}$$`;
    }
  });
  source.replace(/\\\[([\s\S]*?)\\\]/g, (match, mathContent) => `$$${mathContent}$$`)
  let latexsource = newsource.replace(/\\\[([\s\S]*?)\\\]|\\\(([\s\S]*?)\\\)/g, (match, mathContentBrackets, mathContentParentheses) => {
    const mathContent = mathContentBrackets || mathContentParentheses;
    return `$$ ${mathContent} $$`;
  });
  return latexsource;
}
function removeCodeFences(source: string) {
  // return source.replace(/^```|```$/g, '');
  let noCodeFences = source.replace(/^```|```$/gm, '');
  let noHtmlTags = noCodeFences.replace(/<[^>]*>/g, '');
  return noHtmlTags;
}
const Markdown = ({ source, isChatting = false, tooltype, showNavBar = false, codeShowHeader = true }: { source: string; isChatting?: boolean; tooltype?: string; showNavBar?: boolean, codeShowHeader?: boolean }) => {
  const preprocessedSource = preprocessMathFormulas(source);
  const newpreprocessedSource = preprocessedSource.replace(/\*\*(.*?)\*\*/g, '**$1** ')
  const pptToolSource = removeCodeFences(source);

  return (
    <>
      <div id='markdownContent'>
        <ReactMarkdown
          className={`markdown ${styles.markdown}
      ${isChatting ? (source === '' ? styles.waitingAnimation : styles.animation) : ''}
    `}
          remarkPlugins={[RemarkGfm, RemarkMath, RemarkBreaks]}
          rehypePlugins={[RehypeKatex]}
          components={{
            //@ts-ignore 
            a: Link,
            img: Image,
            pre: 'div',
            code: (params) => {
              return <Code codeShowHeader={codeShowHeader} {...params} />
            }
          }}
        >
          {/* {preprocessedSource} */}
          {(tooltype && tooltype === 'pptTool') ? pptToolSource : newpreprocessedSource}
        </ReactMarkdown>
        {
          showNavBar && <div className={styles.navigation}>
            <MarkdownNavbar source={source} onNavItemClick={(_e: Event, _element: Element, hash: string) => {
              const ele = document.body.querySelector(`[data-id='${hash}']`) as HTMLHRElement;

              const content = document.getElementById('markdownContent') as HTMLDivElement;
              //@ts-ignore
              content.parentNode?.scrollTo({
                top: ele?.offsetTop,
                behavior: 'smooth'
              })
            }} />
          </div>
        }
      </div>
    </>
  );
};

export default Markdown;
