import { Box, Text } from "@chakra-ui/react";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { chatStore } from "@/store/chat";
import { AIContentInfo } from "./AIContent";
import { userStore } from "@/store/user";
import { _AI_DefaultModel } from "@/utils/common";
import { WiseImage } from "@/components/Image";
import { debounce } from "lodash";

import styles from './index.module.scss'

const ChatContent: FC<{ info: any, showAgent?: boolean }> = ({ info, showAgent }) => {
  return <>
    <AIContent info={info} showAgent={showAgent} />
  </>
}

const AIContent: FC<{ info: any, showAgent?: boolean }> = observer(({ info, showAgent = true }) => {
  const { modelInfo, currentChat, needReRender, generateMsg, aiChatInfo, playingId } = chatStore;
  const { userInfo } = userStore;
  const containerRef = useRef<HTMLDivElement>(null);
  const [length, setLength] = useState(10);
  const list = useRef<ChatInfoHistory[]>([])
  // const info = useRef<AIChatInfoResult | undefined>();
  const maxLen = useRef(0);
  const [isInited, setIsInited] = useState(false);
  const [selfRender, setSelfRender] = useState(false);
  // info.current = aiChatInfo.get(currentChat?.chatId || 'newChatId');
  const newsetGenerateMsg = generateMsg.get(currentChat?.chatId || 'newChatId');

  useEffect(() => {
    const inputDom = document.getElementById('inputArea') as HTMLInputElement;
    if (!inputDom || !containerRef.current) return;
    // 监听输入框的高度变化，用于调整滚动条
    inputDom.addEventListener('DOMNodeInserted', () => {
      const height = inputDom.offsetHeight;
      containerRef.current!.style.height = `calc(100% - ${height - 100}px)`; // 100% - 输入框高度
    });
  }, [])

  useEffect(() => {

    // 滚动到底部，防止加载全部
    containerRef.current?.scrollTo({ top: 0, behavior: 'auto' });
    setIsInited(false);
    // eslint-disable-next-line
  }, [currentChat?.chatId])

  useEffect(() => {
    maxLen.current = info?.history?.length || 0;
    // 滚动到底部，防止加载全部
    containerRef.current?.scrollTo({ top: 0, behavior: 'auto' });
    if (!isInited) {
      setIsInited(true);
      setLength(length === 10 ? 12 : 10); // 用于触发useEffect
      return;
    }
    const _LIST = info?.history?.slice(-(length > maxLen.current ? maxLen.current : length))?.reverse() || [];
    list.current = _LIST;
    //外部手动触发重新渲染
    setSelfRender(!selfRender);
    // eslint-disable-next-line 
  }, [length, needReRender, info])

  useEffect(() => {
    const observerElement = document.querySelector('#loadMore') as HTMLDivElement;

    if (!observerElement) {
      return;
    }
    // 监听可视dom 用于渲染更多
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (length >= maxLen.current) {
            observer.unobserve(entry.target);
          } else {
            observerElement.style.display = 'none';
            loadMore();
            setTimeout(() => {
              observerElement.style.display = 'block';
            }, 300)
          }
        }
      });
    });

    observer.observe(observerElement as Element);

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line
  }, [list, length])

  const loadMore = debounce(() => {
    isInited && setLength(length + 12);
  }, 100)


  const renderContent = useMemo(() => {
    // 由于列表已经反转，所以这里需要判断第一个是否是AI
    const lastIsAI = list.current[0]?.obj === 'AI';
    let reGenerate = '';
    list.current.some(item => {
      if (item.obj === 'Human') {
        reGenerate = item.value;// 用于重新生成新问题
      }
      return item.obj === 'Human';
    })
    return <>
      {
        list && list.current.map((item, index) => {
          return <AIContentInfo
            key={item._id}
            avatar={{ userAvatar: userInfo?.avatar || '', aiAvatar: modelInfo[item.modelName]?.avatar || '' }}
            chatItem={item}
            modelNames={modelInfo[item.modelName]?.name || ''}
            price={modelInfo[item.modelName]?.price || 0}
            reGenerate={lastIsAI && index === 0 ? reGenerate : ''}
            value={item.value}
            riskInfo={item.contentCheck}
          />
        })
      }
      <Box w={0} h={0} id='loadMore' className={styles.loadMore}></Box>
    </>
    // eslint-disable-next-line
  }, [selfRender, playingId])

  const isAgent = currentChat?.modelId ? currentChat?.modelId !== _AI_DefaultModel && !currentChat.isPlugin : false;

  return <Box flex={1} className={styles.chatContainer}>
    <Box className={`${styles.listBox}`} ref={containerRef}>
      <Box flexGrow={1} flexShrink={1}></Box>
      <Box className={styles.bottomPos}></Box>
      {
        newsetGenerateMsg?.status === 'loading' && <AIContentInfo
          avatar={{ userAvatar: userInfo?.avatar || '', aiAvatar: modelInfo[newsetGenerateMsg.modelName].avatar }}
          chatItem={newsetGenerateMsg} modelNames={modelInfo[newsetGenerateMsg.modelName].name} value={newsetGenerateMsg.value}
        />
      }
      {renderContent}
      {
        isAgent && showAgent && <Box className={styles.agent}>
          <WiseImage src={info?.model?.avatar} className={styles.avatar} />
          <Text className={styles.title}>{info?.model?.name}</Text>
          <Text className={styles.desc}>{info?.model?.intro}</Text>
        </Box>
      }
      <Box className={styles.headerPos}></Box>
    </Box>
  </Box >
})
export default ChatContent;