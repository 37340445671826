import { WiseImage } from "@/components/Image";
import { Box, Icon, Text } from "@chakra-ui/react";
import { FC, memo, MouseEventHandler, useState } from "react";
import { CreationType, DrawImageStatusEnum } from "@/store/creation";
// import Loading from "@/components/Loading";
import { CButton } from "@/components/Button";

import styles from './index.module.scss'
import RotatingCircles from "@/components/RotatingCircles";
import { useTranslation } from "react-i18next";

interface CreationImageItemProps {
  url: string;
  type?: `${CreationType}`;
  isFavorite: boolean;
  onFavorite?: () => void;
  favoriteLoading?: boolean;
  onMore?: MouseEventHandler<SVGElement>;
  onView?: () => void;
  isVideo?: boolean;
  songdata: any
}
export const CreationImageItem: FC<CreationImageItemProps> = (props) => {
  const { url, type, onMore, onView, isFavorite, favoriteLoading, onFavorite, songdata } = props;
  const [isHovered, setIsHovered] = useState(false);
  return <Box className={`${styles.creationImgItem}  ${type ? styles[type] : ''}`} onClick={onView}
    onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
    <Box className={styles.imgBox} bg={`url(${require('@/assets/png/transparent_bg.png')})`}>
      <WiseImage src={url} />
      <Box className={styles.songlyricBox}>
        <Text fontSize={'14px'} fontWeight={'bold'} mb={3}>{songdata.music.clips[0].title}</Text>
        <Text fontSize={'12px'} fontWeight={'400'}>{songdata.music.clips[0].metadata.prompt}</Text>
      </Box>
    </Box>
    <Box className={styles.mask}>
      <Box visibility='hidden'></Box>
      {/* <Box className={styles.favorite} onClick={onFavorite}>
        {
          favoriteLoading && <Loading.Icon />
        }
        {
          isFavorite ? <Icon as={require('@/assets/svg/heart_red.svg').ReactComponent} />
            : <Icon as={require('@/assets/svg/heart_hollow.svg').ReactComponent} />
        }
        <Box className={`${styles.heart} ${isFavorite ? styles.active : ''}`} />
      </Box> */}
      <Icon as={require('@/assets/svg/more.svg').ReactComponent} className={styles.more} onClick={onMore} />
    </Box>
    <Box className={styles.view} style={{ opacity: isHovered ? 1 : 0, transition: 'opacity 0.3s' }}>
      <Icon as={require('@/assets/svg/view_icon.svg').ReactComponent} />
    </Box>
  </Box>
}


interface RenderImgProps {
  item: DrawSunoSchema
  favorites?: string[]
  type: `${CreationType}`
  sizeScale?: string
  onDelete?: () => void
  onReDraw?: () => void
  onMore?: (e: React.MouseEvent<SVGElement, MouseEvent>, url: string) => void;
  onFavorite?: (id: string, url: string) => void;
  onView?: (index: number) => void;
}
export const RenderSong: FC<RenderImgProps> = memo((props) => {
  const { item, favorites, type = CreationType.MJ, onMore, onFavorite, onReDraw, onView, onDelete } = props;
  const status = Number(item.status) || 0;
  const scales = [1, 1];
  const { t } = useTranslation();

  const _baseWidth = 280;
  const scalesHeight = Math.ceil(_baseWidth / Number(scales[0]) * Number(scales[1]));

  if (status > 10) {
    return <Box className={`${styles.fail} ${styles[type]}`}>
      <Icon as={require('@/assets/svg/creation_fail.svg').ReactComponent} />
      <Text>{t('creation.SongFaid')}</Text>
      <Box className={styles.btns}>
        <CButton className={styles.btn} theme='outline' onClick={onReDraw}>{t('creation.Redraw')}</CButton>
        <CButton className={styles.btn} theme='outline' onClick={onDelete}>{t('delete')}</CButton>
      </Box>
    </Box>
  } else if (status === DrawImageStatusEnum.DEAWRISK) {
    return <Box className={`${styles.risk} ${styles[type]}`}>
      <Icon as={require('@/assets/svg/risk_red.svg').ReactComponent} />
      <Box className={styles.riskText}>
        {/* <Text>检测到生成的图片可能包含： {item.contentCheck?.labels?.split(',').map(str => i18n.t('riskContent.' + str)).join(',')}，暂时无法显示。</Text> */}
      </Box>
    </Box>
  } else if (status === DrawImageStatusEnum.PROCESSING || status === DrawImageStatusEnum.WAIT) {
    return <Box className={styles.process}>
      <RotatingCircles />
      <Text>{t('creation.SongLoading')}...</Text>
    </Box>
  } else {
    return <Box className={styles.imgContent}>
      {item.music.clips[0] &&
        <CreationImageItem
          isFavorite={favorites?.includes(item.music.clips[0].image_url) || false}
          key={item.music.clips[0].image_url}
          url={item.music.clips[0].image_url + `?x-oss-process=image/resize,w_${_baseWidth},h_${scalesHeight}`}
          type={type}
          onFavorite={() => onFavorite && onFavorite(item._id, item.music.clips[0].image_url)}
          onMore={e => onMore && onMore(e, item.music.clips[0].image_url)}
          onView={() => onView && onView(1)}
          songdata={item}
        />
      }
    </Box>
  }
})