import { DELETE, GET, POST, PUT } from "./request";

/**
 * 创建一个智能体
 */
export const postCreateAgent = (data: {
  name: string;
  avatar?: string;
  prompt?: string;
  intro?: string;
  presence_penalty?: number;
  temperature?: number;
}) => POST<string>('/model/create', data);

/**
 * 根据 ID 删除智能体
 */
export const delAgentById = (id: string) => DELETE(`/model/del?modelId=${id}`);

/**
 * 根据 ID 更新模型
 */
export const putAgentById = (id: string, data: AgentUpdateParams) =>
  PUT(`/model/update?modelId=${id}`, data);

/**
 * 根据 ID 获取模型
 */
export const getAgentById = (id: string) => GET<ModelSchema>(`/model/detail?modelId=${id}`);