
export const gen3styles: Gen3StyleConfig[] = [
  {
    view_image: require('@/assets/img/drawGENstyle/runwayzdy.jpg'),
    drawType: 'runwayzdy',
    name: '自定义',
    tags: [], desc: '', key: 'runwayzdy', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        w: '512',
        "key": "11",
        "name": "1:1",
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/abandoned.jpg'),
    drawType: 'abandoned',
    name: '废墟',
    tags: [], desc: '', key: 'abandoned', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/abstract_sculpture.jpg'),
    drawType: 'abstract_sculpture',
    name: '抽象雕塑',
    tags: [], desc: '', key: 'abstract_sculpture', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/actor_casting.jpg'),
    drawType: 'actor_casting',
    name: '演员试镜',
    tags: [], desc: '', key: 'actor_casting', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/advertising.jpg'),
    drawType: 'advertising',
    name: '广告',
    tags: [], desc: '', key: 'advertising', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/anime.jpg'),
    drawType: 'anime',
    name: '动漫',
    tags: [], desc: '', key: 'anime', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/architectural.jpg'),
    drawType: 'architectural',
    name: '建筑',
    tags: [], desc: '', key: 'architectural', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/cartoon.jpg'),
    drawType: 'cartoon',
    name: '卡通',
    tags: [], desc: '', key: 'cartoon', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/cine_lens.jpg'),
    drawType: 'cine_lens',
    name: '电影镜头',
    tags: [], desc: '', key: 'cine_lens', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/cinematic.jpg'),
    drawType: 'cinematic',
    name: '电影风',
    tags: [], desc: '', key: 'cinematic', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/claymation.jpg'),
    drawType: 'claymation',
    name: '黏土动画',
    tags: [], desc: '', key: 'claymation', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/concept_art.jpg'),
    drawType: 'concept_art',
    name: '概念艺术',
    tags: [], desc: '', key: 'concept_art', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/digital_art.jpg'),
    drawType: 'digital_art',
    name: '数字艺术',
    tags: [], desc: '', key: 'digital_art', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/duotone_artistic_photo.jpg'),
    drawType: 'duotone_artistic_photo',
    name: '双色调艺术',
    tags: [], desc: '', key: 'duotone_artistic_photo', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/forestpunk.jpg'),
    drawType: 'forestpunk',
    name: '森林朋克',
    tags: [], desc: '', key: 'forestpunk', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/frost.jpg'),
    drawType: 'frost',
    name: '霜冻风',
    tags: [], desc: '', key: 'frost', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/graphic_novel.jpg'),
    drawType: 'graphic_novel',
    name: '图形小说风',
    tags: [], desc: '', key: 'graphic_novel', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/graphite.jpg'),
    drawType: 'graphite',
    name: '石墨风',
    tags: [], desc: '', key: 'graphite', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/impressionist_painting.jpg'),
    drawType: 'impressionist_painting',
    name: '印象派绘画风',
    tags: [], desc: '', key: 'impressionist_painting', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/isometric_3d.jpg'),
    drawType: 'isometric_3d',
    name: '等距3D风',
    tags: [], desc: '', key: 'isometric_3d', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/low_poly_3d.jpg'),
    drawType: 'low_poly_3d',
    name: '低多边形3D风',
    tags: [], desc: '', key: 'low_poly_3d', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/macro_photography.jpg'),
    drawType: 'macro_photography',
    name: '微距摄影风',
    tags: [], desc: '', key: 'macro_photography', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/marker_drawing.jpg'),
    drawType: 'marker_drawing',
    name: '马克笔绘图',
    tags: [], desc: '', key: 'marker_drawing', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/moody_film.jpg'),
    drawType: 'moody_film',
    name: '情绪化电影',
    tags: [], desc: '', key: 'moody_film', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/pixel_art.jpg'),
    drawType: 'pixel_art',
    name: '像素艺术风',
    tags: [], desc: '', key: 'pixel_art', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/retro_photography.jpg'),
    drawType: 'retro_photography',
    name: '复古摄影风',
    tags: [], desc: '', key: 'retro_photography', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/sci-fi_art.jpg'),
    drawType: 'sci-fi_art',
    name: '科幻艺术风',
    tags: [], desc: '', key: 'sci-fi_art', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/stickers.jpg'),
    drawType: 'stickers',
    name: '贴纸风',
    tags: [], desc: '', key: 'stickers', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/storyboard.jpg'),
    drawType: 'storyboard',
    name: ' 分镜头脚本',
    tags: [], desc: '', key: 'storyboard', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/thriller.jpg'),
    drawType: 'thriller',
    name: '惊悚风',
    tags: [], desc: '', key: 'thriller', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/3d_cartoon.jpg'),
    drawType: '3d_cartoon',
    name: '3D卡通风',
    tags: [], desc: '', key: '3d_cartoon', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/3d_render.jpg'),
    drawType: '3d_render',
    name: '3D渲染风',
    tags: [], desc: '', key: '3d_render', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/35mm.jpg'),
    drawType: '35mm',
    name: '35mm镜头摄影',
    tags: [], desc: '', key: '35mm', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
  {
    view_image: require('@/assets/img/drawGENstyle/80s_vaporwave.jpg'),
    drawType: '80s_vaporwave',
    name: '80年代蒸汽波',
    tags: [], desc: '', key: '80s_vaporwave', cost_base: 5, enable: true,
    draw_scal: [
      {
        "cost_coef": 1,
        "enable": true,
        "h": '512',
        "key": "11",
        "name": "1:1",
        "w": '512'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '543',
        "key": "169",
        "name": "16:9",
        "w": '965'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '965',
        "key": "916",
        "name": "9:16",
        "w": '543'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '592',
        "key": "32",
        "name": "3:2",
        "w": '888'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '888',
        "key": "23",
        "name": "2:3",
        "w": '592'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '724',
        "key": "12",
        "name": "1:2",
        "w": '362'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '362',
        "key": "21",
        "name": "2:1",
        "w": '724'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '627',
        "key": "43",
        "name": "4:3",
        "w": '836'
      },
      {
        "cost_coef": 1,
        "enable": true,
        "h": '836',
        "key": "34",
        "name": "3:4",
        "w": '627'
      }
    ]
  },
]