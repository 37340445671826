import { Box, Icon, Text, Textarea } from "@chakra-ui/react";
import { FC, useState } from "react";
import { CreationTitle, CreationTitleBox } from "../";
import { CreationPromptOptimization } from "../Optimization";
import { CreationType } from "@/store/creation";

import styles from './index.module.scss'
import { useTranslation } from "react-i18next";

const _MAX_LENGTH = 500;
interface CreationPromptProps {
  onClear: () => void;
  cnPrompt: string
  onInput: (value: string) => void
  type: CreationType
  showOptimization?: boolean
  empty?: boolean
}
export const CreationPrompt: FC<CreationPromptProps> = (props) => {
  const { onClear, cnPrompt, onInput, type, showOptimization = true, empty = false } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  return <Box className={styles.prompt}>
    <Box className={styles.title}>
      <CreationTitleBox className={styles.text}>
        <CreationTitle title={t('creation.prompt')} />
        {
          empty && <Text className={styles.require}>*</Text>
        }
      </CreationTitleBox>
      <Box className={styles.op}>
        {
          showOptimization && <Box className={`${styles.optimization} ${!cnPrompt ? styles.disabled : ''}`} onClick={() => {
            if (!cnPrompt) return;
            setOpen(true);
          }}>
            <Icon className={styles.icon} as={require('@/assets/svg/creation_prompt_optimization.svg').ReactComponent} />
            <Text>{t('creation.Optimization')}</Text>
          </Box>
        }
        <Box className={styles.delete} onClick={onClear}>
          <Icon className={styles.icon} as={require('@/assets/svg/creation_prompt_clear.svg').ReactComponent} />
          <Text>{t('Clear')}</Text>
        </Box>
      </Box>
    </Box>
    <Box className={styles.inputs}>
      <Textarea value={cnPrompt} className={styles.area} resize='none' maxLength={500} placeholder={t('PlsInputPrompt')} onChange={e => onInput(e.target.value)} />
      <Text className={styles.length}>{cnPrompt.length}/{_MAX_LENGTH}</Text>
    </Box>
    {
      open && <CreationPromptOptimization
        originPrompt={cnPrompt}
        open={open}
        onClose={() => setOpen(false)}
        type={type}
        onOk={onInput}
      />
    }
  </Box>
}


interface NegativePromptProps {
  negativeCNPrompt: string
  onInput: (value: string) => void
}
export const NegativePrompt: FC<NegativePromptProps> = (props) => {
  const { negativeCNPrompt, onInput } = props;
  const { t } = useTranslation();

  return <Box className={styles.prompt}>
    <CreationTitleBox className={styles.title}>
      <CreationTitle title={t('creation.NegativePrompt')} />
    </CreationTitleBox>
    <Box className={styles.inputs}>
      <Textarea className={styles.area} value={negativeCNPrompt} resize='none' maxLength={500} placeholder={t('creation.PlsEnterNegativePrompt')} onChange={e => onInput(e.target.value)} />
      <Text className={styles.length}>{negativeCNPrompt.length}/{_MAX_LENGTH}</Text>
    </Box>
  </Box>
}