import { Box, Flex, Icon, Image, Text, } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { userStore } from "@/store/user";
import { useMessage } from "@/hooks/useMessage";
import { toolStore } from "@/store/tool";
import { HdrestorationListType, ToolSchema } from "@/@types/tools";
import styles from "./index.module.scss"
import { ScrollBarBox } from "@/components/ScrollBox";
import { TranslationTitle } from "@/pages/tools/components/ComTarnsTitle";
import { ComFile } from "../Components/ComFile";
import { CButton } from "@/components/Button";
import { ImgsHistory } from "../Components/history";
import { ComComboBox } from "@/pages/tools/components/ComBoBox";
import AmplificationHD from "../Components/amplificationHD";
import axios from "axios";
import { getHdrestoration } from "@/api/tools";
import { useQuery } from "@tanstack/react-query";
import { creationStore } from "@/store/creation";
import { isDesktop } from "@/utils/electron";
import { useTranslation } from "react-i18next";

export enum HdrestorationModelEnum {
  UltraSharp = '4x-UltraSharp',
  ESRGAN4x = 'ESRGAN_4x',
  RESRGAN4x = 'R-ESRGAN 4x+',
  RESRGAN4xAnime6B = 'R-ESRGAN 4x+ Anime6B',
}

export const Enlarge: FC = observer(() => {
  const [tool, SetTool] = useState<ToolSchema>()
  const [selectmodel, SetSelectModel] = useState<any>()
  const { getToolByLink } = toolStore;
  const { userInfo } = userStore;
  const message = useMessage();
  const [chooseFile, setChooseFile] = useState<File>()
  const [list, setList] = useState<HdrestorationListType[]>([])
  const { t } = useTranslation();
  const modelDesc = [
    {
      name: 'R-ESRGAN 4x+ Anime6B',
      text: t('creation.PhotoFixModel'),
      desc: 'R-ESRGAN 4x+ Anime68:' + t('creation.PhotoFixModelDesc'),
    },
    {
      name: '4x-UltraSharp',
      text: t('creation.PublicFixModel'),
      desc: '4x-UltraSharp:' + t('creation.PublicFixModelDesc'),
    },
    {
      name: 'ESRGAN_4x',
      text: t('creation.RealFixModel'),
      desc: 'ESRGAN 4x (Real ESRGAN) : ' + t('creation.RealFixModelDesc'),
    },
    {
      name: 'R-ESRGAN 4x+',
      text: t('creation.ESRGANFixMode'),
      desc: 'R-ESRGAN 4x+:' + t('creation.ESRGANFixModeDesc'),
    }
  ]
  useEffect(() => {
    const fetchData = async () => {
      const data = await getToolByLink({ toollink: 'amplificationHD' });
      SetTool(data[0]);
      const res = await getHdrestoration('image')
      setList(res || [])
    };
    fetchData();
  }, []);
  const [isHovered, setIsHovered] = useState(false);
  const uploadFile = async (file: File) => {
    if (!selectmodel) { return }
    let matchingItem: any;
    if (selectmodel && !selectmodel.name) {
      matchingItem = modelDesc.find(item => item.text === selectmodel);
    }
    // 创建FormData对象
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('type', 'image');
    formData.append('model', selectmodel.name ? selectmodel.name : matchingItem.name as any);
    formData.append('toolid', tool ? tool._id : '');
    // formData.append('scale', String(params.scale));
    try {
      // 发送POST请求
      const response = await axios.post(
        (isDesktop() ? (window.proxyApi || '') : '') + '/api/videore/hdrestoration/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent: any) => {
            if (progressEvent && progressEvent.total && progressEvent.total > 0) {
              const perCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              console.log('Upload progress:', perCompleted);
              // 在这里可以更新上传进度的显示
            }
          }
        }
      );
      setChooseFile(undefined)
      // setUploading(false)
      document.getElementById('chooseFile')?.setAttribute('src', '')
      if (response.data?.code == 200) {
        await getMyImgs()
      } else if (response.data?.message.indexOf('余额不足') > -1) {
        message.warning({ title: t('creation.ImgEnlargFaild') })
      }
    } catch (err) {
      setChooseFile(undefined)
      document.getElementById('chooseFile')?.setAttribute('src', '')
      // setUploading(false)
      console.log(err)
    }
  }
  useQuery(['refreshList'], () => { getMyImgs(); return null }, {
    refetchInterval: 10000,
  })
  const getMyImgs = async () => {
    // if (uploading) return
    try {
      const res = await getHdrestoration('image')
      setList(res || [])
    } catch (err) {
      console.log(err)
    }
  }

  const startGenerate = () => {
    if (!userInfo?.monVip && (userInfo?.balance && userInfo?.balance < 1)) {
      message.warning({ title: t('PlsRecharge') })
      return;
    }
    if (chooseFile) {
      uploadFile(chooseFile)
    }
  }

  const { showNav } = creationStore.publicStore

  return (
    <Flex key={'amplificationHD'} w={!showNav ? '100%' : 'calc(100% - 340px)'} className={styles.toollistBox}>
      <Flex className={styles.listBox}>
        <TranslationTitle
          icon={tool ? tool.icon.toString() : ''}
          title={tool ? tool.name.toString() : ''}
          historytitle='language'
        />
        <Box className={styles.contentsBox}>
          <ScrollBarBox>
            <Box className={styles.imageBox}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}>
              <Box className={`${styles.fade} ${isHovered ? styles.visible : styles.hidden}`}>
                <AmplificationHD />
              </Box>
              <Box className={`${styles.fade} ${isHovered ? styles.hidden : styles.visible}`}>
                <Image className={styles.image} src={require('@/assets/png/amplificationHD.png')} />
              </Box>
            </Box>
            <Flex>
              <ComFile
                title={t('creation.UploadUWantEnlargImg')}
                // prompt={'如果您的图片小于2048x2048像素，我们会自动放大它，使得最长边达到2048像素，并保持图片的原始比例。这样，您的图片既能以高分辨率展示，又不会变形'}
                prompt={t('creation.EnlargUploadImgDesc')}
                chooseFile={chooseFile}
                setChooseFile={setChooseFile}
              />
            </Flex>
            <Box>
              <ComComboBox
                selectLanguage={selectmodel}
                title={t('SelectModel')}
                langs={modelDesc}
                SetSelectLanguage={(value: any) => SetSelectModel(value)} />
            </Box>
          </ScrollBarBox>
        </Box>
        <Flex className={styles.footbutton}>
          {userInfo?.monVip ?
            <CButton className={styles.vipbtn} theme='primary' onClick={() => { if (chooseFile) { startGenerate() } }}>
              <Box className={styles.vipprice}>
                <Icon as={require('@/assets/svg/navisVip.svg').ReactComponent} />
                <Text>{t('creation.FreeDrawing')}</Text>
              </Box>
            </CButton>
            :
            <CButton bg={chooseFile ? '#565CFA' : 'rgba(153,153,153,0.5)'}
              className={styles.button} onClick={() => { if (chooseFile) { startGenerate() } }}>
              <Text>{t('creation.StartDrawing')} 20</Text>
              <Icon as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
            </CButton>
          }
        </Flex>
      </Flex>
      <Flex className={styles.previewBox}>
        <ImgsHistory
          key={tool?.name.toString()}
          history={list}
          type={true}
        />
      </Flex>
    </Flex>
  )
})