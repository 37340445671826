import { Box, Icon, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect, useState } from 'react';
import i18n from '@/utils/i18n';
import Agent from '../Agent';
import { observer } from 'mobx-react-lite';
import { chatStore } from '@/store/chat';
import { userStore } from '@/store/user';

import styles from './index.module.scss'



const AIWelcome: FC = () => {
  const AICase = [
    { icon: '1', text: i18n.t('welcome.ai.case1') },
    { icon: '2', text: i18n.t('welcome.ai.case2') },
    { icon: '3', text: i18n.t('welcome.ai.case3') },
    { icon: '4', text: i18n.t('welcome.ai.case4') },
    { icon: '5', text: i18n.t('welcome.ai.case5') },
    { icon: '6', text: i18n.t('welcome.ai.case6') },
    { icon: '7', text: i18n.t('welcome.ai.case7') },
    { icon: '8', text: i18n.t('welcome.ai.case8') },
    { icon: '9', text: i18n.t('welcome.ai.case9') },
    { icon: '10', text: i18n.t('welcome.ai.case10') },
    { icon: '11', text: i18n.t('welcome.ai.case11') },
    { icon: '12', text: i18n.t('welcome.ai.case12') },
    { icon: '8', text: i18n.t('welcome.ai.case13') },
    { icon: '4', text: i18n.t('welcome.ai.case14') },
    { icon: '6', text: i18n.t('welcome.ai.case15') },
    { icon: '10', text: i18n.t('welcome.ai.case16') },
    { icon: '8', text: i18n.t('welcome.ai.case17') },
    { icon: '9', text: i18n.t('welcome.ai.case18') },
    { icon: '1', text: i18n.t('welcome.ai.case19') },
    { icon: '12', text: i18n.t('welcome.ai.case20') },
    { icon: '8', text: i18n.t('welcome.ai.case21') },
    { icon: '3', text: i18n.t('welcome.ai.case22') },
    { icon: '6', text: i18n.t('welcome.ai.case23') },
    { icon: '9', text: i18n.t('welcome.ai.case24') },
    { icon: '5', text: i18n.t('welcome.ai.case25') },
    { icon: '11', text: i18n.t('welcome.ai.case26') },
    { icon: '10', text: i18n.t('welcome.ai.case27') },
    { icon: '9', text: i18n.t('welcome.ai.case28') },
    { icon: '11', text: i18n.t('welcome.ai.case29') },
    { icon: '4', text: i18n.t('welcome.ai.case30') },
    { icon: '1', text: i18n.t('welcome.ai.case31') },
  ]
  const { t } = useTranslation();
  const { setOtherStartQuestion } = chatStore;
  const { userInfo } = userStore
  const [list, setList] = useState<Array<typeof AICase[0]>>([]);

  useEffect(() => {
    randomList();
    // eslint-disable-next-line
  }, [])

  const randomList = useCallback(() => {
    // 根据数组长度生成四个数，且不重复
    const numbers = new Set();
    const _List = [];
    while (numbers.size < 4) {
      const randomNumber = Math.floor(Math.random() * AICase.length); // 假设生成的数字范围是0到
      if (numbers.has(randomNumber)) continue;
      numbers.add(randomNumber);
      _List.push(AICase[randomNumber]);
    }
    setList(_List);
  }, [])


  return <Box className={styles.welcome} id='welcome'>
    <WelcomeText
      title={t('welcome.ai.title')}
      desc={t('welcome.ai.desc')}
    />
    <Box className={styles.cases}>
      {
        list.map((item, index) => {
          return <Box key={index} className={styles.case} onClick={() => setOtherStartQuestion(item.text)}>
            <Icon className={styles.icon} as={require(`@/assets/svg/case_${item.icon}.svg`).ReactComponent} />
            <Text className={styles.text}>{item.text}</Text>
          </Box>
        })
      }
      <Box className={styles.change} onClick={randomList}>
        <Icon className={styles.icon} as={require('@/assets/svg/case_change.svg').ReactComponent} />
        <Text>{t('change')}</Text>
      </Box>
    </Box>
    {
      userInfo && <Agent />
    }
  </Box >
};

const PluginWelcome: FC = () => {
  const PluginBottomCase = [
    { icon: '9', text: i18n.t('welcome.plugin.case1') },
    { icon: '4', text: i18n.t('welcome.plugin.case2') },
    { icon: '1', text: i18n.t('welcome.plugin.case3') },
    { icon: '5', text: i18n.t('welcome.plugin.case4') },
    { icon: '6', text: i18n.t('welcome.plugin.case5') },
    { icon: '2', text: i18n.t('welcome.plugin.case6') },
    { icon: '3', text: i18n.t('welcome.plugin.case7') },
    // { icon: '4', text: i18n.t('welcome.plugin.case8') },
    { icon: '7', text: i18n.t('welcome.plugin.case9') },
    { icon: '8', text: i18n.t('welcome.plugin.case10') },
    { icon: '10', text: i18n.t('welcome.plugin.case11') },
    { icon: '10', text: i18n.t('welcome.plugin.case12') },
    { icon: '6', text: i18n.t('welcome.plugin.case13') },
    { icon: '3', text: i18n.t('welcome.plugin.case14') },
  ]
  const { t } = useTranslation();
  const { getPluginSupportFuncs, supportFuncs, setOtherStartQuestion, setTextareaValue } = chatStore;
  const [list, setList] = useState<Array<typeof PluginBottomCase[0]>>([]);


  useEffect(() => {
    getSupportFunction();
    randomList();
    // eslint-disable-next-line
  }, [])

  const randomList = useCallback(() => {
    // 根据数组长度生成四个数，且不重复
    const numbers = new Set();
    const _List = [];
    while (numbers.size < 4) {
      const randomNumber = Math.floor(Math.random() * PluginBottomCase.length); // 假设生成的数字范围是0到
      if (numbers.has(randomNumber)) continue;
      numbers.add(randomNumber);
      _List.push(PluginBottomCase[randomNumber]);
    }
    setList(_List);
  }, [])

  const clickFunc = (title: string) => {
    const textarea = document.getElementById('inputAreaDom') as HTMLDivElement;
    if (!textarea) return
    setTextareaValue(title);
    textarea.innerText = title + ':';
  }

  const getSupportFunction = () => {
    getPluginSupportFuncs()
  }

  return <Box className={styles.welcome} id='pluginWelcome'>
    <WelcomeText
      title={t('welcome.plugin.title')}
      desc={t('welcome.plugin.desc')}
    />
    <Box className={styles.pluginCases}>
      {
        supportFuncs.map((item, index) => {
          return <Box key={index} className={styles.item} onClick={() => clickFunc(item.title)}>
            <Box className={styles.icon} dangerouslySetInnerHTML={{ __html: item.svg }} />
            <Text className={styles.text}>{item.title}</Text>
          </Box>
        })
      }
    </Box>
    <Box className={styles.pluginBottomCase} id='pluginBottomCase'>
      <Box className={styles.list}>
        {
          list.map((item, index) => {
            return <Box key={index} className={styles.item} onClick={() => setOtherStartQuestion(item.text)}>
              <Icon className={styles.icon} as={require(`@/assets/svg/case_${item.icon}.svg`).ReactComponent} />
              <Text className={styles.text}>{item.text}</Text>
            </Box>
          })
        }
      </Box>
      <Box className={styles.change} onClick={randomList}>
        <Icon className={styles.icon} as={require('@/assets/svg/case_change.svg').ReactComponent} />
        <Text>{t('change')}</Text>
      </Box>
    </Box>
  </Box>
}

interface WelcomeTextProps {
  title: string;
  desc: string;
}
const WelcomeText: FC<WelcomeTextProps> = (props) => {
  const { title, desc } = props
  return <Box>
    <Text className={styles.title}>Hello!</Text>
    <Text className={styles.title}>{title}</Text>
    <Text className={styles.desc}>{desc}</Text>
  </Box>
}

export const Welcome = {
  AI: observer(AIWelcome),
  Plugin: observer(PluginWelcome)
};