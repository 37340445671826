
import { LocalDB } from ".";

class CreationDB extends LocalDB {
  getStyleConfig(key: string): Promise<StyleConfig[] | null> {
    return this.db.getItem(key)
  }

  setStyleConfig(key: string, config: StyleConfig[]) {
    this.db.setItem(key, config)
  }

  getDalle3History(): Promise<DrawDallE3Type[] | null> {
    return this.db.getItem('dalle3')
  }

  setDalle3History(history: DrawDallE3Type[]) {
    this.db.setItem('dalle3', history)
  }

}

export const creationDb = new CreationDB('creation');