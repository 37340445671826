import { Flex, Box, Text, ChakraProvider, Progress, Icon } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import styles from "./index.module.scss";
import { useMessage } from "@/hooks/useMessage";
import { CButton } from "@/components/Button";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { ScrollBarBox } from "@/components/ScrollBox";
import { ProgressBar } from "@/components/ProgressBar";
import { toolStore } from "@/store/tool";
import { uiStrore } from "@/store/ui";


export type ExportChatType = 'md' | 'pdf' | 'html' | 'word';
export const TranslationHistory: FC<any> = observer(({
  historyCache,
  type,
  setHistoryCache,
  setViewhistory,
  bindprompt,
  onDelete,
  historytitle,
  historycontent
}: {
  historyCache: any[];
  type: string,
  setHistoryCache: (data: any) => void;
  setViewhistory: (...args: any[]) => void;
  bindprompt: any;
  onDelete: (id: string) => void;
  historytitle?: string;
  historycontent?: string;
  isVIP?: boolean
}) => {
  const { getTranslations } = toolStore;
  const updateHistoryCache = async () => {
    const hisdata = await getTranslations({ types: type });
    setHistoryCache(hisdata);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const itemWithStatusOne = sortedList.find(item => item.status === 1);
      if (itemWithStatusOne) {
        updateHistoryCache();
      }
    }, 3000);

    return () => clearInterval(interval); // 清理定时器
  }, []);
  const message = useMessage();
  const { openAlert, closeAlert } = uiStrore;
  let parentField = '';
  let childField = '';
  if (historytitle && historytitle.includes('.')) {
    const fields = historytitle.split('.');
    parentField = fields[0];
    childField = fields[1];
  } else {
    parentField = historytitle!
  }

  const truncateAfterSecondDash = (inputString: any) => {
    const firstDashIndex = inputString.indexOf('-');
    if (firstDashIndex !== -1) {
      const secondDashIndex = inputString.indexOf('-', firstDashIndex + 1);
      if (secondDashIndex !== -1) {
        return inputString.substring(0, secondDashIndex);
      }
    }
    return inputString;
  };


  const sortedList = historyCache.slice().sort((a, b) => new Date(b.createdate).getTime() - new Date(a.createdate).getTime());
  return (
    <Flex className={styles.hisFlex}>
      <Box className={styles.centerItemsbox}>
        <Text className={styles.title}>生成历史</Text>
      </Box>
      <ScrollBarBox className={styles.hisBox}>
        {sortedList.map((item, index) => {
          const statusDesc = (() => {
            switch (item.status.toString()) {
              case '0':
                return '待开始';
              case '8':
                return '排队中';
              case '1':
                return '生成中';
              case '2':
                return '已完成';
              case '3':
                return '生成失败';
              default:
                return '未知状态';
            }
          })();
          const statusBorder = (() => {
            switch (item.status.toString()) {
              case '0':
              case '8':
              case '1':
                return '1px solid rgba(255,149,26,0.5)';
              case '2':
                return '1px solid rgba(104,159,56,0.5)';
              case '3':
                return '1px solid rgba(247,101,96,0.5)';
              default:
                return '1px solid rgba(255,149,26,0.5)';
            }
          })();
          const statusColor = (() => {
            switch (item.status.toString()) {
              case '0':
              case '8':
              case '1':
                return '#FF951A';
              case '2':
                return '#689F38';
              case '3':
                return '#F76560';
              default:
                return '#FF951A';
            }
          })();
          const statusBg = (() => {
            switch (item.status.toString()) {
              case '0':
              case '8':
              case '1':
                return '#FEF7EF';
              case '2':
                return '#F9FEEF';
              case '3':
                return '#FEF0EF';
              default:
                return '#FEF7EF';
            }
          })();
          const subtitleType = (() => {
            switch (item.extends.des_subtitle_type.toString()) {
              case '0':
                return '单语言'
              case '1':
              case '2':
                return '双语言'
              default:
                return ''
            }
          })();
          const handleClick = async () => {
            const dateString = item.createdate.slice(0, 10);
            const type = item.extends.des_file.slice(-4);
            try {
              const response = await fetch(item.extends.des_file);
              if (!response.ok) throw new Error('Network response was not ok');
              const blob = await response.blob();
              const fileName = `${item.language}翻译${dateString}${type}`
              const anyWindow: any = window;
              const options = {
                suggestedName: `${item.language}翻译${dateString}${type}`, // 建议的文件名
                types: [{
                  description: 'Download File',
                  accept: { '*/*': ['.txt', '.srt'] }
                }],
              };
              if (anyWindow.showSaveFilePicker) {
                // @ts-ignore
                const handle = await window.showSaveFilePicker(options);
                const writable = await (handle as any).createWritable();
                await writable.write(blob);
                await writable.close();
              } else {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                setTimeout(() => {
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);
                }, 0);
              }
              message.success({ title: '保存成功' })
            } catch (err) {
              message.warning({ title: '取消导出' })
            }
          };
          return (
            <Flex key={item._id} className={styles.historyValue} cursor={item.status === 2 ? 'pointer' : ''}
              onClick={() => { if (item.status !== 3) { setViewhistory(item); } }}>
              {item.status === 1 && <Flex className={styles.prohibitBox} onClick={(e) => e.stopPropagation()}></Flex>}
              <Flex className={styles.historyValuebox}>
                <Box className={styles.hisTitleBox}>
                  <Text>
                    {/* {item.extends.level === 'VIP' ? 'VIP翻译 - ' : ''}
                    {historytitle ? (childField.length > 0 ? item[parentField][childField] : item[parentField]) : item.template}
                    {item.extends.file_ex === 'srt' ? ` - ${subtitleType}` : ''} */}
                    {item.filename}
                  </Text>
                  <Flex color={statusColor} border={statusBorder} bg={statusBg}>
                    {statusDesc}
                  </Flex>
                </Box>
                <Box className={styles.hisTitleBox}>
                  {historytitle ? (childField.length > 0 ? item[parentField][childField] : item[parentField]) : item.template}
                  {item.extends.file_ex === 'srt' ? ` - ${subtitleType}` : ''}
                </Box>
                <Text className={styles.hisContentsBox}>
                  {item.status === 3 ? item.extends.error : historycontent ? item[historycontent] : item.contents}
                </Text>
                {item.status === 1 && <ProgressBar value={item.extends.process ? item.extends.process : '0'} />}
                <Flex className={styles.hisDateBox}>
                  <Flex>
                    <Icon w={'18px'} height={'18px'} as={require(`@/assets/svg/datetime.svg`).ReactComponent} className={styles.icon} />
                    <Text>
                      {item.begin_time ? (
                        item.begin_time.toLocaleString()
                          .replace('Z', '')
                          .replace('T', ' ')
                          .substring(0, 16)
                      ) : (
                        ''
                      )}
                    </Text>
                  </Flex>
                  <Flex mb={'3px'}>
                    <Icon w={'18px'} height={'18px'} pt={'6px'} as={require(`@/assets/svg/imgmodel.svg`).ReactComponent} className={styles.icon} />
                    <Text>{truncateAfterSecondDash(item.extends.model)}</Text>
                  </Flex>
                  <Flex>
                    <Icon w={'15px'} height={'15px'} pt={'3px'} as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
                    <Text>{item.extends.price}</Text>
                  </Flex>
                  <Flex >
                    {item.status === 2 && (
                      <Icon w={'22px'} height={'22px'} ml={'10px'} onClick={(e) => { e.stopPropagation(); handleClick() }} as={require('@/assets/svg/toolsicon/download.svg').ReactComponent} />
                    )}
                    <Icon w={'22px'} height={'22px'} ml={'10px'} onClick={(e) => {
                      e.stopPropagation();
                      openAlert({
                        title: '提示',
                        content: '请确认是否要删除这条记录？',
                        onOk: () => {
                          closeAlert();
                          onDelete(item._id)
                        }
                      })
                    }} as={require('@/assets/svg/toolsicon/delete.svg').ReactComponent} />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )
        })}
      </ScrollBarBox>
    </Flex>
  );
})

