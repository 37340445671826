import { Box } from "@chakra-ui/react";
import { FC } from "react";

import styles from './index.module.scss'


const Content: FC<Children> = (props) => {
  return <Box className={styles.creationContent}>
    {props.children}
  </Box>
}

export const Inputs: FC<Children> = (props) => {
  return <Box className={styles.inputParams}>
    {props.children}
  </Box>
}

export const History: FC<Children> = (props) => {
  return <Box className={styles.history}>
    {props.children}
  </Box>
}

export const CreationContent = {
  Inputs,
  History,
  Content
}