import { Box, Icon } from "@chakra-ui/react"
import { FC } from "react"
import AgentsNav from "./components/Nav"
import AgentsContent from "./components/Content"
import { WiseImage } from "@/components/Image"
import { observer } from "mobx-react-lite"
import { agentsStore } from "@/store/agents"

import styles from './index.module.scss'

const Agents: FC = observer(() => {
  const { showNav, setShowNav } = agentsStore;

  return <Box className={styles.agents}>
    <AgentsNav />
    <Box className={`${styles.nav}  ${!showNav ? styles.hide : ''}`} onClick={() => setShowNav(!showNav)}>
      <Box className={styles.navBox}>
        <WiseImage className={styles.navOp} src={require('@/assets/png/nav_op_bg.png')} />
        <Icon className={styles.navOpIcon} as={require('@/assets/svg/right.svg').ReactComponent} />
      </Box>
    </Box>
    <AgentsContent />
  </Box>
})

export default Agents