import React, { useEffect, useState } from 'react';
import styles from './index.module.scss'
import { ScrollBarBox } from '../ScrollBox';
import { Box, Icon } from '@chakra-ui/react';

const DatePicker = (
  { range, setRange, onClose }
    : { range: any, setRange: any, onClose: () => void }
) => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  // const [range, setRange] = useState<[Date | null, Date | null]>([null, null]); // [start, end]
  const [view, setView] = useState('date'); // 'year', 'date'
  const months = [
    '01月', '02月', '03月', '04月', '05月', '06月',
    '07月', '08月', '09月', '10月', '11月', '12月'
  ];

  const changeMonth = (increment: number) => {
    let newMonth = currentMonth + increment;
    let newYear = currentYear;

    if (newMonth > 11) {
      newMonth = 0;
      newYear++;
    } else if (newMonth < 0) {
      newMonth = 11;
      newYear--;
    }

    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
  };

  const toggleView = () => {
    setView(view === 'date' ? 'year' : 'date');
  };

  const selectDate = (day: number, month: number, year: number) => {
    const selectedDate = new Date(year, month, day);
    if (!range[0] || (range[0] && range[1])) {
      setRange([selectedDate, null]);
    } else {
      const [start] = range;
      if (start && selectedDate < start) {
        setRange([selectedDate, start]);
      } else {
        setRange([start, selectedDate]);
      }
    }
  };

  // useEffect(() => {
  //   console.log('选择时间发生改变', range);
  // }, [range]);

  const renderDays = (month: number, year: number) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayIndex = new Date(year, month, 1).getDay();
    const days = [];
    const isSameDay = (date1: Date, date2: Date): boolean => {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    };

    for (let i = 0; i < firstDayIndex; i++) {
      days.push(<div key={'empty-' + i} className={styles.empty}></div>);
    }
    for (let i = 1; i <= daysInMonth; i++) {
      const dayDate = new Date(year, month, i);
      const isSelected = range[0] && isSameDay(dayDate, range[0]);
      const isEnd = range[1] && isSameDay(dayDate, range[1]);
      const isInRange = range[0] && range[1] &&
        (dayDate >= new Date(range[0].setHours(0, 0, 0, 0)) &&
          dayDate <= new Date(range[1].setHours(0, 0, 0, 0)));
      days.push(
        <div
          key={i}
          // className={`${styles.day} ${isSelected ? `${styles.selected}` : ''} ${isInRange ? `${styles.in_range}` : ''}`}
          className={`${styles.day} 
            ${isSelected ? styles.selected + " " + styles.start : ''} 
            ${isEnd ? styles.selected + " " + styles.end : ''} 
            ${isInRange && !isSelected && !isEnd ? styles.in_range : ''}`}
          onClick={() => { selectDate(i, month, year) }}
        >
          {i}
        </div>
      );
    }
    return days;
  };

  const renderMonths = () => {
    const monthElements = [];
    for (let i = 0; i < 12; i++) {
      monthElements.push(
        <div key={i} className={styles.month_container}>
          <span>{currentYear}年{months[i]}</span>
          <div className={styles.days}>{renderDays(i, currentYear)}</div>
        </div>
      );
    }
    return monthElements;
  };

  const renderYears = () => {
    const nowYear = new Date().getFullYear();
    const startYear = nowYear - 12;
    const years = Array.from({ length: 25 }, (_, i) => startYear + i);
    return years.map((year) => (
      <div
        key={year}
        className={`${styles.year} ${year === currentYear ? styles.current : ''}`}
        onClick={() => { setCurrentYear(year); setView('date'); }}
      >
        {year}
      </div>
    ));
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Box onClick={toggleView}>
          <span>选择日期</span>
          {view === 'date' ?
            <Icon as={require(`@/assets/svg/datePicker.svg`).ReactComponent} /> :
            <Icon as={require(`@/assets/svg/datePicker.svg`).ReactComponent} transform='rotate(180deg)' />
          }
        </Box>
        <Icon className={styles.closebut} as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={onClose} />
      </div>
      <div className={styles.weekdays}>
        {['日', '一', '二', '三', '四', '五', '六'].map(day => (
          <div key={day} className={styles.weekday}>{day}</div>
        ))}
      </div>
      <ScrollBarBox className={styles.calendar}>
        <ScrollBarBox className={styles.months}>
          {renderMonths()}
        </ScrollBarBox>
        {view !== 'date' && (
          <ScrollBarBox className={styles.years}>{renderYears()}</ScrollBarBox>
        )}
      </ScrollBarBox>
    </div>
  );
};

export default DatePicker;