import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import { ComComboBox } from "../components/ComBoBox";
import { ComButton } from "../components/ComButton";
import { userStore } from "@/store/user";
import { useMessage } from "@/hooks/useMessage";
import { LanguageEnum, toolStore, languageArray } from "@/store/tool";
import { TranslationTitle } from "../components/ComTarnsTitle";
import { TranslationTextarea } from "../components/ComTarnsTextarea";
import { ToolSchema, TranslationSchema } from "@/@types/tools";
import styles from "./index.module.scss"
import { TranslationHistory } from "../components/ComTarnsHistory";
import { ScrollBarBox } from "@/components/ScrollBox";
import { personalStore } from "@/store/personal";
import { readPdfContents } from "@/utils/file";
import axios from "axios";

const zimulist = [
  { text: '双语言-目标语言在上（推荐）', value: 1 },
  { text: '双语言-目标语言在下', value: 2 },
  { text: '单语言', value: 0 }
]
const _Models = [
  { name: '文思GPT 3.5-16k', key: 'gpt-3.5-turbo-1106', tips: '(速度快，但生成质量一般)', tag: '限时免费' },
  { name: '文思GPT 4o-128k', key: 'gpt-4o', tips: '(生成质量好、理解能力优秀)', price: '200' }
]

export const Langtranslations: FC = observer(() => {

  const [tool, SetTool] = useState<ToolSchema>()
  const [language, Setlanguage] = useState('中文(Chinese)')
  const [lang_key, SetLang_key] = useState('chi_sim')
  const [beforelanguage, SetBeforelanguage] = useState('自动识别')
  const [beforelang_key, SetBeforeLang_key] = useState('chi_sim')
  const [historyCache, setHistoryCache] = useState<TranslationSchema[]>([]);
  const [choosesModel, setChoosesModel] = useState(false);
  const [modelkey, setModelsKey] = useState(_Models[1])
  const [filename, SetFilmname] = useState('');
  const [title, SetTitle] = useState('');
  const { userInfo } = userStore;
  const { handleSuccess } = personalStore;
  const { postCreatTranslations, deleteTranslations, getTranslations, getToolByLink } = toolStore;
  // const languageArray = Object.entries(LanguageEnum).map(([value, text]) => {
  //   return { text, value };
  // });
  const bindGenPrompt = useRef<HTMLTextAreaElement>(null);
  const [textarerabind, Settextareabind] = useState('');
  const [loading, setLoading] = useState(false);
  const [finishdata, Setfinishdata] = useState('');
  const message = useMessage();
  const [process, SetProcess] = useState(false);

  const extendedLanguageArray = [
    { text: '自动识别', value: 'CN', lang_key: 'chi_sim' }, // 注意 value 和 lang_key 的值选择
    ...languageArray
  ];

  useEffect(() => {
    const fetchData = async () => {
      const data = await getToolByLink({ toollink: 'langtranslations' });
      SetTool(data[0]);
      const hisdata = await getTranslations({ types: 'txt' });
      setHistoryCache(hisdata);
    };
    fetchData();
  }, []);
  useEffect(() => {
    let intervalId: any = null;
    const fetchData = async () => {
      const res = await getTranslations({ types: 'txt' })
      setHistoryCache(res);
      const hasStatusOne = res.some(item => (item.status === 1 || item.status === 8));
      SetProcess(hasStatusOne);
    };
    if (process) {
      intervalId = setInterval(() => {
        fetchData();
      }, 3000);
    }
    if (!process) {
      clearInterval(intervalId);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [process]);
  const [isDisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    const checkIfDisabled = () => {
      // 检查 textarerabind 是否为空或者长度小于等于 1
      if (!textarerabind || textarerabind.length <= 1) {
        setIsDisabled(true);
        return;
      }
      // 去除文本中的换行符和空格
      const text_clean = textarerabind.replace(/[ \n]/g, "");
      // 检查去除空格和换行后的文本长度是否小于等于 0
      if (text_clean.length <= 0) {
        setIsDisabled(true);
        return;
      }
      setIsDisabled(false);
    };
    checkIfDisabled();
  }, [textarerabind]);
  // 中断请求
  const controller = useRef(new AbortController());
  const handleGPTGenInfo = async () => {
    if (bindGenPrompt.current !== null) {
      bindGenPrompt.current.value = '';
    }
    if (!textarerabind || textarerabind.length <= 1) {
      message.warning({ title: '请先上传正确的文件' })
      setLoading(false);
      return;
    }
    const text_clean = textarerabind.replace(/[ \n]/g, "");
    if (text_clean.length <= 0) {
      message.warning({ title: '内容为空，请重新上传文档！' })
      // onOpenPayModal()
      setLoading(false);
      return;
    }
    if (userInfo && !userInfo.monVip && userInfo.balance <= 0) {
      message.warning({ title: '由于余额不足, <任务模块>已经停止，请充值后再试。' })
      // onOpenPayModal()
      setLoading(false);
      return;
    }
    setLoading(true);
    const des_lange = extendedLanguageArray.find(item => item.text === beforelanguage)?.lang_key || 'chi_sim';
    const abortSignal = new AbortController();
    controller.current = abortSignal;
    // 流请求，获取数据
    try {
      const res = await postCreatTranslations({
        language: language,
        title: title,
        filename: filename,
        type: "file",
        model: modelkey.key,
        des_subtitle_type: 0,
        level: modelkey.key === 'gpt-4o' ? 'VIP' : 'com',
        template: `translate this into ${language}`,
        tooltype: tool ? tool._id : '',
        lang_key: des_lange,
        des_lange: lang_key
      });
      const hisres = await getTranslations({ types: 'txt' })
      setHistoryCache(hisres);
      if (res) {
        message.success({ title: '开始翻译' })
        handleSuccess();
        SetProcess(true);
      }
    } catch (err: any) {
      if (!(err == 'The user aborted a request.'
        || err == 'BodyStreamBuffer was aborted'
        || err == 'Fetch is aborted')) {
        message.warning({ title: typeof err === 'string' ? err : err?.message || '出错了~' })
      }
      if (err.message && err.message.includes('不足')) {
        // onOpenPayModal()
      }
      setLoading(false);
    }
    setLoading(false);
  };
  const setViewhistory = async (item: any) => {
    Setlanguage(item.language);
    const matched = zimulist?.find((zimu: any) => zimu.value === item.extends.des_subtitle_type);
    // SetIsVIP(item.extends.level === 'VIP' ? 'VIP翻译' : '普通翻译')
    if (item.extends.file_ex === 'pdf') {
      console.log(item.extends.des_file, item.extends.src_lange);
      const text = await readPdfContents(item.extends.des_file, item.extends.src_lange);
      Setfinishdata(text);
    } else {
      const res = await axios.get(item.extends.des_file)
      Setfinishdata(res.data)
    }
  };
  return (
    <Flex key={'langtranslations'} className={styles.toollistBox}>
      <Flex className={styles.listBox}>
        <TranslationTitle
          key={tool?.name.toString()}
          color="black"
          backgroundColor="white"
          setViewhistory={setViewhistory}
          setHistoryCache={setHistoryCache}
          icon={tool ? tool.icon.toString() : ''}
          title={tool ? tool.name.toString() : ''}
          historytitle='language'
          historycontent='title'
          historyCache={historyCache}
          bindprompt={bindGenPrompt}
          onDelete={async (id: string) => {
            await deleteTranslations({ _id: id, types: 'file' });
            const data = await getTranslations({ types: 'txt' })
            setHistoryCache(data);
            if (bindGenPrompt.current !== null) {
              bindGenPrompt.current.value = '';
            }
          }}
        />
        <ScrollBarBox display={'flex'} flexDir={'column'} height={'100%'}>
          <Box>
            <ComComboBox
              selectLanguage={beforelanguage}
              title={'请选择翻译前的语言*'}
              langs={extendedLanguageArray}
              setlang_key={SetBeforeLang_key}
              SetSelectLanguage={(value: any) => SetBeforelanguage(value)}
            />
          </Box>
          <Box >
            <ComComboBox
              selectLanguage={language}
              title={'请选择翻译后的语言*'}
              langs={languageArray}
              setlang_key={SetLang_key}
              SetSelectLanguage={(value: any) => Setlanguage(value)}
            />
          </Box>
          <Box flex={1}>
            <TranslationTextarea
              title={'上传翻译文件*'}
              prompt={'请输入您想要翻译的内容（支持任何语言）'}
              textvalue={textarerabind}
              textchange={(value: any) => Settextareabind(value)}
              maxCount={99999}
              rows={20}
              SetFilmname={SetFilmname}
              SetTitle={SetTitle}
              finishdata={finishdata}
              fileExtension={'.txt,.pdf,.log'}
              beforelang_key={beforelang_key}
            />
          </Box>
        </ScrollBarBox>
        <Flex className={styles.footbutton}>
          <ComButton
            loading={loading}
            setLoading={setLoading}
            casebut={'查看实例'}
            startbut={'开始翻译'}
            stopbut={'停止'}
            stopClick={() => { controller.current?.abort() }}
            startClick={handleGPTGenInfo}
            caseStop={true}
            isnull={isDisabled}
            chooseModel={choosesModel}
            setChooseModel={setChoosesModel}
            modelkey={modelkey}
            setModelsKey={setModelsKey}
            _Models={_Models}
          ></ComButton>
        </Flex>
      </Flex>
      <Flex className={styles.previewBox}>
        <TranslationHistory
          key={tool?.name.toString()}
          type={'txt'}
          setViewhistory={setViewhistory}
          setHistoryCache={setHistoryCache}
          historytitle='language'
          historycontent='title'
          historyCache={historyCache}
          bindprompt={bindGenPrompt}
          onDelete={async (id: string) => {
            await deleteTranslations({ _id: id, types: 'txt' });
            const data = await getTranslations({ types: 'txt' })
            setHistoryCache(data);
            message.success({ title: '删除成功！' })
          }} />
      </Flex>
    </Flex>
  )
})