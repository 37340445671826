import { Box, Flex, Icon, Text, Image } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss"
import { useSelectFile } from "@/hooks/useSelectFile";
import axios from "axios";
import heic2any from 'heic2any';
import { useMessage } from "@/hooks/useMessage";
import { useTranslation } from "react-i18next";

const allowedTypes = [
  'image/jpeg',
  'image/png',
  'image/heic',
  'image/bmp',
  'image/webp'
];
function getTextWidth(text: any, font: any) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d') as any;
  context.font = font;
  return context.measureText(text).width;
}
export function truncateText(filename: any, maxWidth = 190 * 2, font = '16px Arial') {
  const endLength = 3;
  const ellipsis = '...';
  let endPart = filename.slice(-endLength);
  let frontPart = filename;
  const ellipsisWidth = getTextWidth(ellipsis, font);
  const endPartWidth = getTextWidth(endPart, font);

  while (frontPart.length > 0) {
    const currentWidth = getTextWidth(frontPart, font);

    if (currentWidth + ellipsisWidth + endPartWidth <= maxWidth) {
      break;
    }

    frontPart = frontPart.slice(0, -1); // 去掉最后一个字符
  }

  return frontPart + ellipsis + endPart;
}

export const ComFile: FC<any> = observer(({
  title,
  prompt,
  textvalue,
  textchange,
  maxCount,
  rows,
  finishdata,
  required,
  chooseFile,
  setChooseFile,
}: {
  title: string;
  prompt: string;
  textvalue: string;
  textchange: (e: string) => void;
  maxCount: number;
  rows: number;
  finishdata: string,
  chooseFile: File;
  setChooseFile: (name: any) => void;
  required?: boolean;
}) => {

  const message = useMessage();
  const [uploadError, setUploadError] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { File: FileElement, onOpen: onOpenSelectFile } = useSelectFile({
    fileType: '.jpeg,.jpg,.png,.heic,.bmp,.webp',
    multiple: false
  });

  const onSelectFile = useCallback(async (e: File[]) => {
    setLoading(true);
    try {
      const file = e[0];
      if (!file) return;
      if (!allowedTypes.includes(file.type)) {
        message.warning({ title: t('PlsUploadCorrectImg') })
        return;
      }
      let blob = file;

      if (file.type === 'image/heic' || file.type === 'image/heif') {
        let convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
        if (Array.isArray(convertedBlob)) {
          convertedBlob = convertedBlob[0];
        }
        const newFileName = `${file.name.split('.')[0]}.heic`;
        blob = new File([convertedBlob], newFileName, {
          type: 'image/heic',
          lastModified: Date.now(), // 或使用file.lastModified保留原时间
        });
      }

      let img = document.createElement('img')
      img.src = URL.createObjectURL(blob)
      img.onload = () => {
        const { naturalWidth, naturalHeight } = img;
        if (naturalWidth >= 2048 || naturalHeight >= 2048) {
          const title = naturalHeight >= 2048 ? t('height') : t('width');
          message.warning({ title: `${t('Picture')}[${file.name}] ${t('of')}${title} ${t('creation.2048Max')}` })
          // 这里可以选择清空选择的文件
          setChooseFile(null);
          return;
        }
      };
      document.getElementById('chooseFile')?.setAttribute('src', URL.createObjectURL(blob))
      setChooseFile(blob);
      setLoading(false)
    } catch (e) {
      setUploadError(t('UploadFailed'));
      console.log(e);
      setLoading(false)
    }
  }, [])

  let parts = [''];
  if (required === true) {
    if (!title.includes('*')) {
      title = title + '*';
      parts = title.split('*');
    } else {
      parts = title.split('*');
    }
  } else {
    parts = title.split('*');
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (finishdata && finishdata.length > 1) {
          setChooseFile(undefined)
          const res = await axios.get(finishdata)
          textchange(res.data);
        }
      } catch (e) {
        console.error(e);
      }
    }
    fetchData()
  }, [finishdata]);
  return (
    <Flex className={styles.allbox}>
      <Box className={styles.titlebox}>
        {parts.map((part: any, index: any) => (
          <Text className={styles.title} key={index}>
            {part}{index < parts.length - 1 ? <span className={styles.titletips}>*</span> : null}
          </Text>
        ))}
      </Box>
      {chooseFile ? (
        <Box className={styles.uploadBoxx} >
          {loading ?
            <Box className={styles.loader}></Box>
            :
            <Image src={URL.createObjectURL(chooseFile)} />
          }
          <Box>
            <Text className={styles.textWithEllipsis}>{truncateText(chooseFile.name)}</Text>
            {!loading &&
              <Flex className={styles.SucessBox}>
                <Icon as={require('@/assets/svg/hdrSucess.svg').ReactComponent} />
                <Text className={styles.uploadSucess}>{t('UploadSuccess')}</Text>
              </Flex>
            }
          </Box>
          <Icon className={styles.uploadBoxicon} onClick={() => { setUploadError(''); setChooseFile(undefined) }}
            as={require(`@/assets/svg/personalClosed.svg`).ReactComponent} />
        </Box>
      ) : (uploadError ?
        <Box className={styles.uploadBoxx}>
          <Flex className={styles.defaultbox}>
            <Icon as={require('@/assets/svg/errorVideo.svg').ReactComponent} className={styles.uploadIcon} />
            <Text className={styles.errplusIcon} >{t('UploadFailed')}</Text>
          </Flex>
          <Flex className={styles.SucessBox}>
            <Icon className={styles.errsvg} as={require('@/assets/svg/toolFileRegenerate.svg').ReactComponent} />
            <Text className={styles.againText} onClick={() => { onOpenSelectFile() }}>{t('ReUpload')}</Text>
          </Flex>
          <Icon className={styles.uploadBoxicon} onClick={() => { setUploadError(''); setChooseFile(undefined) }} as={require(`@/assets/svg/personalClosed.svg`).ReactComponent} />
        </Box>
        :
        <Box className={styles.uploadBox} onClick={onOpenSelectFile}>
          <input
            type="file"
            accept=".jpeg,.jpg,.png,.heic,.bmp,.webp"
            onChange={onOpenSelectFile}
            className={styles.fileUploadInput}
            id="file-input"
          />
          <Icon as={require('@/assets/svg/imageFile.svg').ReactComponent} className={styles.uploadIcon} />
          <Flex className={styles.plusIcon} >{t('UploadImg')}</Flex>
          <Flex className={styles.ulbox}>
            <Text>{t('Support')} JPG、JPEG、PNG、WEBP、BMP、HEIC、HEIF</Text>
            <Text>{prompt}</Text>
          </Flex>
        </Box>
      )}
      <FileElement onSelect={onSelectFile} />
    </Flex>
  );
})