
import { LocalDB } from ".";

class ChatDB extends LocalDB {
  getHistories(): Promise<UseHistoryObject | null> {
    return this.db.getItem('histories')
  }
  setHistories(histories: UseHistoryObject) {
    this.db.setItem('histories', histories)
  }

  setChatInfo(chatInfo: any) {
    this.db.setItem('chatInfo', chatInfo)
  }
  getChatInfo(): Promise<any> {
    return this.db.getItem('chatInfo')
  }

  /** 设置智能体 */
  setAgents(agents: AgentsType[], mineAgents: MineAgentsType) {
    this.db.setItem('chatAgents', { agents, mineAgents })
  }

  /** 获取智能体 */
  getAgents(): Promise<{ agents: AgentsType[], mineAgents: MineAgentsType } | null> {
    return this.db.getItem('chatAgents')
  }

  /** 设置模型列表 */
  setModels(models: Record<string, ModelsForGroup>) {
    this.db.setItem('chooseModel', models)
  }

  /** 获取模型列表 */
  getModels(): Promise<Record<string, ModelsForGroup> | null> {
    return this.db.getItem('chooseModel')
  }

  /** 存储AI对话信息 */
  setAIChatInfo(chatInfo: AIChatInfoResult) {
    this.db.setItem(chatInfo.chatId!, chatInfo)
  }

  /** 获取AI对话信息 */
  getAIChatInfo(chatId: string): Promise<AIChatInfoResult | null> {
    return this.db.getItem(chatId)
  }

  setSupportFuncs(funcs: { title: string, svg: string }[]) {
    this.db.setItem('supportFuncs', funcs)
  }

  getSupportFuncs(): Promise<{ title: string, svg: string }[] | null> {
    return this.db.getItem('supportFuncs')
  }

  setPluginChatInfo(chatInfo: PluginChatInfo) {
    this.db.setItem(chatInfo.conversation_id, chatInfo);
  }

  getPluginChatInfo(conversation_id: string): Promise<PluginChatInfo | null> {
    return this.db.getItem(conversation_id);
  }
}

export const chatDb = new ChatDB('chat');