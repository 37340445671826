import { Box, Icon, Text } from "@chakra-ui/react"
import { FC, useEffect, useRef, useState } from "react"
import { ObjectId } from "@/utils/chat"
import { userStore } from "@/store/user"
import { WiseImage } from "@/components/Image"
import { getWechatScanResult } from "@/api/user"
import { globalStore } from "@/store/global"
import { useMessage } from "@/hooks/useMessage"
import { useLocation } from "react-router-dom"

import styles from './index.module.scss'

let timer: NodeJS.Timer;
interface LoginByWxProps {
  onAgreen: (callback?: () => void) => void
  onToBindPhone: () => void
  isAgree?: boolean
}
let _isAgree = false;
export const LoginByWx: FC<LoginByWxProps> = (props) => {
  const { onAgreen, isAgree, onToBindPhone } = props;

  const [wxQrcode, setWxQrcode] = useState('');
  const randonKey = useRef(new ObjectId().toString());
  const [geting, setGeting] = useState(false);
  const expiredTime = useRef(0); // 二维码过期时间 最大为5分钟
  const [isExpired, setExpired] = useState(false); // 二维码是否过期
  const { getWxQrCode, setUserInfo, setWxUnbindUserInfo } = userStore;
  const { setLoginIsOpen, loginSuccess } = globalStore;
  const message = useMessage();
  const location = useLocation();

  useEffect(() => {
    _isAgree = isAgree || false;
  }, [isAgree])

  useEffect(() => {
    getCodeUrl();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (wxQrcode) {
      timer = setInterval(() => {
        getCodeScanResult()
        expiredTime.current += 1;
        if (expiredTime.current >= 300) {
          clearInterval(timer);
          setExpired(true);
        }
      }, 1000)
      return () => {
        clearInterval(timer);
        expiredTime.current = 0;
      }
    }
    // eslint-disable-next-line
  }, [wxQrcode])

  const getCodeUrl = async () => {
    if (geting) return;
    try {
      setGeting(true);
      setExpired(false)
      const res = await getWxQrCode(randonKey.current);
      expiredTime.current = 0;
      setWxQrcode(res);
      setGeting(false);
    } catch (e) {
      setGeting(false);
    }
  }

  const getCodeScanResult = async () => {
    try {
      const res = await getWechatScanResult(randonKey.current);
      if (res === 'scanWait') {
      } else if (res._id) {
        const isPhone = /^1[3-9][0-9]{9}$/.test(res.username);
        if (isPhone) {
          const loginFn = () => {
            setUserInfo(res);
            setLoginIsOpen(false);
            loginSuccess(location.pathname);
            message.success({ title: '登录成功' })
          }
          if (!_isAgree) {
            onAgreen(loginFn);
          } else {
            loginFn();
          }
        } else {
          setWxUnbindUserInfo(res);
          if (!_isAgree) {
            onAgreen(() => {
              onToBindPhone();
            })
          } else {
            onToBindPhone();
          }
        }
        clearInterval(timer);
        expiredTime.current = 0;
      }
    } catch (e) {
      console.log(e)
    }
  }

  return <Box className={styles.wx}>
    <Box className={styles.wximg}>
      {
        wxQrcode ? <WiseImage src={wxQrcode} className={styles.wxQrcode} />
          : <Icon className={styles.wxLogo} as={require('@/assets/svg/wx.svg').ReactComponent} />
      }
      {
        isExpired && <Box className={styles.expired} onClick={getCodeUrl}>
          <Icon className={`${styles.loading} ${geting ? styles.ing : ''}`} as={require('@/assets/svg/refresh.svg').ReactComponent} />
          <Text>二维码已失效，请刷新</Text>
        </Box>
      }
    </Box>
    <Text className={styles.loginTips}>
      <Icon className={`${styles.loading} ${geting ? styles.ing : ''}`} as={require('@/assets/svg/wx_circle.svg').ReactComponent} />
      请使用微信扫码登录
    </Text>
  </Box>
}