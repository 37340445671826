import { Box, Icon, Text } from "@chakra-ui/react";
// import "@xkit-yx/im-kit-ui/es/conversation/style/css";

// import 'antd/lib/dropdown/style/index.css';
// import 'antd/lib/menu/style/css';
// import 'antd/lib/button/style/css';
// import './antDefault.css';
import './conversation.css'
import styles from './index.module.scss'
import { AddContainer, ContactListContainer, ConversationContainer, NimKitCoreTypes, useEventTracking, useStateContext } from "../components";
import { ScrollBarBox } from "@/components/ScrollBox";
import { observer } from "mobx-react-lite";
import { imStore } from "@/store/im";
import { IMSearch } from "../components/IMSearch";
import SearchModal, { SectionListItem } from "../components/search/search/components/SearchModal";
import { useEffect, useState } from "react";
import packageJson from '../components/package.json';
import { Team, TMsgScene } from "@xkit-yx/im-store";
import { useTranslation } from "react-i18next";

export const IMContact: React.FC = observer(() => {
  const { type, setType, setUnread, teamIgnores } = imStore;
  const [visible, setVisible] = useState(false);
  const { nim, store, initOptions } = useStateContext();
  const [locaChatlUnreat, setLocalChatUnread] = useState(0);
  const { t } = useTranslation();
  const contactTypes: { type: ContactType, text: string }[] = [{ type: 'chat', text: t('Chat') }, { type: 'contacts', text: t('Contacts') }];

  useEventTracking({
    appkey: initOptions.appkey,
    version: packageJson.version,
    component: 'SearchUIKit',
    imVersion: nim.version,
  })

  useEffect(() => {
    return () => {
      store.uiStore.selectSession('');
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let teamMuteCount = 0;
    if (teamIgnores.size > 0) {
      teamIgnores.forEach((value, key) => {
        if (value) {
          const teamInfo = store.sessionStore.sessions.get(`team-${key}`);
          teamMuteCount = teamMuteCount + (teamInfo?.unread || 0);
        }
      })
    }
    setLocalChatUnread(store.uiStore.sessionUnread - teamMuteCount);
    setUnread(store.uiStore.sessionUnread + store.sysMsgStore.unreadSysMsgCount - teamMuteCount);
    // eslint-disable-next-line 
  }, [store.uiStore.sessionUnread, store.sysMsgStore.unreadSysMsgCount, teamIgnores])

  const handleChat = async (item: SectionListItem) => {
    let scene: TMsgScene
    let to = ''
    if ((item as NimKitCoreTypes.IFriendInfo).account) {
      scene = 'p2p'
      to = (item as NimKitCoreTypes.IFriendInfo).account
    } else if ((item as Team).teamId) {
      scene = 'team'
      to = (item as Team).teamId
    } else {
      throw Error('unknow scene')
    }

    await store.sessionStore.insertSessionActive(scene, to)
    setVisible(false)
    setType('chat')
  }

  return <Box className={styles.contact}>
    <Box className={styles.search}>
      <Box className={styles.searchInput} onClick={() => setVisible(true)}>
        <IMSearch disabled />
      </Box>
      <AddContainer />
    </Box>
    <Box className={styles.type}>
      {
        contactTypes.map(item => {
          return <Box key={item.type} onClick={() => setType(item.type)} className={`${styles.typeItem} ${type === item.type ? styles.active : ''}`}>
            <Text className={styles.text}>
              {item.text}
              {
                item.type === 'chat' && locaChatlUnreat > 0 && <Box className={styles.unread}></Box>
              }
              {
                item.type === 'contacts' && store.sysMsgStore.unreadSysMsgCount > 0 && <Box className={styles.unread}></Box>
              }
            </Text>
            <Icon className={styles.icon} as={require('@/assets/svg/chat_header_active.svg').ReactComponent} />
          </Box>
        })
      }
    </Box>
    <IMChats hidden={type === 'contacts'} />
    <IMContacts hidden={type === 'chat'} />
    <SearchModal
      visible={visible}
      friends={store.uiStore.friendsWithoutBlacklist}
      teams={store.uiStore.teamList}
      onCancel={() => setVisible(false)}
      prefix={'search'}
      onResultItemClick={handleChat}
      commonPrefix={'common'}
    />
  </Box>;
})

interface IMChatsProps {
  hidden?: boolean
}
const IMChats: React.FC<IMChatsProps> = (props) => {
  const { hidden } = props;
  return <Box className={`${styles.chatList} ${hidden ? styles.hidden : ''}`}>
    <ScrollBarBox>
      <ConversationContainer onSessionItemClick={e => console.log(e)} />
    </ScrollBarBox>
  </Box>;
}

interface IMContactsProps {
  hidden?: boolean
}
const IMContacts: React.FC<IMContactsProps> = (props) => {
  const { hidden } = props;
  return <Box className={`${styles.contactList} ${hidden ? styles.hidden : ''}`}>
    <ContactListContainer />
  </Box>
}