import { Box, Flex, Text, Image } from "@chakra-ui/react";
import { FC } from "react";
import styles from './index.module.scss';
import { useTranslation } from "react-i18next";
import { uiStrore } from "@/store/ui";
import { useMessage } from "@/hooks/useMessage";
import { CButton } from "@/components/Button";

export const ContactUs: FC = () => {
  const { t } = useTranslation();
  const contactlist = [
    {
      image: 'https://cachefile.wisetalker.cn/file/config/wxjlq.jpeg',
      title: t('contactUs.title1'),
      content: t('contactUs.content1')
    },
    {
      image: 'https://cachefile.wisetalker.cn/file/config/kfewm.jpeg',
      title: t('contactUs.title2'),
      content: t('contactUs.content2'),
      Customer: 'wsyyai666'
    },
    {
      image: 'https://cachefile.wisetalker.cn/file/config/gzh.jpeg',
      title: t('contactUs.title3'),
      content: t('contactUs.content3'),
      account: '文思逸言'
    }
  ]
  const message = useMessage();
  const { openAlert, closeAlert } = uiStrore;
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      message.success({ title: '复制成功' })
    } catch (err) {
      message.error({ title: '复制失败' })
    }
  };
  return (
    <Box className={styles.parent}>
      <Flex className={styles.child}>
        {contactlist.map((item, index) => {
          return (
            <Flex className={styles.listbox} key={index}>
              <Flex className={styles.contentsbox}>
                <Text className={styles.titletext}>{item.title}</Text>
                <Text className={styles.contenttext}>{item.content}</Text>
              </Flex>
              <Flex className={styles.imagetextbox}>
                <Box className={styles.imagebox} onClick={() => {
                  openAlert({
                    title: item.title,
                    onclose: closeAlert,
                    footer: <Box className={styles.detailbox}>
                      <Image className={styles.detailimg} src={item.image} />
                      {item.account && (
                        <Flex className={styles.copybox}>
                          <Text>微信公众号：<span> {item.account}</span></Text>
                          <CButton className={styles.copybut} onClick={() => copyToClipboard(item.account)}>复制</CButton>
                        </Flex>
                      )}
                      {item.Customer && (
                        <Flex className={styles.copybox}>
                          <Text>客服微信号：<span> {item.Customer}</span></Text>
                          <CButton className={styles.copybut} onClick={() => copyToClipboard(item.Customer)}>复制</CButton>
                        </Flex>
                      )}
                    </Box>
                  })
                }}>
                  <Image className={styles.image} src={item.image} />
                </Box>
                <Text className={styles.imagetext} onClick={() => {
                  openAlert({
                    title: item.title,
                    onclose: closeAlert,
                    footer: <Box className={styles.detailbox}>
                      <Image className={styles.detailimg} src={item.image} />
                      {item.account && (
                        <Flex className={styles.copybox}>
                          <Text>微信公众号：<span> {item.account}</span></Text>
                          <CButton className={styles.copybut} onClick={() => copyToClipboard(item.account)}>复制</CButton>
                        </Flex>
                      )}
                      {item.Customer && (
                        <Flex className={styles.copybox}>
                          <Text>客服微信号：<span> {item.Customer}</span></Text>
                          <CButton className={styles.copybut} onClick={() => copyToClipboard(item.Customer)}>复制</CButton>
                        </Flex>
                      )}
                    </Box>
                  })
                }}>{t('Click_view')}</Text>
              </Flex>
            </Flex>
          )
        })}
      </Flex>
    </Box>
  )
}