import { Box } from "@chakra-ui/react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { CButton } from "@/components/Button";
import { WiseSelect } from "@/components/WiseSelect";
import { Option } from "@mui/base";
import { observer } from "mobx-react-lite";
import { uiStrore } from "@/store/ui";

import styles from './index.module.scss'


const Lang: FC = (props) => {
  const { t } = useTranslation();
  const { language, setLanguage } = uiStrore;
  const [value, setValue] = useState(language)
  const confirmChange = () => {
    setLanguage(value)
  }
  return <Box className={styles.lang}>
    <Box className={styles.box}>
      <Box className={styles.title}>{t('lang')}</Box>
      <WiseSelect defaultValue={language}
        onChange={value => { setValue(value) }}
      >
        <Option key='zh' value='zh'>中文</Option>
        <Option key='en' value='en'>English</Option>
      </WiseSelect>
      <Box className={styles.btn} onClick={confirmChange}>
        <CButton disabled={language === value} theme='primary'>{t('changLang')}</CButton>
      </Box>
    </Box>
  </Box>
}

export default observer(Lang)