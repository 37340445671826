import draw from './draw.json'
import chat from './chat.json'
import personal from './personal.json'
import tips from './tips.json'
import tool from './tool.json'

const zh = {
  ...draw,
  ...chat,
  ...personal,
  ...tips,
  ...tool
}

export default zh