import { AudioSchema, HdrestorationListType, InitChatResponse, MarkmapSchema, ModelListGroupResponse, NamingSchema, TarotSchema, ToolhistorySchema, ToolSchema, TranslationSchema, VideoSummaryModelSchema, WeChatAISchema, WriteModelSchema } from "@/@types/tools";
import { POST, GET, DELETE } from "./request";

export type VipType = 'novel' | 'video' | 'wxrobot' | ''

export interface VipPriceResult {
  expand: {
    price: {
      discount: string
      payPrice: number
      price: number
      month: number
    }[]
  }
  subType: VipType
  type: 'vipPay'
}

export const fetchAllTools = () => POST<ToolSchema[]>(`/user/tool/alllist`);

// 创建对应工具的历史记录
export const fetchCreateToolHistory = ({
  toolsid,
  newChatId,
  toolsnanme,
  userinput,
  improve,
  contents,
  model,
  template
}: {
  toolsid: string,
  newChatId: string,
  toolsnanme: string,
  userinput: string,
  improve: string,
  contents: string,
  model: any,
  template: string,
}) => POST<ToolhistorySchema>(`/user/toolshistory/create`, {
  toolsid,
  newChatId,
  toolsnanme,
  userinput,
  improve,
  contents,
  model,
  template
});

export const fetchAllToolsHistory = ({ _id }: { _id: string }) => POST<ToolhistorySchema[]>(`/user/toolshistory/list`, {
  _id
});

export const fetchDeleteToolsHistory = ({ _id }: { _id: string }) =>
  POST<ToolhistorySchema[]>(`/user/toolshistory/delete`, {
    _id
  });

/** 生成翻译任务 */
export const fetchCreatTranslations = ({
  language,
  title,
  filename,
  type,
  model,
  des_subtitle_type,
  level,
  template,
  tooltype,
  lang_key,
  des_lange
}: {
  language: string;
  title: string;
  filename: string;
  type: string;
  model: string;
  des_subtitle_type: number;
  level: string;
  template: string;
  tooltype: string;
  lang_key: string;
  des_lange: string;
}) =>
  POST<TranslationSchema>('/user/translations/create', {
    language,
    title,
    filename,
    type,
    model,
    des_subtitle_type,
    level,
    template,
    tooltype,
    lang_key,
    des_lange
  });

/* 获取所有翻译任务*/
export const fetchGetTranslations = ({ types }: { types: string }) =>
  POST<TranslationSchema[]>(`/user/translations/list`, {
    types
  });

/* 通过link获取对应的工具 */
export const fetchGetToolByLink = ({ toollink }: { toollink: string }) => POST<ToolSchema[]>(`/user/tool/queryOne`, {
  toollink
});

/* 删除翻译任务*/
export const fetchDeleteTranslations = ({ _id, types }: { _id: string, types: string }) =>
  POST<TranslationSchema[]>(`/user/translations/delete`, {
    _id,
    types
  });

/*
  上传图片
*/
export const uploadImg = (base64Img: string) => POST<string>('/system/uploadImage', { base64Img });

export const fetchcreateVideoSummary = (url: string, toolid: string, model: string) => POST<VideoSummaryModelSchema>('/tools/videoSummary/urlParsing', { url, toolid, model });

export const fetchVideoSummaryList = () => GET<VideoSummaryModelSchema[]>('/tools/videoSummary/list')

/**
 * 获取初始化聊天内容
 */
export const getInitChatSiteInfo = (modelId: '' | string, chatId: '' | string) =>
  GET<InitChatResponse>(`/chat/init?modelId=${modelId}&chatId=${chatId}`);

//清空聊天
export const deleteVideoSummaryChat = (id: string) => DELETE(`/tools/videoSummary/deleteChat?id=${id}`)

export const getVideoSummaryDetail = (id: string) => GET<VideoSummaryModelSchema>('/tools/videoSummary/detail', { id })

export const getUrltoVideo = (url: string) => GET<any>('/tools/videoSummary/urltoVideo', { url })

export const deleteVideoSummary = (id: string) => GET<VideoSummaryModelSchema>('/tools/videoSummary/delete', { id })

/** 生成微信聊天机器人 */
export const postCreatWeChatAI = ({
  chat_reply_prefix,
  chat_prefix,
  private_chat_prefix,
  group_name_white_list,
  character_desc,
  group_welcome_msg,
  chatgpt_key,
  personality_setting,
  isReGen,
  toolid
}: {
  chat_reply_prefix: string;
  chat_prefix: string;
  private_chat_prefix: string;
  group_name_white_list: string;
  character_desc: string;
  group_welcome_msg: string;
  chatgpt_key: string;
  personality_setting: string;  //1自定义，2选择应用
  isReGen: boolean;
  toolid: string;
}) =>
  POST<WeChatAISchema>('/user/wxChatAI/create', {
    chat_reply_prefix,
    chat_prefix,
    private_chat_prefix,
    group_name_white_list,
    character_desc,
    group_welcome_msg,
    chatgpt_key,
    personality_setting,
    isReGen,
    toolid
  });

/** 生成微信聊天机器人 */
export const postSignOutWeChatAI = ({
  isReGen,
  toolid
}: {
  isReGen: boolean;
  toolid: string;
}) =>
  POST<WeChatAISchema>('/user/wxChatAI/singnOut', {
    isReGen,
    toolid
  });

/* 获取微信聊天机器人*/
export const getWeChatAI = () => POST<WeChatAISchema[]>(`/user/wxChatAI/list`);

export const createPayVipOrder = (type: VipType, month: number) => POST<{ user: UserResult }>('/user/vip/pay', { type, month })

export const getVipPrice = (type: VipType) => GET<VipPriceResult>('/user/vip/price', { type });

export const saveWrite = (data: { _id?: string, title?: string, content?: string, toolid: string }) =>
  POST<{ _id: string }>('/tools/writingAssistant/update', data);


export const getWrites = (data: { isCollection?: boolean, pageNum: number, pageSize: number }) =>
  POST<{ list: WriteModelSchema[], total: number }>(`/tools/writingAssistant/list`, { ...data })

export const getCollWrites = () => POST<{ list: WriteModelSchema[] }>(`/tools/writingAssistant/collectionlist`)

export const updateWriteCollection = (_id: string) => POST<WriteModelSchema>('/tools/writingAssistant/collection', { _id })
export const deleteWrite = (_id: string) => DELETE<WriteModelSchema>('/tools/writingAssistant/delete?_id=' + _id)
export const getHdrestoration = (type: 'video' | 'image' | 'removebg') => POST<HdrestorationListType[]>(`/videore/hdrestoration/list`, { type });

/**
 * 获取共享市场模型
 */
export const getShareModelsForGroup = (group: string) =>
  GET<ModelListGroupResponse>(`/model/grouplist?group=${group}`);

/* 获取思维导图的任务列表， 单次获取全部 */
export const getTextMarkmap = () => POST<MarkmapSchema[]>(`/user/markmap/list`);

/** 请求创建一个思维导图的任务 */
export const postCreateMarkmap = ({
  demand,
  text,
  effectmap,
  model,
  template,
  toolid
}: {
  demand: string;
  text: string;
  effectmap: any;
  model: any;
  template: string;
  toolid: string;
}) =>
  POST<MarkmapSchema>('/user/markmap/create', {
    demand,
    text,
    effectmap,
    model,
    template,
    toolid
  });

/* 删除一条思维导图的任务 */
export const deleteTextMarkmap = ({ _id }: { _id: string }) =>
  POST<MarkmapSchema[]>(`/user/markmap/delete`, {
    _id
  });


/** 请求创建一个塔罗牌占卜的任务 */
export const postCreateTarot = ({
  problem,
  parameters,
  content,
  model,
  template,
  toolid
}: {
  problem: string;
  parameters: any;
  content: string;
  model: any;
  template: string;
  toolid: string
}) =>
  POST<TarotSchema>('/user/tarot/create', {
    problem,
    parameters,
    content,
    model,
    template,
    toolid
  });

/* 获取生成塔罗牌占卜的任务列表， 单次获取全部 */
export const getTarot = () => POST<TarotSchema[]>(`/user/tarot/list`);

/* 删除一个塔罗牌占卜的任务 */
export const deleteTarot = ({ _id }: { _id: string }) =>
  POST<TarotSchema[]>(`/user/tarot/delete`, {
    _id
  });

/* 获取视频总结支持的平台 */
export const getVideoTab = () => POST<any[]>(`/user/tool/getVideoTab`);

/* 获取对应的历史记录 */
export const gettoolhis = ({ _id }: { _id: string }) => POST<any>(`/user/toolshistory/querytoolhis`, {
  _id
});
/* 获取塔罗牌对应的历史记录 */
export const getTarothis = ({ _id }: { _id: string }) => POST<any>(`/user/tarot/querytoolhis`, {
  _id
});
/* 获取思维导图最新的一条历史记录 */
export const getMarkMaphis = () => POST<any>(`/user/markmap/querytoolhis`);

/** 请求创建一个塔罗牌占卜的任务 */
export const postCreateNaming = ({
  year,
  month,
  day,
  hour,
}: {
  year: number;
  month: number;
  day: number;
  hour: number;
}) =>
  POST<any>('/user/naming/create', {
    year,
    month,
    day,
    hour,
  });

/* 获取生成塔罗牌占卜的任务列表， 单次获取全部 */
export const getNaming = () => POST<NamingSchema[]>(`/user/naming/list`);

/* 删除一个塔罗牌占卜的任务 */
export const deleteNaming = ({ _id }: { _id: string }) =>
  POST<NamingSchema[]>(`/user/naming/delete`, {
    _id
  });

/* 获取文本转音频列表， 单次获取全部 */
export const getAllAudios = () => POST<any>(`/system/getConfig`);

/* 获取文本转音频列表， 单次获取全部 */
export const getTextAudios = () =>
  POST<any>(`/user/audio/list`, { pageNum: 1, pageSize: 99 });

/* 删除一个文本转音频 */
export const deleteTextAudio = ({ _id }: { _id: string }) =>
  POST<AudioSchema[]>(`/user/audio/delete`, {
    _id
  });

/** 请求创建一个文本转音频任务 */
export const postCreateTextAudio = ({
  speaks,
  defaultLangeuage,
  extends: { fileFormat },
  toolid,
  modeltype,
  quality
}: {
  speaks: any;
  defaultLangeuage: string;
  extends: {
    fileFormat: string;
  };
  toolid: string;
  modeltype: string;
  quality: string;
}) =>
  POST<AudioSchema>('/user/audio/create', {
    speaks,
    defaultLangeuage,
    extends: {
      fileFormat
    },
    toolid,
    modeltype,
    quality
  });
