import { GET, POST } from "./request"

//获取点数记录
export const fetchBills = (params: RequestPaging) =>
  POST<FecthBillsResult>('/user/getBill', params)

//获取消息记录
export const fetchInforms = (data: RequestPaging) =>
  POST<FecthInformResult>(`/user/inform/list`, data);

// 获取邀请好友的记录
export const fetchPromotionData = () =>
  GET<PromotionData>('/user/promotion/getPromotionData')

//邀请好友的佣金记录
export const fetchPromotions = (params: Partial<QueryChatAgents>) =>
  GET<FecthPromotionsResult>('/user/promotion/getPromotions', params)

//获取充值记录
export const fetchPayOrders = (params: RequestPaging) =>
  POST<FecthPayOrdersResult>('/user/getPayOrders', params)
//获取充值记录
export const fetchVipPayOrders = (params: RequestPaging) =>
  POST<FecthPayOrdersResult>('/user/getVipPayOrders', params)

// 销毁账号，谨慎使用，因为是直接物理删除
export const destoryAccount = () => GET('/user/destoryAccount');

// 提交反馈信息
export const postFeedbacks = ({ contents, images, phone, type, info_array }: FeedbacksParams) => {
  return POST<{ user: UserResult, isRegister: boolean }>('/user/feedback/create', {
    contents,
    images,
    phone,
    type,
    info_array
  });
}

// 获取vip充值档位
export const getRechargeData = () =>
  GET<any>('/user/vip/price?type=vip');

//获取vip权益
export const getAndroidSystemVipConfig = () => GET<any>('/config/getAndroidSetting');

/* 查询二维码登录记录，查看登陆结果, 通过 */
export const queryQrcodeLogin = ({ key }: { key: string }) =>
  POST<WxLogin>('/user/qrcodelogin?type=query', {
    key
  });