import React from 'react'
import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export interface WelcomeProps {
  prefix?: string
}

export const Welcome: React.FC<WelcomeProps> = ({ prefix = 'common' }) => {
  const _prefix = `${prefix}-welcome`

  const { t } = useTranslation()

  return (
    <div className={`${_prefix}-wrap`}>
      <Box as={require('@/assets/svg/im_hi.svg').ReactComponent} />
      <span className={`${_prefix}-start`}>{t('StartChat')}</span>
    </div>
  )
}
