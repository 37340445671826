import { Box, Flex, Text, Icon, Image, Slider, SliderTrack, SliderFilledTrack, SliderThumb, VStack, Switch, Stack, FormControl, FormLabel, ChakraProvider } from "@chakra-ui/react";
import { FC, useState } from "react";
import styles from './index.module.scss';
import style from './components.module.scss';
import { useTranslation } from "react-i18next";
import { CSwitch } from "@/components/Switch";

export const Appearance: FC = () => {
  const { t } = useTranslation();
  const [fontSize, setFontSize] = useState(16);

  const SliderComponent = () => {
    const [fontSize, setFontSize] = useState(16);

    const handleSliderChange = (event: any) => {
      setFontSize(parseInt(event.target.value));
    };

    type TranslationKeys = 'standard' | 'point';
    const translations: Record<TranslationKeys, string> = {
      'standard': '标准',
      'point': '点'
    };

    const t = (key: TranslationKeys): string => {
      return translations[key];
    };

    return (
      <div className={style.contentlistbox}>
        <div className={style.slider_container}>
          <div className={style.slider_labels}>
            <span className={style.label}>A</span>
            <span className={style.label}>{t('standard')}</span>
            <span className={style.label}>A</span>
          </div>
          <input
            type="range"
            min="14"
            max="26"
            step="2"
            value={fontSize}
            onChange={handleSliderChange}
          />
          <div className={style.slider_marks}>
            {[14, 16, 18, 20, 22, 24, 26].map((value) => (
              <span
                key={value}
                className={style[`mark ${fontSize === value ? 'slider_value' : ''}`]}
              >
                {value}{t('point')}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  };
  return (
    <Box className={styles.parent}>
      <Box className={styles.child}>
        <Text className={styles.boxtext}>{t('Background_settings')}</Text>
        <Flex className={styles.contentbox}>
          <Box className={styles.item}>
            <Text className={styles.label}>{t('Follow_system')}</Text>
            <CSwitch />
          </Box>
          <Box className={styles.item}>
            <Text className={styles.label}>{t('Day_mode')}</Text>
            <CSwitch />
          </Box>
          <Box className={styles.item}>
            <Text className={styles.label}>{t('Dark_mode')}</Text>
            <CSwitch />
          </Box>
        </Flex>
        <Text className={styles.boxtext}>{t('Font_size')}</Text>
        <Flex className={styles.fontsizebox}>
          <Flex className={styles.usercontentflex}>
            <Box className={styles.usercontentbox}>
              <Text className={styles.usercontent}>{t('Example_one')}</Text>
            </Box>
            <Image className={styles.avatar} src={require('@/assets/logo.png')} />
          </Flex>
          <Flex>
            <Image className={styles.avatar} src={require('@/assets/logo.png')} />
            <Box className={styles.systemcontentbox}>
              <Text className={styles.systemcontent}>{t('Example_two')}</Text>
            </Box>
          </Flex>
          <Flex>
            <Image className={styles.avatar} src={require('@/assets/logo.png')} />
            <Box className={styles.systemcontentbox}>
              <Text className={styles.systemcontent}>{t('Example_three')}</Text>
            </Box>
          </Flex>
        </Flex>
        {/* <Flex className={styles.contentlistbox}>
          <Box className={styles.slider_container}>
            <Box className={styles.slider_labels}>
              <Text className={styles.label}>A</Text>
              <Text className={styles.label}>{t('standard')}</Text>
              <Text className={styles.label}>A</Text>
            </Box>
            <Slider
              defaultValue={16}
              min={14}
              max={26}
              step={2}
              onChange={(value) => setFontSize(value)}
            >
              <SliderTrack bg="gray.300">
                <SliderFilledTrack bg="blue.500" />
              </SliderTrack>
              <SliderThumb boxSize={6} bg="blue.500" />
            </Slider>
            <Box className={styles.slider_marks}>
              {[14, 16, 18, 20, 22, 24, 26].map((value) => (
                <Text
                  key={value}
                  className={`${styles.mark} ${fontSize === value ? styles.slider_value : ''}`}
                >
                  {value}{t('point')}
                </Text>
              ))}
            </Box>
          </Box>
        </Flex> */}
        <SliderComponent />
      </Box>
    </Box>
  )
}