import { Image, Box, Divider, Flex, Icon, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss"
import { toolStore } from "@/store/tool";
import { personalStore } from "@/store/personal";
import { ScrollBarBox } from "@/components/ScrollBox";
import PinyinMatch from 'pinyin-match';
import { userStore } from "@/store/user";
import { useTranslation } from "react-i18next";

interface HomeProps {
  selectTool: string
  selectedTag: string;
  setSelectedTag: (value: string) => void;
  SetSelectTool: (value: string) => void;
}

const icons = [
  {
    key: '收藏',
    icon: require('@/assets/svg/toolstag/collect.svg').ReactComponent,
    hovericon: require('@/assets/svg/toolstag/collecthover.svg').ReactComponent,
    selecticon: require('@/assets/svg/toolstag/collected.svg').ReactComponent
  },
  {
    key: '全部',
    icon: require('@/assets/svg/toolstag/all.svg').ReactComponent,
    hovericon: require('@/assets/svg/toolstag/allhover.svg').ReactComponent,
    selecticon: require('@/assets/svg/toolstag/alled.svg').ReactComponent
  },
  {
    key: '热门',
    icon: require('@/assets/svg/toolstag/hot.svg').ReactComponent,
    hovericon: require('@/assets/svg/toolstag/hothover.svg').ReactComponent,
    selecticon: require('@/assets/svg/toolstag/hoted.svg').ReactComponent
  }
];

export const ToolList: FC<HomeProps> = observer(({ selectedTag, setSelectedTag, selectTool, SetSelectTool }) => {
  const { tools, getToollist, toolShowNav } = toolStore;
  const { userInfo } = userStore;
  const { worktool, getAllWorktool } = personalStore
  const [collectall, setCollectAll] = useState<any>([]);
  const { t } = useTranslation();
  useEffect(() => {
    const fetchSignInDates = async () => {
      await getToollist();
      await getAllWorktool();
    }
    fetchSignInDates();
  }, [])
  useEffect(() => {
    const worktools = worktool as any;
    setCollectAll(worktools[0] ? worktools[0].extends : []);
  }, [worktool])
  const tagsSet = new Set();
  tools.forEach(item => {
    if (!item.tags) { return }
    item.tags.split('，').forEach(tag => {
      tag = tag.trim();
      if (tag) {
        tagsSet.add(tag);
      }
    });
  });
  const uniqueTags = Array.from(tagsSet);
  const finalTags: any[] = ['热门', '收藏', '全部', ...uniqueTags];
  const [searchValue, SetSearchValue] = useState('')
  const [hoverTag, setHoverTag] = useState(null);

  const toolsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectTool && toolsContainerRef.current) {
      const selectedToolElement = toolsContainerRef.current.querySelector(`.${styles.selecttoolBox}`);
      if (selectedToolElement) {
        selectedToolElement.scrollIntoView({ block: "center", behavior: "smooth" });
      } else {
        const firstToolElement = toolsContainerRef.current.querySelector(`.${styles.toolBox}`);
        if (firstToolElement) {
          firstToolElement.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }
    }
  }, [selectTool, selectedTag]);

  return (<Box className={`${styles.listbox} ${!toolShowNav ? styles.hide : ''}`}>
    <Flex className={styles.titlebox} onClick={() => SetSelectTool('')}>
      <Icon className={styles.titleicon} as={require('@/assets/svg/backtoolHome.svg').ReactComponent} />
      <Flex className={styles.titleinput}>返回工具首页</Flex>
    </Flex>
    <Flex className={`${styles.flexContainer} ${styles.flexContainerExpanded}`}>
      {finalTags.map((itme) => {
        const iconObj = icons.find(iconItem => iconItem.key === itme);
        const isSelected = selectedTag === itme;
        const isHovering = hoverTag === itme;
        let displayIcon;
        if (isSelected) {
          displayIcon = iconObj ? iconObj.hovericon : '';
        } else if (isHovering) {
          displayIcon = iconObj ? iconObj.hovericon : '';
        } else {
          displayIcon = iconObj ? iconObj.icon : '';
        }
        return (
          <Flex key={itme} className={`${styles.tagsbox} ${isSelected ? styles.selected : ''} `}
            onClick={() => setSelectedTag(itme)}
            onMouseEnter={() => setHoverTag(itme)}
            onMouseLeave={() => setHoverTag(null)}>
            {iconObj &&
              <Icon as={displayIcon} />
            }
            <Text className={styles.tagstext}>{itme}</Text>
          </Flex>
        )
      })}
    </Flex>
    <Flex className={styles.toolsBox}>
      <Text className={styles.toolsBoxtitle} >
        {selectedTag === '收藏' ? '我收藏的工具'
          : selectedTag === '热门' ? '热门工具排行榜'
            : `${selectedTag}工具`}</Text>
      <ScrollBarBox className={styles.toolslist}>
        <Flex className={styles.toolsbox} ref={toolsContainerRef}>
          {(() => {
            const filteredTools = tools.filter((item) => {
              const matchesTag = item.tags?.includes(selectedTag);
              const matchesPopular = selectedTag === "热门" &&
                item.assembly.Incomplete?.length > 1 &&
                item.assembly.Incomplete.includes("火爆");
              const matchesAll = selectedTag === "全部" && (
                item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                PinyinMatch.match(item.name.toLowerCase(), searchValue.toLowerCase()) ||
                (item.synopsis && PinyinMatch.match(item.synopsis.toLowerCase(), searchValue.toLowerCase())) ||
                (item.assembly.ToolIndex && PinyinMatch.match(item.assembly.ToolIndex.toLowerCase(), searchValue.toLowerCase()))
              );
              const matchesFavorite = selectedTag === "收藏" &&
                collectall?.some(
                  (collectItem: any) => collectItem.label === item.name && collectItem.status === 1
                );
              const isPublicOrWhiteListed = (item.assembly.status !== "不公开") ||
                (userInfo?.isWhiteList === 1);

              return (matchesTag || matchesPopular || matchesAll || matchesFavorite) && isPublicOrWhiteListed;
            });

            if (filteredTools.length === 0) {
              return (
                <Box className={styles.noResults}>
                  <Icon as={require(`@/assets/svg/toolsicon/nofound.svg`).ReactComponent} />
                  <Text>{t('NoCollectallTool')}</Text>
                </Box>
              );
            }

            return filteredTools.map((item) => (
              <Flex
                key={item.name}
                className={selectTool === item.assembly.link ? styles.selecttoolBox : styles.toolBox}
                onClick={() => { SetSelectTool(item.assembly.link); }}
              >
                <Image src={item.icon} className={styles.avatar} />
                <Box className={styles.contentbox}>
                  <Text className={styles.contName}>{item.name}</Text>
                  <Text className={styles.contSynopsis}>{item.synopsis}</Text>
                </Box>
              </Flex>
            ));
          })()}
        </Flex>
      </ScrollBarBox>
    </Flex>
  </Box>)
})