import { collectionImg, deleteShareImg, getShareImgs } from "@/api/creation";
import { makeAutoObservable } from "mobx";
import { CreationType } from ".";

export class CreationSquare {
  params: {
    pageNum: number;
    pageSize: number;
    filterType: SquareFilterType;
  } = { pageNum: 1, pageSize: 50, filterType: 'ALL' };
  list: CreationShareType[] = [];
  total = 0;
  isInit = false;
  urlList: string[] = [];
  favorites: Map<string, string[]> = new Map();
  isGetList = false;
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    console.log('CreationSquare constructor');
  }

  async getList() {
    const { pageNum, pageSize, filterType } = this.params;
    if (this.isGetList) return;
    this.isGetList = true;
    const res = await getShareImgs({ pageNum, pageSize, filter: filterType });
    this.isGetList = false;
    this.setList(res.data, pageNum === 1);
    this.setTotal(res.total || 0);
    this.setIsInit(true);
  }

  async adminDelete(id: string) {
    await deleteShareImg(id);
  }

  async postCollection(id: string, url: string, type: CreationType) {
    return await collectionImg(id, url, type);
  }

  setParams(params: Partial<CreationSquare['params']>) {
    this.params = { ...this.params, ...params };
  }

  setList(list: CreationShareType[], concat = false) {
    this.list = concat ? list : this.list.concat(list);

    let urls: string[] = [];
    this.list.forEach(item => {
      urls.push(item.url);
      if (item.favorite.length) {
        this.setFaverites(item.drawId, item.url);
      }
    })
    this.setUrlList(urls);
  }

  setTotal(total: number) {
    this.total = total;
  }

  setIsInit(isInit: boolean) {
    this.isInit = isInit;
  }

  setUrlList(urlList: string[]) {
    this.urlList = urlList;
  }

  setFaverites(id: string, url: string | string[]) {
    if (Array.isArray(url)) {
      this.favorites.set(id, url);
      return;
    }
    if (this.favorites.has(id)) {
      !this.favorites.get(id)?.includes(url) && this.favorites.get(id)?.push(url); // 防止重复添加
    } else {
      this.favorites.set(id, [url]);
    }
  }
}