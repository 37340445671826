import { Box, Button, Flex, Icon, Image, Input, Text, Tooltip } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { FC } from "react";
import styles from "./index.module.scss"
import { CButton } from "@/components/Button";

export const ComSex: FC<any> = observer(({
  sex,
  SetSex,
  title,
  itema,
  itemb,
  issex
}: {
  sex: any;
  SetSex: (e: any) => void;
  title: string
  itema: string;
  itemb: string
  issex: boolean
}) => {
  return (
    <Flex className={styles.allbox}>
      <Box className={styles.titlebox}>
        <Text className={styles.title}>
          {title}
        </Text>
      </Box>
      <Flex className={styles.listbox}>
        <Box className={`${sex === itema ? styles.selbox : styles.sexbox}`} onClick={() => { SetSex(itema) }}>
          {issex && <Icon as={require('@/assets/svg/toolBoy.svg').ReactComponent} />}{itema}
        </Box>
        <Box className={`${sex !== itema ? styles.selbox : styles.sexbox}`} onClick={() => { SetSex(itemb) }}>
          {issex && <Icon as={require('@/assets/svg/toolGirl.svg').ReactComponent} />}{itemb}
        </Box>
      </Flex>
    </Flex>
  );
})