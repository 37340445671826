import { LocalDB } from ".";

class IMDB extends LocalDB {
  getTeamIgnores(): Promise<TeamMute | null> {
    return this.db.getItem('teamIgnores')
  }
  setTeamIgnores(sessions: TeamMute) {
    this.db.setItem('teamIgnores', sessions)
  }
}


export const imDb = new IMDB('im');