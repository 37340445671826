import React from 'react'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import classnames from 'classnames'
import { ComplexAvatarContainer, useStateContext, useTranslation } from '../../../common'
import { Box, Icon } from '@chakra-ui/react'
import { CSwitch } from '@/components/Switch'

export interface ChatP2pSettingProps {
  onCreateGroupClick: () => void
  account: string
  alias?: string
  nick?: string
  isMute?: boolean
  isTop?: boolean
  sessionId?: string

  prefix?: string
  commonPrefix?: string
}

const ChatP2pSetting: React.FC<ChatP2pSettingProps> = ({
  onCreateGroupClick,
  account,
  alias = '',
  nick = '',
  isMute = false,
  isTop = false,
  sessionId = '',
  prefix = 'chat',
  commonPrefix = 'common',
}) => {
  const _prefix = `${prefix}-person-setting`
  const { t } = useTranslation()
  const { store } = useStateContext()

  return (
    <div
      className={classnames(`${_prefix}-wrap`)}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <div className={`${_prefix}-user-wrap`}>
        <div className={classnames(`${_prefix}-item`)}>
          <ComplexAvatarContainer
            account={account}
            canClick={false}
            prefix={commonPrefix}
          />
          <span className={`${_prefix}-item-label`}>
            {alias || nick || account}
          </span>
        </div>

        <div className={classnames(`${_prefix}-item`)}>
          <Box onClick={onCreateGroupClick} as={require('@/assets/svg/add_create_group.svg').ReactComponent} className={`${_prefix}-add-btn`} />
          <span className={`${_prefix}-item-label`}>
            {t('addChatMemberText')}
          </span>
        </div>
      </div>
      <div className={`${_prefix}-msg-remind`} onClick={() => {
        store.relationStore.setMuteActive({
          account,
          isAdd: !isMute,
        })
      }}>
        <span className={`${_prefix}-remind-label`}>开启消息提醒</span>
        <CSwitch checked={!isMute} />
      </div>
      <div className={`${_prefix}-top-fixed`} onClick={() => {
        if (isTop) {
          store.sessionStore.deleteStickTopSessionActive(sessionId)
        } else {
          store.sessionStore.addStickTopSessionActive(sessionId)
        }
      }}>
        <span className={`${_prefix}-fixed-label`}>聊天置顶</span>
        <CSwitch checked={isTop} />
      </div>
    </div >
  )
}

export default ChatP2pSetting
