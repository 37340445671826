import React, { FC, useState } from 'react'
import { MsgList } from './components/MsgList'
import { useEventTracking, useStateContext, useTranslation } from '../../common'
import packageJson from '../../package.json'
import { observer } from 'mobx-react'
import { logger } from '../../utils'
import { useMessage } from '@/hooks/useMessage'
import { uiStrore } from '@/store/ui'

export interface MsgListContainerProps {
  /**
   自定义渲染消息中心为空时内容
   */
  renderMsgListEmpty?: () => JSX.Element
  /**
   自定义渲染消息中心头部内容
   */
  renderMsgListHeader?: () => JSX.Element
  /**
   点击发送消息后的事件
   */
  afterSendMsgClick?: () => void
  /**
   通过入群申请后的事件
   */
  afterAcceptApplyTeam?: (options: { teamId: string; from: string }) => void
  /**
   拒绝入群申请后的事件
   */
  afterRejectApplyTeam?: (options: { teamId: string; from: string }) => void
  /**
   通过入群邀请后的事件
   */
  afterAcceptTeamInvite?: (options: { teamId: string; from: string }) => void
  /**
   拒绝入群邀请后的事件
   */
  afterRejectTeamInvite?: (options: { teamId: string; from: string }) => void
  /**
   通过好友申请后的事件
   */
  afterAcceptApplyFriend?: (account: string) => void
  /**
   拒绝好友申请后的事件
   */
  afterRejectApplyFriend?: (account: string) => void
  /**
   样式前缀
   */
  prefix?: string
  /**
   公共样式前缀
   */
  commonPrefix?: string
}

export const MsgListContainer: FC<MsgListContainerProps> = observer(
  ({
    renderMsgListHeader,
    renderMsgListEmpty,
    afterSendMsgClick,
    afterAcceptApplyTeam,
    afterRejectApplyTeam,
    afterAcceptTeamInvite,
    afterRejectTeamInvite,
    afterAcceptApplyFriend,
    afterRejectApplyFriend,
    prefix = 'contact',
    commonPrefix = 'common',
  }) => {
    const { nim, store, initOptions } = useStateContext()

    const message = useMessage()
    const { openAlert, closeAlert } = uiStrore;

    const { t } = useTranslation()

    useEventTracking({
      appkey: initOptions.appkey,
      version: packageJson.version,
      component: 'ContactUIKit',
      imVersion: nim.version,
    })

    const [applyTeamLoaidng, setApplyTeamLoaidng] = useState(false)
    const [teamInviteLoading, setTeamInviteLoading] = useState(false)
    const [applyFriendLoading, setApplyFriendLoading] = useState(false)

    const onAcceptApplyTeamClick = (options: {
      teamId: string
      from: string
    }) => {
      setApplyTeamLoaidng(true)
      store.teamStore
        .passTeamApplyActive(options)
        .then(() => {
          message.success({ title: t('acceptedText') })
          afterAcceptApplyTeam?.(options)
        })
        .catch((err) => {
          message.error({ title: t('acceptFailedText') })
          logger.error('同意该申请失败: ', err)
        })
        .finally(() => {
          setApplyTeamLoaidng(false)
        })
    }

    const onRejectApplyTeamClick = (options: {
      teamId: string
      from: string
    }) => {
      setApplyTeamLoaidng(true)
      store.teamStore
        .rejectTeamApplyActive(options)
        .then(() => {
          message.success({ title: t('rejectedText') })
          afterRejectApplyTeam?.(options)
        })
        .catch((err) => {
          message.error({ title: t('rejectFailedText') })
          logger.error('拒绝该申请失败: ', err)
        })
        .finally(() => {
          setApplyTeamLoaidng(false)
        })
    }

    const onAcceptTeamInviteClick = (options: {
      teamId: string
      from: string
    }) => {
      setTeamInviteLoading(true)
      store.teamStore
        .acceptTeamInviteActive(options)
        .then(() => {
          message.success({ title: t('acceptedText') })
          afterAcceptTeamInvite?.(options)
        })
        .catch((err) => {
          message.error({ title: t('acceptFailedText') })
          logger.error('同意该申请失败: ', err)
        })
        .finally(() => {
          setTeamInviteLoading(false)
        })
    }

    const onRejectTeamInviteClick = (options: {
      teamId: string
      from: string
    }) => {
      setTeamInviteLoading(true)
      store.teamStore
        .rejectTeamInviteActive(options)
        .then(() => {
          message.success({ title: t('rejectedText') })
          afterRejectTeamInvite?.(options)
        })
        .catch((err) => {
          message.error({ title: t('rejectFailedText') })
          logger.error('拒绝该申请失败: ', err)
        })
        .finally(() => {
          setTeamInviteLoading(false)
        })
    }

    const onAcceptApplyFriendClick = async (account: string) => {
      try {
        setApplyFriendLoading(true)
        await store.friendStore.passFriendApplyActive(account)
        message.success({ title: t('acceptedText') })
        afterAcceptApplyFriend?.(account)
      } catch (error) {
        message.error({ title: t('acceptFailedText') })
        logger.error('同意该申请失败: ', error)
      } finally {
        setApplyFriendLoading(false)
      }
    }

    const onRejectApplyFriendClick = (account: string) => {
      setApplyFriendLoading(true)
      store.friendStore
        .rejectFriendApplyActive(account)
        .then(() => {
          message.success({ title: t('rejectedText') })
          afterRejectApplyFriend?.(account)
        })
        .catch((err) => {
          message.error({ title: t('rejectFailedText') })
          logger.error('拒绝该申请失败: ', err)
        })
        .finally(() => {
          setApplyFriendLoading(false)
        })
    }

    const clearSystemMsg = () => {
      openAlert({
        title: "提示",
        content: "是否确认清空消息？",
        status: 'warning',
        onOk: async () => {
          try {
            store.sysMsgStore.applyMsgs.forEach((msg) => {
              store.sysMsgStore.deleteApplyMsg(msg)
            })
            closeAlert()
            message.success({ title: '清空成功' })
          } catch (error) {
            message.error({ title: '清空失败' })
          }
        },
      })
    }

    return (
      <MsgList
        msgs={store.uiStore.applyMsgList}
        applyTeamLoaidng={applyTeamLoaidng}
        teamInviteLoading={teamInviteLoading}
        applyFriendLoading={applyFriendLoading}
        onAcceptApplyTeamClick={onAcceptApplyTeamClick}
        onRejectApplyTeamClick={onRejectApplyTeamClick}
        onAcceptTeamInviteClick={onAcceptTeamInviteClick}
        onRejectTeamInviteClick={onRejectTeamInviteClick}
        onAcceptApplyFriendClick={onAcceptApplyFriendClick}
        onRejectApplyFriendClick={onRejectApplyFriendClick}
        afterSendMsgClick={afterSendMsgClick}
        renderMsgListHeader={renderMsgListHeader}
        renderMsgListEmpty={renderMsgListEmpty}
        prefix={prefix}
        commonPrefix={commonPrefix}
        onClearSystemMsg={clearSystemMsg}
      />
    )
  }
)
