import { Box, Icon, Text } from "@chakra-ui/react";
import { FC, useRef, useState } from "react";
import { LoginByWx } from "./ByWx";
import { LoginByCode } from "./ByCode";
import LoginByPwd from "./ByPwd";
import ForgetPwd from "./Forget";
import { globalStore } from "@/store/global";
import { observer } from "mobx-react-lite";
import { CModal } from "@/components/Modal";
import { ScrollBarBox } from "@/components/ScrollBox";
import { uiStrore } from "@/store/ui";
import { WxLoginBindPhone } from "./BindPhone";

import styles from './index.module.scss'

export const LoginType: FC = observer(() => {
  const [type, setType] = useState<'wx' | 'pwd' | 'phone' | 'forgot' | 'bindPhone'>('wx');

  const { loginIsAgreeProtocol, setLoginIsAgreeProtocol } = globalStore;
  const { openAlert, closeAlert } = uiStrore
  const [ysxyOpen, setYsxyOpen] = useState(false); // 隐私协议
  const [yhxyOpen, setYhxyOpen] = useState(false); // 用户协议
  const isAutoAgreen = useRef(false);

  const alert = (callback?: () => void) => {
    isAutoAgreen.current = true;
    openAlert({
      title: '提示',
      status: 'warning',
      content: <Box className={styles.protocolContent} maxW='400px'>
        <Text className={styles.text}>登录文思逸言APP，请先阅读同意</Text>
        <Text className={styles.wise} onClick={() => { setYsxyOpen(true); closeAlert(); }}>《文思逸言隐私政策》</Text>
        <Text className={styles.text}>和</Text>
        <Text className={styles.wise} onClick={() => { setYhxyOpen(true); closeAlert(); }}>《文思逸言用户协议》</Text>
      </Box>,
      overlayProps: { zIndex: 40 },
      contentProps: { zIndex: 41 },
      onOk: () => {
        setLoginIsAgreeProtocol(true);
        callback && callback();
        uiStrore.closeAlert();
      }
    })
  }

  return <Box className={styles.loginContent}>
    {
      type === 'forgot' ? <ForgetPwd onBack={() => setType('pwd')} /> :
        type === 'bindPhone' ? <WxLoginBindPhone onBack={() => setType('wx')} /> : <>
          <Box className={styles.loginHeader}>
            <Box className={type === 'wx' ? styles.active : ''} onClick={() => setType('wx')}>
              <Text className={styles.text}>微信登录</Text>
              <Icon className={styles.icon} as={require('@/assets/svg/chat_header_active.svg').ReactComponent} />
            </Box>
            <Box className={type === 'phone' ? styles.active : ''} onClick={() => setType('phone')}>
              <Text className={styles.text}>验证码登录</Text>
              <Icon className={styles.icon} as={require('@/assets/svg/chat_header_active.svg').ReactComponent} />
            </Box>
            <Box className={type === 'pwd' ? styles.active : ''} onClick={() => setType('pwd')}>
              <Text className={styles.text}>密码登录</Text>
              <Icon className={styles.icon} as={require('@/assets/svg/chat_header_active.svg').ReactComponent} />
            </Box>
          </Box>
          <Box className={styles.content}>
            <Box display={type === 'wx' ? 'block' : 'none'}><LoginByWx onAgreen={alert} isAgree={loginIsAgreeProtocol} onToBindPhone={() => setType('bindPhone')} /></Box>
            {
              type === 'phone' ? <LoginByCode onAgreen={alert} /> : null
            }
            {
              type === 'pwd' ? <LoginByPwd onForgetPwd={() => setType('forgot')} onAgreen={alert} /> : null
            }
          </Box>
          <Box className={styles.loginBottom}>
            {/* <Box className={`${styles.travelUser} ${type === 'wx' ? styles.wx : ''}`}>
            <Icon as={require('@/assets/svg/travel_user.svg').ReactComponent} />
            <Text>游客登录</Text>
          </Box> */}
            <Box className={styles.protocol}>
              <Box className={`${styles.agree} ${loginIsAgreeProtocol ? styles.active : ''}`}
                onClick={() => setLoginIsAgreeProtocol(!loginIsAgreeProtocol)}>
                {
                  loginIsAgreeProtocol ? <Icon as={require('@/assets/svg/radio_primary.svg').ReactComponent} /> : null
                }
              </Box>
              <Box className={styles.protocolContent}>
                <Text className={styles.text}>我已阅读并同意</Text>
                <Text className={styles.wise} onClick={() => { setYsxyOpen(true) }}>《文思逸言隐私政策》</Text>
                <Text className={styles.text}>和</Text>
                <Text className={styles.wise} onClick={() => setYhxyOpen(true)}>《文思逸言用户协议》</Text>
                {
                  type !== 'pwd' && <Text className={styles.text}>
                    ，未注册的{type === 'wx' ? '微信号' : '手机号'}将自动创建文思逸言账号
                  </Text>
                }
              </Box>
            </Box>
          </Box>
        </>
    }
    <YSXY isOpen={ysxyOpen} onClose={() => {
      if (isAutoAgreen.current) {
        alert();
      }
      setYsxyOpen(false);
    }} />
    <YHXY isOpen={yhxyOpen} onClose={() => {
      if (isAutoAgreen.current) {
        alert();
      }
      setYhxyOpen(false);
    }} />
  </Box>
})


interface ProtocolProps {
  isOpen: boolean;
  onClose: () => void;
}
export const YSXY: FC<ProtocolProps> = (props) => {
  const { isOpen, onClose } = props;
  return <CModal isOpen={isOpen} onClose={onClose} overlayProps={{ zIndex: 42 }} contentProps={{ zIndex: 43, containerProps: { zIndex: 43 } }}>
    <Box className={styles.protocolInfo}>
      <Icon as={require('@/assets/svg/close_x.svg').ReactComponent} className={styles.close} onClick={onClose} />
      <p className={styles.title}>《文思逸言隐私政策 》</p>
      <Box className={styles.content}>
        <ScrollBarBox className={styles.info}>
          <h3 id="【引言】">【引言】</h3>
          <p>欢迎您体验我们的文思逸言大模型。我们深知个人信息对您的重要性，您的信赖对我们非常重要，我们将严格遵守法律法规要求采取相应的安全保护措施，致力于保护您的个人信息。基于此，我们制定本《文思逸言隐私政策》（下称“本政策”或“本隐私政策”），帮助您充分了解在您使用产品的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便您更好地作出适当的选择。
            在您使用我们的产品前，请您务必先仔细阅读和理解本政策，确保您充分理解和同意之后再开始使用。除本政策外，在特定场景下，我们还会通过即时告知（含弹窗、页面提示等）、功能更新说明等方式，向您说明对应的信息收集目的、范围及使用方式，这些即时告知及功能更新说明等构成本政策的一部分，并与本政策具有同等效力。本政策中涉及的专业词汇，我们尽量以简明通俗的表述向您解释，以便于您理解。如对本政策内容有任何疑问、意见或建议，您可通过本政策文末的联系方式与我们联系。
            本隐私政策将帮助您了解以下内容：
            <p>一、适用范围</p>
            <p>二、信息收集及使用</p>
            <p>三、您的权利</p>
            <p>四、信息的存储</p>
            <p>五、政策的更新</p>
            <p>六、未成年人保护</p>
            <p>七、联系我们</p>
            <p>附录：相关定义</p>
          </p>
          <h3 id="一、适用范围">一、适用范围</h3>
          <p>本政策适用于文思逸言大模型通过网站、APP、公众号、小程序向您提供的产品服务。 </p>
          <h3 id="二、信息收集及使用">二、信息收集及使用</h3>
          <p>在您使用我们的产品时，我们需要或可能需要收集和使用的您的个人信息包括如下两种：
            1、为实现向您提供我们产品的基本功能，及履行法律法规规定的义务，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品体验；
            2、为实现向您提供我们产品体验的附加功能，您可选择单独同意或不同意我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但不会影响您使用我们的基本功能。
            我们会为您提供的各项具体产品场景包括： </p>
          <h5 id="（一）注册及登录">（一）注册及登录</h5>
          <p>您可以通过关联公司的账号体系授权登录使用产品。 </p>
          <h5 id="（二）产品申请及回访">（二）产品申请及回访</h5>
          <p>为便于与您联系，我们需要您在申请产品时提供您的手机号、邮箱等。为保障您的产品体验，我们会在产品名额通过时以手机短信及邮件的方式通知您。
            在您开通体验后，我们可能会通过手机号码或邮箱地址联系您，以期得到您的反馈，帮助我们更好迭代。 </p>
          <h5 id="（三）产品体验">（三）产品体验</h5>
          <p>在您参与产品体验过程中，我们可能会收到以下您提供的信息：
            1、您输入的文本信息
            本产品体验的对话依赖于您的输入，我们需要接收并记录您与文思逸言对话时所输入的文本信息，文思逸言将根据您的输入为您提供智能对话内容。
            在经过去标识化且无法重新识别特定个人的前提下，我们会使用单个对话内的上下文信息提高文思逸言的智能性，以改善对话质量和响应速度，提高文思逸言对您输入内容的理解能力。
            2、对话内容评价
            在您产品体验过程中，您可以根据文思逸言的输出是否满足您的需求进行评价，包括点赞、点踩、我要反馈等。在经过去标识化且无法重新识别特定个人的前提下，我们将收集您的评价及反馈，以改善文思逸言的对话质量，优化产品体验。
            如您拒绝我们收集和处理前述信息，请您谨慎输入文本信息。一旦您参与产品体验，将被视为您已经接受以上条款。
            如您输入的内容（包括但不限于对话信息、评价、反馈）涉及第三方的信息（包括但不限于个人信息），在输入这些信息之前，您需确保您已经取得合法的授权，避免泄露他人个人信息，侵犯他人权利。 </p>
          <h5 id="（四）对话历史查询和管理">（四）对话历史查询和管理</h5>
          <p>为向您提供连续性、一致化的产品体验，保障产品质量，我们会记录您的对话记录，包括您输入的文本对话信息、以及基于上述信息形成的对话主题。您可以查找并管理您尚未删除的对话历史。 </p>
          <h5 id="（五）运营分析">（五）运营分析</h5>
          <p>我们可能会使用日志数据（包括您的浏览器信息和类型、您提出请求的日期和时间，以及您如何与我们的网站互动）、使用信息（您的位置、例如客户端日期和访问时间、服务端日期和访问时间、计算机或移动设备的类型、网络信息）、设备信息（包括您正在使用的设备信息、操作系统和浏览器类型）、Cookie的在线分析产品来帮助我们分析用户如何使用我们的产品，并增强您的产品体验。
            我们承诺，不会将Cookie用于本隐私政策所述目的之外的任何其他用途。我们使用Cookie主要为了保障产品体验的安全、高效运转，可以使我们确认您账号的安全状态，排查崩溃、延迟的相关异常情况，帮助您省去重复填写表单、输入搜索内容的步骤和流程。 </p>
          <h5 id="（六）为您提供安全保障">（六）为您提供安全保障</h5>
          <p>为提高产品安全性，保护您或其他用户或公众的人身安全、财产安全、账号安全，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入、恶意程序等安全风险，更准确地识别违反法律法规或相关协议、规则的情况，我们及我们的关联公司会收集您的设备信息、产品日志信息，并可能使用或整合您的设备信息、产品日志信息，来综合判断您账号及使用风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。 </p>
          <h5 id="（七）依法豁免征得您的授权同意的情形">（七）依法豁免征得您的授权同意的情形</h5>
          <p>请您理解，在下列情形中，根据法律法规及国家标准，我们转移、公开您的个人信息无需征得您的授权同意：
            1、为履行法定职责或者法定义务所必需：我们可能会根据法律法规规定、诉讼、争议解决的必要，或按行政、司法机关依法提出的要求，以及其他法定义务履行的必需，共享您的个人信息。
            2、为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需。
            3、为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息。
            4、在合理的范围内处理您自行公开的个人信息，或者其他已经合法公开的个人信息（如合法的新闻报道、政府信息公开等渠道合法公开的个人信息）。
            5、法律法规规定的其他情形。 </p>
          <h3 id="三、您的权利">三、您的权利</h3>
          <p>您可以通过以下方式查阅和管理您的信息，我们会在符合法律法规要求的情形下响应您的请求： </p>
          <h5 id="（一）查阅、更正和补充">（一）查阅、更正和补充</h5>
          <p>您有权通过以下方式查阅、更正和补充您的信息：登录账号中心后在页面设置信息。 </p>
          <h5 id="（二）复制">（二）复制</h5>
          <p>您可以通过以下方式复制您的信息：登录账号中心后在页面复制您所需的信息。 </p>
          <h5 id="（三）删除">（三）删除</h5>
          <p>您可以登录账号中心后申请注销账户以删除您的全部信息。
            在以下情形中，您可以向我们提出删除个人信息的请求：
            1、如果我们处理个人信息的行为违反法律法规；
            2、如果我们收集、使用您的个人信息，却未征得您的明确同意；
            3、如果我们处理个人信息的行为严重违反了与您的约定；
            4、如果我们的处理目的已实现、无法实现或者为实现处理目的不再必要；
            5、如果我们停止提供产品，或者保存期限已届满。
            若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。
            当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清除或实现匿名化。 </p>
          <h5 id="（四）改变授权范围">（四）改变授权范围</h5>
          <p>您可以通过联系我们改变或者撤回您授权我们处理您的个人信息的范围。 </p>
          <h5 id="（五）注销账户">（五）注销账户</h5>
          <p>您可以登录账号中心后申请注销账户。在您主动注销账户之后，我们将停止为您提供产品，并删除或匿名化处理您的个人信息，但法律法规另有规定的除外。 </p>
          <h5 id="（六）响应您的上述请求">（六）响应您的上述请求</h5>
          <p>您或可能的您的监护人、近亲属及其他有权主体向我们提出上述请求，以及主张中华人民共和国法律与其他适用法律规定的您的相关个人信息权利，可以通过<a href="mailto:tongyiqianwen@service.aliyun.com">tongyiqianwen@service.aliyun.com</a>发起请求。我们将在15天内做出答复。
            为保障您的账户安全和您的个人信息安全，您向我们提出上述请求时，我们可能会先验证您的身份（如增加账户核验、要求您提供书面请求或其他合理方式），然后再处理您的请求。
            对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无合理理由重复申请的信息，或者需要过多技术手段（如需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。 </p>
          <h3 id="四、信息的存储">四、信息的存储</h3>
          <h5 id="（一）存储期限">（一）存储期限</h5>
          <p>我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的留存要求。 在超出保留期间后，我们会根据适用法律的要求删除或匿名化处理您的用户信息。 </p>
          <h5 id="（二）存储位置">（二）存储位置</h5>
          <p>我们在中华人民共和国境内产生或收集的个人信息，存储在中国境内的服务器。除非取得您的单独同意，或为了履行与您订立的合同，或为了遵循法律法规规定的义务，我们不会向境外提供您的任何个人信息。 </p>
          <h5 id="（三）存储安全">（三）存储安全</h5>
          <p>1、数据保护技术措施
            我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。
            2、数据保护管理组织措施
            我们建立了行业内先进的以数据为核心、围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理及技术的维度提升个人信息的安全性。我们通过培训课程和考试，不断加强员工对于保护个人信息重要性的认识。
            3、个人信息安全事件的响应
            如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损的，我们会及时启动应急预案，采取合理必要的措施，以尽可能降低对您个人的影响。如发生个人信息安全事件，我们还将按照法律法规的要求向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将以短信、电话、推送通知及其他合理渠道告知您，难以逐一告知的，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
            4、账户安全风险的防范
            请您妥善保护自己的个人信息，不要通过产品披露个人信息。
            如您担心自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络我们以便我们根据您的申请采取相应措施。 </p>
          <h3 id="五、政策的更新">五、政策的更新</h3>
          <p>为给您带来更好的产品体验，我们持续努力改进我们的技术。我们可能会更新我们的隐私政策，并通过短信或其他适当的方式提醒您更新的内容，以便您及时了解本隐私政策的最新版本。但未经您明确同意，我们不会限制您按照本政策所享有的权利。
            对于重大变更，我们还会提供更为显著的通知（包括我们会通过短信、邮件进行通知或向您提供弹窗提示）再次向您告知，并根据法律法规的相关要求征得您的同意。 </p>
          <h3 id="六、未成年人保护">六、未成年人保护</h3>
          <p>在本产品中我们推定您具有相应的民事行为能力。如您为未成年人，我们要求您请您的父母或其他监护人仔细阅读本政策，并在征得您的父母或其他监护人同意的前提下使用我们的产品或向我们提供信息。
            如您为14周岁以下儿童，在您进行产品前，您和您的监护人应仔细阅读本政策，确保在取得监护人对本政策的同意后，您才可在监护人的指导下使用我们的产品，确保您在使用我们的产品时的安全。
            若您是儿童的父母或其他监护人，请您关注儿童是否是在取得您的授权同意之后使用我们的产品。如您对儿童的个人信息有疑问，请与我们联系。 </p>
          <h3 id="七、联系我们">七、联系我们</h3>
          <p>您可以通过以下方式与我们联系，我们将在15天内回复您的请求：
            1、如您对本政策内容有任何疑问、意见或建议，您可通过<a href="mailto:wisitalker@hotmail.com">wisitalker@hotmail.com</a>与我们联系；
            2、您可以通过<a href="mailto:wisitalker@hotmail.com">wisitalker@hotmail.com</a>与其联系（请您注明与文思逸言产品有关，并说明具体事实情况），需注意的是，我们可能不会回复与本政策或您的个人信息权利无关的问题。
            如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。 </p>
          <h3 id="附录：相关定义">附录：相关定义</h3>
          <p>个人信息：以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息,不包括匿名化处理后的信息。
            敏感个人信息：敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。
            儿童：指不满十四周岁的未成年人。
            设备信息：包括硬件型号、硬件序列号、软件列表、应用安装列表、软件安装列表、唯一设备识别码IMEI、设备MAC地址、SIM卡IMSI信息、Android ID、MEID、OpenUDID、GUID、蓝牙信息、广播组件通讯信息、ICCID、运营商信息、传感器（陀螺仪、重力传感器、加速度传感器、环境传感器、光学心率传感器）、剪切板。以实际采集情况为准。
            产品日志信息：包括时间、用户ID、对话ID、对话内容，以及IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间。
            去标识化：指个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。
            匿名化：指个人信息经过处理无法识别特定自然人且不能复原的过程。
            Cookie：Cookie和同类设备信息标识技术是互联网中普遍使用的技术。当您进行产品时，我们可能会使用相关技术向您的设备发送一个或多个Cookie或匿名标识符，以收集、标识和存储您访问、使用本产品时的信息。 </p>
        </ScrollBarBox>
      </Box>
    </Box>
  </CModal>
}

export const YHXY: FC<ProtocolProps> = (props) => {
  const { isOpen, onClose } = props;
  return <CModal isOpen={isOpen} onClose={onClose} overlayProps={{ zIndex: 42 }} contentProps={{ zIndex: 43, containerProps: { zIndex: 43 } }}>
    <Box className={styles.protocolInfo}>
      <Icon as={require('@/assets/svg/close_x.svg').ReactComponent} className={styles.close} onClick={onClose} />
      <h1 className={styles.title} id="《文思逸言用户协议》">《文思逸言用户协议》</h1>
      <Box className={styles.content}>
        <ScrollBarBox className={styles.info}>
          <h4 id="欢迎您来到文思逸言。">欢迎您来到文思逸言。</h4>
          <p>请您仔细阅读以下条款，如果您对本协议的任何条款表示异议，您可以选择不进入文思逸言。当您注册成功，无论是进入文思逸言，还是在文思逸言上输入任何内容（即「内容」），均意味着您（即「用户」）完全接受本协议项下的全部条款。 </p>
          <h3 id="使用规则">使用规则</h3>
          <p>1.用户注册成功后，文思逸言将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。
            2.用户须对在文思逸言的注册信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；不得利用他人的名义发布任何信息；不得恶意使用注册帐号导致其他用户误认；否则文思逸言有权立即停止提供服务，收回其帐号并由用户独自承担由此而产生的一切法律责任。
            3.用户直接或通过各类方式（如 RSS 源和站外 API 引用等）间接使用文思逸言服务和数据的行为，都将被视作已无条件接受本协议全部内容；若用户对本协议的任何条款存在异议，请停止使用文思逸言所提供的全部服务。
            4.文思逸言是一个信息分享、传播及获取的平台，用户通过文思逸言制作与发表的信息为公开的信息，其他第三方均可以通过文思逸言获取用户制作的信息，用户对任何信息的发表即认可该信息为公开的信息，并单独对此行为承担法律责任；任何用户不愿被其他第三人获知的信息都不应该在文思逸言上进行发表。
            5.用户承诺不得以任何方式利用文思逸言直接或间接从事违反中国法律以及社会公德的行为，文思逸言有权对违反上述承诺的内容予以删除。
            6.用户不得利用文思逸言服务制作、上载、复制、发布、传播或者转载如下内容：
            a.反对宪法所确定的基本原则的；
            b.危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
            c.损害国家荣誉和利益的；
            d.煽动民族仇恨、民族歧视，破坏民族团结的；
            e.侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的；
            f.破坏国家宗教政策，宣扬邪教和封建迷信的；
            g.散布谣言，扰乱社会秩序，破坏社会稳定的；
            h.散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
            i.侮辱或者诽谤他人，侵害他人合法权益的；
            j.含有法律、行政法规禁止的其他内容的信息。
            7.文思逸言有权对用户使用文思逸言的情况进行审查和监督，如用户在使用文思逸言时违反任何上述规定，文思逸言或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容、暂停或终止用户使用文思逸言的权利）以减轻用户不当行为造成的影响。 </p>
          <h3 id="知识产权">知识产权</h3>
          <p>文思逸言是一个信息获取、分享及传播的平台，我们尊重和鼓励文思逸言用户创作的内容，认识到保护知识产权对文思逸言生存与发展的重要性，承诺将保护知识产权作为文思逸言运营的基本原则之一。
            1.用户在文思逸言上通过AI生成的内容，相关知识产权归文思逸言所有。
            2.文思逸言提供的网络服务中包含的标识、版面设计、排版方式、文本、图片、图形等均受著作权、商标权及其它法律保护，未经相关权利人（含文思逸言及其他原始权利人）同意，上述内容均不得在任何平台被直接或间接发布、使用、出于发布或使用目的的改写或再发行，或被用于其他任何商业目的。
            3.为了促进知识的分享和传播，用户将其在文思逸言上发表的全部内容，授予文思逸言免费的、不可撤销的、非独家使用许可，文思逸言有权将该内容用于文思逸言各种形态的产品和服务上，包括但不限于网站以及发表的应用或其他互联网产品。
            4.第三方若出于非商业目的，将用户在文思逸言上发表的内容转载在文思逸言之外的地方，应当在作品的正文开头的显著位置注明原作者姓名（或原作者在文思逸言上使用的帐号名称），给出原始链接，注明「来源于文思逸言」，并不得对作品进行修改演绎。若需要对作品进行修改，或用于商业目的，第三方应当联系用户获得单独授权，按照用户规定的方式使用该内容。
            5.在文思逸言上传或发表的内容，用户应保证其为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益。如果第三方提出关于著作权的异议，文思逸言有权根据实际情况删除相关的内容，且有权追究用户的法律责任。给文思逸言或任何第三方造成损失的，用户应负责全额赔偿。
            6.如果任何第三方侵犯了文思逸言用户相关的权利，用户同意授权文思逸言或其指定的代理人代表文思逸言自身或用户对该第三方提出警告、投诉、发起行政执法、诉讼、进行上诉，或谈判和解，并且用户同意在文思逸言认为必要的情况下参与共同维权。
            7.文思逸言有权但无义务对用户发布的内容进行审核，有权根据相关证据结合法律法规及文思逸言社区指导原则对侵权信息进行处理。 </p>
          <h3 id="个人隐私">个人隐私</h3>
          <p>尊重用户个人隐私信息的私有性是文思逸言的一贯原则，文思逸言将通过技术手段、强化内部管理等办法充分保护用户的个人隐私信息，除法律或有法律赋予权限的政府部门要求或事先得到用户明确授权等原因外，文思逸言保证不对外公开或向第三方透露用户个人隐私信息，或用户在使用服务时存储的非公开内容。同时，为了运营和改善文思逸言的技术与服务，文思逸言将可能会自行收集使用或向第三方提供用户的非个人隐私信息，这将有助于文思逸言向用户提供更好的用户体验和服务质量。
            您使用或继续使用我们的服务，即意味着同意我们按照文思逸言《文思逸言隐私保护指引》收集、使用、储存和分享您的相关信息。 </p>
          <h3 id="侵权举报">侵权举报</h3>
          <p>1.处理原则
            文思逸言作为知识讨论社区，高度重视自由表达和个人、企业正当权利的平衡。依照法律规定删除违法信息是文思逸言社区的法定义务，文思逸言社区亦未与任何中介机构合作开展此项业务。
            2.受理范围
            受理文思逸言社区内侵犯企业或个人合法权益的侵权举报，包括但不限于涉及个人隐私、造谣与诽谤、商业侵权。
            a.涉及个人隐私：发布内容中直接涉及身份信息，如个人姓名、家庭住址、身份证号码、工作单位、私人电话等详细个人隐私；
            b.造谣、诽谤：发布内容中指名道姓（包括自然人和企业）的直接谩骂、侮辱、虚构中伤、恶意诽谤等；
            c.商业侵权：泄露企业商业机密及其他根据保密协议不能公开讨论的内容。
            3.举报条件
            用户在文思逸言发表的内容仅表明其个人的立场和观点，并不代表文思逸言的立场或观点。如果个人或企业发现文思逸言上存在侵犯自身合法权益的内容，可以先尝试与作者取得联系，通过沟通协商解决问题。如您无法联系到作者，或无法通过与作者沟通解决问题，您可通过点击内容下方的举报按钮来向文思逸言平台进行投诉。为了保证问题能够及时有效地处理，请务必提交真实有效、完整清晰的材料，否则投诉将无法受理。您需要向文思逸言提供的投诉材料包括：
            a.权利人对涉嫌侵权内容拥有商标权、著作权和/或其他依法可以行使权利的权属证明，权属证明通常是营业执照或组织机构代码证；
            b.举报人的身份证明，身份证明可以是身份证或护照；
            c.如果举报人非权利人，请举报人提供代表权利人进行举报的书面授权证明。
            d.为确保投诉材料的真实性，在侵权举报中，您还需要签署以下法律声明： • 我本人为所举报内容的合法权利人； • 我举报的发布在文思逸言社区中的内容侵犯了本人相应的合法权益； • 如果本侵权举报内容不完全属实，本人将承担由此产生的一切法律责任，并承担和赔偿文思逸言因根据投诉人的通知书对相关帐号的处理而造成的任何损失，包括但不限于文思逸言因向被投诉方赔偿而产生的损失及文思逸言名誉、商誉损害等。
            4.处理流程
            出于网络平台的监督属性，并非所有申请都必须受理。文思逸言自收到举报的七个工作日内处理完毕并给出回复。处理期间，不提供任何电话、邮件及其他方式的查询服务。
            出现文思逸言已经删除或处理的内容，但是百度、谷歌等搜索引擎依然可以搜索到的现象，是因为百度、谷歌等搜索引擎自带缓存，此类问题文思逸言无权也无法处理，因此相关申请不予受理。您可以自行联系搜索引擎服务商进行处理。
            此为文思逸言社区唯一的官方侵权投诉渠道，暂不提供其他方式处理此业务。
            用户在文思逸言中的商业行为引发的法律纠纷，由交易双方自行处理，与文思逸言无关。 </p>
          <h3 id="免责声明">免责声明</h3>
          <p>1.文思逸言不能对用户发表的内容的正确性进行保证。
            2.用户在文思逸言发表的内容仅表明其个人的立场和观点，并不代表文思逸言的立场或观点。作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。文思逸言不承担任何法律及连带责任。
            3.文思逸言不保证网络服务一定能满足用户的要求，也不保证网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作保证。
            4.对于因不可抗力或文思逸言不能控制的原因造成的网络服务中断或其它缺陷，文思逸言不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。 </p>
          <h3 id="协议修改">协议修改</h3>
          <p>1.根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，文思逸言有权对本协议的条款作出修改或变更，一旦本协议的内容发生变动，文思逸言将会直接在文思逸言网站上公布修改之后的协议内容，该公布行为视为文思逸言已经通知用户修改内容。文思逸言也可采用电子邮件或私信的传送方式，提示用户协议条款的修改、服务变更、或其它重要事项。
            2.如果不同意文思逸言对本协议相关条款所做的修改，用户有权并应当停止使用文思逸言。如果用户继续使用文思逸言，则视为用户接受文思逸言对本协议相关条款所做的修改。 </p>
          <h3 id="极光推送-sdk">极光推送 SDK</h3>
          <p>第三方主体：深圳市和讯华谷信息技术有限公司
            SDK 用途：为 APP 用户提供信息推送服务
            收集个人信息类型：
            设备参数及系统信息(设备类型、设备型号、系统版本、及相关硬件信息)：用于识别用户的设备类型、设备型号、系统版本等，确保消息准确下发；
            设备标识符（IMEI、IDFA、Android ID、GAID、 MAC、OAID、VAID、AAID、IMSI、MEID、UAID、硬件序列号信息、ICCID、SIM信息）：用于识别唯一用户，保证推送的精准送达及推送信息的准确统计；
            网络信息(IP 地址、WiFi 信息、基站信息、DNS地址、DHCP地址、SSID、BSSID)与位置信息（经纬度）：用于优化SDK与极光服务器的网络连接请求，保证服务的稳定性和连续性，同时实现区域推送功能；
            应用列表信息(应用崩溃信息、通知开关状态、APP 应用列表及活跃状态、APP 应用页面信息、APP 功能事件相关信息)：当一个设备有多个 APP 的推送链路同时活跃时，我们采用合并链路技术，随机合并成一条链路，以达到为用户节省电省流量的目的。
            数据处理方式：通过去标识化、加密传输及其他安全方式</p>
          <p>官网链接：<a href="https://www.jiguang.cn/push">https://www.jiguang.cn/push</a></p>
          <p>隐私政策链接：<a href="https://www.jiguang.cn/license/privacy">https://www.jiguang.cn/license/privacy</a>
          </p>
        </ScrollBarBox>
      </Box>
    </Box>
  </CModal>
}

export const VIPHYXY: FC<ProtocolProps> = (props) => {
  const { isOpen, onClose } = props;
  return <CModal isOpen={isOpen} onClose={onClose} overlayProps={{ zIndex: 42 }} contentProps={{ zIndex: 43, containerProps: { zIndex: 43 } }}>
    <Box className={styles.protocolInfo}>
      <Icon as={require('@/assets/svg/close_x.svg').ReactComponent} className={styles.close} onClick={onClose} />
      <p className={styles.title}>《文思逸言会员服务协议》</p>
      <Box className={styles.content}>
        <ScrollBarBox className={styles.info}>
          <h2 id="欢迎您成为文思逸言会员">欢迎您成为文思逸言会员</h2>
          <p>《文思逸言会员服务协议》（以下简称为“本协议”）是您与成都足不出户教育有限公司（以下统称“足不出户”）之间关于“文思逸言会员”以及“文思逸言会员”中足不出户提供的各项服务的法律协议，具有合同效力。足不出户在此特别提醒，请您在使用文思逸言会员服务前阅读并充分理解本协议，特别是免除或者限制责任的相应条款（该等免责、限制条款可能以黑体加粗、颜色标记或其他合理方式提示您注意）。
            <h4>足不出户可能会根据文思逸言会员的整体规划等，对文思逸言会员及/或本协议进行修改或变更，若您不同意文思逸言会员及/或本协议的前述修改或变更，您可停止使用文思逸言会员服务。您使用文思逸言会员服务即视为您接受并同意本协议所有条款，包括但不限于前述修改及变更。如违反本协议约定，足不出户有权随时中止或终止文思逸言会员服务。</h4>
            <h4>如果您未满18周岁，请在法定监护人的陪同下阅读本协议。</h4>
            <h4>特别提示：您通过足不出户指定渠道开通文思逸言会员服务后，可能会由于您使用的软件版本、设备、操作系统等不同已经第三方原因导致您实际可使用的具体服务有差别，由此可能给您带来的不便，您表示理解，并不会因此向足不出户提出任何主张或追究足不出户任何责任。</h4></p>
          <h2 id="定义和说明">一、 定义和说明</h2>
          <p>1.1【文思逸言会员及账号】
            文思逸言会员服务是足不出户在wisetalker.cn网站、文思逸言App中设立的网络增值服务。您如果需要使用和享受文思逸言会员服务，则您需要将您享有合法使用权的文思逸言账号作为会员账号，并购买相应的会员服务，您即成为文思逸言会员用户。</p>
          <br />
          <p>1.2【本服务】
            在本协议中，“本服务”特指“文思逸言会员服务”，指用户通过付费购买或好友赠送等方式获取的增值权益服务，包括但不限于调用更大参数的底座大模型、可调用更多可选择插件等，文思逸言会员具体权益种类和内容以 “会员权益介绍”页面和相关服务页面公布、实际提供的内容为准。您可以根据您的需求自行选择相应的服务。
            足不出户郑重声明：本服务不影响足不出户向您提供的现有的一切免费服务并保证服务质量。您理解并同意，鉴于网络服务的特殊性，足不出户有权根据市场情况进行会员权益及特色会员服务的全部或部分变更、调整、取消、增加，并可能会对您已享有或正在享有的会员权益造成影响。您同意，如您继续按照调整后的内容使用文思逸言会员服务，您不要求足不出户承担任何责任。</p>
          <br />
          <p>1.3【文思逸言会员规则】
            即文思逸言会员服务提供方不时发布并修订的关于文思逸言会员的用户守则、活动规则、使用规则、公告、提示、通知及在线FAQ等内容。</p>
          <br />
          <p>1.4【协议更新】
            足不出户可以对本协议下服务条款进行更新（包含变更、增加、减少相应的条款内容），一旦服务条款发生更新，足不出户会通过系统提示、信息推送和／或您在我方平台留下的联系方式通知和提醒您注意。更新条款一经正式发布，即为本协议不可分割的组成部分。如果您不同意变更后的协议，可以停止对本服务的使用。您继续使用足不出户提供的服务，则视为您已经接受本协议的全部修改。</p>
          <br />
          <p>1.5【本协议】
            本协议是基于《文思逸言用户协议》以及足不出户相关协议规范（简称为“足不出户服务条款”）制定，是其不可分割的组成部分，与其构成统一整体。本协议与上述内容存在冲突的，以本协议为准。
            同时，足不出户不时发布的关于文思逸言会员的相关协议、服务规则等也是本协议的一部分，您在使用文思逸言会员服务的同时应遵守本协议、足不出户服务条款及足不出户旗下相关会员产品协议、服务规则等所有条款。</p>
          <br />
          <h2 id="账号管理及使用规则">二、 账号管理及使用规则</h2>
          <p>2.1【账号管理】<br />
          </p>
          <ol>
            <li>您需先登录文思逸言，方可购买文思逸言会员服务。您应正确使用及妥善保管、维护您的足不出户账号及密码，如发生任何泄漏、遗失、被盗等行为，而该等行为并非足不出户法定过错导致，所有损失将由您自行承担。 </li>
            <li>您在此授予足不出户保护您账号安全的相关权利，足不出户可定期或不定期采用不同的方式对您账号的使用安全进行检查、验证，包括但不限于主动联系您进行身份验证、短消息验证、邮箱认证及密码修改等。如您无法完成验证或无正当理由拒绝验证的，足不出户可合理怀疑您的账号出现异常或被盗，并中止向该账号提供服务或采取进一步措施。</li>
          </ol>

          <p>2.2 【使用规则】<br />
          </p>
          <ul>
            <li>【合法获取及使用】<br />
            </li>
            <ul>
              <li>文思逸言会员服务，均仅限于您在足不出户平台内使用，任何以破解、转译、转录等非法手段将文思逸言会员服务与足不出户平台分离的行为，均不属于本协议中约定的文思逸言会员服务。足不出户保留追究侵权人的法律责任及索赔的权利。</li>
              <li><h4>使用文思逸言会员服务及/或参加相关活动时，亦应通过足不出户官方公布/授权的方式进行，不得采取出售、转让、盗用、租赁其他用户账户等方式进行文思逸言会员注册或通过出售、转让、盗用、转借其他文思逸言会员账户等方式享用文思逸言会员。任何以盗窃、利用系统漏洞、通过任何非足不出户官方或授权途径获得的文思逸言会员服务（包括但不限于购买、租用、借用、分享、受让等方式获得）、恶意利用或破坏文思逸言会员活动获得的文思逸言会员服务（包括且不限于账号、会员权益、会员服务激活码等）或参加活动获取福利（包括但不限于获得实体/虚拟礼品、会员权益、会员服务激活码等），均不获相关保护，且一经发现足不出户有权立即封停账户及/或取消其文思逸言会员资格，且不给予任何赔偿或退还费用/会员积分，所有产生的损失及责任由行为人自行承担。</h4></li>
              <li>您成为文思逸言会员后，可享有文思逸言会员的各项专属特权和增值服务，同时应遵守文思逸言会员服务的相关协议及服务规则等。</li>
              <li>如您违反本协议或足不出户服务条款，足不出户有权利取消您的文思逸言会员资格，足不出户可根据您的实际情况采取修改、限制、中止或终止全部或部分文思逸言会员服务或追究您的法律责任等措施，足不出户也无需向您退还任何费用/会员积分，且您需承担您、第三方及/或足不出户由此遭受的损失。</li>
            </ul>

            <li>【专有及非商业目的使用】<br />
            </li>
            <ul>
              <li>文思逸言会员服务，是在您遵守本协议及相关法律法规的前提下，足不出户给予您一项个人的、不可转让及非排他性的许可。您仅可为非商业目的使用，并仅可用作私人使用。</li>
              <li> <h4>您理解并同意，您不得将享有文思逸言会员服务的足不出户账号以任何方式提供给他人使用，包括但不限于不得以转让、出租、借用、分享、出售等方式提供给他人使用。否则，因此产生任何法律后果及责任均由您自行承担，一经发现足不出户有权中止或终止对您提供服务。</h4></li>
            </ul>

            <li>【账号独立】<br />
            </li>
            <ul>
              <li>同一主体拥有多个足不出户账号并进行文思逸言会员登录的，每一个足不出户账号将被识别为一个独立的账号。</li>
              <li><h4> 每个足不出户账号之间的充值记录、会员权益无法进行转移、迁徙、转让、赠与、售卖、租借、分享，无论该等账号是否由同一使用人拥有，请您在登录及/或充值/参加活动时注意区分，避免造成损失。</h4></li>
            </ul>

            <li>【账号找回】<br />
              <h4>您登录文思逸言会员时使用的账号信息遗失、忘记密码，导致无法登录的，您如需找回的，需按照足不出户官方公布的账号找回流程的要求提供相应的信息和证明，并确保提供的信息和证明文件的合法真实有效，若提供的材料不真实或不符合要求，无法通过足不出户的安全验证，可能会导致账号找回失败，相应的风险和损失将由您自行承担。 您理解足不出户对您的请求采取行动及处理均需要合理时间，除足不出户存在法定过错外，足不出户对您的损失不承担任何责任。</h4></li>

            <li>【妥善保管】<br />
              请您负责妥善且正确地保管、使用、维护您的文思逸言会员资料、账户信息及账户密码。请您对您账户信息和账户密码采取必要和有效的保密措施。</li>
          </ul>

          <h2 id="会员服务内容">三、 会员服务内容</h2>
          <p>3.1 【会员特权】<br />
          </p>
          <ul>
            <li>3.1.1 在您开通文思逸言会员服务后，根据您的会员购买情况将会享有对应服务体验，包括但不限于调用更大参数的底座大模型、可调用更多可选择插件等，具体以相关服务页面公布、实际提供的内容为准。</li>
            <li>3.1.2 <h4>您在购买文思逸言会员后可享受的全部权益以足不出户官方公布的会员权益内容为准，足不出户有权基于自身业务发展需要变更全部或部分会员权益。就前述权益调整足不出户会通过在相应服务页面更新展示、系统提示、信息推送和／或您在足不出户平台留下的联系方式通知和提醒您注意，您也可通过足不出户官方网站或移动端查询最新的会员权益内容。同时我们也鼓励您定期查看本协议内容，以更好地保障您的权益。</h4></li>
            <li>3.1.3 <h4>为了向您提供更好的平台服务，足不出户可能会对会员权益进行调整，包括但不限于：
              <ol>
                <li>会员权益具体内容的调整</li>
                <li>会员权益等级等计算方式的变更</li>
                <li>会员付款方式、价格等的变更</li>
                <li>其他平台公示的变更内容</li>
              </ol></h4></li>
          </ul>
          <p>3.2 【虚拟产品】<br />
            <h4>基于虚拟产品的性质和特征，您所获取的虚拟产品不能够进行退货、换货、兑换现金，亦不得用于商业领域，如买卖、置换、抵押等。为了保证您的用户权益，请您通过官方渠道购买文思逸言会员服务，一切通过非官方公布渠道取得的虚拟产品及其衍生服务均不获保护，足不出户有权中止或终止对您的服务；由此带来的损失，足不出户不承担相关责任。</h4></p>

          <p>3.3 【设备及系统差异】<br />
            <h4>您通过足不出户指定渠道开通本服务后，可能会由于您使用的软件版本、设备、操作系统等不同以及其他第三方原因等导致您实际可使用的具体服务有差别，由此可能给您带来的不便，您表示理解，您不予追究或者豁免足不出户的相关责任。</h4></p>

          <p>3.4 【关于收费】<br />
            本服务是足不出户提供的收费服务，您须在按照本服务的收费标准支付相应费用后，方可使用本服务。涉及收费环节时，我们将在相关用户界面上做出提示。如您拒绝支付费用，则无法继续享受文思逸言会员服务，但您仍可以继续使用免费服务。
            <h4>足不出户可能会根据本服务的整体规划，对本服务收费标准、方式等进行修改和变更，前述修改、变更，足不出户将在相应服务页面进行更新展示。足不出户会根据实际状况，对不同阶段充值、续费的会员给予不同的优惠，具体优惠政策和收费规则以足不出户在相关服务页面公示的信息为准。您在此理解并同意，如果足不出户发现用户滥用收费规则获取优惠等任何作弊的行为，可以判定该等用户享有的优惠无效。</h4></p>

          <p>3.5 【支付】<br />
          </p>
          <ul>
            <li>3.5.1 您应该通过足不出户指定的包括但不限于微信支付等方式等现有方式或今后足不出户指定方式，依约支付。
              <h4>请您在购买本服务和支付费用前仔细阅读并理解本协议和相关的收费规则、政策。如您不同意，请您停止支付行为。如果您实际进行了付费行为，则代表您同意本协议与相关收费规则，您无权转让本服务或要求足不出户退款；但如果因足不出户产品技术原因，导致您购买时被重复扣款或因足不出户产品技术原因导致您无法享有购买的服务时，您可提出退款申诉，经过足不出户核实后可为您办理退款，但您需要提供相应信息并配合足不出户处理。</h4></li>
            <li>3.5.2 您不得通过以下任何方式为自己或他人开通本服务：
              <ol>
                <li>以营利、经营等非个人使用的目的为自己或他人开通本服务；</li>
                <li>通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序、软件方式为自己或他人开通本服务；</li>
                <li>通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通本服务；</li>
                <li>通过非足不出户指定的方式为自己或他人开通本服务；</li>
                <li>通过侵犯足不出户或他人合法权益的方式为自己或他人开通本服务；</li>
                <li>通过其他违反相关法律、行政法规、国家政策等的方式为自己或他人开通本服务；</li>
              </ol>
            </li>

            <li>3.5.3您理解并认可，如果您通过第三方支付工具在足不出户平台账户支付或充值后可能产生的任何商业风险（包括但不限于不法分子利用您账户或银行卡等有价卡等进行违法活动，第三方代为充值后再退款导致您的会员权益无法实现；采用第三方黑产渠道代为充值，或通过第三方非正规渠道购买远低于市场价值的文思逸言会员权益等），该等风险均有可能给您造成相应的经济损失，足不出户在充分履行其在本协议项下义务和符合法律规定的前提下，仅在法律有明确规定的情况和范围内，承担相应的责任。</li>
          </ul>

          <p>3.6 【服务期限】<br />
          </p>
          <ul>
            <li>3.6.1 您理解并同意，本服务具有一定的服务期限。足不出户可能向您提供可选择的服务期限（如月度、季度、年度等，具体以产品页面为准）。除本协议另有约定外，本服务的期限以您自行选择并支付相应服务费用的期限为准，自您选择并购买本服务后生效。本服务的有效日期以文思逸言会员服务开通的时间算起，直至选定的服务期限到期或您终止本服务/注销足不出户账号或<h4>您因违规使用导致账号封禁</h4>时终止。您可以在本服务的相应页面查询服务期限选项与剩余服务期限。</li>
            <li>36.2 您的文思逸言会员到期前，足不出户将通过站内信等方式提示您文思逸言会员即将到期，您可再次通过购买延续您的文思逸言会员。若您的文思逸言会员到期时，足不出户将终止提供文思逸言会员服务。</li>
            <li>3.6.3 <h4>请您理解，由于互联网服务的特殊性，为向您提供优质服务，足不出户须不定时进行服务器的维修、调整、升级等，且在黑客攻击、计算机病毒侵入、监管机构政策通知等不可抗力事件发生后，足不出户须解决故障并处理相关侵权事宜，因此您接受，本服务的服务期限包含足不出户解决故障、服务器维修、调整、升级、因第三方侵权处理等所需的合理时间，足不出户对上述情况所需用的时间不另行补偿。</h4></li>
          </ul>
          <p>3.7 【服务保障】<br />
            足不出户在为文思逸言会员提供多项专属特权和增值服务同时，尽最大努力确保文思逸言会员服务的稳定性，为此提供各项技术及客服支持，包括但不限于：</p>
          <ul>
            <li>3.7.1 开通、获赠成为文思逸言会员期间所遇到的注册、登陆、购买、等问题的网上咨询客户服务；</li>
            <li>3.7.2 文思逸言会员服务积极有效的内容支持和技术支持，包括日常运营、维护、修复及更新；</li>
            <li>3.7.3 文思逸言会员服务提供期间产生的常见问题解答、基本规则解释；</li>
            <li>3.7.4 尽最大努力防止文思逸言会员服务的服务器和技术设备遭受恶意攻击，以保障您的合法利益；</li>
            <li>3.7.5 足不出户提供给您的其他技术和/或服务。</li>
          </ul>
          <p>3.8 【保密责任】<br />
            尊重文思逸言用户个人隐私是足不出户的责任，足不出户在未经文思逸言会员的授权时不会公开或透露其文思逸言会员注册资料及保存的非公开内容，但由于法律法规需要等原因除外。</p>

          <h2 id="违约责任">四、 违约责任</h2>
          <p>4.1 您在使用本服务的过程中，不得进行以下违约行为：</p>
          <ul>
            <li>4.1.1 违反本协议约定的；</li>
            <li>4.1.2 违反法律、法规、规章、条例以及任何具有法律效力之规范规定的；</li>
            <li>4.1.3 破坏、影响足不出户对任何第三方提供本服务；</li>
            <li>4.1.4 进行危害计算机网络安全的行为；</li>
            <li><h4>4.1.5 对足不出户及关联第三方（包括授权方和合作方）提供的本协议服务、活动造成不良影响，侵犯及/或关联第三方足不出户及/或其他用户的正当利益；</h4></li>
            <li>4.1.6 被有权机构认定侵犯任何第三方的合法权益的；</li>
            <li>4.1.7 利用本服务获取非法利益的，包括但不限于通过转售、转让、转授权等行为不正当牟利；</li>
            <li>4.1.8 其他侵犯足不出户合法利益的行为。</li>
          </ul>
          <p>4.2 【违约行为处理】<br />
            <h4>您确认并同意，如果足不出户发现或收到他人举报您有违反本协议任何行为的，足不出户有权依法进行独立判断并采取以下一项或多项措施处理：</h4></p>
          <ul>
            <li><h4>(1) 根据相应规则立即删除、屏蔽或断开相关的信息；</h4></li>
            <li><h4>(2) 采取包括但不限于中止或终止部分或全部本服务的措施；</h4></li>
            <li><h4>(3) 采取扣除违约行为发生时间内的会员权益时长的措施；</h4></li>
            <li><h4>(4) 足不出户无需向您退还任何费用，未使用的服务费用作为违约金归足不出户所有，您的相关会员权益的损失由您自行承担，足不出户在法律有明确规定的情况下承担相应的责任；</h4></li>
            <li><h4>(5) 如您的行为使足不出户遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿足不出户的上述全部损失；</h4></li>
            <li><h4>(6) 如您的行为使足不出户遭受第三人主张权利，足不出户可在对第三人承担金钱给付等义务后就全部损失向您追偿。</h4></li>
          </ul>

          <h2 id="服务的变更、中止及终止">五、 服务的变更、中止及终止</h2>
          <p>5.1 本服务的中止或终止包含如下情况：</p>
          <ul>
            <li>5.1.1用户主动中止或终止，包括但不限于会员到期未进行续费等；</li>
            <li>5.1.2 因为用户违约行为，足不出户主动中止或终止服务的；</li>
            <li>5.1.3 因国家或相关部门要求或发生不可抗力事件时，足不出户中止或终止服务的；</li>
            <li>5.1.4 其他根据法律法规或足不出户业务调整等应当或者需要中止或终止服务的；</li>
          </ul>
          <p><h4>5.2 中止或终止服务后，足不出户将尽最大努力通过合理方式通知用户。当您发现无法登录或享受服务时，可以咨询我们的在线客服。</h4></p>
          <p>5.3 中止或终止后的处理:</p>
          <ul>
            <li><h4>5.3.1 除法律规定的责任外，足不出户对本服务的中止或终止对用户和任何第三人不承担任何责任；</h4></li>
            <li><h4>5.3.2 除法律规定或本协议另有约定外，已收取的费用不予退还；</h4></li>
            <li><h4>5.3.3 正在进行的平台交易，足不出户将根据情况合理判断，是否继续进行或终止相关交易，并视情况决定是否进行退费或扣除相关费用作为违约金。</h4></li>
          </ul>
          <p>5.4 <h4>足不出户依据本协议的约定变更服务内容、服务方式以及本协议内容的，如果会员不同意足不出户的变更，有权选择取消并停止使用已经获取的对应的全部或部分服务；如果会员继续使用足不出户提供的上述服务，则视为会员已经接受足不出户的上述调整。</h4></p>
          <p>5.5 <h4>会员如果不同意条款的修改，可主动向足不出户提出终止会员服务，但足不出户不退还自开通会员服务至提出终止会员服务所在月的已享受会员服务的部分费用；如果会员继续享用会员服务，则视为会员已经接受条款的修改。</h4></p>

          <h2 id="通知和送达">六、 通知和送达</h2>
          <p>本协议项下足不出户对于用户所有的通知均可通过网页公告、手机短信或常规的信件传送等方式进行，该等通知于发送之日视为已送达用户。为便于您收取相关通知，您应确保预留的联系方式为正确及有效，并在变更后及时登录足不出户修改，如因您未提供正确的联系信息或未及时告知新的联系方式，导致无法及时收取通知，将由您自行承担由此产生的损失及法律后果。 足不出户可通过上述方式之一或其中若干向您送达各类通知，而此类通知的内容或将对您产生重大有利或不利影响，请您务必确保联系方式为有效并请及时关注。</p>

          <h2 id="法律的适用和管辖">七、 法律的适用和管辖</h2>
          <p>7.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。</p>
          <p><h4>7.2 如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向足不出户所在地有管辖权的人民法院提起诉讼。</h4></p>
          <p>7.3 足不出户未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。</p>
          <p>7.4 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
          <p><h4>7.5 在法律法规允许的范围内，足不出户有权对本协议进行解释。</h4></p>

          <p>成都足不出户教育科技有限公司</p>
          <p>本版更新时间：2024年8月1日</p>
        </ScrollBarBox>
      </Box>
    </Box>
  </CModal>
}

export const CZFWXY: FC<ProtocolProps> = (props) => {
  const { isOpen, onClose } = props;
  return <CModal isOpen={isOpen} onClose={onClose} overlayProps={{ zIndex: 42 }} contentProps={{ zIndex: 43, containerProps: { zIndex: 43 } }}>
    <Box className={styles.protocolInfo}>
      <Icon as={require('@/assets/svg/close_x.svg').ReactComponent} className={styles.close} onClick={onClose} />
      <h1 className={styles.title} id="《文思逸言充值服务协议》">《文思逸言充值服务协议》</h1>
      <Box className={styles.content}>
        <ScrollBarBox className={styles.info}>
          <h3 id="文思逸言人工智能APP充值协议">《文思逸言》人工智能APP充值协议</h3>
          <p>版本日期：[2023.11.15]</p>
          <p>欢迎使用《文思逸言》的充值服务。在您使用充值服务之前，请您仔细阅读并理解本充值协议（以下简称“本协议”）。您一旦使用充值服务，即表示您已阅读并同意接受本协议的所有条款和条件。如果您不同意本协议的任何内容，或者无法准确理解我们的解释，请不要进行充值。</p>
          <h3 id="定义">定义</h3>
          <p>充值：指您使用《文思逸言》提供的在线支付方式，为您的《文思逸言》账户增加点数的行为。<br />
            点数：指充值后存入您的《文思逸言》账户中的点数，可用于《文思逸言》提供的服务。</p>
          <h3 id="充值规则">充值规则</h3>
          <ul>
            <li>您可以通过网银、支付宝、微信支付等我们提供的支付方式进行充值。</li>
            <li>充值金额需遵循我们设定的最低和最高充值限额。</li>
            <li>充值成功后，相应金额将即时充入您的《文思逸言》账户中。</li>
            <li>您的预付款仅限于在《文思逸言》中使用，不可提现，不计息。</li>
          </ul>
          <h3 id="用户责任">用户责任</h3>
          <ul>
            <li>您应确保在充值过程中使用的支付工具（如银行卡、支付账户等）为您合法所有，且未被挂失、冻结或作废。</li>
            <li>您应当对您账户中的点数安全负责，包括但不限于保管账户信息、密码、支付信息等。</li>
            <li>如发现任何未经授权的充值行为，您应立即通知我们，并配合我们进行调查。</li>
          </ul>
          <h3 id="退款政策">退款政策</h3>
          <ul>
            <li>如因系统故障或其他可归责于我们的原因导致充值金额未能正确充入您的账户，我们将协助您进行退款或者补充充值。</li>
            <li>除上述情形外，一旦充值成功，充值金额将不予退款。</li>
          </ul>
          <h3 id="变更和终止">变更和终止</h3>
          <ul>
            <li>我们有权在必要时修改本协议的任何条款，修改后的协议将在《文思逸言》上公布。</li>
            <li>如您不同意修改后的协议，应立即停止使用充值服务；继续使用充值服务将视为您已接受修改后的协议。</li>
          </ul>
          <h3 id="法律适用与争议解决">法律适用与争议解决</h3>
          <ul>
            <li>本协议的订立、执行和解释及争议的解决均适用您所在地的法律法规。</li>
            <li>因本协议所引起的任何争议或纠纷，双方应首先尽力友好协商解决；协商不成时，任何一方均可向《文思逸言》所在地的有管辖权的法院提起诉讼。</li>
          </ul>
          <h3 id="其他">其他</h3>
          <ul>
            <li>本协议中的标题仅为方便阅读而设，不影响对条款本身的解释。</li>
            <li>如本协议中的任何一条或多条被认定为无效、非法或不可执行，该等条款将被视为可分割，不影响其他条款的有效性。</li>
            <li>《文思逸言》保留对本协议的最终解释权。</li>
          </ul>
        </ScrollBarBox>
      </Box>
    </Box>
  </CModal>
}

export const RobotYHXY: FC<ProtocolProps> = (props) => {
  const { isOpen, onClose } = props;
  return <CModal isOpen={isOpen} onClose={onClose} overlayProps={{ zIndex: 42 }} contentProps={{ zIndex: 43, containerProps: { zIndex: 43 } }}>
    <Box className={styles.protocolInfo}>
      <Icon as={require('@/assets/svg/close_x.svg').ReactComponent} className={styles.close} onClick={onClose} />
      <h1 className={styles.title} id="《文思逸言充值服务协议》">《微信AI机器人用户使用协议》</h1>
      <Box className={styles.content}>
        <ScrollBarBox className={styles.info}>
          <h3 id="欢迎使用文思逸言的微信AI机器人服务">欢迎使用文思逸言的微信AI机器人服务！</h3>
          <p>在开始使用之前，请您务必仔细阅读并理解以下使用协议。若您不同意本协议的任何条款，请停止使用本服务。</p>

          <h2>1. 服务概述</h2>
          <p>1.1 我们的微信AI机器人服务是基于人工智能技术的自动回复系统，能够理解和处理用户输入的文本信息。</p>
          <p>1.2 本服务可能随时根据技术和业务的发展进行更新和改进，用户在使用过程中应保持关注相关更新。</p>

          <h2>2. 使用规范</h2>
          <p>2.1 用户在使用微信AI机器人服务时，应遵守相关法律法规，不得违反公序良俗。</p>
          <p>2.2 用户不得通过本服务进行违法、违规、侵权或其他不当行为。</p>
          <p>2.3 用户应当对使用本服务过程中产生的信息负有责任，包括但不限于所发布信息的合法性和真实性。</p>

          <h2>3. 隐私保护</h2>
          <p>3.1 为保护用户隐私，我们将严格按照相关法律法规的规定处理用户的个人信息。</p>
          <p>3.2 用户了解并同意，在提供个人信息时可能会涉及到网络传输，存在一定的风险。</p>

          <h2>4. 知识产权</h2>
          <p>4.1 微信AI机器人服务所使用的所有软件、技术和内容的知识产权归属本公司所有。</p>
          <p>4.2 用户在使用本服务过程中产生的内容的知识产权归属用户所有，但用户同意授予本公司免费、不可撤销的许可权，使本公司可以合法使用、复制、修改、发布和展示这些内容。</p>

          <h2>5. 使用注意事项 ★</h2>
          <p>5.1 由于微信平台规定及技术限制，包括但不限于违规使用或不当使用。</p>
          <p>5.2 请勿用于商业用途，不要添加过多好友，建议仅自己使用。</p>
          <p>5.3 若因使用本服务导致微信账号封号，我方概不负责。</p>

          <h2>6. 免责声明</h2>
          <p>6.1 由于技术和网络环境的限制，本服务可能会受到各种因素的影响，包括但不限于不稳定性、延迟等问题。</p>
          <p>6.2 用户在使用本服务过程中的风险由用户自行承担，本公司不对用户使用本服务可能产生的任何损失或损害负责。</p>

          <h2>7. 终止服务</h2>
          <p>7.1 本公司有权根据实际情况中断或终止向用户提供微信AI机器人服务，用户对此无异议。</p>
          <p>7.2 用户有权随时停止使用本服务。</p>

          <h2>8. 协议修改</h2>
          <p>8.1 本公司有权根据实际情况对本协议进行修改，并通过适当方式向用户通知。</p>
          <p>8.2 若用户不同意修改后的协议内容，应停止使用本服务；若用户继续使用本服务，则视为用户已接受修改后的协议。</p>
        </ScrollBarBox>
      </Box>
    </Box>
  </CModal>
}