import { POST } from '@/api/request';
import { createHash } from 'crypto-browserify';
import FileSaver from 'file-saver';
import { isDesktop } from './electron';
export const unAuthRoute = ['/login']

export const _AI_DefaultModel = '64aff279487c27a5f4693e60'
export const PRICE_SCALE = 1000000;
export const SHOW_PRICE_SCALE = 100;
export const createHashPassword = (text: string) => {
  const hash = createHash('sha256').update(text).digest('hex');
  return hash;
};


/**
 * @description: 复制到剪切板
 */
export const copyDataFunc = () => {
  return {
    copyData: async (data: string) => {
      try {
        if (navigator.clipboard) {
          await navigator.clipboard.writeText(data);
        } else {
          throw new Error('');
        }
      } catch (error) {
        const textarea = document.createElement('textarea');
        textarea.value = data;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
      }
    }
  };
};

export const formatPrice = (val = 0, multiple = 1) => {
  return Number(((val / PRICE_SCALE) * multiple).toFixed(10));
};

export const uploadFiles = (e: File) => {
  const formData = new FormData();
  formData.append('file', e)
  return POST<string[]>(
    (isDesktop() ? (window.proxyApi + '/api') || '' : '') + '/system/uploadToAliOss',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    }
  );
}

export const getOSSBlobResource = (url: string): Promise<Blob> => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      }
    };
    xhr.send();
  });
};

export const saveBlobToLocal = async (blob: Blob, fileName: string) => {
  if (!window.showSaveFilePicker) {
    FileSaver.saveAs(blob, fileName)
  } else {
    try {
      const handle = await window.showSaveFilePicker({ suggestedName: fileName });

      //@ts-ignore
      const writableStream = await handle.createWritable();
      await writableStream.write(blob);
      await writableStream.close();
    } catch (err) {
      throw err
    }
  }
}

export const isChromeHighVersion = () => {
  const ua = navigator.userAgent.toLowerCase();
  const chromeIndex = ua.indexOf("chrome");
  if (chromeIndex > -1) {
    const version = ua.substring(chromeIndex + 7);
    const majorVersion = parseInt(version.split(".")[0], 10);
    return majorVersion > 127;
  }
  return false;
};
export function zoomPlugin() {
  const originalGetBoundingClientRect = Element.prototype.getBoundingClientRect;
  if (!isChromeHighVersion()) {
    return;
  }
  Element.prototype.getBoundingClientRect = function () {
    //@ts-ignore
    const rect = originalGetBoundingClientRect.call(this);
    // @ts-ignore
    const zoom = Number(document.body.style.zoom) || 1;
    //@ts-ignore
    if (rect.isZoomed) {
      return rect;
    }
    const newRect = new DOMRect(
      rect.x / zoom,
      rect.y / zoom,
      rect.width / zoom,
      rect.height / zoom,
    );
    // @ts-ignore
    newRect.isZoomed = true;
    return newRect;
  };
}