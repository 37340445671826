import { Box, Icon, Text } from "@chakra-ui/react";
import { FC, ReactNode, useEffect, useRef } from "react";
import { WiseImage } from "@/components/Image";
import Markdown from "@/components/Markdown";
import dayjs from "dayjs";
import { copyDataFunc } from "@/utils/common";
import { chatStore } from "@/store/chat";
import { voiceBroadcast } from "@/utils/chat";
import Image from "@/components/Markdown/img/Image";
import { useMessage } from "@/hooks/useMessage";
import { userStore } from "@/store/user";
import { uiStrore } from "@/store/ui";
import i18n from '@/utils/i18n';
import { PhotoProvider, PhotoView } from 'react-photo-view';

import styles from './index.module.scss'
import { t } from "i18next";

interface AIContentInfoProps {
  avatar: {
    userAvatar: string
    aiAvatar: string
  }
  chatItem: ChatInfoHistory
  reGenerate?: string
  value: string | string[]
  modelNames?: string
  price?: number
  isPlugin?: boolean
  isVip?: boolean
  riskInfo?: ChatInfoHistory['contentCheck']
}
export const AIContentInfo: FC<AIContentInfoProps> = (props) => {
  const { chatItem, avatar, reGenerate, modelNames, price, value, isPlugin, isVip, riskInfo } = props;
  const itemRef = useRef<HTMLDivElement>(null);

  const isAI = chatItem.obj === 'AI';
  const isFunctionCall = Array.isArray(value);
  let valStr = ''
  if (isFunctionCall) {
    value.forEach(str => {
      const noText = str.startsWith('\n\n```function_call') || str.endsWith('```\n\n') || str.startsWith('\n\n```finish_reason')
      if (!noText) {
        valStr = str;
      }
    })
  }
  const val = (isPlugin || isFunctionCall) ? valStr : value;
  const riskValue = riskInfo?.status !== false ? val : (`${isAI ? t('DetectedTextMayContain') : t('creation.TextMayContain')}${riskInfo.labels?.split(',').map(str => i18n.t('riskContent.' + str)).join(',')} ${t('creation.TemporarilyUnavailable')} ${isAI ? '' : t('creation.NoConsume') + t('period')}` || t('ContentRiskBlocked'))
  return <Box ref={itemRef} className={styles.chatItemBox}>
    <BaseContent
      avatar={isAI ? avatar.aiAvatar : avatar.userAvatar}
      time={chatItem.createTime}
      model={modelNames || chatItem.modelName}
      isAI={isAI}
      id={chatItem._id}
      urls={chatItem.imgUrls}
      value={riskValue}
      isVip={isVip || false}
      isGenerate={chatItem.status === 'loading'}
      reGenerate={reGenerate}
      price={price}
      docFiles={chatItem.fileUrls}
      isRisk={riskInfo?.status === false} // 不能取反，因为有可能没有riskInfo
      {...(isFunctionCall && { pluginEle: <PluginFuncEle value={value} /> })}
    />
  </Box>
}

interface BaseContentProps {
  appName?: string
  avatar: string
  time: Date | string
  model: string
  isAI: boolean
  isVip: boolean
  value: string
  price?: number
  urls?: string[]
  id: string
  isGenerate?: boolean
  reGenerate?: string
  pluginEle?: ReactNode
  isRisk?: boolean
  docFiles?: string[]
}
const BaseContent: FC<BaseContentProps> = (props) => {
  const { avatar, isAI, model, appName, time, value, urls, id, isGenerate, reGenerate, price, pluginEle, isVip, isRisk, docFiles } = props;

  const { copyData } = copyDataFunc();
  const { userInfo } = userStore;
  const { setQuote, setOtherStartQuestion, setPlayingId, playingId } = chatStore;
  const { setOpenVip } = uiStrore;
  const quoteRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const androidRef = useRef<{ ttsWS: WebSocket; audioPlayer: any; }>();
  const message = useMessage();
  let humanImg: string[] = [];
  let humalVal = value;
  if (!isAI) {
    const matchImg = /!\[(.*?)\]\((.*?)\)/mg
    let matcher: RegExpExecArray | null
    if (urls?.length) {
      urls.forEach(url => {
        humanImg.push(url)
      })
    } else {
      while ((matcher = matchImg.exec(value)) !== null) {
        humanImg.push(matcher[2])
        humalVal = humalVal.replace(matcher[0] + '\n', '')
      }
    }
  }

  const quoteReg = /^\[.*\s*\]\s---------------------------------------------\s/;

  let quoteStr = '';
  if (quoteReg.test(humalVal)) {
    const quoteAllString = quoteReg.exec(humalVal)?.[0] || '';
    humalVal = humalVal.replace(quoteAllString, '');

    const getStrReg = /^\[(.*\s*?)\]/g;

    let matcher: RegExpExecArray | null;
    while ((matcher = getStrReg.exec(quoteAllString)) !== null) {
      quoteStr = matcher[1].trim();
    }
  }

  // 用于计算引用的宽度
  useEffect(() => {
    if (contentRef.current && quoteRef.current) {
      quoteRef.current.style.width = contentRef.current.clientWidth + 'px';
      quoteRef.current.style.display = 'block';
    }
    return () => {
      if (window['speechSynthesis'] === undefined) {
        androidRef.current?.ttsWS.close()
        androidRef.current?.audioPlayer.reset()
      } else {
        window.speechSynthesis.cancel();
      }
      setPlayingId('');
    }
    // eslint-disable-next-line
  }, [])

  const copy = () => {
    copyData(value);
    message.success({ title: t('CopySuccess') });
  }

  const onQuote = () => {
    setQuote({
      text: isAI ? value : humalVal, //humanImg?.map(_ => '[图片]').join('') +  
      model: isAI ? model : t('Me'),
      urls: humanImg || [],
      originText: value,
      id
    });
  }

  const play = () => {
    if (playingId === id) {
      if (window['speechSynthesis'] === undefined) {
        androidRef.current?.ttsWS.close()
        androidRef.current?.audioPlayer.reset()
      } else {
        window.speechSynthesis.cancel();
      }
      setPlayingId('');
    } else {
      if (window['speechSynthesis'] === undefined) {
        // androidPlayAudio(value) // 安卓播放音频 暂时不支持
      } else {
        voiceBroadcast({
          text: value,
          onEndCallback: () => {
            setPlayingId(''); // 朗读结束时更新状态
          }
        });
      }
      setPlayingId(id);
    }
  }

  return <Box flex={1}>
    <Box className={`${styles.chatItem}  ${isAI ? styles.ai : styles.user}`}>
      {
        <Box className={styles.avatar} order={isAI ? 0 : 1}>
          <WiseImage src={avatar} alt="" />
        </Box>
      }
      <Box className={styles.info}>
        {
          appName && isAI && <Text className={styles.app}>{appName}</Text>
        }
        <Box className={appName ? styles.appDesc : styles.desc}>
          {
            isAI && <Text className={styles.model}>{model.replace('-', ' ')}</Text>
          }
          <Text className={styles.time}>{dayjs(time).format('MM-DD HH:mm')}</Text>
        </Box>
        {
          docFiles?.length ? <Box className={styles.docFiles}>
            {
              docFiles.map((item, index) => {
                let fileType = item.split('.').pop();
                if (fileType === 'docx') {
                  fileType = 'doc'
                }
                return <Box key={index} className={styles.docFile} onClick={() => {
                  window.open(item)
                }}>
                  <Text className={styles.name}>{item}</Text>
                  <Box className={styles.fileType}>
                    <Icon as={require(`@/assets/svg/im_${fileType}.svg`).ReactComponent} />
                    <Text>{fileType?.toUpperCase()}</Text>
                  </Box>
                </Box>
              })
            }
          </Box> : null
        }
        {
          !isAI && humanImg.length ? <PhotoProvider maskOpacity={0.7}>
            {
              humanImg.map((item, index) => {
                return <PhotoView key={index} src={item}>
                  <Box className={styles.userImg}>
                    <Image src={item} download={false} />
                  </Box>
                </PhotoView>
              })
            }
          </PhotoProvider> : null
        }
        <Box className={`${styles.content} ${isRisk ? styles.risk : ''}`} ref={contentRef}>
          {pluginEle}
          <Box className={`${styles.text} ${isAI ? styles.ai : ''} ${isGenerate ? styles.ing : ''}`}>
            {
              isRisk && <Icon as={require('@/assets/svg/risk_white.svg').ReactComponent} className={styles.riskIcon} />
            }
            {!isAI ? (
              <div className="markdown" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all', overflow: 'hidden' }}>
                {humalVal}
              </div>
            ) : <Markdown source={value} />
            }
            {
              isGenerate && <Box className={styles.loading}>
                <Text className={styles.ing}>{t('AIGenerating')}</Text>
                <Box className={styles.dot1}></Box>
                <Box className={styles.dot2}></Box>
                <Box className={styles.dot3}></Box>
              </Box>
            }
          </Box>
          {
            !isGenerate && <Box className={styles.bottomInfo}>
              <Box className={styles.operates}>
                {
                  !isRisk && <>
                    <Box className={styles.icon} onClick={copy}>
                      <Icon as={require('@/assets/svg/copy.svg').ReactComponent} />
                    </Box>
                    <Box className={styles.icon} onClick={onQuote}>
                      <Icon as={require('@/assets/svg/quote.svg').ReactComponent} />
                    </Box>
                    <Box className={styles.icon} onClick={play}>
                      {
                        playingId === id ? <Box className={`${styles.playing} ${!isAI ? styles.humanPlaying : ''}`}>
                          <Text className={styles.line}></Text>
                          <Text className={styles.line}></Text>
                          <Text className={styles.line}></Text>
                          <Text className={styles.line}></Text>
                        </Box> :
                          <Icon as={require(`@/assets/svg/play.svg`).ReactComponent} />
                      }
                    </Box>
                  </>
                }
                {/* <Box className={styles.icon}>
              <Icon as={require('@/assets/svg/like.svg').ReactComponent} />
            </Box> */}
                {/* <Box className={styles.icon}>
                  <Icon as={require('@/assets/svg/share.svg').ReactComponent} />
                </Box> */}
                {
                  reGenerate && !isRisk && <Box className={`${styles.icon} ${styles.regen}`} onClick={() => setOtherStartQuestion(reGenerate)}>
                    <Icon as={require('@/assets/svg/refresh.svg').ReactComponent} />
                  </Box>
                }
              </Box>
              {
                isAI && (isVip || userInfo?.monVip ?
                  <Box className={styles.vipprice}>
                    <Icon as={require('@/assets/svg/chatisVip.svg').ReactComponent} />
                    <Text>{t('VipNoPoints')}</Text>
                  </Box>
                  :
                  <Box className={styles.price}>
                    <Text>{t('PointsConsumed')}：{price}</Text>
                    <Icon as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
                    <Icon className={styles.vipsvg} as={require('@/assets/svg/navisVip.svg').ReactComponent} />
                    <Text className={styles.viptext} onClick={() => setOpenVip(true)}>{t('FreeMembership')}</Text>
                    <Icon className={styles.vipsvg} as={require('@/assets/svg/arrow_r.svg').ReactComponent} />
                  </Box>
                )}
            </Box>
          }

        </Box>
        {
          quoteStr && <Box className={styles.quote} ref={quoteRef} display='none'>
            <Text className={styles.quoteText}>{quoteStr}</Text>
          </Box>
        }
      </Box>
    </Box>
  </Box>
}

interface PluginFuncEleProps {
  value: string[]
}
const PluginFuncEle: FC<PluginFuncEleProps> = (props) => {
  const { value } = props
  return <Box flex={1}>
    {
      value.map((str, i) => {
        if (str.startsWith('\n\n```function_call') && str.endsWith('```\n\n')) {
          const paramStr = str.replace('\n\n```function_call', '').replace('```\n\n', '')
          const param = JSON.parse(paramStr);
          const name = param.name.split('正在使用 ')[1]
          return <Box key={i} className={styles.functionCall}>
            <Icon as={require('@/assets/svg/creation_loading.svg').ReactComponent} className={styles.loading} mr='4px' />
            {t('InUse')}
            <Box className={styles.svg} dangerouslySetInnerHTML={{ __html: param.svg }}></Box>
            {name}
          </Box>
        } else if (str.startsWith('\n\n```finish_reason') && str.endsWith('```\n\n')) {
          const paramStr = str.replace('\n\n```finish_reason', '').replace('```\n\n', '')
          const param = JSON.parse(paramStr);
          const name = param.name.split('使用 ')[1]
          return <Box key={i} className={styles.functionFinish}>
            {/* {!param.finish_reason && <Icon className={styles.loading} as={require('@/assets/svg/creation_loading.svg').ReactComponent} />} */}
            {t('Use')}
            <Box className={styles.svg} css={{ ' svg': { width: '14px', height: '14px' } }} dangerouslySetInnerHTML={{ __html: param.svg }}></Box>
            {name}
          </Box>
        } else {
          return null
        }
      })
    }
  </Box>
}