import localForage from "localforage";

localForage.config({
  name: 'Wisetalker',
  description: '将数据存储在本地，以便下次更快加载',
});

export class LocalDB {
  db: LocalForage;
  constructor(namespace: string) {
    this.db = localForage.createInstance({ name: namespace });
  }

  getDbStroe() {
    return this.db.driver();
  }
}