import { encoding_for_model } from '@dqbd/tiktoken';

export const getOpenAiEncMap = () => {
  if (typeof window !== 'undefined' && (window as any).OpenAiEncMap) {
    return (window as any).OpenAiEncMap;
  }
  if (typeof global !== 'undefined' && (global as any).OpenAiEncMap) {
    return (global as any).OpenAiEncMap;
  }
  const enc = encoding_for_model('gpt-3.5-turbo', {
    '<|im_start|>': 100264,
    '<|im_end|>': 100265,
    '<|im_sep|>': 100266
  });

  if (typeof window !== 'undefined') {
    (window as any).OpenAiEncMap = enc;
  }
  if (typeof global !== 'undefined') {
    (global as any).OpenAiEncMap = enc;
  }

  return enc;
};

export const authOpenAiKey = async (key: string) => {
  // return axios
  //   .get('https://yyomkjvvykki.cloud.sealos.io/openai/v1/dashboard/billing/subscription', {
  //     headers: {
  //       Authorization: `Bearer ${key}`
  //     }
  //   })
  //   .then((res) => {
  //     if (!res.data.access_until) {
  return Promise.resolve('OpenAI Key 可能无效');
  //   }
  // })
  // .catch((err) => {
  //   console.log(err);
  //   return Promise.reject(err?.response?.data?.error?.message || 'OpenAI Key 可能无效');
  // });
};