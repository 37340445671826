import { Box, Icon, Input } from "@chakra-ui/react";
import { FC } from "react";
import { CreationTitle, CreationTitleBox } from "../";
import { CButton } from "@/components/Button";
import { useTranslation } from "react-i18next";

import styles from './index.module.scss'

interface CreationSeedProps {
  value?: number;
  onSeed?: (seed: number) => void;
}
export const CreationSeed: FC<CreationSeedProps> = (props) => {
  const { value = -1, onSeed } = props;
  const { t } = useTranslation();
  return <Box className={styles.seed}>
    <CreationTitleBox>
      <CreationTitle title={t('creation.Seed')} />
    </CreationTitleBox>
    <Box className={styles.num}>
      <Box className={styles.icon} onClick={() => onSeed && onSeed(value - 1 < -1 ? -1 : value - 1)}>
        <Icon as={require('@/assets/svg/sub.svg').ReactComponent} />
      </Box>
      <Input className={styles.value} value={value} type='number' min={-1} max={10000000}
        onChange={e => {
          const v = Number(e.target.value) || 0
          if (v < -1) {
            onSeed && onSeed(-1)
          } else if (v > 10000000) {
            onSeed && onSeed(10000000)
          } else {
            onSeed && onSeed(v)
          }
        }}
      />
      <Box className={styles.icon} onClick={() => onSeed && onSeed(value + 1 > 10000000 ? 10000000 : value + 1)}>
        <Icon as={require('@/assets/svg/add.svg').ReactComponent} />
      </Box>
      <CButton theme='primary' ml='12px' onClick={() => onSeed && onSeed(-1)}>{t('Reset')}</CButton>
    </Box>
  </Box>
}