import { Box, BoxProps } from "@chakra-ui/react";
import { MacScrollbar, MacScrollbarProps } from "mac-scrollbar";
import { FC } from "react";

import './index.css'

interface ScrollBarBoxProps extends BoxProps {
  barProps?: MacScrollbarProps
}
export const ScrollBarBox: FC<ScrollBarBoxProps> = (props) => {
  const { barProps, ...others } = props;

  return <MacScrollbar thumbStyle={horizontal => { return horizontal ? {} : ({ right: '4px' }) }} {...barProps}>
    <Box {...others}></Box>
  </MacScrollbar>
}