import { FC, useEffect } from "react";
import { Navigation } from "./components/Navigation";
import { Report } from "./components/Report";
import { Box } from "@chakra-ui/react";
import styles from './index.module.scss'
import { useState } from "react";
import { PersonalData } from "./components/PersonalData";
import { BalanceTop_up } from "./components/BalanceTop_up";
import { About } from "./components/About";
import { Suggestions } from "./components/Suggestions";
import { Points } from "./components/Points";
import { Recharge } from "./components/recharge";
import { Appearance } from "./components/Appearance";
import { ContactUs } from "./components/ContactUs";
import { SignIn } from "./components/SignIn";
import { Invite } from "./components/Invite";
import { Agents } from "./components/Agents";
import { useTranslation } from "react-i18next";
import { Home } from "./components/Home";
import { useLocation } from "react-router-dom";
import Lang from "./components/Lang";

const Personal: FC = () => {
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState('');
  const { t } = useTranslation();
  const renderContent = () => {
    switch (selectedIndex) {
      // case t('My_agent'):
      //   return <Agents />;
      case t('SignIn_is_courteous'):
        return <SignIn />;
      case t('My_invitation'):
        return <Invite />;
      // case t('Balance_top_up'):
      // return <BalanceTop_up />;
      case t('Personal_Data'):
        return <PersonalData />;
      case t('Point_records'):
        return <Points />;
      case t('Top_up_history'):
        return <Recharge />;
      case t('Appearance_preference'):
        return <Appearance />;
      case t('Contact_us'):
        return <ContactUs />;
      case t('Suggestions_Feedback'):
        return <Suggestions />;
      case t('report'):
        return <Report />;
      case t('concerning'):
        return <About />;
      case t('Sign_out'):
        return <Box>退出登录</Box>;
      case t('lang'):
        return <Lang />;
      default:
        return <Lang />;
    }
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const initialSelectedIndex = queryParams.get('selectedIndex') || '';
    if (initialSelectedIndex !== '') {
      setSelectedIndex(initialSelectedIndex)
    } else {
      setSelectedIndex(t('Personal_Data'));
    }
  }, [])
  const [paddingTop, setPaddingTop] = useState('0px');

  // 控制窗口高度变化的方法
  const updatePaddingTop = () => {
    const windowHeight = window.innerHeight;
    if (windowHeight < 700) {
      if (selectedIndex === t('My_invitation')) {
        setPaddingTop('270px');
      } else if (selectedIndex === t('report')) {
        setPaddingTop('170px');
      } else if (selectedIndex === t('SignIn_is_courteous')) {
        setPaddingTop('150px');
      } else if (selectedIndex === t('Contact_us')
        || selectedIndex === t('Suggestions_Feedback')
        || selectedIndex === t('concerning')) {
        setPaddingTop('120px');
      } else {
        setPaddingTop('0px');
      }
    } else {
      setPaddingTop('0px'); // 默认 padding-top 当高度不满足条件时
    }
  };
  useEffect(() => {
    updatePaddingTop();
    window.addEventListener('resize', updatePaddingTop);
    return () => window.removeEventListener('resize', updatePaddingTop);
  }, [selectedIndex]);
  return <Box className={styles.personal}>
    <Navigation selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
    { }
    <Box className={styles.content} pt={paddingTop}>
      {renderContent()}
    </Box>
  </Box >
}

export default Personal