import { Box, Button, Flex, Icon, Image, Input, Text, Tooltip } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { FC } from "react";
import styles from "./index.module.scss"
import { CButton } from "@/components/Button";

export const ComImageSelect: FC<any> = observer(({
  title,
  isPc,
  combos,
  selectvalue,
  SetSelectvalue
}: {
  title: string;
  isPc: boolean;
  combos: any[];
  selectvalue: string;
  SetSelectvalue: (e: string) => void;
}) => {
  const parts = title.split('*');

  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  useEffect(() => {
    const scrollable = scrollableRef.current as any;
    if (scrollable === null || typeof scrollable === 'undefined') { return }
    const onMouseDown = (e: any) => {
      isDragging.current = true;
      startX.current = e.pageX - scrollable.offsetLeft;
      scrollLeft.current = scrollable.scrollLeft;
      scrollable.classList.add('active'); // Optionally add a class when dragging
    };

    const onMouseMove = (e: any) => {
      if (!isDragging.current) return;
      e.preventDefault();
      const x = e.pageX - scrollable.offsetLeft;
      const walk = (x - startX.current) * 1; // Adjust the speed of scrolling
      scrollable.scrollLeft = scrollLeft.current - walk;
    };

    const onMouseUpOrLeave = () => {
      isDragging.current = false;
      scrollable.classList.remove('active'); // Optionally remove the class when not dragging
    };

    scrollable.addEventListener('mousedown', onMouseDown);
    scrollable.addEventListener('mouseleave', onMouseUpOrLeave);
    scrollable.addEventListener('mouseup', onMouseUpOrLeave);
    scrollable.addEventListener('mousemove', onMouseMove);

    return () => {
      scrollable.removeEventListener('mousedown', onMouseDown);
      scrollable.removeEventListener('mouseleave', onMouseUpOrLeave);
      scrollable.removeEventListener('mouseup', onMouseUpOrLeave);
      scrollable.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  return (
    <Flex className={styles.allbox}>
      <Box className={styles.titlebox}>
        {parts.map((part: any, index: any) => (
          <Text key={index} className={styles.title}>
            {part}{index < parts.length - 1 ? <span className={styles.titletips}>*</span> : null}
          </Text>
        ))}
      </Box>
      <Flex ref={scrollableRef} className={styles.scrollStyle} >
        {combos.map((item) => {
          return (
            <Box key={item.value} className={styles.imgsbox} onClick={(e) => { e.stopPropagation(); SetSelectvalue(item.value) }} >
              <Image src={require(`@/assets${item.img}`)} />
              <Text color={selectvalue == item.value ? '#000' : '#999'}>{item.text}</Text>
              {selectvalue == item.value &&
                <Box className={styles.selectIcon}>
                  <Icon as={require('@/assets/svg/imgselect.svg').ReactComponent} />
                </Box>
              }
            </Box>
          );
        })}
      </Flex>
    </Flex>
  );
})