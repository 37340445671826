import { Box, Icon, Text, useOutsideClick } from "@chakra-ui/react";
import { FC, FormEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { chatStore, ChatType } from "@/store/chat";
import { cloneDeep, debounce } from "lodash";
import { observer } from "mobx-react-lite";
import { useSelectFile } from "@/hooks/useSelectFile";
import axios from "axios";
import { userStore } from "@/store/user";
import { ObjectId, WSActionStatsTypeEnum, WSActionStatsTypeMap, adaptChatItem_openAI } from "@/utils/chat";
import { streamFetch, streamPluginFetch } from "@/utils/stream";
import { WiseImage } from "@/components/Image";
import Loading from "@/components/Loading";
import { useMessage } from "@/hooks/useMessage";
import { globalStore } from "@/store/global";

import styles from './index.module.scss'
import { fetchTokenLogin } from "@/api/user";
import { uiStrore } from "@/store/ui";

const fileMaxSize = 20 * 1024 * 1024 // 20MB
const docFileMaxSize = 10 * 1024 * 1024 // 10MB
interface InputAreaProps {
  onScrollToBottom: () => void
}
const InputArea: FC<InputAreaProps> = (props) => {
  const { onScrollToBottom } = props;

  const { t } = useTranslation();
  const InputAreaDom = useRef<HTMLDivElement>(null);
  const {
    TextareaValue, aiChatInfo, chooseModel, currentChat, ohterStartQuestion, quote, generateMsg, mediaModels,
    currentType, pluginChatInfo, generatePluginMsg, chatFile, chatDocFile, uploading, setPluginChatInfo,
    setPluginChatInfoAsFunction, setGeneratePluginMsg, getHistories, resetDefaultChatInfo, setTextareaValue,
    setChatInfo, setOtherStartQuestion, setQuote, setChatFile, setNeedReRender, setGenerateMsg, setCurrentChat,
    setChatInfoAsFunction, setChooseModel, setAgentLine, setChatDocFile, setUploading
  } = chatStore;
  const { userInfo, setUserInfo } = userStore;
  const { setLoginIsOpen } = globalStore
  const { openAlert, closeAlert } = uiStrore;
  // const [isSpeak, setIsSpeak] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const message = useMessage();
  const uploadRef = useRef<HTMLDivElement>(null);
  const [hasResult, setHasResult] = useState(true);

  const info = aiChatInfo.get(currentChat?.chatId || 'newChatId');
  const pluginInfo = pluginChatInfo.get(currentChat?.chatId || 'newChatId');
  const isChatting = generateMsg.get(currentChat?.chatId || 'newChatId')?.status === 'loading' || generatePluginMsg.get(currentChat?.chatId || 'newChatId')?.message.status === 'generating';
  const infoIsSameWithCurrentType = currentType === (currentChat?.isPlugin ? ChatType.Plugin : ChatType.AI);
  const isRisk = info?.isRisk || Object.keys(pluginInfo?.mapping || {}).some(key => pluginInfo?.mapping[key]?.contentCheck?.status === false) || false;

  const { onOpen, File } = useSelectFile({ fileType: '.jpeg,.jpg,.png', multiple: true });
  const { onOpen: onOpenDoc, File: DocFile } = useSelectFile({ fileType: '.txt,.pdf,.docx,.doc' });

  useOutsideClick({
    ref: uploadRef,
    handler: () => {
      setShowUpload(false)
    }
  })

  useEffect(() => {
    if (ohterStartQuestion) {
      setTextareaValue(ohterStartQuestion);
      InputAreaDom.current!.innerText = ohterStartQuestion;
      sendPromptBefore();
      setOtherStartQuestion('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ohterStartQuestion])

  useEffect(() => {
    if (InputAreaDom.current) {
      InputAreaDom.current.innerText = TextareaValue;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const inputDom = document.getElementById('inputArea') as HTMLInputElement;
    // 监听输入框的高度变化，用于调整滚动条
    if (!inputDom) return;
    // 监听输入框的高度变化，用于调整滚动条
    const observer = new ResizeObserver(() => {
      const agents = document.getElementById('agents') as HTMLDivElement;
      const pluginBottomCase = document.getElementById('pluginBottomCase') as HTMLDivElement;
      const toBottom = document.getElementById('toBottom') as HTMLDivElement;
      if (!inputDom) return;
      const height = inputDom.offsetHeight;
      toBottom && (toBottom.style.bottom = `${height + 20}px`); // 100% - 输入框高度
      if (agents) {
        agents!.style.bottom = `${height}px`; // 100% - 输入框高度
      }
      if (pluginBottomCase) {
        pluginBottomCase.style.bottom = `${height + 20}px`; // 100% - 输入框高度
      }
    });
    observer.observe(inputDom);

    window.addEventListener('resize', () => {
      resizeAgents();
      resizePlugin();
    }, false);

    resizeAgents();
    resizePlugin();
  }, [currentType, currentChat, userInfo])

  const resizePlugin = () => {
    const pluginBottomCase = document.getElementById('pluginBottomCase') as HTMLDivElement;
    const pluginWelcome = document.getElementById('pluginWelcome') as HTMLDivElement;
    if (!pluginBottomCase || !pluginWelcome) return;
    const welcomeRect = pluginWelcome.getBoundingClientRect();
    const agentRect = pluginBottomCase!.getBoundingClientRect();
    if ((welcomeRect.bottom + 80) > agentRect.top && window.innerHeight < 720) {
      pluginBottomCase.style.display = 'none';
    } else if (window.innerHeight > 760) {
      pluginBottomCase.style.display = 'flex';
    } else {
      pluginBottomCase.style.display = 'none';
    }
  }

  const resizeAgents = () => {
    const welcome = document.getElementById('welcome') as HTMLDivElement;
    const agents = document.getElementById('agents') as HTMLDivElement;
    if (!welcome || !agents) return;
    const welcomeRect = welcome.getBoundingClientRect();
    const agentRect = agents!.getBoundingClientRect();
    if ((welcomeRect.bottom + 80) > agentRect.top && window.innerHeight < 660) {
      agents.style.display = 'none';
    } else if (window.innerHeight > 660 && window.innerHeight < 720) {
      agents.style.display = 'block';
      setAgentLine(1);
    } else if (window.innerHeight > 720 && window.innerHeight < 770) {
      agents.style.display = 'block';
      setAgentLine(2);
    } else if (window.innerHeight > 770) {
      agents.style.display = 'block';
      setAgentLine(3);
    }
  }

  const openUpload = () => {
    if (isRisk && infoIsSameWithCurrentType) {
      message.warning({ title: t('question.risk') });
      return
    }
    if (chatFile?.length && chatFile.length >= 4) {
      message.warning({ title: t('MaxUploadImg') });
      return;
    } else if (chatDocFile?.length && chatDocFile.length >= 2) {
      message.warning({ title: t('MaxUploadFile') });
      return;
    } else {
      setShowUpload(true)
    }
  }

  const selectImgFiles = useCallback(async (files: File[]) => {
    if (files && files.length) {
      const filesInfo: ChatFileType[] = [];
      files.forEach(file => {
        if (file.size > fileMaxSize) {
          message.error({ title: `${file.name}${t('FileTooLarge')}` })
          return false
        }
        if (file.type.startsWith('image/')) {
          filesInfo.push({
            type: 'image_url',
            name: file.name,
            file,
            url: ''
          })
        } else {
          message.error({ title: `${t('NotSupported')} ${file.name} ${t('FileType')}` });
          return false
        }
      })

      if (filesInfo.length) {
        const beforeFiles = chatFile || []
        setChatFile(beforeFiles.concat(filesInfo))
        setUploading(true)
        const afterInfo: ChatFileType[] = []
        for (const item of filesInfo) {
          if (item.file) {
            try {
              const res = await uploadFiles(item.file)
              afterInfo.push({
                type: item.type,
                name: item.name,
                url: res.data.data[0]
              })
            } catch (err) {
              message.error({ title: `${item.name} ${t('UploadFailed')}` });
            }
          }
        }
        setUploading(false)
        setChatFile(beforeFiles.concat(afterInfo))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatFile])

  const selectDocFiles = useCallback(async (files: File[]) => {
    if (files && files.length) {
      const filesInfo: ChatFileType[] = [];
      files.forEach(file => {
        if (file.size > docFileMaxSize) {
          message.error({ title: `${file.name} ${t('FileTooLarge10')}` })
          return false
        }
        if (file.type.startsWith('application/') || file.type === 'text/plain') {
          filesInfo.push({
            type: file.type,
            name: file.name,
            file,
            url: ''
          })
        } else {
          message.error({ title: `${t('NotSupported')} ${file.name} ${t('FileType')}` });
          return false
        }
      })

      if (filesInfo.length) {
        const beforeFiles = chatDocFile || []
        setChatDocFile(beforeFiles.concat(filesInfo))
        setUploading(true)
        const afterInfo: ChatFileType[] = []
        for (const item of filesInfo) {
          if (item.file) {
            try {
              const res = await uploadFiles(item.file)
              afterInfo.push({
                type: item.type,
                name: item.name,
                url: res.data.data[0]
              })
            } catch (err) {
              message.error({ title: `${item.name} ${t('UploadFailed')}` });
            }
          }
        }
        setUploading(false)
        setChatDocFile(beforeFiles.concat(afterInfo))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatDocFile])

  const pasted = (e: React.ClipboardEvent<HTMLDivElement>) => {
    if (!e.clipboardData) return;
    const _imgs: File[] = []
    const items = e.clipboardData.items;
    let uploadedLenth = chatFile?.length || 0;

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.startsWith('image/')) {
        var blob = items[i].getAsFile();
        _imgs.push(blob as File);
      }
    }
    if (_imgs.length + uploadedLenth > 4) {
      message.warning({ title: t('MaxUploadImg') });
    } else if (_imgs.length) {
      selectImgFiles(_imgs)
    }
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain'); //仅提取文字
    let imgs: ChatFileType[] = [];
    let inputText: string = text;
    const matchImg = /!\[(.*?)\]\((.*?)\)/mg;
    let matcher: RegExpExecArray | null
    while ((matcher = matchImg.exec(text)) !== null) {
      imgs.push({ url: matcher[2], type: 'image_url', name: matcher[1] })
      console.log(matcher)
      inputText = inputText.replace(matcher[0], '').trim();
    }
    setChatFile(chatFile ? chatFile.concat(imgs).slice(0, 4) : imgs)
    document.execCommand("insertText", false, inputText);
    InputAreaDom.current?.scrollTo(0, InputAreaDom.current?.scrollHeight || 0);
  }

  const uploadFiles = (e: File) => {
    const formData = new FormData();
    formData.append('file', e)
    return axios.post(
      (window.proxyApi || '') + '/api/system/uploadToAliOss',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      }
    );
  }

  const deleteFile = (index: number) => {
    const new_file = chatFile?.filter((_, i) => i !== index) || [];
    setChatFile(new_file)
  }

  const deleteDocFile = (index: number) => {
    const new_file = chatDocFile?.filter((_, i) => i !== index) || [];
    setChatDocFile(new_file)
  }

  // 发送消息提前处理
  const sendPromptBefore = async () => {
    let errMeg = '';
    const value = TextareaValue || InputAreaDom.current!.innerText || '';
    const chatModel = chooseModel?.key;
    const chatId = currentChat?.chatId;
    const aiChatId = currentChat?.isPlugin ? '' : chatId

    if (!userInfo) {
      setLoginIsOpen(true)
      return
    } else if (info?.isRisk && infoIsSameWithCurrentType) {
      errMeg = t('question.risk');
    } else if (isChatting) {
      // errMeg = '正在聊天中...请等待结束';
      abortMessage();
      return;
    } else if (!value.trim().replace(/\n\s*/g, '\n')) {
      errMeg = t('PlsEnterQuestion');
    } else if (!chatModel) {
      errMeg = t('PlsSelectModel');
    } else if (uploading) {
      errMeg = t('PlsWaitImgUpload');
    }

    if (errMeg) {
      message.warning({ title: errMeg });
      return;
    }
    setHasResult(false);

    const file = chatFile || [];
    const docFile = chatDocFile || [];
    let _clone_quote = cloneDeep(quote);

    if (currentType === ChatType.Plugin) {
      // 清空输入内容
      setTextareaValue('');
      InputAreaDom.current!.innerText = '';
      setChatFile(undefined)
      onScrollToBottom(); // 滚动到底部
      setQuote(); // 清空引用
      setNeedReRender(); // 重新渲染

      let urlStrs = '';
      chatFile?.forEach(file => {
        urlStrs += `![${file.name}](${file.url})\n`
      })
      pluginSendingPrompt({
        value: `${quote?.text ? `[${quote.text}]\n---------------------------------------------\n${urlStrs + value}` : urlStrs + value}`,
        file,
        cloneQuote: _clone_quote
      })
      return;
    }

    if (currentChat?.isPlugin) {
      setCurrentChat();
    }
    const isMedia = (chatModel === 'gpt-4o' && chatFile?.length) ? true : false;
    if (!isMedia && file.length) {
      openAlert({
        title: t('tip'),
        status: 'ask',
        content: <Box className={styles.onlyMedia}>
          <Text>{t('SupportToSwitchModel')}</Text>
          <Box className={styles.model}>
            <Box className={styles.info}>
              <Box className={styles.icon}>
                <WiseImage src={mediaModels[0].expand.group.avatar || ''} />
              </Box>
              <Text className={styles.name}>
                {mediaModels[0].expand.group.name}
                <Text className={styles.key}>
                  {mediaModels[0].name && (mediaModels[0].name.slice(mediaModels[0].name.indexOf('-') + 1))}
                </Text>
              </Text>
              {
                mediaModels[0].key === 'gpt-4o' && <Text className={styles.recommend}>{t('Recommend')}</Text>
              }
            </Box>
            <Text className={styles.price}>
              {mediaModels[0].params.single_price} <Icon as={require('@/assets/svg/personalBalanced.svg').ReactComponent} />/{t('times')}
            </Text>
          </Box>
        </Box>,
        okBtnText: t('SwitchModel'),
        onclose: () => { closeAlert() },
        onOk: async () => {
          setChooseModel(mediaModels[0]);
          setHasResult(true);
          closeAlert();
        }
      })
      return
    }
    let sendHistory: ChatInfoHistory[] = [];

    setChatInfoAsFunction({
      info: (info) => {
        sendHistory = [
          {
            _id: new ObjectId().toString(),
            obj: 'Human',
            modelName: chatModel || '',
            value: quote?.text ? `[${quote.text}]\n---------------------------------------------\n${value}` : value,
            ...(isMedia ? { imgUrls: chatFile?.map(file => file.url) } : {}),
            status: 'finish',
            createTime: new Date().toISOString()
          },
          {
            _id: new ObjectId().toString(),
            obj: 'AI',
            modelName: chatModel || '',
            value: '',
            status: 'loading',
            createTime: new Date().toISOString()
          }
        ]

        return {
          ...info,
          history: [
            ...info.history,
            sendHistory[0]
          ]
        }
      },
      chatId: aiChatId
    }, true);

    // 清空输入内容
    setTextareaValue('');
    InputAreaDom.current!.innerText = '';
    isMedia && setChatFile(undefined)
    setChatDocFile(undefined)
    onScrollToBottom(); // 滚动到底部
    setQuote(); // 清空引用
    setNeedReRender(); // 重新渲染

    try {
      await sendingPrompt(sendHistory, value, isMedia, file, docFile, _clone_quote, aiChatId);
    } catch (error: any) {
      message.error({ title: error?.message || 'error' });
      setTextareaValue(value);
      InputAreaDom.current!.innerText = value;
      isMedia && setChatFile(file);
      setChatDocFile(docFile);
      const newInfo = { ...info, history: info?.history.slice(0, info.history.length - 1) || [] };
      setChatInfo({ info: newInfo, chatId: aiChatId });
      setGenerateMsg(aiChatId);
      setQuote(_clone_quote); // 恢复引用
      setNeedReRender(); // 重新渲染
      setHasResult(true);
    }
  }

  const abortMessage = () => {
    if (generateMsg.get(currentChat?.chatId || 'newChatId')) {
      const currentGeneratin = generateMsg.get(currentChat?.chatId || 'newChatId')
      if (!currentGeneratin) return;
      currentGeneratin.status = 'finish';
      setChatInfo({
        info: { ...info, history: info?.history.concat(currentGeneratin) || [] },
        chatId: currentChat?.chatId || 'newChatId'
      });
      currentGeneratin?.abortController?.abort();
      setGenerateMsg(currentChat?.chatId, undefined);
    } else {
      const currentGeneratin = generatePluginMsg.get(currentChat?.chatId || 'newChatId')
      if (!currentGeneratin) return;
      currentGeneratin.message.status = 'finished_successfully';
      setGeneratePluginMsg(currentChat?.chatId, currentGeneratin);
      currentGeneratin.abortController?.abort();
    }
  }

  const sendingPrompt = useCallback(async (prompts: ChatInfoHistory[], value: string, isMedia: boolean, file: ChatFileType[], docFiles: ChatFileType[], _clone_quote?: QuoteType, chatId?: string) => {
    const abortSignal = new AbortController();
    const signal = abortSignal.signal;
    signal.onabort = () => {
      console.log('signal.onabort')
    };
    setGenerateMsg(chatId, { ...prompts[1], abortController: abortSignal });

    const messages = adaptChatItem_openAI({ messages: prompts, reserveId: true });
    const paramData: FetchDataProps = {
      messages,
      chatId: chatId || '',
      appId: info?.modelId || WSActionStatsTypeMap[WSActionStatsTypeEnum.Chat].modelId,
      model: chooseModel?.key || '',
      type: 'chat',
      stream: true,
      quote: quote ? { model: quote.model, id: quote.id, text: quote.text } : undefined,
      ...(docFiles ? { fileUrls: docFiles.map(item => item.url) } : {})
    };

    try {
      let isFirst = true;
      // 流请求，获取数据
      const { newChatId, ...other } = await streamFetch({
        data: paramData,
        onMessage: text => {
          if (isFirst) {
            isFirst = false;
            setHasResult(true);
          }
          setGenerateMsg(chatId, newst => {
            if (newst) {
              if (text.startsWith('```__PRICE__:') && text.endsWith('__PRICE__END__```')) {
                let tokens = 0;
                try {
                  tokens = JSON.parse(text.replace('```__PRICE__: ', '').replace('__PRICE__END__```', '')).tokens
                } catch (err) {
                  tokens = 0;
                }
                newst.tokens = tokens;
              } else {
                newst.value += text;
              }
              return { ...newst, abortController: abortSignal }
            }
          });
        },
        abortSignal
      })

      const userdata = await fetchTokenLogin() as UserResult;
      setUserInfo(userdata);
      // 设置聊天内容为完成状态
      setGenerateMsg(chatId, generated => {
        generated && setChatInfoAsFunction({
          info: info => {
            return {
              ...info,
              history: [
                ...info.history,
                { ...generated, status: 'finish' }
              ]
            }
          },
          chatId,
        }, true);
        return undefined
      })
      if (other.errMsg && other.errMsg?.message === 'BodyStreamBuffer was aborted') {
        console.log(other.errMsg)
      } else if (other.errMsg && JSON.parse(other.errMsg).code === 501) {
        throw JSON.parse(other.errMsg)
      }

      setNeedReRender();
      setTimeout(() => {
        if (newChatId) {
          getHistories();
          resetDefaultChatInfo();
          setCurrentChat({
            isPlugin: false,
            chatId: newChatId,
            modelId: paramData.appId || '',
          })
        } else {
          setCurrentChat({
            isPlugin: false,
            chatId: chatId || 'newChatId',
            modelId: paramData.appId || '',
          })
        }
      }, 100);

    } catch (error: any) {
      if (Number(error.code) === 501) {
        message.error({ title: error?.message || '您的操作太快啦 ～ 请稍后重试哦' });
      } else {
        message.error({ title: error?.message || '哎呀，AI有点走神了，重新发送试试呢～' });
      }
      setGenerateMsg(chatId);
      setTextareaValue(value);
      setChatDocFile(docFiles);
      InputAreaDom.current!.innerText = value;
      setQuote(_clone_quote); // 恢复引用
      isMedia && setChatFile(file);
      if (chatId) {
        if (error.statusText === 'insufficientQuota' || Number(error.code) === 501) {
          const newInfo = { ...info, history: info?.history.slice(0, info.history.length) || [] };
          setChatInfo({ info: newInfo, chatId });
        } else {
          const newInfo = { ...info, history: info?.history.slice(0, info.history.length - 1) || [] };
          setChatInfo({ info: newInfo, chatId });
        }
      } else {
        resetDefaultChatInfo();
      }
    } finally {
      setHasResult(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info, quote, chooseModel, currentChat])

  /**
   * 多模态发送信息
   * @param value 输入内容
   */
  const pluginSendingPrompt = async (data: { value: string, file: ChatFileType[], cloneQuote?: QuoteType }) => {
    const { value, file, cloneQuote } = data;
    let beforeData: PluginChatInfo | undefined = cloneDeep(pluginChatInfo.get(currentChat?.chatId || 'newChatId'));
    try {
      const abortSignal = new AbortController();

      let params: PluginChatParamsProps = {
        uid: crypto.randomUUID(),
        conversation_id: crypto.randomUUID(),
        parent_uid: crypto.randomUUID(),
        children_uid: crypto.randomUUID(),
        model: 'gpt-4o',
        userInput: value
      }
      const pluginChatData = pluginChatInfo.get(currentChat?.chatId || 'newChatId');
      if (pluginChatData) {
        beforeData = pluginChatData
        const current = pluginChatData.mapping[pluginChatData.current_node]
        params = {
          ...params,
          conversation_id: pluginChatData.conversation_id,
          parent_uid: current.parent,
        }
      }

      // 更新页面chat对话
      const parentItem: PluginChatInfo['mapping'] = {
        [params.uid]: {
          id: params.uid,
          message: {
            id: params.uid,
            author: {
              role: 'user',
              metadata: {}
            },
            create_time: (new Date()).getTime() / 1000,
            content: {
              content_type: 'text',
              parts: [value]
            },
            status: 'finished_successfully',
            weight: 1,
            metadata: { timestamp_: 'absolute' },
            recipient: 'all',
          },
          parent: params.parent_uid,
          children: [params.children_uid]
        }
      }

      const childrenItem: PluginChatInfo['mapping'] = {
        [params.children_uid]: {
          id: params.children_uid,
          message: {
            id: params.children_uid,
            author: {
              role: 'assistant',
              metadata: {},
            },
            content: {
              content_type: 'text',
              parts: ['']
            },
            status: 'generating',
            create_time: (new Date()).getTime() / 1000,
            end_turn: true,
            weight: 1,
            metadata: { timestamp_: 'absolute' },
            recipient: 'all'
          },
          parent: params.uid,
          children: []
        }
      }
      setPluginChatInfo(beforeData ? {
        ...beforeData,
        current_node: params.children_uid,
        mapping: {
          ...beforeData.mapping,
          ...parentItem,
          [params.parent_uid]: {
            ...beforeData.mapping[params.parent_uid],
            children: [params.uid],
          }
        },
      } : {
        title: "",
        create_time: new Date(),
        update_time: new Date(),
        mapping: parentItem,
        moderation_results: [],
        current_node: params.children_uid,
        conversation_id: params.conversation_id,
        system_id: params.parent_uid
      })
      setGeneratePluginMsg(params.conversation_id, { ...childrenItem[params.children_uid], abortController: abortSignal });

      if (currentChat?.chatId !== params.conversation_id) {
        // 设置当前对话id
        setCurrentChat({
          isPlugin: true,
          chatId: params.conversation_id,
          modelId: '',
        })
      }
      // 渲染一次
      setNeedReRender();
      let isFirst = true;
      const { errMsg } = await streamPluginFetch({
        data: params,
        onMessage: (text) => {
          if (isFirst) {
            isFirst = false;
          }
          if ((text.startsWith('\n\n```function_call') && text.endsWith('```\n\n'))
            || (text.startsWith('\n\n```finish_reason') && text.endsWith('```\n\n'))) {
            setGeneratePluginMsg(params.conversation_id, (info) => {
              if (!info) return;
              return {
                ...info,
                message: {
                  ...info.message,
                  content: {
                    content_type: 'text',
                    parts: [text]
                  }
                },
              }
            })
          } else {
            setGeneratePluginMsg(params.conversation_id, (info) => {
              if (!info) return;
              const lastLength = info.message?.content?.parts?.length || 0

              const parts = info.message.content.parts;
              const position = lastLength - 1 < 0 ? 0 : lastLength - 1
              if (parts[position].startsWith('\n\n```') && parts[position].endsWith('```\n\n')) {
                parts.push(text)
              } else {
                parts[position] = (parts[position] || '') + text
              }
              return {
                ...info,
                message: {
                  ...info.message,
                  content: {
                    content_type: 'text',
                    parts: parts
                  }
                }
              }
            })
          }
        },
        abortSignal
      })
      if (errMsg && errMsg?.message === 'BodyStreamBuffer was aborted') {
        console.log(errMsg);
      } else if (errMsg && JSON.parse(errMsg).code === 501) {
        throw JSON.parse(errMsg)
      }

      setGeneratePluginMsg(params.conversation_id, (info) => {
        if (!info) return;
        setPluginChatInfoAsFunction({
          info: (state) => {
            return {
              ...state,
              mapping: {
                ...state.mapping,
                [params.children_uid]: {
                  ...info,
                  message: {
                    ...info.message,
                    status: 'finished_successfully',
                  }
                }
              }
            }
          },
          conversation_id: params.conversation_id
        })
        return {
          ...info,
          message: {
            ...info.message,
            status: 'finished_successfully',
          }
        }
      })
      setCurrentChat({
        isPlugin: true,
        chatId: params.conversation_id,
        modelId: '',
      })
      setNeedReRender();
      await getHistories();
    } catch (err: any) {
      if (Number(err.code) === 501) {
        message.error({ title: err?.message || '您的操作太快啦 ～ 请稍后重试哦' });
      } else {
        message.error({ title: err?.message || '哎呀，AI有点走神了，重新发送试试呢～' });
      }
      setTextareaValue(value);
      InputAreaDom.current!.innerText = value;
      setChatFile(file);
      setQuote(cloneQuote); // 恢复引用
      setGeneratePluginMsg(beforeData?.conversation_id)
      beforeData && setPluginChatInfo(beforeData)
    } finally {
      setHasResult(true);
    }

  }

  const changeValue: FormEventHandler<HTMLDivElement> = debounce((e) => {
    setTextareaValue((e.target as HTMLDivElement).innerText)
  }, 20)

  // const isMediaModel = chooseModel?.key === 'gpt-4o';
  return <Box className={styles.inputArea} id="inputArea">
    <Box className={styles.container}>
      <Box className={`${styles.uploadTypes} ${showUpload ? styles.show : ''}`} ref={uploadRef}>
        {/* <Box className={styles.item}>
          <Icon as={require('@/assets/svg/camera.svg').ReactComponent} />
          <Text>拍照</Text>
        </Box> */}
        <Box className={styles.item} onClick={
          () => {
            if (chatFile?.length) {
              message.warning({ title: t('AddedImgNotBoth') });
              return
            }
            if (isRisk && infoIsSameWithCurrentType) {
              message.warning({ title: t('question.risk') });
              return
            }
            if (chatDocFile?.length && chatDocFile.length >= 2) {
              message.warning({ title: t('MaxUploadFile') });
            } else {
              onOpenDoc();
            }
          }}>
          <Icon as={require('@/assets/svg/upload_file.svg').ReactComponent} />
          <Text>{t('UploadFile')}</Text>
        </Box>
        <Box className={styles.item} onClick={() => {
          if (chatDocFile?.length) {
            message.warning({ title: t('AddedFileNotBoth') });
            return
          }
          if (isRisk && infoIsSameWithCurrentType) {
            message.warning({ title: t('question.risk') });
            return
          }
          if (chatFile?.length && chatFile.length >= 4) {
            message.warning({ title: t('MaxUploadImg') });
          } else {
            onOpen();
          }
        }}>
          <Icon as={require('@/assets/svg/upload_img.svg').ReactComponent} />
          <Text>{t('UploadImg')}</Text>
        </Box>
      </Box>
      <Box className={styles.inputContainer}>
        {
          uploading ? <Loading.Icon className={styles.uploading} />
            : <Icon className={`${styles.addFile} ${isRisk && infoIsSameWithCurrentType ? styles.disabled : ''}`}
              onClick={openUpload} as={require('@/assets/svg/add_circle.svg').ReactComponent}
            />
        }

        <Box className={styles.userInput}>
          {
            chatFile && chatFile.length ? <Box className={styles.fileList}>
              {
                chatFile.map((file, index) => {
                  return <Box key={index} className={styles.fileItem}>
                    <Icon onClick={() => deleteFile(index)} className={styles.closeIcon} as={require('@/assets/svg/clear.svg').ReactComponent} />
                    <WiseImage className={styles.img} src={file.url} />
                  </Box>
                })
              }
              {
                chatFile.length >= 4 ? <Box className={`${styles.fileItem} ${styles.max}`}>
                  <Icon as={require('@/assets/svg/warning_gray.svg').ReactComponent} />
                  <Text className={styles.maxText}>{t('MaxUploadImg')}</Text>
                </Box> : null
              }
            </Box> : null
          }
          {
            chatDocFile && chatDocFile.length ? <Box className={styles.fileList}>
              {
                chatDocFile.map((file, index) => {
                  const type = file.type === 'application/pdf' ? 'pdf' : file.type.indexOf('doc') > -1 ? 'doc' : 'txt';
                  return <Box key={index} className={styles.docFileItem}>
                    <Icon onClick={() => deleteDocFile(index)} className={styles.closeIcon} as={require('@/assets/svg/clear.svg').ReactComponent} />
                    <Box className={styles.docName}>{file.name}</Box>
                    <Box className={styles.fileType}>
                      <Icon as={require(`@/assets/svg/im_${type}.svg`).ReactComponent} />
                      <Text>{type.toUpperCase()}</Text>
                    </Box>
                  </Box>
                })
              }
              {
                chatDocFile.length >= 2 ? <Box className={`${styles.fileItem} ${styles.max}`}>
                  <Icon as={require('@/assets/svg/warning_gray.svg').ReactComponent} />
                  <Text className={styles.maxText}>{t('MaxUploadFile')}</Text>
                </Box> : null
              }
            </Box> : null
          }
          {
            quote ? <Box className={styles.quote}>
              <Text className={styles.quoteText}>{quote.model}: {quote.text}</Text>
              <Icon className={styles.delete} onClick={() => setQuote()} as={require('@/assets/svg/close_x.svg').ReactComponent} />
            </Box> : null
          }
          <Box
            ref={InputAreaDom}
            id='inputAreaDom'
            contentEditable={!(isRisk && infoIsSameWithCurrentType)}
            content={(isRisk && infoIsSameWithCurrentType) ? t('question.risk') : t('question.input_your_question')}
            className={`${styles.input} ${styles.hasFile}`}
            onClick={() => {
              if (isRisk && infoIsSameWithCurrentType) {
                message.warning({ title: t('question.risk') });
              }
            }}
            onKeyDown={e => {
              // shift + enter 换行
              if (e.keyCode === 13 && e.shiftKey) {
              } else if (e.keyCode === 13 && !e.shiftKey) {// 触发快捷发送
                if (isChatting) {
                  message.warning({ title: t('WaitChatEnd') });
                } else {
                  sendPromptBefore();
                }
                e.preventDefault();
              }
            }}
            onPaste={pasted}
            onInput={changeValue}
          />
        </Box>
        {/* <Icon className={styles.micphone}
          as={require(`@/assets/svg/${isSpeak ? 'chat_micphone_active' : 'chat_micphone'}.svg`).ReactComponent}
        /> */}
        {/*!TextareaValue && !isChatting ? <Icon className={styles.call} as={require('@/assets/svg/chat_audio_call.svg').ReactComponent} /> */}
        {

          <Box className={`${styles.send} ${((!TextareaValue && !isChatting) || !hasResult) ? styles.disabled : ''}`} onClick={() => {
            if (hasResult) {
              sendPromptBefore();
            }
          }}>
            {
              isChatting ? <Box className={styles.generatingIcon}></Box>
                : <Icon as={require('@/assets/svg/send.svg').ReactComponent} />
            }
          </Box>
        }
      </Box>
    </Box>
    <File onSelect={selectImgFiles} />
    <DocFile onSelect={selectDocFiles} />
  </Box >
}

export default observer(InputArea)