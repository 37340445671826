export const imLottieJson = {
  "v": "5.8.1",
  "fr": 60,
  "ip": 0,
  "op": 60,
  "w": 70,
  "h": 70,
  "nm": "选中——聊天",
  "ddd": 0,
  "assets": [
    {
      "id": "image_0",
      "w": 150,
      "h": 151,
      "p": require('@/assets/lottie/im.png'),
      "e": 0
    },
    {
      "id": "comp_0",
      "nm": "静态——聊天",
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          "nm": "长",
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 10,
                  "s": [
                    13.938,
                    21,
                    0
                  ],
                  "to": [
                    3.677,
                    0,
                    0
                  ],
                  "ti": [
                    -3.677,
                    0,
                    0
                  ]
                },
                {
                  "t": 20,
                  "s": [
                    36,
                    21,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                -890.094,
                -93.019,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 20,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 30,
                  "s": [
                    50,
                    100,
                    100
                  ]
                },
                {
                  "t": 40,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "rc",
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      19,
                      3.962
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 184,
                    "ix": 4
                  },
                  "nm": "矩形路径 1",
                  "mn": "ADBE Vector Shape - Rect",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "填充 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      -899.594,
                      -93.019
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "矩形 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 120,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          "nm": "长1",
          "tt": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                25.937,
                20.867,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0.406,
                -6.06,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "rc",
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      22,
                      8
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "nm": "矩形路径 1",
                  "mn": "ADBE Vector Shape - Rect",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "填充 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0.438,
                      -6.06
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "矩形 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 120,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          "nm": "短",
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 20,
                  "s": [
                    13.812,
                    30,
                    0
                  ],
                  "to": [
                    3.698,
                    0,
                    0
                  ],
                  "ti": [
                    -3.698,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 0.667
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0.333
                  },
                  "t": 30,
                  "s": [
                    36,
                    30,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 40,
                  "s": [
                    36,
                    30,
                    0
                  ],
                  "to": [
                    -1.167,
                    0,
                    0
                  ],
                  "ti": [
                    1.167,
                    0,
                    0
                  ]
                },
                {
                  "t": 50,
                  "s": [
                    29,
                    30,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                -893.594,
                -93.019,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 30,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 40,
                  "s": [
                    50,
                    100,
                    100
                  ]
                },
                {
                  "t": 50,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "rc",
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      12,
                      3.962
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 184,
                    "ix": 4
                  },
                  "nm": "矩形路径 1",
                  "mn": "ADBE Vector Shape - Rect",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "填充 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      -899.594,
                      -93.019
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "矩形 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 120,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          "nm": "短1",
          "tt": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                25.937,
                29.867,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0.406,
                -6.06,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "rc",
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      22,
                      8
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "nm": "矩形路径 1",
                  "mn": "ADBE Vector Shape - Rect",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "填充 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0.438,
                      -6.06
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "矩形 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 120,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 2,
          "nm": "聊天.png",
          "cl": "png",
          "refId": "image_0",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                26,
                26,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                75,
                75.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                34,
                34,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 120,
          "st": 0,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 0,
      "nm": "静态——聊天",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            34.35,
            61.65,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            26,
            52,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 0,
              "s": [
                0,
                0,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 10,
              "s": [
                110,
                110,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 20,
              "s": [
                80,
                80,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 30,
              "s": [
                105,
                105,
                100
              ]
            },
            {
              "t": 40,
              "s": [
                100,
                100,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 52,
      "h": 52,
      "ip": 0,
      "op": 120,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}