import { Box, Icon, Text } from "@chakra-ui/react";
import { FC } from "react";
import { CreationTitle, CreationTitleBox } from "../";
import { useTranslation } from "react-i18next";

import styles from './index.module.scss'

interface CreationScaleProps {
  value?: string;
  scaleList?: ScalType[];
  onScaleChange?: (scale: string) => void;
}
export const CreationScale: FC<CreationScaleProps> = (props) => {
  const { value, scaleList, onScaleChange } = props;
  const { t } = useTranslation();
  return <Box className={styles.scale}>
    <CreationTitleBox>
      <CreationTitle title={t('creation.imgRatio')} />
    </CreationTitleBox>
    <Box className={styles.list}>
      {
        scaleList?.map((scale, index) => {
          const scales = scale.name.split(':');
          return <Box
            key={index}
            className={`${styles.item} ${value === scale.name ? styles.active : ''}`}
            onClick={() => onScaleChange?.(scale.name)}
          >
            <Icon className={styles.icon} as={require(`@/assets/svg/scale_${scales[0]}_${scales[1]}.svg`).ReactComponent} />
            <Text>{scale.name}</Text>
          </Box>
        })
      }
    </Box>
  </Box>
}