import { FC } from "react";
import { Box, Modal, ModalBody, ModalContent, ModalContentProps, ModalOverlay, ModalOverlayProps, ModalProps } from "@chakra-ui/react";

import styles from './index.module.scss'

interface CModalProps extends ModalProps {
  overlayProps?: ModalOverlayProps
  contentProps?: ModalContentProps
}
export const CModal: FC<CModalProps> = (props) => {
  const { overlayProps, contentProps, ...others } = props
  return <Modal isCentered {...others}>
    <ModalOverlay className={styles.overlay} zIndex={39} {...overlayProps} />
    <ModalBody>
      <ModalContent className={styles.content} onClick={e => props.onClose()} containerProps={{ zIndex: 40, w: '111.11vw' }} {...contentProps}>
        <Box onClick={e => e.stopPropagation()}>
          {
            props.children
          }
        </Box>
      </ModalContent>
    </ModalBody>
  </Modal >
}
