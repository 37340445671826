import { Box, BoxProps, Icon, Text } from "@chakra-ui/react";
import { FC } from "react";

import styles from './index.module.scss'

interface WisePageProps extends BoxProps {
  total: number
  pageNum: number
  pageSize?: number
  onPageChange?: (page: number) => void
}
export const WisePage: FC<WisePageProps> = (props) => {
  const { total, pageNum, pageSize = 10, onPageChange, className, ...others } = props;

  const page = Math.ceil(total / pageSize);
  const isLast = pageNum >= page;
  const isFirst = pageNum <= 1;

  const prevPage = () => {
    const prevNum = pageNum - 1;
    if (prevNum < 1 || !onPageChange || isFirst) return;
    onPageChange && onPageChange(prevNum)
  }

  const nextPage = () => {
    const nextNum = pageNum + 1;
    if (nextNum > page || !onPageChange || isLast) return;
    onPageChange && onPageChange(nextNum)
  }
  return <Box className={`${styles.wisePage} ${className}`} {...others}>
    <Box className={styles.content}>
      <Box className={`${styles.l} ${isFirst ? styles.disable : ''}`} onClick={prevPage}>
        <Icon as={require('@/assets/svg/arrow_r.svg').ReactComponent} />
      </Box>
      {
        new Array(page).fill(0).map((_, index) => {
          return <>
            {
              index === 1 && page > 8 && pageNum > 2 && < Text className={styles.page}>...</Text >
            }
            {
              page > 8 && index < (pageNum + 3) && index > (pageNum - 3) ?
                <Text key={index} className={`${styles.page} ${pageNum === index + 1 ? styles.active : ''}`}
                  onClick={() => {
                    if (!onPageChange || index + 1 === pageNum) return;
                    onPageChange && onPageChange(index + 1)
                  }}
                >
                  {index + 1}
                </Text> : page <= 8 && <Text key={index} className={`${styles.page} ${pageNum === index + 1 ? styles.active : ''}`}
                  onClick={() => {
                    if (!onPageChange || index + 1 === pageNum) return;
                    onPageChange && onPageChange(index + 1)
                  }}
                >
                  {index + 1}
                </Text>
            }
            {
              index === page - 2 && page > 8 && pageNum < page - 3 && <Text className={styles.page}>...</Text>
            }
          </>
        })
      }
      <Box className={`${styles.r} ${isLast ? styles.disable : ''}`} onClick={nextPage}>
        <Icon as={require('@/assets/svg/arrow_r.svg').ReactComponent} />
      </Box>
    </Box>
  </Box >
}