import { Text, Icon, Modal, ModalContent, ModalOverlay, Box } from "@chakra-ui/react";
import React, { FC, useCallback } from "react";
import styles from './index.module.scss'
import { useGlobalToast } from "@/hooks/useGlobalToast";

export interface GlobalToastProps {
  status?: 'warning' | 'tips' | 'success' | 'error'
  title?: React.ReactNode
  content?: React.ReactNode
  onOk?: () => void
  onClose?: () => void
  footer?: React.ReactNode
  showClose?: boolean
  children?: React.ReactNode
  isOpen?: boolean
  okBtn?: React.ReactNode
  cancelBtn?: React.ReactNode
}
/**
 * footer优先级比okBtn高
 * @param props 
 * @returns 
 */
const GlobalToast: FC<GlobalToastProps> = (props) => {
  const { status, isOpen, title } = props
  const { closeToast } = useGlobalToast()
  const cls = `${styles.newtoast} ${styles[props?.status || '']}`
  const StatusIcon = useCallback(() => {
    switch (status) {
      case 'error':
        return <Icon as={require('@/assets/toasticon/error.svg').ReactComponent} className={styles.icon} />
      case 'success':
        return <Icon as={require('@/assets/toasticon/success.svg').ReactComponent} className={styles.icon} />
      case 'tips':
        return <Icon as={require('@/assets/toasticon/tips.svg').ReactComponent} className={styles.icon} />
      case 'warning':
        return <Icon as={require('@/assets/toasticon/warning.svg').ReactComponent} className={styles.icon} />
      default:
        return <Icon as={require('@/assets/toasticon/warning.svg').ReactComponent} className={styles.icon} />
    }
  }, [status])

  return <Modal isOpen={!!isOpen} onClose={closeToast} isCentered>
    <ModalContent className={styles.modalcontent}>
      <Box className={cls}>
        <StatusIcon />
        <Text>{title}</Text>
      </Box>
    </ModalContent>
  </Modal>
}

export default GlobalToast