import { ipcRendererInvoke } from "@/utils/electron";
import { makeAutoObservable } from "mobx";
import { chatStore } from "./chat";
import { creationStore } from "./creation";
import { makePersistable } from "mobx-persist-store";

class GlobalStore {
  loginIsOpen: boolean = false;
  loginPhone: string = '';
  loginIsAgreeProtocol: boolean = false;
  canCloseLoginModal: boolean = true;
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'globalStore',
      properties: ['loginIsAgreeProtocol'],
      storage: window.localStorage
    })
  }
  setLoginIsOpen(isOpen: boolean) {
    this.loginIsOpen = isOpen;
  }
  setLoginPhone(phone: string) {
    this.loginPhone = phone;
  }
  setLoginIsAgreeProtocol(isAgree: boolean) {
    this.loginIsAgreeProtocol = isAgree;
  }

  clear() {
    ipcRendererInvoke('clearCookie');
    chatStore.reset();
    creationStore.reset();
    localStorage.clear(); //清除所有缓存
  }

  setCanCloseLoginModal(canClose: boolean) {
    this.canCloseLoginModal = canClose;
  }

  loginSuccess(path: string) {
    if (path === '/chat') {
      chatStore.getHistories();
    }
    console.log('loginSuccess', path)
  }
}

export const globalStore = new GlobalStore();