import { Flex, Box, Text, ChakraProvider, Progress, Icon } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, MouseEventHandler, useEffect } from "react";
import styles from "./index.module.scss";
import { useMessage } from "@/hooks/useMessage";
import { CButton } from "@/components/Button";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { ScrollBarBox } from "@/components/ScrollBox";
import { ProgressBar } from "@/components/ProgressBar";
import { toolStore } from "@/store/tool";
import { uiStrore } from "@/store/ui";


export type ExportChatType = 'md' | 'pdf' | 'html' | 'word';
export const ComToSpeechHistory: FC<any> = observer(({
  historyCache,
  type,
  setHistoryCache,
  setViewhistory,
  onDelete,
  onclick,
  historytitle,
  historycontent,
  onMore
}: {
  historyCache: any[];
  type: string,
  setHistoryCache: (data: any) => void;
  setViewhistory: (...args: any[]) => void;
  onDelete: (id: string) => void;
  onclick: (item: any) => void;
  historytitle?: string;
  historycontent?: string;
  isVIP?: boolean
  onMore: any;
}) => {
  const { getTranslations } = toolStore;
  const updateHistoryCache = async () => {
    const hisdata = await getTranslations({ types: type });
    setHistoryCache(hisdata);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const itemWithStatusOne = sortedList.find(item => item.status === 1);
      if (itemWithStatusOne) {
        updateHistoryCache();
      }
    }, 3000);

    return () => clearInterval(interval); // 清理定时器
  }, []);
  const message = useMessage();
  const { openAlert, closeAlert } = uiStrore;
  let parentField = '';
  let childField = '';
  if (historytitle && historytitle.includes('.')) {
    const fields = historytitle.split('.');
    parentField = fields[0];
    childField = fields[1];
  } else {
    parentField = historytitle!
  }

  const sortedList = historyCache.slice().sort((a, b) => new Date(b.createdate).getTime() - new Date(a.createdate).getTime());
  return (
    <Flex className={styles.hisFlex}>
      <Box className={styles.centerItemsbox}>
        <Text className={styles.title}>生成历史</Text>
      </Box>
      <ScrollBarBox className={styles.hisBox}>
        {sortedList.map((item, index) => {
          const statusDesc = (() => {
            switch (item.status.toString()) {
              case '0':
                return '待开始';
              case '8':
                return '排队中';
              case '1':
                return '正在生成中，请稍等....';
              case '2':
                return '有新声音已生成';
              case '3':
                return '生成失败';
              default:
                return '未知状态';
            }
          })();
          const statusColor = (() => {
            switch (item.status.toString()) {
              case '0':
              case '8':
              case '1':
                return '#FF951A';
              case '2':
                return '#689F38';
              case '3':
                return '#F76560';
              default:
                return '#FF951A';
            }
          })();
          return (
            <Flex key={item._id} className={styles.historyValue} cursor={item.status === 2 ? 'pointer' : ''}
              onClick={() => { if (item.status !== 3) { onclick(item); setViewhistory(item); } }}>
              {item.status === 1 && <Flex className={styles.prohibitBox} onClick={(e) => e.stopPropagation()}></Flex>}
              <Flex className={styles.historyValuebox}>
                <Box className={styles.hisTitleBox}>
                  <Flex color={statusColor}>
                    {statusDesc}
                  </Flex>
                  <Icon as={require('@/assets/svg/speechMore.svg').ReactComponent} className={styles.more}
                    onClick={(e: any) => { e.stopPropagation(); onMore && onMore(e, index, item) }} />
                </Box>
                <Box className={styles.hisTitleBox}>
                  <Text>
                    文本转语音 · {item.apiType === 'microsoft' ? '微软语音' : '文思GPT'}
                  </Text>
                </Box>
                <Text className={styles.hisContentsBox}>
                  {item.speaks[0].text}
                </Text>
                {item.status === 1 && <ProgressBar value={item.extends.process ? item.extends.process : '0'} />}
                <Flex className={styles.hisDateBox}>
                  <Flex>
                    <Icon w={'18px'} height={'18px'} as={require(`@/assets/svg/datetime.svg`).ReactComponent} className={styles.icon} />
                    <Text>
                      {item.time ? (
                        item.time.toLocaleString()
                          .replace('Z', '')
                          .replace('T', ' ')
                          .substring(0, 16)
                      ) : (
                        ''
                      )}
                    </Text>
                  </Flex>
                  <Flex mb={'3px'}>
                    <Icon w={'18px'} height={'18px'} as={require(`@/assets/svg/imgmodel.svg`).ReactComponent} />
                    <Text>{item.speaks[0].name}</Text>
                  </Flex>
                  <Flex>
                    <Icon w={'15px'} height={'15px'} as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
                    <Text>{item.price}</Text>
                  </Flex>
                  <Flex >
                    {/* {item.status === 2 && (
                      <Icon w={'22px'} height={'22px'} ml={'10px'} onClick={(e) => { e.stopPropagation(); handleClick() }} as={require('@/assets/svg/toolsicon/download.svg').ReactComponent} />
                    )}
                    <Icon w={'22px'} height={'22px'} ml={'10px'} onClick={(e) => {
                      e.stopPropagation();
                      openAlert({
                        title: '提示',
                        content: '请确认是否要删除这条记录？',
                        onOk: () => {
                          closeAlert();
                          onDelete(item._id)
                        }
                      })
                    }} as={require('@/assets/svg/toolsicon/delete.svg').ReactComponent} /> */}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )
        })}
      </ScrollBarBox>
    </Flex>
  );
})

