import { createHash } from 'crypto-browserify';
import { useMessage } from '@/hooks/useMessage';

/**
 * copy text data
 */
export const useCopyData = () => {
  const message = useMessage()

  return {
    copyData: async (data: string, title: string = '复制成功') => {
      try {
        if (navigator.clipboard) {
          await navigator.clipboard.writeText(data);
        } else {
          throw new Error('');
        }
      } catch (error) {
        const textarea = document.createElement('textarea');
        textarea.value = data;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
      }
      message.success({ title })
    }
  };
};

/**
 * 密码加密
 */
export const createHashPassword = (text: string) => {
  const hash = createHash('sha256').update(text).digest('hex');
  return hash;
};

/**
 *
 * @returns 判断是否微信浏览器打开
 */
export function isWechatOpen() {
  return typeof navigator != 'undefined' ? navigator?.userAgent?.toLowerCase().indexOf('micromessenger') !== -1 : false
}