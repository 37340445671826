import { Box, Button, Flex, Icon, Image, Input, Text, Tooltip } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { FC } from "react";
import styles from "./index.module.scss"
import { CButton } from "@/components/Button";

export const ComTags: FC<any> = observer(({
  title,
  array,
  style,
  SetStyle,
  fathervalue,
  fatherlist,
  father,
  isselectvalue
}: {
  title: string;
  array: any[];
  style: any;
  SetStyle: (e: any) => void;
  fathervalue?: any;
  fatherlist?: any;
  father?: any;
  isselectvalue?: boolean;
}) => {
  const uniqueParents = Array.from(new Set(father.map((item: any) => item.parents)));
  useEffect(() => {
    const allContainStyle = array.some(item => item.text === style);
    const test = array.includes(style);
    const zdyContain = zdyselects.includes(style);
    if (test === false && allContainStyle === false && zdyContain === false) {
      SetZdySelects((prevState: any) => ([...prevState, style]));
      handleButtonClick(style);
    }
  }, [array, style])
  useEffect(() => {
    if (array && array.length > 0 && isselectvalue) {
      SetStyle(array[0].text);
      SetZdySelects([]);
    }
  }, [])
  let conttitle = title;
  if (fatherlist) {
    const ctitle = title.replace(/\.?\s*\${(.*?)}/g, (_: any, key: any) => {
      return key in fatherlist ? fatherlist[key] : '无';
    });
    conttitle = ctitle;
  }

  const handleButtonClick = (item: any) => {
    SetStyle(item);
  };
  const [hover, setHover] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const checkScrollButtons = () => {
    if (scrollableRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollableRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };
  useEffect(() => {
    const checkForScrollbar = () => {
      if (scrollableRef.current) {
        const hasHorizontalScrollbar = scrollableRef.current.scrollWidth > scrollableRef.current.clientWidth;
        setShowScrollButton(hasHorizontalScrollbar);
        checkScrollButtons(); // Check initial scroll button state
      }
    };
    checkForScrollbar();
    window.addEventListener('resize', checkForScrollbar);
    // Add scroll event listener
    scrollableRef.current?.addEventListener('scroll', checkScrollButtons);

    return () => {
      window.removeEventListener('resize', checkForScrollbar);
      // Remove scroll event listener
      scrollableRef.current?.removeEventListener('scroll', checkScrollButtons);

    };
  }, []);

  const scroll = (direction: any) => {
    if (scrollableRef.current) {
      const { current } = scrollableRef;
      const scrollAmount = direction === 'left' ? -75 : 75;
      current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      setTimeout(checkScrollButtons, 200);
    }
  };
  const parts = conttitle.split('*');

  const [editMode, setEditMode] = useState<null | number>(null);
  const [zdyselects, SetZdySelects] = useState<any>([]);
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const addzdySelects = () => {
    SetZdySelects((prevState: any) => ([...prevState, '']));
  }
  const handleInputChange = (index: any, value: any) => {
    const newZdySelects = zdyselects.map((item: any, i: any) => {
      if (i === index) {
        return value;
      } else {
        return item;
      }
    });
    SetZdySelects(newZdySelects);
    SetStyle(value);
  };
  const handleZdyClick = (index: any) => {
    SetStyle(zdyselects[index])
    setTimeout(() => inputRefs.current[index]?.focus(), 0);
  };
  const handleClickOutside = (event: any) => {
    if (editMode !== null && inputRefs.current[editMode] && !inputRefs.current[editMode].contains(event.target)) {
      setEditMode(null);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editMode]);

  return (
    <div>
      {(
        typeof fathervalue === 'undefined' ||
        typeof fatherlist === 'undefined' ||
        uniqueParents.includes(fatherlist[fathervalue])
      ) && (
          <Flex className={styles.allbox}>
            <Box className={styles.titlebox}>
              {parts.map((part: any, index: any) => (
                <Text key={index} className={styles.title}>
                  {part}{index < parts.length - 1 ? <span className={styles.titletips}>*</span> : null}
                </Text>
              ))}
            </Box>
            <Box>
              {array.map((item) => {
                const shouldDisplayOption = !item.parents || !style || style[item.parents] === item.parents;
                // console.log(item.parents,"判断是否存在父级",shouldDisplayOption)
                if (!item.parents) {
                  return (
                    <CButton
                      key={item.text} className={style === item.text ? styles.selectButton : styles.button}
                      onClick={() => handleButtonClick(item.text)}
                    >
                      {item.value && <Icon mr={1} name={item.value} />}
                      <Text>{item.text}</Text>
                    </CButton>
                  )
                } else {
                  if (fatherlist && fatherlist[fathervalue] === item.parents) {
                    return (
                      <CButton
                        key={item.text} className={style === item.text ? styles.selectButton : styles.button}
                        onClick={() => handleButtonClick(item.text)}
                      >
                        {item.value && <Icon mr={1} name={item.value} />}
                        <Text>{item.text}</Text>
                      </CButton>
                    )
                  }
                  return null;
                }
              })}
              {zdyselects.map((item: any, index: any) => {
                return (
                  <React.Fragment key={'zdybuttons' + index}>
                    {editMode === index ? (
                      <Input key={'zdyinput' + index}
                        value={item} ref={el => inputRefs.current[index] = el!}
                        onChange={(e) => handleInputChange(index, e.target.value)} placeholder={`请输入${conttitle}`}
                        onBlur={() => setEditMode(null)} />
                    ) : (
                      <Tooltip label={'双击即可编辑此自定义选项'}>
                        <CButton
                          key={'zdy' + index}
                          onClick={() => { handleZdyClick(index) }}
                          onDoubleClick={() => { setEditMode(index) }}
                        >
                          {item}
                        </CButton>
                      </Tooltip>
                    )}
                  </React.Fragment>
                )
              })}
              {/* <CButton className={styles.zdybutton} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
                onClick={addzdySelects}>
                +
              </CButton> */}
            </Box>
          </Flex>
        )}
    </div>
  );
})