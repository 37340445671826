import { Flex, Box, Text, Icon, Image, BoxProps } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useState } from "react";
import styles from "./index.module.scss";
import { ScrollBarBox } from "@/components/ScrollBox";
import { HdrestorationUpStatusEnum } from "@/store/tool";
import RotatingCircles from "@/components/RotatingCircles";
import { useTranslation } from "react-i18next";

interface ListBox extends BoxProps {
  item: any
  history: any
}

export const ImgsHistory: FC<any> = observer(({
  history,
  type
}: {
  history: any[]
  type: boolean
}) => {
  const { t } = useTranslation();

  const handleDownload = (url: any) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = '';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const ListBox: FC<ListBox> = useCallback((props: any) => {
    const { item, history } = props;
    let statusInfo = {
      color: '',
      text: ''
    }
    if (type) {
      switch (Number(item.status)) {
        case HdrestorationUpStatusEnum.PROCESSING:
          statusInfo = { text: t('creation.Zoomingin') + '...', color: '#565CFA' }
          break;
        case HdrestorationUpStatusEnum.COMPLETED:
          statusInfo = { text: t('creation.AmplifySuccess'), color: '#09AD19' }
          break;
        case HdrestorationUpStatusEnum.FAIL:
          statusInfo = { text: t('creation.ZoomFailed'), color: '#F76560' }
          break;
        default:
          statusInfo = { text: t('creation.Zoomingin') + '...', color: '#565CFA' }
          break;
      }
    } else {
      switch (Number(item.status)) {
        case HdrestorationUpStatusEnum.PROCESSING:
          statusInfo = { text: t('creation.BGRemovaling') + '...', color: '#565CFA' }
          break;
        case HdrestorationUpStatusEnum.COMPLETED:
          statusInfo = { text: t('creation.RemovalSuccess'), color: '#09AD19' }
          break;
        case HdrestorationUpStatusEnum.FAIL:
          statusInfo = { text: t('creation.RemovalFailed'), color: '#F76560' }
          break;
        default:
          statusInfo = { text: t('creation.BGRemovaling') + '...', color: '#565CFA' }
          break;
      }
    }
    const date = new Date(item.uploadTime);
    date.setHours(date.getHours());
    const month = String(date.getMonth() + 1).padStart(2, '0 ')
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const [size, setSize] = useState<{ before: { w: number, h: number }, after: { w: number, h: number } }>()
    const formattedDate = `${month}-${day} ${hours}:${minutes}`;
    return (
      <Box key={item._id} className={styles.hisbox}>
        <Flex className={styles.headbox}>
          <Text className={styles.filename}>{item.fileName}</Text>
          <Flex color={statusInfo.color}>
            {Number(item.status) === HdrestorationUpStatusEnum.COMPLETED &&
              <Icon as={require('@/assets/svg/hdrSucess.svg').ReactComponent} />}
            {Number(item.status) === HdrestorationUpStatusEnum.FAIL &&
              <Icon as={require('@/assets/svg/hdrError.svg').ReactComponent} />}
            {statusInfo.text}
          </Flex>
        </Flex>
        <Flex className={styles.imgboxs}>
          {type ?
            <Box className={styles.imgbox}>
              <Box w={((size?.before?.w || 0) / (size?.after?.w || 0) * 100) + '%'}
                h={((size?.before?.h || 0) / (size?.after?.h || 0) * 100) + '%'} alignItems={'center'}>
                <Image onLoad={e => {
                  const { naturalWidth, naturalHeight } = e.target as HTMLImageElement;
                  setSize(pre => ({
                    ...pre!,
                    before: { w: naturalWidth, h: naturalHeight }
                  }))
                }} className={styles.brforeimg} src={item.beforeUrl} />
              </Box>
              <Flex className={styles.tipstext}>{t('creation.BeforeZoomin')}</Flex>
            </Box>
            :
            <Box className={styles.imgbox}>
              <Image className={styles.brforeimg} src={item.beforeUrl} />
              <Flex className={styles.tipstext}>{t('creation.BeforeRemoval')}</Flex>
            </Box>
          }
          {Number(item.status) === HdrestorationUpStatusEnum.COMPLETED ? (
            <Box className={styles.imgbox} _hover={{ ' #see-detail': { display: 'flex' } }}>
              < Image onLoad={e => {
                const { naturalWidth, naturalHeight } = e.target as HTMLImageElement;
                setSize(pre => ({
                  ...pre!,
                  after: { w: naturalWidth, h: naturalHeight }
                }))
              }} src={item.afterUrl} className={styles.afterimg}
              />
              <Box id='see-detail' className={styles.downloadicon} onClick={() => { handleDownload(item.afterUrl) }}>
                <Icon as={require('@/assets/svg/toolsicon/downloadnot.svg').ReactComponent} />
              </Box>
              <Flex className={styles.tipstext}>{type ? t('creation.AfterZoomin') : t('creation.AfterRemoval')}</Flex>
            </Box>
          ) : Number(item.status) === HdrestorationUpStatusEnum.FAIL ? (
            <Box className={styles.errorbox}>
              <Icon as={require('@/assets/svg/creation_fail.svg').ReactComponent} />
              <Text>{item.message ? item.message : t('creation.ImageFixFailed')}</Text>
              <Flex className={styles.tipstext}>{type ? t('creation.AfterZoomin') : t('creation.AfterRemoval')}</Flex>
            </Box>
          ) : (
            <Box className={styles.loadingbox}>
              <RotatingCircles />
              <Text>{t('creation.PlsWait3Min') + '~'}</Text>
              <Flex className={styles.tipstext}>{type ? t('creation.AfterZoomin') : t('creation.AfterRemoval')}</Flex>
            </Box>
          )}
        </Flex>
        <Flex className={styles.footbox}>
          {type &&
            <>
              <Icon as={require(`@/assets/svg/imgmodel.svg`).ReactComponent} className={styles.icon} />
              <Text>{item.elements.model}</Text>
            </>}
          <Icon as={require(`@/assets/svg/datetime.svg`).ReactComponent} className={styles.icon} />
          <Text>{formattedDate}</Text>
          {Number(item.status) === HdrestorationUpStatusEnum.COMPLETED && (
            <Flex>
              <Flex textDecoration={item.isuservip ? 'line-through' : ''}>
                <Icon className={styles.points} as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
                {item.price} {t('points')}
              </Flex>
              {item.isuservip && <Flex className={styles.viptext}>
                <Icon as={require(`@/assets/svg/navisVip.svg`).ReactComponent} />
                {t('vipFree')}
              </Flex>}
            </Flex>
          )}
        </Flex>
      </Box>
    )
  }, []);

  return (
    <Flex className={styles.hisFlex}>
      <Box className={styles.centerItemsbox}>
        <Text className={styles.title}>{t('history')}</Text>
      </Box>
      <ScrollBarBox className={styles.hisBoxs}>
        {history.length > 0 ?
          history.map((item, index) => { return <ListBox key={index} item={item} history={history} /> })
          :
          <Flex className={styles.fullbox}>
            <Icon as={require(`@/assets/svg/toolsicon/nofound.svg`).ReactComponent} />
            <Text className={styles.prompt}>{t('NoHistory')}</Text>
          </Flex>
        }
      </ScrollBarBox>
    </Flex>
  );
})

