import { Icon, IconProps } from "@chakra-ui/react";
import { FC } from "react";

import styles from './index.module.scss'

interface LoadingProps extends IconProps { }
const WiseIcon: FC<LoadingProps> = (props) => {
  const { className, ...others } = props
  return <Icon
    as={require('@/assets/svg/loading.svg').ReactComponent}
    className={`${styles.loading} ${className}`}
    w='32px' h='32px'
    {...others}
  />
}

const DotLoading: FC<LoadingProps> = (props) => {
  const { className, ...others } = props
  return <Icon
    as={require('@/assets/svg/creation_loading.svg').ReactComponent}
    className={`${styles.loading} ${className}`}
    w='32px' h='32px'
    {...others}
  />
}

const Loading = {
  Icon: WiseIcon,
  Dot: DotLoading
}

export default Loading