import { ChatType, chatStore } from "@/store/chat";
import { Box, Icon } from "@chakra-ui/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import Models from "../Models";

import styles from './index.module.scss'

const ChatHeader: FC = () => {
  const { currentType, setChatType } = chatStore

  const { t } = useTranslation();

  return <Box className={styles.header}>
    <Box className={styles.type}>
      <Box className={`${styles.chatType} ${currentType === ChatType.AI ? styles.active : ''}`}>
        <Box onClick={() => setChatType(ChatType.AI)}>
          {t('ai_chat')}
        </Box>
        <Icon className={styles.icon} as={require('@/assets/svg/chat_header_active.svg').ReactComponent} />
      </Box>
      <Box className={`${styles.chatType} ${currentType === ChatType.Plugin ? styles.active : ''}`}>
        <Box onClick={() => setChatType(ChatType.Plugin)}>
          {t('plugin_chat')}
        </Box>
        <Icon className={styles.icon} as={require('@/assets/svg/chat_header_active.svg').ReactComponent} />
      </Box>
    </Box>
    {
      currentType === ChatType.AI && <Models />
    }
  </Box>
}

export default observer(ChatHeader)