import React, { FC } from 'react'
import { ContactItemProps, ContactItem } from './ContactItem'
import { useTranslation } from '../../../common'
import { ContactType } from '@xkit-yx/im-store'
import { Icon } from '@chakra-ui/react'

export interface ContactListProps {
  selectedContactType: ContactType | ''
  onItemClick: (contactType: ContactType) => void
  renderCustomContact?: (
    contactType: ContactType
  ) => JSX.Element | null | undefined
  systemMsgUnread: number
  prefix?: string
}

export const ContactList: FC<ContactListProps> = ({
  selectedContactType,
  onItemClick,
  renderCustomContact,
  systemMsgUnread,
  prefix = 'contact',
}) => {
  const _prefix = `${prefix}-list`

  const { t } = useTranslation()

  const dataSource: ContactItemProps[] = [
    {
      contactType: 'msgList',
      label: t('msgMenuText'),
      icon: <Icon as={require('@/assets/svg/im_msg.svg').ReactComponent} />,
      backgroundColor: '#565CFA',
      onItemClick: (contactType) => {
        onItemClick(contactType)
      },
      unread: systemMsgUnread,
    },
    {
      contactType: 'friendList',
      label: t('friendMenuText'),
      icon: <Icon as={require('@/assets/svg/im_friend.svg').ReactComponent} />,
      backgroundColor: '#45A6FF',
      onItemClick: (contactType) => {
        onItemClick(contactType)
      },
    },
    {
      contactType: 'blackList',
      label: t('blackMenuText'),
      icon: <Icon as={require('@/assets/svg/im_black.svg').ReactComponent} />,
      backgroundColor: '#666666',
      onItemClick: (contactType) => {
        onItemClick(contactType)
      },
    },
    {
      contactType: 'groupList',
      label: t('teamMenuText'),
      icon: <Icon as={require('@/assets/svg/im_group.svg').ReactComponent} />,
      backgroundColor: '#29A835',
      onItemClick: (contactType) => {
        onItemClick(contactType)
      },
    },
  ]

  return (
    <div className={`${_prefix}-wrapper`}>
      {dataSource.map((item) => {
        return (
          renderCustomContact?.(item.contactType) ?? (
            <ContactItem
              key={item.contactType}
              prefix={prefix}
              isSelectd={item.contactType === selectedContactType}
              {...item}
            />
          )
        )
      })}
    </div>
  )
}
