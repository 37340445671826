import { CModal } from "@/components/Modal";
import { Box, Icon, Text } from "@chakra-ui/react";
import { FC } from "react";
import { WiseImage } from "@/components/Image";
import { LoginType } from "./LoginType";
import { globalStore } from "@/store/global";
import { ipcRendererInvoke, isDesktop } from "@/utils/electron";

import styles from './index.module.scss'
import { observer } from "mobx-react-lite";

const Login: FC = observer(() => {

  const { setLoginIsOpen, canCloseLoginModal } = globalStore;

  const onClose = () => {
    if (canCloseLoginModal) {
      setLoginIsOpen(false);
    }
  }
  return <CModal isOpen onClose={onClose}>
    <Box className={styles.login}>
      <Box className={styles.close} onClick={onClose}>
        <Icon className={styles.icon} as={require('@/assets/svg/close_circle.svg').ReactComponent} />
      </Box>
      <WiseImage className={styles.bg} src={require('@/assets/png/login_bg.png')} />
      <LoginLeft />
      <LoginType />
    </Box>
  </CModal>
})

const LoginLeft: FC = () => {
  // const { t } = useTranslation();

  const downloadApp = () => {
    isDesktop() ? ipcRendererInvoke('openUrl', 'https://www.wisetalker.cn/') : window.open('https://www.wisetalker.cn/')
  }
  return <Box className={styles.loginLeft}>
    <Box className={styles.lingOne}>
      <Box className={`${styles.leftGrandient} ${styles.logo}`}>
        <Box className={styles.logoImg}>
          <WiseImage src={require('@/assets/logo.png')} />
        </Box>
      </Box>
      <Box className={`${styles.llms} ${styles.leftGrandient}`}>
        <WiseImage src={require('@/assets/png/login_llms.png')} className={styles.bg} />
        <Text className={styles.title}>多款AI大语言模型</Text>
        <Text className={styles.desc}>支持联网搜索，自定义智能体对话让AI更懂你</Text>
      </Box>
    </Box>
    <Box className={styles.lineTwo}>
      <Box className={styles.left}>
        <Box className={styles.top}>
          <Box className={`${styles.generate} ${styles.leftGrandient}`}>
            <WiseImage src={require('@/assets/png/login_generate.png')} className={styles.bg} />
            <Text className={styles.title}>高级写作、智能PPT一键生成</Text>
          </Box>
          <Box className={`${styles.creation} ${styles.leftGrandient}`}>
            <WiseImage src={require('@/assets/png/login_creation.png')} className={styles.bg} />
            <Text className={styles.title}>您的一站式AI创作平台</Text>
          </Box>
        </Box>
        <Box className={`${styles.cloud} ${styles.leftGrandient}`}>
          <WiseImage src={require('@/assets/png/login_cloud.png')} className={styles.bg} />
          <Text className={styles.title}>云端图片、音视频创作工具、</Text>
          <Text className={styles.desc}>SD、MJ、Suno、Gen2</Text>
        </Box>
      </Box>
      <Box className={`${styles.tools} ${styles.leftGrandient}`}>
        <WiseImage src={require('@/assets/png/login_tools.png')} className={styles.bg} />
        <Text className={styles.title}>百余款工具</Text>
        <Text className={styles.desc}>助力你的工作和学习</Text>
      </Box>
    </Box>
    {
      !isDesktop() && <Box className={styles.download} onClick={downloadApp}>
        <Text>前往下载电脑、手机客户端</Text>
        <Icon as={require('@/assets/svg/download_to.svg').ReactComponent} />
      </Box>
    }

  </Box>
}

export default Login