import { GET, POST, DELETE } from "./request"

export const fetchHistorys = (params: RequestPaging) => {
  return GET<ResultHistoryItem[]>('/chat/history/getHistory', params)
}

export const fetchChatInfo = (params: QueryChatInfo) => {
  return GET<AIChatInfoResult>('/chat/init', params)
}

export const getPluginChatInfo = (conversation_id: string) =>
  GET<PluginChatInfo>(`/openapi/v1/chat/getPluginChatInfo?conversation_id=${conversation_id}`);

export const fetchChatModels = () => GET<ResultModels[]>('/config/chatModellist')
export const fetchAgents = (params: Partial<QueryChatAgents>) => POST<FecthAgentsResult>('/model/share/getModels', params)
export const fetchMineAgents = () => GET<MineAgentsType>('/model/list?group=all')
export const fetchCreatAgents = (data: { name: String }) => POST<string>('/model/create', data)
export const fetchDelAgents = (id: string) => DELETE(`/model/del?modelId=${id}`)
export const fetchUpdateAgents = (id: string, data: AgentsType) => POST(`/model/update?modelId=${id}`, data)

// 删除对话
export const delChatHistoryById = (ids: string[]) => POST(`/chat/removeHistory`, { ids });

// 置顶对话
export const topChatHistoryById = (chatId: string, top?: boolean, title?: string) => {
  return POST(`/chat/history/updateChatHistory`, { chatId, top, customTitle: title });
}

// 获取插件支持的功能
export const getSupportFuncs = () => GET<{ title: string, svg: string }[]>(`/chat/getPluginFuncs`);