import { FC, useEffect, useRef, useState } from "react";
import { Box, Icon } from "@chakra-ui/react";
import { useMessage } from "@/hooks/useMessage";
import { useTranslation } from "react-i18next";

import styles from './index.module.scss';

interface CreationSwiperProps {
  audioSrc: string
  lyrics: string
}

const splitLyrics = (lyrics: string, maxCharsPerLine: number) => {
  const lines: string[] = [];
  lyrics.split('\n').forEach((line) => {
    let startIndex = 0;
    while (startIndex < line.length) {
      let endIndex = Math.min(startIndex + maxCharsPerLine, line.length);
      // 确保不在单词的中间分隔
      // if (endIndex < line.length && line[endIndex] !== ' ' && line[endIndex - 1] !== ' ') {
      //   endIndex = line.lastIndexOf(' ', endIndex) + 1 || endIndex;
      // }
      // 确保不在单词的中间分隔
      if (endIndex < line.length && line[endIndex] !== ' ' && line[endIndex - 1] !== ' ') {
        let spaceIndex = line.lastIndexOf(' ', endIndex);
        if (spaceIndex > startIndex) {
          endIndex = spaceIndex;
        } else {
          // 如果没有找到合适的空格，允许分割
          endIndex = Math.min(endIndex, line.length);
        }
      }
      // 截取这一段落并去除多余的空格
      const snippet = line.substring(startIndex, endIndex).trim();
      if (snippet) {
        lines.push(snippet);
      }
      startIndex = endIndex + (line[endIndex] === ' ' ? 1 : 0);;
    }
  });
  return lines;
};
export const SpeechPlayer: FC<CreationSwiperProps> = (props) => {
  const { audioSrc, lyrics } = props;
  const audioRef = useRef<HTMLAudioElement>(null);
  const message = useMessage();

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const lyricsRef = useRef<HTMLDivElement>(null); // 用于歌词滚动
  const { t } = useTranslation();
  const processedLyrics = splitLyrics(lyrics, 35);
  const lyricsData = processedLyrics.map((text, index) => ({
    time: (index / processedLyrics.length) * duration,
    text,
  }));

  const togglePlayPause = () => {
    if (audioRef.current && duration !== 0) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    } else {
      message.warning({ title: t('creation.MusicLoading') })
    }
  };

  const skipForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime += 15;
    }
  };

  const skipBackward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime -= 15;
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const time = (e.target.valueAsNumber / 100) * duration;
    if (audioRef.current) {
      audioRef.current.currentTime = time;
    }
    setCurrentTime(time);
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onloadedmetadata = () => {
        if (audioRef.current) {
          setDuration(audioRef.current.duration);
        }
      };
      audioRef.current.ontimeupdate = () => {
        if (audioRef.current) {
          setCurrentTime(audioRef.current.currentTime);
        }
      };
    }
  }, []);

  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
  const timerRef = useRef<any>(null);
  // 自动滚动歌词到当前
  const scrollToCurrentLyric = () => {
    if (lyricsRef.current && isAutoScrollEnabled) {
      const currentIndex = lyricsData.findIndex((lyric) => currentTime < lyric.time) - 1;
      const currentLyricElement = lyricsRef.current.children[currentIndex] as HTMLDivElement;
      if (currentLyricElement) {
        const offset = lyricsRef.current.clientHeight / 2 - currentLyricElement.clientHeight / 2;
        lyricsRef.current.scrollTop = currentLyricElement.offsetTop - offset;
      }
    }
  };

  // 监听歌词变化
  useEffect(() => {
    scrollToCurrentLyric();
  }, [currentTime, isAutoScrollEnabled]);
  // 处理滚动和延迟
  useEffect(() => {
    const handleScroll = () => {
      setIsAutoScrollEnabled(false);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      // 5秒后启用自动滚动
      timerRef.current = setTimeout(() => {
        setIsAutoScrollEnabled(true);
      }, 5000);
    };

    const lyricsElement = lyricsRef.current;
    if (lyricsElement) {
      lyricsElement.addEventListener('scroll', handleScroll);
      return () => {
        lyricsElement.removeEventListener('scroll', handleScroll);
        clearTimeout(timerRef.current);
      };
    }
  }, []);

  const sliderRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (sliderRef.current) {
      updateSliderBackground((currentTime / duration) * 100 || 0);
    }
  }, [currentTime, duration]);
  const updateSliderBackground = (value: any) => {
    const percentage = (value / 100) * 100;
    if (sliderRef.current) {
      sliderRef.current.style.background = `linear-gradient(to right, #fff 0%, #fff ${percentage}%, rgb(255,255,255,0.3) ${percentage}%, rgb(255,255,255,0.3) 100%)`;
    }
  };

  const onChange = (e: any) => {
    handleSliderChange(e);
    updateSliderBackground(e.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      if (duration > 0 && currentTime === duration) {
        setIsPlaying(false);
      }
    }, 500);
  }, [currentTime])
  return (
    <Box className={styles.swiper}>
      <Box className={styles.swiperbefore}></Box>
      <div className={styles.musicPlayer}>
        <audio
          ref={audioRef}
          src={audioSrc}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
        />
        <div className={styles.content}>
          <div ref={lyricsRef} className={styles.lyricsContainer}>
            {lyricsData.length > 0 ? lyricsData.map((lyric, index) => {
              const targetIndex = lyricsData.findIndex(lyric => currentTime < lyric.time) - 1;
              const centerIndex = targetIndex >= 0 ? targetIndex : lyricsData.length - 1;
              const distance = Math.abs(centerIndex - index);
              let color;
              if (centerIndex === index) {
                color = `#ffffff`;
              } else if (distance === 1) {
                color = `rgba(255, 255, 255, 0.7)`; // 邻近行，70%白色
              } else {
                color = `rgba(255, 255, 255, 0.4)`; // 其他行，40%白色
              }
              return (
                <div
                  key={index}
                  className={`
                    ${centerIndex === index ? styles.activeLyric : ''}
                  `}
                  style={{ color }}
                >
                  {lyric.text}
                </div>
              );
            })
              : <div style={{ marginTop: '100%' }}>
                {lyrics}
              </div>
            }
          </div>
        </div>
        <div className={styles.audioControls}>
          <input
            type="range"
            ref={sliderRef}
            value={(currentTime / duration) * 100 || 0}
            onChange={onChange}
            className={styles.slider}
          />
        </div>
        <div className={styles.time}>
          <span>
            {Math.floor(currentTime / 60)}:{Math.floor(currentTime % 60).toString().padStart(2, '0')}
          </span>
          <span>
            {Math.floor(duration / 60)}:{Math.floor(duration % 60).toString().padStart(2, '0')}
          </span>
        </div>
        <div className={styles.controls}>
          <Icon w={'32px'} h={'32px'} onClick={skipBackward} as={require('@/assets/svg/mathcurent.svg').ReactComponent} />
          {!audioRef.current || duration == 0 ?
            <Box className={styles.loader}></Box>
            :
            <Icon w={'40px'} h={'40px'} mx={'30px'} onClick={() => {
              if (currentTime === duration) {
                setCurrentTime(0)
                setIsPlaying(true)
                if (audioRef.current) {
                  audioRef.current.play();
                }
              } else {
                togglePlayPause()
              };
            }} as={!isPlaying ? require('@/assets/svg/songPause.svg').ReactComponent
              : require('@/assets/svg/songPlay.svg').ReactComponent
            } />
          }
          <Icon w={'32px'} h={'32px'} onClick={skipForward} as={require('@/assets/svg/mathduration.svg').ReactComponent} />
        </div>
      </div>
    </Box>
  );
};
