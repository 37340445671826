import { collectionImg, deleteDallE3Record, drawAsDallE3, getDallE3History, getDallE3Info } from "@/api/creation";
import { creationDb } from "@/db/creation";
import { makeAutoObservable } from "mobx";
import { CreationType } from ".";

export class CreationDallE3 {
  history: DrawDallE3Type[] = [];
  pageNum: number = 1;
  pageSize: number = 10;
  total: number = 0;
  info?: DrawDallE3Type;
  favorite?: string[]
  prompt: string = '';

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  reset() {
    this.history = [];
    this.pageNum = 1;
    this.pageSize = 10;
    this.total = 0;
    this.info = undefined;
    this.favorite = [];
    this.prompt = '';
  }

  async getHistory() {
    try {
      let isGet = false;
      creationDb.getDalle3History().then(res => {
        if (res && !isGet) {
          this.setHistory(res);
        }
      })
      const res = await getDallE3History({ pageNum: this.pageNum, pageSize: this.pageSize });
      isGet = true;
      this.setHistory(res.list);
      this.setTotal(res.total);
    } catch (err) {
      console.log(err)
    }
  }

  async getInfo(id: string) {
    const res = await getDallE3Info(id);
    this.setInfo(res.list);
    this.setFavorite(res.favorites.map(f => f.url));
  }

  async chatCreation(prompt: string, id?: string) {
    return await drawAsDallE3({ prompt: prompt, id });
  }

  async delDalle3(id: string) {
    return await deleteDallE3Record(id);
  }

  async postCollection(id: string, url: string) {
    return await collectionImg(id, url, CreationType.E3);
  }

  setHistory(history: DrawDallE3Type[]) {
    this.history = history;
  }

  setPageNum(pageNum: number) {
    this.pageNum = pageNum;
    this.getHistory();
  }

  setTotal(total: number) {
    this.total = total;
  }

  setInfo(info?: DrawDallE3Type) {
    this.info = info;
  }

  setFavorite(favorite: string[]) {
    this.favorite = favorite;
  }

  setPrompt(prompt: string) {
    this.prompt = prompt;
  }
}