import { ForwardedRef, forwardRef, useContext, useEffect, useRef, useState } from "react";
import { Select, SelectListboxSlotProps, CssTransition, SelectProps } from "@mui/base";
import { PopupContext } from '@mui/base/Unstable_Popup';
import { styled } from '@mui/system';
import { Box, Icon } from "@chakra-ui/react";
import { ScrollBarBox } from "../ScrollBox";

import styles from './index.module.scss'

interface WiseSelectProps extends SelectProps<string, false> {
  showArrow?: boolean
  onChange?: (value: any) => void
}
export const WiseSelect = forwardRef((props: WiseSelectProps, ref: ForwardedRef<HTMLButtonElement>) => {
  const { showArrow = true, children, onChange, className, slots, ...others } = props;
  const [open, setOpen] = useState(false);
  const [boxWidth, setBoxWidth] = useState(0);
  const _boxRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (_boxRef.current) {
      setBoxWidth(_boxRef?.current?.offsetWidth || 0)
    }
    // eslint-disable-next-line
  }, [])
  return <Box className={styles.selectBox} ref={_boxRef}>
    <Select {...others} className={`${styles.select} ${className}`} ref={ref} slots={slots || {
      listbox: AnimatedListbox,
      popup: styled('div')`
      z-index: 9999;
      width: ${boxWidth}px;
    `
    }}
      onListboxOpenChange={setOpen}
      onChange={(_, value) => onChange && onChange(value)}
    >
      {children}
    </Select>
    {
      showArrow && <Icon as={require('@/assets/svg/arrow_r.svg').ReactComponent} className={`${styles.arrow} ${open ? styles.open : ''}`} />
    }
  </Box>
})

const Listbox = styled(Box)(
  () => `
  overflow: auto;
  outline: 0px;
  };
  
  .closed & {
    opacity: 0;
    transform: scale(0.95, 0.8);
    transition: opacity 200ms ease-in, transform 200ms ease-in;
  }
  
  .open & {
    opacity: 1;
    transform: scale(1, 1);
    transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
  }

  .placement-top & {
    transform-origin: bottom;
  }

  .placement-bottom & {
    transform-origin: top;
  }
  `,
);

const AnimatedListbox = forwardRef(function AnimatedListbox<
  Value extends {},
  Multiple extends boolean,
>(
  props: SelectListboxSlotProps<Value, Multiple>,
  ref: React.ForwardedRef<HTMLUListElement>,
) {
  const { ownerState, ...other } = props;
  const popupContext = useContext(PopupContext);

  if (popupContext == null) {
    throw new Error(
      'The `AnimatedListbox` component cannot be rendered outside a `Popup` component',
    );
  }

  const verticalPlacement = popupContext.placement.split('-')[0];

  return <CssTransition
    className={`placement-${verticalPlacement} ${styles.listbox}`}
    enterClassName="open"
    exitClassName="closed"
  >
    <ScrollBarBox display={ownerState.open ? 'block' : 'none'}>
      <Listbox {...other} ref={ref} />
    </ScrollBarBox>
  </CssTransition>
});