
import { useRoutes } from "react-router-dom";
import { router } from "./router";

function Router() {
  const elements = useRoutes(router);

  return elements
}

export default Router;