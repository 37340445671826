import React from 'react'
import { CommonIcon } from '../../../../common'
import { PanelScene } from '../../Container'
import { Box } from '@chakra-ui/react'

export interface AddItemProps {
  scene: PanelScene
  icon: string
  content: string
  onClick: (scene: PanelScene) => void
  prefix: string
}

const AddItem: React.FC<AddItemProps> = ({
  scene,
  icon,
  content,
  onClick,
  prefix,
}) => {
  const _prefix = `${prefix}-add-item`
  return (
    <div className={_prefix} onClick={() => onClick(scene)}>
      <Box as={require(`@/assets/svg/${icon}.svg`).ReactComponent} className={`${_prefix}-icon`} />
      <div className={`${_prefix}-content`}>{content}</div>
    </div>
  )
}
export default AddItem
