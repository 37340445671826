export const icon_zoomin = (size: number) => {
  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox={'0 0 200 200'}>
      <path
        id="svg_1"
        d="m100,200c-55.24862,0 -100,-44.75138 -100,-100c0,-55.24862 44.75138,-100 100,-100c55.24862,0 100,44.75138 100,100c0,55.24862 -44.75138,100 -100,100z"
        opacity="undefined"
        strokeWidth="0"
        stroke="#000"
        fill="#000000"
      />
      <path
        id="svg_2"
        d="m30.99989,76.60761l45.60769,0l0,-45.60763l46.78483,0l0,45.60763l45.60769,0l0,46.78477l-45.60769,0l0,45.60763l-46.78483,0l0,-45.60763l-45.60769,0l0,-46.78477z"
        opacity="undefined"
        strokeWidth="0"
        stroke="#000"
        fill="#ffffff"
      />
    </svg>
  );
};

export const icon_zoomout = (size: number) => {
  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox={'0 0 200 200'}>
      <path
        id="svg_1"
        d="m100,200c-55.24862,0 -100,-44.75138 -100,-100c0,-55.24862 44.75138,-100 100,-100c55.24862,0 100,44.75138 100,100c0,55.24862 -44.75138,100 -100,100z"
        opacity="undefined"
        strokeWidth="0"
        stroke="#000"
        fill="#000000"
      />
      <path
        id="svg_2"
        d="m45,83.5l110,0l0,33l-110,0l0,-33z"
        strokeWidth="0"
        stroke="#000"
        fill="#ffffff"
      />
    </svg>
  );
};

export const icon_fit = (size: number) => {
  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox={'0 0 200 200'}>
      <path
        fill="#000000"
        stroke="#000"
        strokeWidth="0"
        opacity="undefined"
        d="m100,200c-55.24862,0 -100,-44.75138 -100,-100c0,-55.24862 44.75138,-100 100,-100c55.24862,0 100,44.75138 100,100c0,55.24862 -44.75138,100 -100,100z"
        id="svg_1"
      />
      <path
        stroke="#000"
        fill="#ffffff"
        strokeWidth="0"
        opacity="undefined"
        d="m43.91415,65.45l18.69528,0l0,-23.03333l18.69528,0l0,46.06667l-37.39056,0l0,-23.03333zm0,69.1l18.69528,0l0,23.03333l18.69528,0l0,-46.06667l-37.39056,0l0,23.03333zm112.17169,-69.1l-18.69528,0l0,-23.03333l-18.69528,0l0,46.06667l37.39056,0l0,-23.03333zm0,69.1l-18.69528,0l0,23.03333l-18.69528,0l0,-46.06667l37.39056,0l0,23.03333z"
        id="svg_2"
      />
    </svg>
  );
};

export const icon_openNew = (size: number) => {
  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox={'0 0 200 200'}>
      <path
        fill="#000000"
        stroke="#000"
        strokeWidth="0"
        opacity="undefined"
        d="m100,200c-55.24862,0 -100,-44.75138 -100,-100c0,-55.24862 44.75138,-100 100,-100c55.24862,0 100,44.75138 100,100c0,55.24862 -44.75138,100 -100,100z"
        id="svg_1"
      />
      <path
        stroke="null"
        id="svg_6"
        fill="#ffffff"
        d="m139.6,100a7.2,7.2 0 1 1 14.4,0l0,46.8a7.2,7.2 0 0 1 -7.2,7.2l-93.6,0a7.2,7.2 0 0 1 -7.2,-7.2l0,-93.6a7.2,7.2 0 0 1 7.2,-7.2l46.8,0l0,0.018a7.2,7.2 0 0 1 0,14.364l0,0.018l-39.6,0l0,79.20001l79.20001,0l0,-39.6z"
      />
      <path
        stroke="null"
        id="svg_7"
        fill="#ffffff"
        d="m153.94753,51.49263l0,19.47788a6.49263,6.49263 0 1 1 -12.98526,0l0,-3.99297l-28.87596,28.87921a6.49263,6.49263 0 1 1 -9.18382,-9.18058l28.69092,-28.69092l-3.61639,0a6.49263,6.49263 0 1 1 0,-12.98526l19.47788,0a6.49263,6.49263 0 0 1 6.49263,6.49263z"
      />
    </svg>
  );
};

export const icon_close = (size: number) => {
  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox={'0 0 200 200'}>
      <path
        fill="#000000"
        stroke="#000"
        strokeWidth="0"
        opacity="undefined"
        d="m100,200c-55.24862,0 -100,-44.75138 -100,-100c0,-55.24862 44.75138,-100 100,-100c55.24862,0 100,44.75138 100,100c0,55.24862 -44.75138,100 -100,100z"
        id="svg_1"
      />
      <path
        stroke="null"
        id="svg_6"
        p-id="2638"
        fill="#ffffff"
        d="m99.99646,91.32308l-47.70429,-47.70429a6.13323,6.13323 0 0 0 -8.67239,8.67239l47.69815,47.70429l-47.69815,47.70429a6.13323,6.13323 0 1 0 8.67239,8.67852l47.70429,-47.71042l47.70429,47.71042a6.13323,6.13323 0 1 0 8.67852,-8.67852l-47.71042,-47.70429l47.71042,-47.70429a6.13323,6.13323 0 0 0 -8.67852,-8.67239l-47.70429,47.69815l0,0.00613z"
      />
    </svg>
  );
};
