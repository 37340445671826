const LocaleConfig = {
  // common
  saveText: 'Save',
  setText: 'Setting',
  saveSuccessText: 'Saved successfully',
  saveFailedText: 'Save failed',
  addFriendSuccessText: 'Add friend successfully',
  applyFriendSuccessText: 'Application to add friend successfully',
  addFriendFailedText: 'Failed to add friend',
  applyFriendFailedText: 'Application to add friend failed',
  okText: 'Confirm',
  cancelText: 'Cancel',
  deleteText: 'Delete',
  recallText: 'Withdraw',
  forwardText: 'Forward',
  forwardFailedText: 'Forwarding failed',
  sendBtnText: 'send',
  replyText: 'Reply',
  commentText: 'Comment',
  recentSessionText: 'Recent Visits',
  you: 'you',
  deleteFriendText: 'Deleting a friend',
  confirmDeleteText: 'Are you sure you want to delete?',
  confirmDeleteFriendText: 'Are you sure you want to delete friend?',
  deleteFriendSuccessText: 'Delete friend successfully',
  deleteFriendFailedText: 'Failed to delete friend',
  blackText: 'Block friends',
  removeBlackText: 'Unblock',
  blackSuccessText: 'Blacklist success',
  blackFailedText: 'Blacklist failed',
  removeBlackSuccessText: 'Successfully unblocked',
  removeBlackFailedText: 'Unblock failed',
  maxSelectedText: 'You can only select',
  selectedText: 'Selected',
  friendsText: 'Friends',
  strangerText: 'Strangers',
  emailErrorText: 'The email format is incorrect',
  uploadLimitText: 'Maximum supported image, video or file size',
  uploadLimitUnit: 'M',
  uploadImgFailedText: 'Failed to upload image',
  accountText: 'Account',
  nickText: 'Nickname',
  genderText: 'gender',
  phoneText: 'Phone',
  emailText: 'Mail',
  signText: 'Signature',
  accountPlaceholder: 'Please enter your account number',
  teamIdPlaceholder: 'Please enter a group ID',
  nickPlaceholder: 'Please enter your nickname',
  genderPlaceholder: 'Please select gender',
  phonePlaceholder: 'Please enter your phone number',
  emailPlaceholder: 'Please enter your email address',
  signPlaceholder: 'Please enter your signature',
  searchInputPlaceholder: 'Search for friends or groups',
  searchTeamMemberPlaceholder: 'Search group members',
  searchText: 'search',
  man: 'male',
  woman: 'female',
  unknow: 'unknown',
  welcomeText: 'Welcome to WSYY',
  notSupportMessageText: 'This message is not currently supported',
  applyTeamText: 'Apply to join the group',
  applyTeamSuccessText: 'Application for joining the group is successful',
  rejectText: 'Reject',
  acceptText: 'Agree',
  inviteTeamText: 'Invite you to join the group',
  applyFriendText: 'Add you as a friend',
  acceptResultText: 'Agreed',
  rejectResultText: 'Rejected',
  beRejectResultText: 'Friend request rejected',
  passResultText: 'Accepted friend request',
  rejectTeamInviteText: 'Rejected group invitation',
  updateTeamAvatar: 'Updated group avatar',
  updateTeamName: 'Update group name to',
  updateTeamIntro: 'Updated group introduction',
  updateTeamInviteMode: 'Updated the group permission "Invite others permission" to',
  updateTeamUpdateTeamMode: 'Updated the group permission "Group information modification permission" to',
  updateAllowAt: 'Updated "@Everyone permissions" to',
  updateTeamMute: 'Updated "Group Mute" to',
  onlyTeamOwner: '仅Only group owners群主',
  teamAll: 'All',
  closeText: 'Closure',
  openText: 'Open',
  inviteText: 'invite',
  aliasText: 'Remark',
  updateAliasSuccessText: 'Modify the note successfully',
  updateAliasFailedText: 'Failed to modify the comment',
  sendText: 'Send Message',
  noPermission: 'You do not have permission to operate',
  unreadText: 'Unread',
  readText: 'Read',
  allReadText: 'All read',
  amap: 'Amap',
  txmap: 'Tencent Maps',
  bdmap: 'Baidu Map',
  callDurationText: 'Talk time',
  callCancelText: 'Cancelled',
  callRejectedText: 'Rejected',
  callTimeoutText: 'Timed out',
  callBusyText: 'The other party is busy',
  // conversation-kit
  onDismissTeamText: 'The group has been disbanded',
  onRemoveTeamText: 'You have been removed from the group',
  textMsgText: 'Text Messages',
  customMsgText: 'Custom Message',
  audioMsgText: 'Audio Message',
  videoMsgText: 'Video Message',
  fileMsgText: 'File Message',
  callMsgText: 'Call record message',
  geoMsgText: 'Location Message',
  imgMsgText: 'Picture Message',
  notiMsgText: 'Notification Message',
  robotMsgText: 'Machine Message',
  tipMsgText: 'Prompt message',
  unknowMsgText: 'Unknown message',
  deleteSessionText: 'Deleting a Session',
  muteSessionText: 'Turn on Do Not Disturb',
  unmuteSessionText: 'Cancel Do Not Disturb',
  deleteStickTopText: 'Unpin',
  addStickTopText: 'Pin',
  beMentioned: '[Someone @ me]',

  // contact-kit
  teamListTitle: 'My Groups',
  friendListTitle: 'My Friends',
  blackListTitle: 'Blacklist',
  msgListTitle: 'Message Center',
  blackListDesc: '(You will not receive messages from any of the contacts in the list)',
  teamMenuText: 'My Groups',
  friendMenuText: 'My Friends',
  blackMenuText: 'Blacklist',
  msgMenuText: 'Message Center',
  acceptedText: 'The application has been approved',
  acceptFailedText: 'Approval of the application failed',
  rejectedText: 'The application has been rejected',
  rejectFailedText: 'Rejection of the application failed',
  getApplyMsgFailedText: 'Failed to get message',

  // search-kit
  addFriendText: 'Add friends',
  createTeamText: 'Create a group',
  joinTeamText: 'Join a group',
  joinTeamSuccessText: 'Joined the group successfully',
  beRemoveTeamText: 'Removed from group',
  addButtonText: 'Add to',
  addSuccessText: 'Added successfully',
  addFailedText: 'Add failed',
  createButtonText: 'Create',
  createTeamSuccessText: 'Group created successfully',
  createTeamFailedText: 'Failed to create group',
  chatButtonText: 'To chat',
  getRelationFailedText: 'Failed to get relationship',
  accountNotMatchText: 'No such account found',
  teamIdNotMatchText: 'No such group number found',
  searchButtonText: 'Find',
  searchTeamPlaceholder: 'Please enter a group name',
  teamTitle: 'Name',
  teamAvatarText: 'Avatar',
  addTeamMemberText: 'Add Members',
  searchEmptyText: 'You have not added any friends or groups yet',
  searchNoResText: 'No search results yet',
  searchFriendTitle: 'Friends',
  searchTeamTitle: 'Groups',
  notSupportJoinText: 'The discussion group cannot be joined directly, please contact the administrator to add you to the group',

  // chat-kit
  sendToText: 'Send to',
  sendUsageText: '(Press enter to send directly, shift+enter to wrap)',
  sendEmptyText: 'Cannot send blank message',
  teamMutePlaceholder: 'The current group chat is muted',
  enterTeamText: 'Entered the group',
  leaveTeamText: 'Left the group',
  teamMuteText: 'Group mute',
  muteAllTeamSuccessText: 'Successfully enabled the mute function for all members',
  unmuteAllTeamSuccessText: 'Successfully ended the ban on all members',
  muteAllTeamFailedText: 'Failed to enable muting for all members',
  unmuteAllTeamFailedText: 'Failed to end the ban',
  updateTeamSuccessText: 'Modification successful',
  updateTeamFailedText: 'Modification failed',
  leaveTeamSuccessText: 'Successfully left this group',
  leaveTeamFailedText: 'Failed to leave this group',
  dismissTeamSuccessText: 'The group was successfully disbanded',
  dismissTeamFailedText: 'Group disbanding failed',
  addTeamMemberSuccessText: 'Add member successfully',
  addTeamMemberFailedText: 'Failed to add member',
  addTeamMemberConfirmText: 'Please select the group member you want to add',
  removeTeamMemberText: 'Remove',
  removeTeamMemberConfirmText: 'Are you sure you want to remove this member?',
  removeTeamMemberSuccessText: 'Remove member successfully',
  removeTeamMemberFailedText: 'Failed to remove member',
  teamTitleConfirmText: 'Group name cannot be empty',
  teamAvatarConfirmText: 'Group avatar cannot be empty',
  teamIdText: 'Group ID',
  teamSignText: 'Introduction',
  teamTitlePlaceholder: 'Please enter a group name',
  teamSignPlaceholder: 'Please enter content',
  teamOwnerText: 'owner',
  teamManagerText: 'Admin',
  teamManagerEditText: 'People Management',
  teamManagerEmptyText: 'No group admin',
  teamOwnerAndManagerText: 'Group owners and admin',
  updateTeamManagerSuccessText: 'Modify group admin successfully',
  updateTeamManagerFailText: 'Failed to modify group administrator',
  userNotInTeam: 'The member is no longer in the group',
  teamManagerLimitText: 'Who can edit the information?',
  teamInviteModeText: 'Who can invite new members',
  teamAtModeText: 'Who can @ everyone',
  teamMemberText: 'Group Members',
  teamInfoText: 'Group Information',
  teamPowerText: 'Group Management',
  dismissTeamText: 'Disband a group',
  transferOwnerText: 'Transfer group owner',
  newGroupOwnerText: 'Become a new group owner',
  beAddTeamManagersText: 'Appointed as Administrator',
  beRemoveTeamManagersText: 'Removed Admin',
  transferTeamFailedText: 'Transfer of group owner failed',
  transferToText: 'Transfer to',
  transferTeamSuccessText: 'Transfer group owner successfully',
  transferOwnerConfirmText: 'Confirm the transfer of group owner',
  dismissTeamConfirmText: 'Do you confirm to disband the group?',
  leaveTeamTitle: 'Leave the group',
  leaveTeamConfirmText: 'Are you sure to exit this group?',
  personUnit: 'people',
  leaveTeamButtonText: 'Delete and exit',
  sendMsgFailedText: 'Message sending failed',
  getHistoryMsgFailedText: 'Failed to obtain historical messages',
  sendBlackFailedText: 'You have been blacklisted by the other party',
  recallMessageText: 'Withdrawn a message',
  recallReplyMessageText: 'This message has been withdrawn or deleted',
  reeditText: 'Re-edit',
  addChatMemberText: 'Add chat members',
  chatHistoryText: 'Chat History',
  noMoreText: 'No more news',
  receiveText: 'You have received a new message',
  strangerNotiText: 'This person is not your friend at the moment. Please be careful to protect your privacy.',
  nickInTeamText: 'My nickname in the group',
  editNickInTeamText: 'Edit my nickname in the group',
  updateMyMemberNickSuccess: 'Updated my group nickname successfully',
  updateMyMemberNickFailed: 'Failed to update my group nickname',
  updateBitConfigMaskSuccess: 'Update group message do not disturb successfully',
  updateBitConfigMaskFailed: 'Update group message do not disturb failed',
  imgText: 'picture',
  videoText: 'video',
  onlineText: '[Online]',
  offlineText: '(Offline)',
  clear: 'Clear',
  noVerifiMessageYet: "No verification message yet",
  noFriendsYet: 'No friends yet',
  noBlacklistYet: 'No blacklisted users',
  noGroupYet: 'No group chat',

  // emoji 不能随便填，要用固定 key，，参考 demo
  Laugh: '[大笑]',
  Happy: '[开心]',
  Sexy: '[色]',
  Cool: '[酷]',
  Mischievous: '[奸笑]',
  Kiss: '[亲]',
  Spit: '[伸舌头]',
  Squint: '[眯眼]',
  Cute: '[可爱]',
  Grimace: '[鬼脸]',
  Snicker: '[偷笑]',
  Joy: '[喜悦]',
  Ecstasy: '[狂喜]',
  Surprise: '[惊讶]',
  Tears: '[流泪]',
  Sweat: '[流汗]',
  Angle: '[天使]',
  Funny: '[笑哭]',
  Awkward: '[尴尬]',
  Thrill: '[惊恐]',
  Cry: '[大哭]',
  Fretting: '[烦躁]',
  Terrorist: '[恐怖]',
  Halo: '[两眼冒星]',
  Shame: '[害羞]',
  Sleep: '[睡着]',
  Tired: '[冒星]',
  Mask: '[口罩]',
  ok: '[OK]',
  AllRight: '[好吧]',
  Despise: '[鄙视]',
  Uncomfortable: '[难受]',
  Disdain: '[不屑]',
  ill: '[不舒服]',
  Mad: '[愤怒]',
  Ghost: '[鬼怪]',
  Angry: '[发怒]',
  Unhappy: '[不高兴]',
  Frown: '[皱眉]',
  Broken: '[心碎]',
  Beckoning: '[心动]',
  Ok: '[好的]',
  Low: '[低级]',
  Nice: '[赞]',
  Applause: '[鼓掌]',
  GoodJob: '[给力]',
  Hit: '[打你]',
  Please: '[阿弥陀佛]',
  Bye: '[拜拜]',
  First: '[第一]',
  Fist: '[拳头]',
  GiveMeFive: '[手掌]',
  Knife: '[剪刀]',
  Hi: '[招手]',
  No: '[不要]',
  Hold: '[举着]',
  Think: '[思考]',
  Pig: '[猪头]',
  NoListen: '[不听]',
  NoLook: '[不看]',
  NoWords: '[不说]',
  Monkey: '[猴子]',
  Bomb: '[炸弹]',
  Cloud: '[筋斗云]',
  Rocket: '[火箭]',
  Ambulance: '[救护车]',
  Poop: '[便便]',
}

export default LocaleConfig
