import { Box, Flex, useDisclosure, Text, Icon } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { Transformer } from 'markmap-lib';
import { Markmap, deriveOptions } from 'markmap-view';
import { loadJS } from 'markmap-common';
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { ComButton } from "../components/ComButton";
import { userStore } from "@/store/user";
import { useMessage } from "@/hooks/useMessage";
import { toolStore } from "@/store/tool";
import { createRoot, Root } from 'react-dom/client';
import { MarkmapSchema, ToolSchema } from "@/@types/tools";
import styles from "./index.module.scss"
import { ScrollBarBox } from "@/components/ScrollBox";
import { _Models } from "..";
import { streamFetch } from "@/utils/stream";
import { ComTextarea } from "../components/ComTextarea";
import { ComImprove } from "../components/ComImprove";
import { CButton } from "@/components/Button";
import SaveUtils from "@/utils/SaveUtils";
import { deleteTextMarkmap, getTextMarkmap, postCreateMarkmap, getMarkMaphis } from "@/api/tools";
import { ComTitle } from "../components/ComTitle";
import MMPreview from "../components/mmPreview";
import { throttle } from "lodash";
import i18n from "@/utils/i18n";
import { useTranslation } from "react-i18next";

const beginText = `---
markmap:
  colorFreezeLevel: 2
---
`;
const clsToolbarItem = 'mm-toolbar-item';
const InitValues = (item: string) => [
  `${item} # （示例）如何跟男朋友在吵架中获胜 🏆

## 超级技能 💪
### 1. 大眼萌瞪 👀
- 魔法效果：使他瞬间心软    
### 2. 模仿他的话 🦜
- 效果：让他知道自己说了啥奇怪的话

## 战略策略 🤔
### 1. 记住他上次的失误
- 使用时机：极端情况下提醒他
### 2. 小提琴背景音 🎻
- 用途：营造悲伤的氛围

## **禁止行为** ❌
### 1. 人身攻击
- 不友好，容易导致战争升级
### 2. 带入第三者
- 除非想变“单身狗”🐶

## 神奇道具 🎩
### 1. “你记得那时候吗”卡片
- 描述：用来回忆甜蜜时光
### 2. 拥抱券 🎫
- 使用条件：争论后和好

## **胜利后** 🥳
### 1. 拥抱和吻 😘
- 感谢对方耐心与你沟通
### 2. 一起做些有趣的事
- 例如：制作晚餐，看电影 
`,
  `${item} # 外星人的日常 👽

## 早晨起床
### 1. 打开三只眼睛 👁👁👁
- 提醒：别忘了第三只
### 2. 梳理触手
- 使用：反重力梳子

## 服饰选择
### 1. 透明太空服
- 功能：显瘦+防宇宙射线
### 2. 三趾鞋 👟
- 时尚提示：闪亮色彩正流行

## 日常交通
### 1. 飞碟出行 🛸
- 注意：避免高峰时段地球堵车
### 2. 传送门
- 目的地：宇宙超市、太空健身房

## 工作
### 1. 地球研究员
- 主要职责：观察人类搞笑行为
### 2. 宇宙旅游导游
- 热门景点：银河系、黑洞冒险之旅

## 娱乐活动
### 1. 宇宙卡拉OK 🎤
- 热门歌曲：《星星之火》、《黑洞的秘密》
### 2. 多维度乒乓球 🏓
- 规则：谁知道呢？太复杂了

## 宠物
### 1. 小型黑洞 ⚫
- 功能：吸尘、处理垃圾
### 2. 闪电蜗牛 🐌
- 功能：快递、邮件传递

## 晚安仪式
### 1. 三眼睡眠面罩 😴
- 保证：完美黑暗，安然入眠
### 2. 悬浮床
- 特点：摇摇晃晃，如摇篮般安稳
`,
  `${item} # （示例）如果宠物能说话他们会说些什么

## 狗狗
- 那根树真的很有趣，你为什么不闻闻？
- 是时候教我如何开冰箱了
- 我真的需要每次都追那个球吗？

## 猫咪
- 每天都是派对，只要你不在家
- 你离开的每一分钟我都在数，只是为了更好地无视你
- 我打翻东西只是为了测试地心引力

## 鱼儿
- 今天的食物是昨天的吗？
- 你们总说“像鱼一样的记忆”，真的很冒犯
- 别敲玻璃，我也不敲你的窗户

## 仓鼠
- 谁说我想天天跑步？
- 这玩具真的很无聊，有没有新的？
- 今晚的逃跑计划是…
`
]

const state = {
  // value: initValues[Math.floor(Math.random() * initValues.length)]
  value: InitValues(beginText)[Math.floor(Math.random() * InitValues(beginText).length)]
};

export const MarkmapClass: FC = observer(() => {

  const [tool, SetTool] = useState<ToolSchema>()

  const [bindTextPrompt, SetbindTextPrompt] = useState('');
  const bindGenPrompt = useRef<HTMLTextAreaElement>(null);
  const bindSvgBox = useRef<HTMLDivElement>(null);
  const bindSvg = useRef<SVGSVGElement>(null);
  const bindToolbar = useRef<HTMLDivElement>(null);
  const { isOpen: isOpenSlider, onClose: onCloseSlider, onOpen: onOpenSlider } = useDisclosure();
  const [historyMarkmap, setHistoryMarkmap] = useState<MarkmapSchema[]>([]);
  const [choosesModel, setChoosesModel] = useState(false);
  const [modelkey, setModelsKey] = useState(_Models[1])
  const message = useMessage();
  const { userInfo } = userStore;
  const [text, setText] = useState(state.value);
  const { getToolByLink, getToolModel } = toolStore;
  const [riskInfo, SetRiskInfo] = useState<any>();
  const { t } = useTranslation();

  let mm: Markmap | null;
  let fullSvgDiv1: any;
  let fullSvgDiv2: any;
  let _previewRoot!: Root | null;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getToolByLink({ toollink: 'swdt' });
      SetTool(data[0]);
    };
    fetchData();
  }, []);

  //更新思维导图
  useEffect(() => {
    if (bindSvg.current) {
      if (!mm) {
        mm = Markmap.create(bindSvg.current, {
          // pan: false
        });
        updateSvg();
      }
    }
    return () => {
      if (mm) {
        mm.destroy();
        mm = null;
      }
    };
  });

  const scrollToBottom = useCallback((behavior: 'smooth' | 'auto' = 'smooth') => {
    if (!bindGenPrompt.current) return;
    bindGenPrompt.current.scrollTo({
      top: bindGenPrompt.current.scrollHeight,
      behavior
    });
  }, []);

  const generatingMessage = useCallback(
    throttle(() => {
      if (!bindGenPrompt.current) return;
      const isBottom =
        bindGenPrompt.current.scrollTop + bindGenPrompt.current.clientHeight + 150 >=
        bindGenPrompt.current.scrollHeight;

      isBottom && scrollToBottom('auto');
    }, 100),
    []
  );

  const controller = useRef(new AbortController());
  const delay = (ms: number) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve('');
      }, ms);
    });
  const handleGPTGenInfo = async () => {
    if (!bindGenPrompt.current) return;
    SetRiskInfo(undefined);
    if (userInfo && !userInfo.monVip && userInfo.balance <= 0) {
      message.warning({ title: '由于余额不足, <任务模块>已经停止，请充值后再试。' })
      // onOpenPayModal()
      setLoading(false);
      return;
    }
    if (!bindTextPrompt) {
      message.warning({ title: '请输入描述！' })
      return;
    }

    const aiwritetemplate = "我要制作一个思维导图，内容是" + bindTextPrompt + " ，内容尽量详尽，使得普通人能够理解。帮我用MarkDown源码格式输出。请注意，只需要返回MarkDown源码格式内容，不需要```和markdown。";

    const aiwritemodel = modelkey.key;
    bindGenPrompt.current.value = beginText;
    setLoading(true);
    const abortSignal = new AbortController();
    controller.current = abortSignal;
    // 流请求，获取数据
    try {
      const ret = await streamFetch({
        data: {
          messages: [
            {
              content: '你是一个思维导图制作家',
              role: 'system'
            },
            {
              content: aiwritetemplate,
              role: 'user'
            }
          ] as any[],
          chatId: '',
          stream: true,
          appId: await getToolModel('Markmap'),
          model: aiwritemodel,
          toolParams: {
            demand: bindTextPrompt,
            model: aiwritemodel,
            template: aiwritetemplate,
            toolsname: tool ? tool.name : '',
            toolsid: tool ? tool._id : ''
          }
        },
        onMessage: (text: string) => {
          if (!bindGenPrompt.current) return;
          bindGenPrompt.current.value = bindGenPrompt.current.value + text;
          generatingMessage();
        },
        abortSignal
      });

      // 等待输出内容结束
      abortSignal.signal.aborted && (await delay(500));

      // await postCreateMarkmap({
      //   demand: bindTextPrompt,
      //   text: bindGenPrompt.current!.value,
      //   effectmap: bindSvg.current?.outerHTML,
      //   model: aiwritemodel,
      //   template: aiwritetemplate,
      //   toolid: tool ? tool._id : ''
      // });
      const res = await getMarkMaphis()
      if (res) {
        if (res.contentCheck && res.contentCheck.status === false) {
          SetRiskInfo(res.contentCheck);
        } else if (res.askCheck && res.askCheck.status === false) {
          SetRiskInfo(res.askCheck);
        } else {
          SetRiskInfo(undefined);
        }
        if (bindGenPrompt.current) {
          const riskValue = res.contentCheck?.status !== false ? res.askCheck?.status !== false ? res.text : (`${t("creation.TextMayContain")}${res.askCheck.labels?.split(',').map((str: any) => i18n.t('riskContent.' + str)).join(',')} ${t('creation.TemporarilyUnavailable')}${t('creation.NoConsume')} ` || t('NoConsume'))
            : (`${t("ContentMayContains")}:${res.contentCheck.labels?.split(',').map((str: any) => i18n.t('riskContent.' + str)).join(',')} ${t('creation.TemporarilyUnavailable')} ` || t('ContentRiskBlocked'))
          bindGenPrompt.current.value = riskValue;
        }
      }
    } catch (err: any) {
      if (!(err == 'The user aborted a request.'
        || err == 'BodyStreamBuffer was aborted'
        || err == 'Fetch is aborted')) {
        message.warning({ title: typeof err === 'string' ? err : err?.message || '聊天出错了~' })
      } else {
        await postCreateMarkmap({
          demand: bindTextPrompt,
          text: bindGenPrompt.current!.value,
          effectmap: bindSvg.current?.outerHTML,
          model: aiwritemodel,
          template: aiwritetemplate,
          toolid: tool ? tool._id : ''
        });
      }
      if (err.message && err.message.includes('不足')) {
        // onOpenPayModal()
      }
      setLoading(false);
    }
    handleChange();
    const data = await getTextMarkmap();
    setHistoryMarkmap(data);
    setLoading(false);
  };

  const handleChange = () => {
    if (!bindGenPrompt.current || loading) return;

    let text: string = bindGenPrompt.current.value;
    text = text.trim();
    setText(text);
    state.value = text;
    updateSvg();
  };

  const setViewhistory = (item: any) => {
    SetbindTextPrompt(item.demand);
    // bindGenPrompt.current!.value = item.text;
    if (item.contentCheck.status === false) {
      SetRiskInfo(item.contentCheck);
    } else if (item.askCheck.status === false) {
      SetRiskInfo(item.askCheck);
    } else {
      SetRiskInfo(undefined);
    }
    if (bindGenPrompt.current) {
      const riskValue = item.contentCheck?.status !== false ? item.askCheck?.status !== false ? item.text : (`${t("creation.TextMayContain")}:${item.askCheck.labels?.split(',').map((str: any) => i18n.t('riskContent.' + str)).join(',')} ${t('creation.TemporarilyUnavailable')}${t('creation.NoConsume')} ` || t('NoConsume'))
        : (`${t("ContentMayContains")}:${item.contentCheck.labels?.split(',').map((str: any) => i18n.t('riskContent.' + str)).join(',')} ${t('creation.TemporarilyUnavailable')} ` || t('ContentRiskBlocked'))
      bindGenPrompt.current!.value = riskValue;
    }
    handleChange();
    onCloseSlider();
  };

  let saveSvgText = '';
  let enabled: Record<string, boolean> = {};
  const updateSvg = () => {
    const transformer = new Transformer();
    saveSvgText = state.value;
    const result = transformer.transform(saveSvgText);

    const keys = Object.keys(result.features).filter((key) => !enabled[key]);
    keys.forEach((key) => {
      enabled[key] = true;
    });
    const { styles, scripts } = transformer.getAssets(keys);
    if (scripts) loadJS(scripts);

    const markmapOptions = result.frontmatter?.markmap;
    const frontmatterOptions = deriveOptions(markmapOptions);

    if (!!mm) {
      mm.setData(result.root, frontmatterOptions);
      const svgNode = mm.svg.node();
      if (svgNode) {
        const { width: offsetWidth, height: offsetHeight } = svgNode!.getBoundingClientRect();
        if (offsetWidth > 0 && offsetHeight > 0) {
          mm.fit();
        }
      }
    }
  };

  const onClick_toolbar_zoomIn = () => {
    if (!!mm) {
      mm.rescale(1.25);
    }
  };

  const onClick_toolbar_zoomOut = () => {
    if (!!mm) {
      mm.rescale(0.8);
    }
  };

  const onClick_toolbar_fit = () => {
    if (!!mm) {
      mm.fit();
    }
  };

  const closePreview = () => {
    if (_previewRoot) {
      _previewRoot.unmount();
      _previewRoot = null;
    }
    if (fullSvgDiv1) {
      fullSvgDiv1.remove();
    }
  };
  const onClick_toolbar_openNew = () => {
    if (!fullSvgDiv1 || !fullSvgDiv2) {
      let newSvgDiv = document.createElement('div');
      newSvgDiv.style.position = 'absolute';
      newSvgDiv.style.left = '0';
      newSvgDiv.style.right = '0';
      newSvgDiv.style.top = '0';
      newSvgDiv.style.bottom = '0';
      newSvgDiv.style.zIndex = '9999';

      let newSvgDiv2 = document.createElement('div');
      newSvgDiv2.style.position = 'absolute';
      newSvgDiv2.style.left = '1rem';
      newSvgDiv2.style.right = '1rem';
      newSvgDiv2.style.top = '1rem';
      newSvgDiv2.style.bottom = '1rem';
      newSvgDiv2.style.overflow = 'auto';
      newSvgDiv2.style.borderRadius = '8px';

      newSvgDiv.append(newSvgDiv2);
      fullSvgDiv1 = newSvgDiv;
      fullSvgDiv2 = newSvgDiv2;
    }

    if (!fullSvgDiv1.parentElement) {
      document.body.append(fullSvgDiv1);

      _previewRoot = createRoot(fullSvgDiv2);
      _previewRoot.render(<MMPreview svgData={saveSvgText} closeMe={closePreview.bind(this)} />);
    }
  };

  const onClick_toolbar_download = async () => {
    if (!mm) return;
    const anyWindow: any = window;
    if (anyWindow.showSaveFilePicker) {
      SaveUtils.downloadSvgAsPng(mm.svg, "思维导图")
        .then((result: any) => {
          message.success({ title: '保存成功！' })
        })
        .catch((error: any) => {
          if (error.includes('The user aborted a request')) {
            // toast({
            //   render: () => (
            //     <Flex w={'125px'} justifyContent={'left'} alignItems={'center'} color="black" p={3} bg="white" borderRadius="8px" boxShadow={'0px 4px 10px 0px rgba(110,110,110,0.24)'}>
            //       <Img w={'22px'} h={'22px'} mr={2} src={require('@/assets/img/toolsicon/CancelDownload.png').default.src} />
            //       <Text>取消导出</Text>
            //     </Flex>
            //   ),
            // });
            message.warning({ title: '取消导出' })
          } else {
            message.error({ title: '下载错误，请联系我们！' })
          }
        });
    } else {
      message.warning({ title: '请使用Chrome浏览器进行下载操作！' })
    }
    const _duration = mm.options.duration;
    mm.options.duration = 0;
    mm.fit();
    mm.options.duration = _duration;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getTextMarkmap();
      setHistoryMarkmap(data);
    };
    if (bindGenPrompt.current) {
      bindGenPrompt.current.value = InitValues(beginText)[Math.floor(Math.random() * InitValues(beginText).length)];
    }
    handleChange();
    fetchData();
  }, []);

  let buttonpd = false;
  if (bindSvg) {
    buttonpd = bindSvg === null;
  }

  return (
    <Flex key={'langtranslations'} className={styles.toollistBox}>
      <Flex className={styles.listBox}>
        <ComTitle
          key={tool?.name.toString()}
          setViewhistory={setViewhistory}
          icon={tool ? tool.icon.toString() : ''}
          title={tool ? tool.name.toString() : ''}
          historyCache={historyMarkmap}
          bindprompt={bindGenPrompt}
          historytitle='demand'
          historycontent='text'
          onDelete={async (id: string) => {
            await deleteTextMarkmap({ _id: id });
            const data = await getTextMarkmap();
            setHistoryMarkmap(data);
            if (bindGenPrompt.current !== null) {
              bindGenPrompt.current.value = '';
            }
          }} />
        <ScrollBarBox display={'flex'} flexDir={'column'} height={'100%'}>
          <Box >
            <ComTextarea
              title={'输入需求描述*'}
              prompt={'例如：外星人的日常'}
              textvalue={bindTextPrompt}
              textchange={SetbindTextPrompt}
              maxCount={300}
              rows={3}
            />
          </Box>
          <Box flex={1}>
            <ComImprove
              title={'文字预览'}
              textareaRef={bindGenPrompt}
              fathervalue={''}
              handleValueChange={handleChange}
              riskInfo={riskInfo}
            />
          </Box>
        </ScrollBarBox>
        <Flex className={styles.footbutton}>
          <ComButton
            loading={loading}
            setLoading={setLoading}
            casebut={'示例'}
            startbut={'生成'}
            stopbut={'停止'}
            stopClick={() => { controller.current?.abort() }}
            startClick={handleGPTGenInfo}
            caseStop={true}
            // isnull={isDisabled}
            chooseModel={choosesModel}
            setChooseModel={setChoosesModel}
            modelkey={modelkey}
            setModelsKey={setModelsKey}
            _Models={_Models}
          ></ComButton>
        </Flex>
      </Flex>
      <Flex className={styles.previewBox}>
        <Flex className={styles.flexColumn}>
          <Box className={styles.centerItemsbox}>
            <Text className={styles.title}>内容预览</Text>
            <Text className={styles.prompt}>* 该结果由人工智能生成，仅供参考</Text>
          </Box>
          <Box h={'100%'} w={'100%'} position={'relative'}>
            <svg className={styles.markmapclass} ref={bindSvg} />
            <div className={styles.markmapclasstoolbar} ref={bindToolbar}>
              <Icon className={styles.buttonsty} as={require('@/assets/svg/zoom_in.svg').ReactComponent}
                onClick={onClick_toolbar_zoomIn.bind(this)} />
              <Icon className={styles.buttonsty} as={require('@/assets/svg/zoom_out.svg').ReactComponent}
                onClick={onClick_toolbar_zoomOut.bind(this)} />
              <Icon className={styles.buttonsty} as={require('@/assets/svg/openwindow.svg').ReactComponent}
                onClick={onClick_toolbar_fit.bind(this)} />
              <Icon className={styles.buttonsty} as={require('@/assets/svg/fitwindow.svg').ReactComponent}
                onClick={onClick_toolbar_openNew.bind(this)} />
              <CButton className={styles.dowbut} onClick={onClick_toolbar_download.bind(this)}>
                <Icon as={require('@/assets/svg/toolsicon/download.svg').ReactComponent} />
                <Text >下载</Text>
              </CButton>
            </div>
          </Box>
          <Box className={styles.bomdiv}> </Box>
        </Flex>
      </Flex>
    </Flex>
  )
})