import { FC } from "react";
import { Switch, SwitchProps } from '@mui/base/Switch';

import styles from './index.module.scss'

interface CSwitchProps extends SwitchProps {
}
export const CSwitch: FC<CSwitchProps> = (props) => {
  return <Switch
    className={styles.swtich}
    slotProps={{
      track: { className: styles.track },
      thumb: { className: styles.thumb },
      input: { className: styles.input },
    }}
    {...props}
  />
}
