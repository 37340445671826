import { FC } from "react";
import { userStore } from "@/store/user";
import { observer } from "mobx-react-lite";
import { IMContact } from "./contact";
import { Box } from "@chakra-ui/react";
import IMContent from "./content";

import styles from './index.module.scss'
import './css/content.css'
import './css/drawer.css'
import './css/popover.css'
import './css/zoom.css'
import './css/common.css'
import './css/dropdown.css'
import './css/tooltip.css'
import './css/modal.css'
import './css/chat.css'

const IM: FC = () => {
  const { userInfo } = userStore;

  return <>
    {userInfo ? <IMConnect /> : <IMNoConnect />}
  </>
}
const IMNoConnect: FC = () => {
  return <div className={styles.unLogin}>
    <Box as={require('@/assets/svg/im_hi.svg').ReactComponent} />
    <span className={styles.text}>请先登录再开始聊天吧</span>
  </div>
}

const IMConnect: FC = () => {
  return <Box className={styles.imContainer}>
    <IMContact />
    <IMContent />
  </Box>
}

export default observer(IM);