import { Box } from "@chakra-ui/react"
import { FC, useState } from "react"
import { CButton } from "@/components/Button"
import { useMessage } from "@/hooks/useMessage"
import { userStore } from "@/store/user"
import { observer } from "mobx-react-lite"
import { globalStore } from "@/store/global"
import { CodeInput, PhoneInput } from "./LoginInput"
import { useLocation } from "react-router-dom"

import styles from './index.module.scss'

interface LoginByCodeProps {
  onAgreen: () => void
}
export const LoginByCode: FC<LoginByCodeProps> = observer((props) => {
  const { onAgreen } = props
  const [error, setError] = useState<string>('')
  const [code, setCode] = useState<string>('');
  const message = useMessage();
  const [loading, setLoading] = useState<boolean>(false)

  const { loginByCodeAction, codeCountdown, getCode } = userStore;
  const { setLoginIsOpen, loginPhone, setLoginPhone, loginIsAgreeProtocol, loginSuccess } = globalStore;
  const location = useLocation();

  const loginByCode = async () => {
    if (!loginIsAgreeProtocol) {
      onAgreen();
      return
    }
    let errMsg = ''
    if (loading || isDisabled) return;
    if (!loginPhone) {
      errMsg = '请输入手机号';
    } else if (!/(^1[3456789]\d{9}$)/.test(loginPhone)) {
      errMsg = '手机号格式错误';
    } else if (!code) {
      errMsg = '请输入验证码';
    }
    if (errMsg) {
      message.error({ title: errMsg })
      return
    }
    try {
      setLoading(true)
      await loginByCodeAction(loginPhone, code);
      message.success({ title: '登录成功' });
      setLoginPhone('');
      loginSuccess(location.pathname);
      setLoading(false);
      setLoginIsOpen(false);
    } catch (err: unknown) {
      setError((err as ErrorResponse).message);
      setLoading(false);
    }
  }

  const sendGetCode = async () => {
    let errMsg = '';
    if (!loginPhone) {
      errMsg = '请输入手机号';
    } else if (!/(^1[3456789]\d{9}$)/.test(loginPhone)) {
      errMsg = '手机号格式错误';
    }
    if (errMsg) {
      message.error({ title: errMsg })
      return
    }

    try {
      await getCode(loginPhone);
    } catch (err: unknown) {
      setError((err as ErrorResponse).message)
    }
  }

  const isDisabled = loginPhone === '' || code === '';
  return <Box className={styles.byCode}>
    <PhoneInput value={loginPhone} err={error} onChange={value => {
      setLoginPhone(value);
      if (error) {
        setError('');
      }
    }} />
    <CodeInput cutdown={codeCountdown} onChange={setCode} onGetCode={sendGetCode} />
    <CButton disabled={isDisabled} isLoading={loading} className={styles.phoneBtn} theme='primary' onClick={loginByCode}>登录/注册</CButton>
  </Box>
})