import { CreationType } from "@/store/creation";
import { DELETE, GET, POST } from "./request"

export const getCreationStyleConfig = (subType: CreationType.MJ | CreationType.SD | CreationType) => {
  return GET<StyleConfig[]>('/config/drawConfiglist?subType=' + subType)
}

export const getCreationHistory = (data: GetCreationListParams) => {
  return POST<HistoryListResult>('/user/draw/list', { data })
}

/* 获取视频列表， 单次获取全部视频 */
export const getDrawVideos = (page: RequestPaging) =>
  POST<PagingData<DrawVideo> & { favorites: DrawFavoriteType[] }>(`/user/draw/videolist`, page);

export const getLangtranslate = (lang: string) => POST<String>('/user/draw/translate', { lang })

/** 请求创建一个画图任务 */
export const postCreateDrawImage = (data: CreateCreationTaskParams) => {
  return POST<{ ret: string; message: string; userBalance: number; newId: string }>('/user/draw/create', data);
}

/** 请求创建一个视频任务 */
export const postCreateDrawGEN2Image = (data: CreateGen3TaskParams) =>
  POST<{ ret: string; message: string; userBalance: number; newId: string }>('/user/draw/createGEN2', data);


export const deleteCreation = (data: { type: CreationType, _id: string }) => POST<String>('/user/draw/delete', data)

/* 删除某一个视频 */
export const deleteDrawVideo = ({ _id, type }: { _id: string; type: string }) =>
  POST<DrawVideo[]>(`/user/draw/deleteVideo`, {
    _id,
    type
  });

/** 收藏绘画图片 */
export const collectionImg = (id: string, imgUrl: string, type: CreationType) => POST<Record<string, string[]>>('/user/draw/favorite', {
  id, imgUrl, type
});


/** 获取图片广场列表 */
export const getShareImgs = (data: RequestPaging & { filter: SquareFilterType }) => {
  return POST<PagingData<CreationShareType>>(`/user/draw/square`, { data });
}

/** 删除广场列表图片 */
export const deleteShareImg = (_id: string) => {
  return POST<PagingData<CreationShareType>>(`/user/draw/deleteShare`, { _id });
}

export const getDallE3History = (data: RequestPaging) => {
  return GET<{ list: DrawDallE3Type[], total: number }>('/user/draw/getDallE3', data);
}

export const getDallE3Info = (id: string) => {
  return GET<{ list: DrawDallE3Type, favorites: DrawFavoriteType[] }>('/user/draw/dalle3Info?id=' + id,);
}

export const drawAsDallE3 = (data: { id?: string, prompt: string }) =>
  POST<{
    _id: string, drawId: string,
    contentCheck?: {
      status: boolean; riskLevel: 'high' | 'medium' | 'low' | ''; riskTips: string; riskWords: string; labels: string; // 风险标签
    },
    result: { revised_prompt: string; url: string, status: 'failed' | 'success' | 'delete' }[]
  }>('/user/draw/dalle3', data);

export const deleteDallE3Record = (id: string) => DELETE('/user/draw/deleteDallE3?id=' + id);

/** 失败重试 */
export const retryDraw = ({ _id, type }: { _id: string; type: string }) => {
  return POST<{ message: string; data: HistoryItem[] }>(`/user/draw/retry`, {
    _id,
    type
  });
}

/** 重试生成视频 */
export const retryDrawVideo = (_id: string) =>
  POST<{ message: string; data: DrawVideo[] }>(`/user/draw/retryVideo`, {
    _id
  });

/** 分享图片 */
export const shareDrawImg = (data: { _id: string, url: string, type: CreationType }) => {
  return POST<HistoryItem>(`/user/draw/share`, data);
}

/** 获取所有suno列表 */
export const getDrawSunoList = () => {
  return GET<DrawSunoSchema[]>(`/user/suno/getsunolist`);
}

/** 获取所有suno风格列表 */
export const getDrawSunoStyleList = () => {
  return GET<any>(`/user/suno/getsunostylelist`);
}

export const creatSuno = (
  {
    draw_type,
    instrumental_music,
    music_style,
    music_model,
    title,
    text,
    prompt,
  }: {
    draw_type: string;
    instrumental_music: boolean;
    music_style: string;
    music_model: string;
    title: string;
    text: string;
    prompt: string;
  }) =>
  POST(`/user/suno/creat`, {
    draw_type, instrumental_music, music_style, music_model, title, text, prompt
  });

export const creatSunoLyric = (
  {
    title,
  }: {
    title: string;
  }) =>
  POST(`/user/suno/creatlyric`, {
    type: 'gen_lyrics', title
  });

/** 获取当前生成的歌词 */
export const getDrawSunoLyric = ({
  _id,
}: {
  _id: string;
}) => {
  return POST<any>(`/user/suno/getsunolyric`, { _id });
}

/** 删除suno任务 */
export const deleteDrawSuno = ({
  _id,
}: {
  _id: string;
}) => {
  return POST<any>(`/user/suno/delete`, { _id });
}