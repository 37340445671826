import { Box, Icon, Text } from "@chakra-ui/react";
import { FC, memo, useEffect, useRef, useState } from "react";
import { CreationTitle, CreationTitleBox } from "../";
import { Slider, SliderProps } from '@mui/base/Slider';
import { useSelectFile } from "@/hooks/useSelectFile";
import { isChromeHighVersion, uploadFiles } from "@/utils/common";
import Loading from "@/components/Loading";
import { v4 as uuidv4 } from 'uuid';
import { WiseImage } from "@/components/Image";
import { uiStrore } from "@/store/ui";
import { useTranslation } from "react-i18next";

import styles from './index.module.scss'

interface CreationReferenceProps {
  title: string
  tips: { title: string, tips: string[] }
  url?: string
  onUrlChange?: (url: string) => void
  showSlider?: boolean
  sliderProps?: SliderProps
  shouldTransToJpeg?: boolean
  isFirst?: boolean
}
export const CreationReference: FC<CreationReferenceProps> = memo((props) => {
  const { title, url, tips, sliderProps, onUrlChange, shouldTransToJpeg, showSlider = true, isFirst } = props;
  const { onOpen, File: FileEle } = useSelectFile({ fileType: '.jpeg,.jpg,.png,.webp', multiple: true });
  const [loading, setLoading] = useState(false);
  const { openAlert, closeAlert } = uiStrore;
  const { t } = useTranslation();

  const selectImgFiles = async (file: File[]) => {
    if (file.length === 0 || loading) return;
    setLoading(true);
    try {
      let uploadFile: File
      if (shouldTransToJpeg) {
        uploadFile = new File([file[0]], uuidv4() + '.jpeg', { type: 'image/jpeg' });
      } else {
        uploadFile = file[0]
      }
      const res = await uploadFiles(uploadFile);
      onUrlChange && onUrlChange(res[0]);
    } catch (err) {
      console.log(err)
    }
    setLoading(false);
  }

  const openTips = () => {
    openAlert({
      title: tips.title,
      content: <Box className={styles.tipsContent}>{tips.tips.map(desc => <Text>{desc}</Text>)}</Box>,
      status: 'ask',
      footer: <Box className={styles.tipsFooter} onClick={closeAlert}>{t('ISee')}</Box>,
      onclose: closeAlert
    })
  }

  return <Box className={`${styles.reference} ${isFirst ? styles.isFirst : ''}`}>
    <CreationTitleBox className={styles.title}>
      <CreationTitle title={title} />
      <Icon as={require('@/assets/svg/question.svg').ReactComponent} w={'20px'} h='20px' onClick={openTips} />
    </CreationTitleBox>
    <Box className={styles.container}>
      {
        url ? <Box className={styles.img}>
          <WiseImage src={url} />
          <Box className={styles.clear} onClick={() => onUrlChange && onUrlChange('')}>
            <Icon as={require('@/assets/svg/close_x.svg').ReactComponent} />
          </Box>
        </Box> :
          <Box className={styles.img} onClick={onOpen}>
            {
              loading ? <Loading.Icon /> : <>
                <Icon as={require('@/assets/svg/img_upload_icon.svg').ReactComponent} w={'30px'} h='30px' />
                <Text className={styles.text}>{t('UploadImg')}</Text>
              </>
            }
          </Box>
      }
      {
        url && showSlider &&
        <Box className={styles.slider}>
          <Text className={styles.number}>{t('creation.Reference')}</Text>
          <Box className={styles.range}>
            <WiseSlider {...sliderProps} />
          </Box>
        </Box>
      }
    </Box>
    <FileEle onSelect={selectImgFiles} />
  </Box>
})

interface WiseSliderProps extends SliderProps { }
export const WiseSlider: FC<WiseSliderProps> = (props) => {
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (ref.current) {
      const originalGetBoundingClientRect = ref.current.getBoundingClientRect;
      if (!isChromeHighVersion()) {
        return;
      }
      ref.current.getBoundingClientRect = function () {
        const rect = originalGetBoundingClientRect.call(this);
        // @ts-ignore
        const zoom = Number(document.body.style.zoom) || 1;
        const newRect = new DOMRect(
          rect.x * zoom,
          rect.y * zoom,
          rect.width * zoom,
          rect.height * zoom
        );
        return newRect;
      };
    }
  }, [])
  return <Slider
    className={styles.sliderStyle}
    slotProps={{
      rail: { className: styles.sliderRail },
      mark: { className: styles.sliderMark },
      markLabel: { className: styles.sliderMarkLabel },
      thumb: { className: styles.sliderThumb },
      track: { className: styles.sliderTrack },
    }}
    ref={ref}
    slots={{
      valueLabel: ({ children }) => {
        return <Box className={`base-Slider-valueLabel ${styles.sliderValueLabel}`}>
          {children}
          <Icon as={require('@/assets/svg/value_label.svg').ReactComponent} />
        </Box>
      }
    }}
    {...props}
  />
}