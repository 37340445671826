import { ToolSchema, VideoSummaryModelSchema } from "@/@types/tools"
import { toolStore, VideoSummaryStatusEnum } from "@/store/tool"
import { userStore } from "@/store/user"
import { Box, Flex, Text, Image, Textarea, Icon, Tabs, TabList, Tab, TabPanels, TabPanel, Drawer, DrawerOverlay, DrawerContent, Menu, MenuButton, Avatar, Img } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { adaptChatItem_openAI, voiceBroadcast } from "@/utils/chat"
import styles from "./index.module.scss"
import { ComButton } from "../components/ComButton"
import { useMessage } from "@/hooks/useMessage"
import { chatStore, defaultChatData } from "@/store/chat"
import { ComTitle } from "../components/ComTitle"
import { ScrollBarBox } from "@/components/ScrollBox"
import Markdown from "@/components/Markdown"
import { deleteVideoSummary, deleteVideoSummaryChat, getInitChatSiteInfo, getVideoSummaryDetail, getVideoTab, getUrltoVideo } from "@/api/tools"
import ChatContent from "./Content"
import { streamFetch } from "@/utils/stream"
import { Types } from "mongoose";
import RotatingCircles from "@/components/RotatingCircles"
import { uiStrore } from "@/store/ui"
import { useQuery } from "@tanstack/react-query"
import { useSelectFile } from "@/hooks/useSelectFile"
import axios from "axios"
import { truncateText } from "@/pages/creation/Tools/Components/ComFile"
import { useTranslation } from "react-i18next"

const caseUrl = 'https://www.bilibili.com/video/BV1AM4y1M71p/'
const modelId = '65601d1fda2f21ff64be9a57'
const icons = [
  {
    key: '复制',
    icon: require('@/assets/svg/toolsicon/copy.svg').ReactComponent,
    hovericon: require('@/assets/svg/toolsicon/copynot.svg').ReactComponent,
    selecticon: require('@/assets/svg/toolsicon/copyed.svg').ReactComponent,
  },
  {
    key: '朗读',
    icon: require('@/assets/svg/toolsicon/play.svg').ReactComponent,
    hovericon: require('@/assets/svg/toolsicon/playnot.svg').ReactComponent,
    selecticon: require('@/assets/svg/toolsicon/played.svg').ReactComponent
  },
  {
    key: '下载',
    icon: require('@/assets/svg/toolsicon/download.svg').ReactComponent,
    hovericon: require('@/assets/svg/toolsicon/downloadnot.svg').ReactComponent,
    selecticon: require('@/assets/svg/toolsicon/downloaded.svg').ReactComponent
  }
];
const _Models = [
  { name: '文思GPT 3.5-16k', key: 'gpt-3.5-turbo-1106', tips: '(速度快，但生成质量一般)', tag: '限时免费' },
  { name: '文思GPT 4o-128k', key: 'gpt-4o', tips: '(生成质量好、理解能力优秀)', price: '200' }
]

const base64ToArrayBuffer = (base64: any) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

const convertVideoUrlToFile = async (url: any) => {
  const filename = url.split('/').pop();
  const extension = filename.split('.').pop();
  const mimeTypes: { [key: string]: string } = {
    mp4: 'video/mp4',
    webm: 'video/webm',
    avi: 'video/x-msvideo',
  };
  const mimeType = mimeTypes[extension] || 'application/octet-stream';
  try {
    const buffer = await getUrltoVideo(url);
    const bufferdata = base64ToArrayBuffer(buffer);
    const file = new File([bufferdata], filename, { type: mimeType });
    return [file];
  } catch (error) {
    console.error('Error converting URL to file:', error);
    return []
  }
}


export const VideoSummary: FC = observer(() => {
  const { openAlert, closeAlert } = uiStrore;
  const [tool, SetTool] = useState<ToolSchema>()
  const { getToolByLink, getVideoSummaryList, createVideoSummary } = toolStore;
  const { videoSummaryChat, setVideoSummaryChat, playingId, setPlayingId } = chatStore;
  const [tabsCurrent, setTabsCurrent] = useState<'summary' | 'videoText'>('summary')
  const [tagsCurrent, setTagsCurrent] = useState<'text' | 'video'>('text')
  const [hislist, sethisList] = useState<VideoSummaryModelSchema[]>([])
  const [info, setInfo] = useState<VideoSummaryModelSchema>()
  const { userInfo } = userStore
  const TextareaDom = useRef<HTMLTextAreaElement>(null);
  const InputTextareaDom = useRef<HTMLTextAreaElement>(null);
  const androidRef = useRef<{ ttsWS: WebSocket; audioPlayer: any; }>()
  const message = useMessage();
  const [caseStop, SetcaseStop] = useState(true);
  const [loading, setLoading] = useState(false);
  const [buttonpd, setButtonpd] = useState(true);
  const [videotabs, SetVideotabs] = useState<any[]>([]);
  const [isnullcontent, SetIsNullContent] = useState(false);
  const tabRef = useRef<any>(null);
  const tabsRef = useRef<any>(null);
  const { t } = useTranslation();

  const isProcessing = info && (info.status == VideoSummaryStatusEnum.PROCESSING
    || info.status == VideoSummaryStatusEnum.PROCESSING13
    || info.status == VideoSummaryStatusEnum.PROCESSING14
    || info.status == VideoSummaryStatusEnum.WAIT)
  useQuery(
    ['getVideoSummaryInfo'],
    () => {
      return getVideoSummaryDetail(info?._id || '');
    },
    {
      enabled: !!(info && isProcessing),
      refetchInterval: 10000,
      async onSuccess(res: VideoSummaryModelSchema) {
        if (res) {
          setInfo(res)
          getSummary();
        }
      }
    }
  );
  // const isChatting = videoSummaryChat.history[videoSummaryChat.history.length - 1]?.status === 'loading'
  // const { videoSummaryChat, setVideoSummaryChat, textAreaInput, setTextAreaInput } = useChatStore()
  useEffect(() => {
    const fetchData = async () => {
      const viodes = await getVideoTab();
      SetVideotabs(viodes);
      if (info) {
        await getHistoryChat(info._id)
      }
      if (tabsCurrent === 'summary') {
        if (info?.summary && info?.summary.length > 0) {
          setButtonpd(false)
        } else {
          setButtonpd(true)
        }
      } else if (tabsCurrent === 'videoText') {
        if (info?.transcription && info?.transcription.length > 0) {
          setButtonpd(false)
        } else {
          setButtonpd(true)
        }
      }
    }
    fetchData();
  }, [info, tabsCurrent])
  const getHistoryChat = async (id: string) => {
    const res = await getInitChatSiteInfo(modelId, id)
    if (res.history?.length) {
      setVideoSummaryChat({
        ...res,
        history: res.history.map((item) => ({
          ...item,
          status: 'finish'
        }))
      });
    } else {
      setVideoSummaryChat({
        ...defaultChatData,
      });
    }
  }
  const onclickCopy = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      message.success({ title: '复制成功' })
    } catch (err) {
      message.error({ title: '复制失败' })
    }
  };
  const play = (id: any) => {
    if (playingId === id) {
      if (window['speechSynthesis'] === undefined) {
        androidRef.current?.ttsWS.close()
        androidRef.current?.audioPlayer.reset()
      } else {
        window.speechSynthesis.cancel();
      }
      setPlayingId('');
    } else {
      if (window['speechSynthesis'] === undefined) {
        // androidPlayAudio(value) // 安卓播放音频 暂时不支持
      } else {
        voiceBroadcast({
          text: tabsCurrent === 'summary' ? info ? info.summary[0].info : '' : info ? info.transcription : '',
          onEndCallback: () => {
            setPlayingId(''); // 朗读结束时更新状态
          }
        });
      }
      setPlayingId(id);
    }
  }
  const [chooseModel, setChooseModel] = useState(false);
  const [modelkey, setModelsKey] = useState(_Models[1]);
  const modelMenuRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const fetchData = async () => {
      setVideoSummaryChat(undefined)
      getSummary();
      const data = await getToolByLink({ toollink: 'videoSummary' });
      SetTool(data[0]);
    };
    fetchData();
  }, []);
  const getSummary = async () => {
    const videohis = await getVideoSummaryList();
    sethisList(videohis)
  }

  const clearChat = async () => {
    if (!info?._id) return
    try {
      await deleteVideoSummaryChat(info?._id)
      setVideoSummaryChat({
        ...defaultChatData,
        chatId: ''
      });
    } catch (err) {
    }
  }
  const download = async () => {
    const text = tabsCurrent === 'summary' ? info?.summary.map((item) => (item.info || '')).join('\n\n') || '' : info ? info.transcription : ''
    if (!text) {
      message.success({
        status: 'warning',
        title: '没有可供下载到内容'
      })
      return
    }
    const blob = new Blob([text], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    const anyWindow: any = window;
    try {
      if (anyWindow.showSaveFilePicker) {
        // @ts-ignore
        const handle = await window.showSaveFilePicker({
          suggestedName: `视频总结.docx`,
          types: [{
            description: 'Microsoft Word document',
            accept: { 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'] },
          }],
        });
        const writableStream = await (handle as any).createWritable();
        await writableStream.write(blob);
        await writableStream.close();
      } else {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `视频总结.docx`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
      message.success({
        title: '保存成功'
      })
    } catch (err) {
      message.warning({
        title: '取消导出'
      })
    }

  }
  const controller = useRef(new AbortController());

  const deleteSummary = async (id: any) => {
    try {
      const re = await deleteVideoSummary(id);
      if (re) {
        message.success({ title: '删除成功' })
        const res = await getVideoSummaryList();
        sethisList(res);
      } else {
        message.error({ title: '删除失败' })
      }
    } catch (error) {
      message.error({ title: '删除失败: 发生异常' })
      console.error(error);
    }
  }

  const startSummary = async () => {
    setLoading(true);
    if (tagsCurrent === 'text') {
      if (!TextareaDom.current || !TextareaDom.current.value) {
        message.warning({ title: '请输入视频链接' })
        return
      }
      try {
        deleteFile(0);
        const res = await createVideoSummary(TextareaDom.current.value, tool ? tool._id : '', modelkey.key)
        setInfo(res)
        await getSummary()
        setLoading(false);
      } catch (err: any) {
        message.warning({ title: err.message });
        setLoading(false);
      }
    } else {
      if (!videoFile || videoFile.length < 1) {
        message.warning({ title: '请上传视频' })
        return
      }
      try {
        if (TextareaDom.current) {
          TextareaDom.current.value = ''
        }
        const res = await createVideoSummary(videoFile[0].url, tool ? tool._id : '', modelkey.key)
        setInfo(res)
        await getSummary()
        setLoading(false);
      } catch (err: any) {
        message.warning({ title: err.message });
        setLoading(false);
      }
    }
  }
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [videoFile, setVideoFile] = useState<ChatFileType[]>();
  const fileMaxSize = 500 * 1024 * 1024 // 500MB
  const { onOpen, File } = useSelectFile({ fileType: '.mp4', multiple: true });
  const selectImgFiles = useCallback(async (files: File[]) => {
    // 清空历史记录和历史对话
    setInfo(undefined);
    setVideoSummaryChat(undefined);
    if (files && files.length) {
      setIsSpeaking(true);
      const filesInfo: ChatFileType[] = [];
      files.forEach(file => {
        if (file.size > fileMaxSize) {
          message.error({ title: `${file.name}过大,目前仅支持500MB以下的文件` })
          return false
        }
        if (file.type.startsWith('video/')) {
          filesInfo.push({
            type: 'image_url',
            name: file.name,
            file,
            url: ''
          })
        } else {
          message.error({ title: `暂不支持${file.name}文件类型` });
          setUploadError('视频文件加载失败，请重试。')
          return false
        }
      })

      if (filesInfo.length) {
        const beforeFiles = videoFile || []
        setVideoFile(beforeFiles.concat(filesInfo))
        // setUploading(true)
        const afterInfo: ChatFileType[] = []
        for (const item of filesInfo) {
          if (item.file) {
            try {
              const res = await uploadFiles(item.file)
              afterInfo.push({
                type: item.type,
                name: res.data.data[0].split('/').pop(),
                url: res.data.data[0]
              })
              setIsSpeaking(false)
            } catch (err) {
              message.error({ title: `${item.name}上传失败，请重试` });
              setIsSpeaking(false)
            }
          }
        }
        setVideoFile(beforeFiles.concat(afterInfo))
      }
    }
  }, [videoFile])
  const uploadFiles = (e: File) => {
    const formData = new FormData();
    formData.append('file', e)
    return axios.post(
      (window.proxyApi || '') + '/api/system/uploadToAliOss',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      }
    );
  }
  const deleteFile = (index: number) => {
    const new_file = videoFile?.filter((_, i) => i !== index) || [];
    setVideoFile(new_file)
  }
  const [uploadError, setUploadError] = useState('');

  const [textAreaInput, setTextAreaInput] = useState('')
  const [isnull, setisnull] = useState(false);
  useEffect(() => {
    if (!InputTextareaDom.current?.value) {
      setisnull(false);
    } else if (!info?._id || videoSummaryChat === undefined) {
      setisnull(false);
    } else if (!userInfo) {
      setisnull(false);
    } else if (loading) {
      setisnull(false);
    } else {
      setisnull(true)
    }
  }, [textAreaInput])
  const sendPrompt = async () => {
    if (!InputTextareaDom.current?.value) {
      message.warning({ title: '内容为空' })
      return;
    }
    if (!info?._id || videoSummaryChat === undefined) {
      message.warning({ title: '请等待视频解析完成或选择历史记录中的解析进行对话' })
      return;
    }
    if (!userInfo) {
      message.warning({ title: '请先登录！' })

      // setLoginIsOpen(true)
      return;
    }
    if (loading) {
      message.warning({ title: '正在聊天中...请等待结束' })
      return;
    }

    const abortSignal = new AbortController();
    controller.current = abortSignal;
    const val = InputTextareaDom.current.value
    InputTextareaDom.current.value = ''
    const newChatList: any[] = [
      ...videoSummaryChat.history,
      {
        _id: String(new Types.ObjectId()),
        obj: 'Human',
        modelName: modelkey.key,
        value: val,
        status: 'finish'
      },
      {
        _id: String(new Types.ObjectId()),
        obj: 'AI',
        modelName: modelkey.key,
        value: '',
        status: 'loading'
      }
    ];

    setVideoSummaryChat((state: any) => ({
      ...state,
      history: newChatList
    }));

    const messages = adaptChatItem_openAI({ messages: newChatList.slice(newChatList.length - 2), reserveId: true });

    try {

      const { quoteLen } = await streamFetch({
        data: {
          type: 'chat',
          stream: true,
          messages: videoSummaryChat.history ? [
            {
              content: `你是一个视频解说助手，视频的文案内容如下：
            ${info?.transcription}
            稍后用户将向你提问，请你根据视频原文内容进行回答。
            `,
              role: 'system'
            },
            ...messages
          ] : messages as any[],
          toolId: videoSummaryChat.chatId ? undefined : info._id as unknown as Types.ObjectId,
          appId: modelId,
          chatId: videoSummaryChat.chatId || '',
          model: modelkey.key,
        },
        onMessage: (text: string) => {
          setVideoSummaryChat((state: any) => ({
            ...state,
            history: state.history.map((item: any, index: any) => {
              if (index !== state.history.length - 1) return item;
              if (text.startsWith('```__PRICE__:') && text.endsWith('__PRICE__END__```')) {
                let tokens = 0
                try {
                  tokens = JSON.parse(text.replace('```__PRICE__: ', '').replace('__PRICE__END__```', '')).tokens
                } catch (err) {
                  tokens = 0
                }
                return {
                  ...item,
                  tokens
                };
              } else {
                return {
                  ...item,
                  value: item.value + text
                };
              }
            })
          }));
          // generatingMessage(); 滚动到底部
        },
        abortSignal
      })
      setVideoSummaryChat((state: any) => ({
        ...state,
        modelId: modelId,
        chatId: info._id, // 如果有 Id，说明是新创建的对话
        history: state.history.map((item: any, index: any) => {
          if (index !== state.history.length - 1) return item;
          return {
            ...item,
            status: 'finish',
            quoteLen,
            systemPrompt: `${videoSummaryChat.systemPrompt}${`${videoSummaryChat.limitPrompt ? `\n\n${videoSummaryChat.limitPrompt}` : ''
              }`}`
          };
        })
      }));
    } catch (err) {
      setVideoSummaryChat((state: any) => ({
        ...state,
        history: newChatList.slice(0, newChatList.length - 2)
      }));
      InputTextareaDom.current.value = val;
      message.warning({ title: '生成错误，请联系管理员！' })
    }
  }
  const ButtonWithIcon = ({ keyName, onClick, isDisabled }: { keyName: any, onClick: any, isDisabled: any }) => {
    const [isHovered, setIsHovered] = useState(false);

    const iconData = icons.find(icon => icon.key === keyName)!;

    let IconComponent = iconData.icon;
    if (isDisabled) {
      IconComponent = iconData.hovericon;
    } else if (isHovered) {
      IconComponent = iconData.selecticon;
    }
    return (
      <Flex
        className={`${styles.button} ${isDisabled ? styles.disabled : ''}`}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <IconComponent />
        <Text whiteSpace="nowrap" color={isHovered ? '#565CFA' : isDisabled ? '#aaa' : '#333'}>{t(keyName)}</Text>
      </Flex>
    );
  };
  useEffect(() => {
    if (videoFile && videoFile.length > 1) {
      deleteFile(0)
    }
  }, [videoFile])
  useEffect(() => {
    if (tagsCurrent === 'text') {
      if (TextareaDom.current && TextareaDom.current.value.length > 0 && !isProcessing) {
        SetIsNullContent(false)
      } else {
        SetIsNullContent(true)
      }
    } else {
      if (videoFile && videoFile.length > 0 && !isSpeaking && !isProcessing) {
        SetIsNullContent(false)
      } else {
        SetIsNullContent(true)
      }
    }
  }, [TextareaDom, videoFile, tagsCurrent, isSpeaking, isProcessing])
  return <Flex className={styles.toollistBox}>
    <Flex className={styles.listBox} >
      <Flex className={styles.toolbox}>
        <ComTitle
          key={tool?.name.toString()}
          setViewhistory={async (item: any) => {
            if (item.video_url.includes('wisetalker-gpt-pub')) {
              if (TextareaDom.current) {
                TextareaDom.current.value = ''
                SetIsNullContent(false);
              }
              if (tabRef.current) {
                tabRef.current.click();
              }
              setTagsCurrent('video');
              const file = await convertVideoUrlToFile(item.video_url) as any
              selectImgFiles(file);
              // handleTextareaChange()
            } else {
              if (tabsRef.current) {
                tabsRef.current.click();
              }
              if (videoFile && videoFile.length > 0) {
                deleteFile(0)
              }
              setTagsCurrent('text');
              setTimeout(() => {
                if (TextareaDom.current) {
                  TextareaDom.current.value = item.video_url
                  SetIsNullContent(false);
                }
              }, 100);
            };
            setInfo(item)
          }}
          icon={tool?.icon.toString()}
          title={tool?.name.toString()}
          historyCache={hislist}
          bindprompt={modelMenuRef}
          historytitle={'video_url'}
          historycontent={'transcription'}
          creatTime={'time'}
          onDelete={async (id: string) => {
            openAlert({
              title: '提示',
              content: '确定要删除吗？',
              onOk: () => {
                closeAlert();
                deleteSummary(id)
              }
            })
          }} />
      </Flex>
      <ScrollBarBox display={'flex'} flexDir={'column'} height={'calc(100% - 20px)'}>
        <Tabs className={styles.tabs}>
          <TabList className={styles.tabList}>
            <Tab className={`${styles.tab} ${tagsCurrent === 'text' ? styles.selectedTab : ''}`} ref={tabsRef}
              key='text' onClick={() => { setTagsCurrent('text') }}>视频链接</Tab>
            <Tab className={`${styles.tab} ${tagsCurrent === 'video' ? styles.selectedTab : ''}`} ref={tabRef}
              key='video' onClick={() => { setTagsCurrent('video') }}>上传视频</Tab>
          </TabList>
          <TabPanels className={styles.tabPanels} h={'180px'}>
            <TabPanel className={styles.tabPanel}>
              <Text className={styles.titleText}>
                (支持以下在线视频平台，消耗点数按次数计算，200点一次)
              </Text>

              <Flex className={styles.videotabs}>
                {(videotabs?.length < 1 ? [] : videotabs).map((item: any) => {
                  return (
                    // <Image key={item} src={require(`@/assets/img/toolsicon/${item}`)} />
                    <Image key={item} src={`${item}`} />
                  )
                })}
              </Flex>
              <Box className={styles.tabTextareaBox}>
                <Textarea className={styles.tabTextarea} ref={TextareaDom} onChange={() => {
                  if (TextareaDom.current && TextareaDom.current.value.length > 0) {
                    SetIsNullContent(false)
                  } else {
                    SetIsNullContent(true)
                  }
                }} />
              </Box>
            </TabPanel>
            <TabPanel className={styles.tabPanel}>
              <Text className={styles.titleText}>
                (消耗点数按次数计算，200点一次)
              </Text>
              {(videoFile && videoFile.length) ? (
                videoFile.map((file, index) => {
                  return <Box className={styles.uploadBoxx} key={index}>
                    {isSpeaking ?
                      // <Icon className={styles.loadicon} as={require('@/assets/svg/creation_loading.svg').ReactComponent} />
                      <Box className={styles.loader}></Box>
                      :
                      <video src={file.url as string} preload="metadata" className={styles.previewVideo} />
                    }
                    <Box>
                      <Text className={styles.textWithEllipsis}>{truncateText(file.name)}</Text>
                      {!isSpeaking &&
                        <Flex className={styles.SucessBox}>
                          <Icon as={require('@/assets/svg/hdrSucess.svg').ReactComponent} />
                          <Text className={styles.uploadSucess}>上传成功</Text>
                        </Flex>
                      }
                    </Box>
                    {!isSpeaking && <Icon className={styles.uploadBoxicon} onClick={() => deleteFile(index)} as={require(`@/assets/svg/personalClosed.svg`).ReactComponent} />}
                  </Box>
                })
              ) : (uploadError ?
                <Box className={styles.uploadBoxx}>
                  <Flex className={styles.defaultbox}>
                    <Icon as={require('@/assets/svg/errorVideo.svg').ReactComponent} className={styles.uploadIcon} />
                    <Text className={styles.errplusIcon} >上传失败</Text>
                  </Flex>
                  <Flex className={styles.SucessBox}>
                    <Icon className={styles.errsvg} as={require('@/assets/svg/toolFileRegenerate.svg').ReactComponent} />
                    <Text className={styles.againText} onClick={() => { onOpen(); setUploadError('') }}>重新上传</Text>
                  </Flex>
                  <Icon className={styles.uploadBoxicon} onClick={() => setUploadError('')} as={require(`@/assets/svg/personalClosed.svg`).ReactComponent} />
                </Box>
                :
                <Box className={styles.uploadBox} onClick={onOpen}>
                  <input
                    type="file"
                    accept="video/*"
                    className={styles.fileUploadInput}
                    id="file-input"
                  />
                  <Icon as={require('@/assets/svg/tooluploadVideo.svg').ReactComponent} className={styles.uploadIcon} />
                  <Text className={styles.plusIcon} >上传视频</Text>
                  <Flex className={styles.ulbox}>
                    <ul>
                      <li>支持视频：AVI、FLV、MOV、MP4、MKV</li>
                      <li>支持音频：MP3、WAV、AAC、FLAC</li>
                      <li>上传音视频进行分析，一键获得内容总结</li>
                      <li>音视频文件大小限制为500M</li>
                    </ul>
                  </Flex>
                </Box>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Tabs className={styles.tabs} flex={1}>
          <TabList>
            <Tab className={`${styles.tab} ${tabsCurrent === 'summary' ? styles.selectedTab : ''}`}
              key='summary' onClick={() => setTabsCurrent('summary')}>总结摘要</Tab>
            <Tab className={`${styles.tab} ${tabsCurrent === 'videoText' ? styles.selectedTab : ''}`}
              key='videoText' onClick={() => setTabsCurrent('videoText')}>视频文本提取</Tab>
          </TabList>
          <TabPanels className={styles.tabPanels} h={'100%'} py={'10px'}>
            <TabPanel className={styles.tabPanel}>
              {info ? (
                <Flex className={styles.fullbox}>
                  {isProcessing &&
                    <Flex className={styles.nullBox}>
                      {/* <Icon as={require('@/assets/svg/loading.svg').default} w='50px' h='50px' /> */}
                      <RotatingCircles />
                      <Text>{info.message || '约3分钟生成完成，请耐心等候~'}</Text>
                    </Flex>}
                  {info.status == VideoSummaryStatusEnum.COMPLETED ?
                    info?.summary.map((i, index) => {
                      return <Markdown key={index} source={i.info} />
                    })
                    :
                    info.status == VideoSummaryStatusEnum.FAIL ? <Flex className={styles.nullBox}>
                      <Image src={require('@/assets/png/videoFail.png')} />
                      <Text>生成失败...</Text>
                    </Flex> : <></>}
                </Flex>
              ) : (
                <Flex className={styles.fullbox}>
                  <Flex className={styles.nullBox}>
                    <Icon as={require(`@/assets/svg/toolsicon/nofound.svg`).ReactComponent} />
                    <Text>当前暂无内容</Text>
                  </Flex>
                </Flex>
              )}
            </TabPanel>
            <TabPanel >
              {info ? (
                <Flex className={styles.fullbox}>
                  {
                    isProcessing &&
                    <Flex className={styles.nullBox}>
                      {/* <Icon as={require('@/assets/svg/loading.svg').default} w='50px' h='50px' /> */}
                      <RotatingCircles />
                      <Text >{info.message || '约3分钟生成完成，请耐心等候~'}</Text>
                    </Flex>
                  }
                  {
                    info.status == VideoSummaryStatusEnum.COMPLETED
                      ? <Markdown source={info?.transcription || ''} />
                      : info.status == VideoSummaryStatusEnum.FAIL ? <Flex className={styles.nullBox}>
                        <Image src={require('@/assets/png/videoFail.png')} />
                        <Text>生成失败...</Text>
                      </Flex> : <></>
                  }
                </Flex>
              ) : (
                <Flex className={styles.fullbox}>
                  <Flex className={styles.nullBox}>
                    <Icon as={require(`@/assets/svg/toolsicon/nofound.svg`).ReactComponent} />
                    <Text>当前暂无内容</Text>
                  </Flex>
                </Flex>
              )
              }
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Flex className={styles.buttonBox}>
          <ButtonWithIcon
            keyName="复制"
            onClick={() => onclickCopy(tabsCurrent === 'summary' ? info ? info.summary[0].info : '' : info ? info.transcription : '')}
            isDisabled={buttonpd}
          />
          <ButtonWithIcon
            keyName="朗读"
            onClick={() => { play(userInfo?._id) }}
            isDisabled={buttonpd}
          />
          <ButtonWithIcon
            keyName="下载"
            onClick={() => { download() }}
            isDisabled={buttonpd}
          />
        </Flex>
      </ScrollBarBox>
      <Flex className={styles.footbutton}>
        <ComButton
          loading={loading}
          setLoading={setLoading}
          casebut={'插入示例'}
          startbut={'开始总结'}
          stopbut={'停止生成'}
          stopClick={() => { controller.current?.abort() }}
          startClick={startSummary}
          caseButtonClick={() => { if (!TextareaDom.current) return; TextareaDom.current.value = caseUrl }}
          caseStop={caseStop}
          chooseModel={chooseModel}
          setChooseModel={setChooseModel}
          modelkey={modelkey}
          setModelsKey={setModelsKey}
          isnull={isnullcontent}
          _Models={_Models}
        ></ComButton>
      </Flex>
    </Flex>
    <Flex className={styles.previewBox}>
      <Box className={styles.centerItemsbox}>
        <Flex alignItems={'center'}>
          <Text className={styles.title}>根据视频内容提问</Text>
          <Text className={styles.prompt}>（提问会按照对话次数进行扣费，一次对话消耗50点数）</Text>
        </Flex>
        <Text className={styles.cleartext} onClick={() => {
          if (videoSummaryChat.history.length > 0) {
            openAlert({
              title: '提示',
              content: '你确定要清空对话吗？',
              onOk: () => {
                closeAlert();
                clearChat();
              }
            })
          }
        }}>
          清空对话
        </Text>
      </Box>
      <ChatContent info={videoSummaryChat} showAgent={false} />
      <Flex className={styles.chatfoot}>
        <Textarea
          className={styles.chattextarea}
          ref={InputTextareaDom}
          onChange={(e) => {
            setTextAreaInput(e.target.value)
          }}
          onKeyDown={(e) => {
            // 触发快捷发送
            if (e.keyCode === 13 && !e.shiftKey) {
              sendPrompt();
              e.preventDefault();
            }
            // 全选内容
            // @ts-ignore
            e.key === 'a' && e.ctrlKey && e.target?.select();
          }}
        />
        <Icon className={isnull ? styles.footbut : styles.notfootbut} as={require('@/assets/svg/send.svg').ReactComponent} onClick={() => { if (isnull) { sendPrompt() } }} />
      </Flex>
    </Flex>
    <File onSelect={selectImgFiles} />
  </Flex >
})