import { Box } from "@chakra-ui/react";
import { FC } from "react";
import styles from './index.module.scss';
import { observer } from "mobx-react-lite";
import BillsHeader from './components/Header';
import BillsContent from './components/Content'


export const Points: FC = observer(() => {

  return (
    <Box className={styles.content}>
      <BillsHeader />
      <BillsContent />
    </Box>
  )
})