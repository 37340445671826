import { collectionImg, deleteCreation, getCreationHistory, getCreationStyleConfig } from "@/api/creation";
import { creationDb } from "@/db/creation";
import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { CreationSquare } from "./square";
import { CreationDallE3 } from "./dallE3";
import { CreationGen3 } from "./gen3";

export enum CreationType {
  Square = 'Square',
  MJ = 'Midjourney',
  SD = 'StableDiffusion',
  SD3 = 'StableDiffusion3',
  SUNO = 'suno',
  E3 = 'DallE3',
  GEN3 = 'GEN3',
  ENLARGE = 'amplificationHD',
  BG = 'imageRemovebg',
  TextToSpeech = 'textToSpeech'
}

// 0等待， 1处理中，2处理完成， 3处理失败， 4排队中，8已取消， 9已删除
export enum DrawImageStatusEnum {
  WAIT = 0,
  PROCESSING = 1,
  SUCCESS = 2,
  FAIL = 3,
  QUEUE = 4,
  CANCEL = 8,
  DELETE = 9,
  PROMPTFAIL = 32,
  DEAWRISK = 33
}

class Creation {
  pageType: `${CreationType}` = CreationType.MJ;
  showNav = true;
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'creationStore',
      properties: ['pageType', 'showNav'],
      storage: window.localStorage
    })
  }

  setPageType(type: `${CreationType}`) {
    this.pageType = type;
  }

  setShowNav(show: boolean) {
    this.showNav = show;
  }
}

// 默认配置
const _DefaultConfig: Omit<CreateCreationParams, 'type'> = {
  sizeScal: '1:1',  // 默认比例
  cnPrompt: '',
  prompt: '',
  negativeCnPrompt: '',
  negativePrompt: '',
  group: "AIDraw",
  drawType: '',
  hrLevel: '',
  hrPriceLevel: '',
  imgToImgUrl: '',
  imgToImgWeight: 0.5,
  vaeName: '',
  seed: -1,
  crefUrl: '',
  crefWeight: 0
}
// MJ绘画store
export class CreationOfType {
  sizeScal: string = _DefaultConfig.sizeScal;
  cnPrompt: string = _DefaultConfig.cnPrompt;
  prompt: string = _DefaultConfig.prompt;
  negativeCnPrompt: string = _DefaultConfig.negativeCnPrompt;
  negativePrompt: string = _DefaultConfig.negativePrompt;
  group: string = _DefaultConfig.group;
  drawType: string = _DefaultConfig.drawType;
  hrLevel: string = _DefaultConfig.hrLevel;
  hrPriceLevel: string = _DefaultConfig.hrPriceLevel;
  imgToImgUrl: string = _DefaultConfig.imgToImgUrl;
  imgToImgWeight: number = _DefaultConfig.imgToImgWeight;
  crefUrl?: string = _DefaultConfig.crefUrl; // 角色参考url
  crefWeight?: number = _DefaultConfig.crefWeight; // 角色参考权重
  seed?: number = _DefaultConfig.seed;
  type = CreationType.MJ;
  styleConfig?: StyleConfig;
  styleList: StyleConfig[] = [];
  showStyles: StyleConfig[] = []; // 展示的5个style
  historyList: HistoryItem[] = [];
  pageNum = 1;
  pageSize = 10;
  total = 0;
  shouldUpdate = false;
  faverites: Map<string, string[]> = new Map(); // 收藏列表
  sd3Type: SD3Type = 'SD3';
  sd3Model: SD3Model = 'sd3-large';

  constructor(type: CreationType, pageSize: number = 10) {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: `creationStore_${type}`,
      properties: ['sizeScal', 'cnPrompt', 'prompt', 'negativeCnPrompt', 'negativePrompt', 'drawType', 'hrLevel', 'hrPriceLevel',
        'imgToImgUrl', 'imgToImgWeight', 'seed', 'type', 'styleConfig', 'sd3Type', 'sd3Model'],
      storage: window.localStorage
    })

    this.type = type;
    this.pageSize = pageSize;
  }

  init() {
    this.getStyleConfig();
    this.getCreationHistory();
  }

  reset() {
    this.sizeScal = _DefaultConfig.sizeScal;
    this.cnPrompt = _DefaultConfig.cnPrompt;
    this.prompt = _DefaultConfig.prompt;
    this.negativeCnPrompt = _DefaultConfig.negativeCnPrompt;
    this.negativePrompt = _DefaultConfig.negativePrompt;
    this.group = _DefaultConfig.group;
    this.drawType = _DefaultConfig.drawType;
    this.hrLevel = _DefaultConfig.hrLevel;
    this.hrPriceLevel = _DefaultConfig.hrPriceLevel;
    this.imgToImgUrl = _DefaultConfig.imgToImgUrl;
    this.imgToImgWeight = _DefaultConfig.imgToImgWeight;
    this.crefUrl = _DefaultConfig.crefUrl;
    this.crefWeight = _DefaultConfig.crefWeight;
    this.seed = _DefaultConfig.seed;
    this.styleConfig = undefined;
    this.showStyles = [];
    this.historyList = [];
    this.pageNum = 1;
    this.total = 0;
    this.shouldUpdate = false;
    this.faverites = new Map();
    this.sd3Type = 'SD3';
    this.sd3Model = 'sd3-large';
  }

  async getStyleConfig() {
    try {
      let isGet = false;
      creationDb.getStyleConfig(this.type + '_styles').then(res => {
        if (res && !isGet) {
          this.setStyles(res)
        }
      })

      const res = await getCreationStyleConfig(this.type);
      isGet = true;

      const _arr: StyleConfig[] = [];
      res.forEach(item => {
        if (item.key === 'zdy') {
          _arr.unshift(item)
        } else {
          _arr.push(item)
        }
      })
      this.setStyles(_arr, true);
      if (!this.styleConfig) {
        let index = 0;
        const select = _arr.filter((item, i) => {
          if (item.key === 'zdy' || item.name === '自定义') {
            index = i;
            return true;
          }
          return false;
        })[0];
        this.setStyleConfig(select || _arr[0], index);
        !this.sizeScal && this.setSizeScal(_arr[index]?.draw_scal[0].name || '1:1');
        !this.hrLevel && this.setHrLevel(_arr[index]?.draw_quality[0].name || '高清');
        !this.hrPriceLevel && this.setHrPriceLevel(_arr[index]?.draw_quality[0].key.split('-')[1] || '高清');
      } else {
        const index = _arr.findIndex(item => item.key === this.styleConfig?.key);
        this.setStyleConfig(_arr[index], index);
      }
    } catch (err) {
      console.log(err)
    }
  }

  async getCreationHistory() {
    try {
      const { pageNum, pageSize } = this;
      const res = await getCreationHistory({ pageNum: pageNum, pageSize: pageSize, toolType: this.type as GetCreationListParams['toolType'] });
      this.historyList = res.data;
      this.setTotal(res.total);
      res.favorites?.forEach(item => {
        this.setFaverites(item.drawId, item.url);
      });
    } catch (err) {
      console.log(err)
    }
  }

  async deleteCreationAction(id: string) {
    return await deleteCreation({ type: this.type, _id: id });
  }

  async postCollection(id: string, url: string) {
    return await collectionImg(id, url, this.type);
  }

  setCnPrompt(prompt: string) {
    this.cnPrompt = prompt;
  }

  setPrompt(prompt: string) {
    this.prompt = prompt;
  }

  setNegativeCnPrompt(prompt: string) {
    this.negativeCnPrompt = prompt;
  }

  setNegativePrompt(prompt: string) {
    this.negativePrompt = prompt;
  }

  setDrawType(drawType: string) {
    this.drawType = drawType;
  }

  setHrLevel(hrLevel: string) {
    console.log(hrLevel)
    this.hrLevel = hrLevel;
  }

  setHrPriceLevel(hrPriceLevel: string) {
    this.hrPriceLevel = hrPriceLevel;
  }

  setImgToImgUrl(url: string) {
    this.imgToImgUrl = url;
  }

  setImgToImgWeight(weight: number) {
    this.imgToImgWeight = weight;
  }

  setCrefUrl(url: string) {
    this.crefUrl = url;
  }

  setCrefWeight(weight: number) {
    this.crefWeight = weight;
  }

  setSeed(seed: number) {
    this.seed = seed;
  }

  setStyleConfig(config: StyleConfig, index?: number) {
    this.styleConfig = config;

    const hasHrLevel = config.draw_quality.some(item => item.name === this.hrLevel);

    if (!hasHrLevel) {
      this.setHrLevel(config.draw_quality[0]?.name);
      this.setHrPriceLevel(config.draw_quality[0]?.key.split('-')[1]);
    }

    if (index !== undefined && index > -1) { // 可能为0
      if (index > this.styleList.length - 3) {
        this.showStyles = this.styleList.slice(-5);
      } else if (index < 3) {
        this.showStyles = this.styleList.slice(0, 5);
      } else {
        this.showStyles = this.styleList.slice(index - 2, index + 3);
      }
    }
  }

  setStyles(styles: StyleConfig[], writeDb = false) {
    this.styleList = styles;
    writeDb && creationDb.setStyleConfig(this.type + '_styles', styles);
  }

  setShowStyles(styles: StyleConfig[]) {
    this.showStyles = styles;
  }

  setPageNum(num: number) {
    this.pageNum = num;
    this.getCreationHistory();
  }

  setTotal(total: number) {
    this.total = total;
  }

  setShouldUpdate(update: boolean) {
    this.shouldUpdate = update;
  }

  setSizeScal(scal: string) {
    this.sizeScal = scal;
  }

  setFaverites(id: string, url: string | string[]) {
    if (Array.isArray(url)) {
      this.faverites.set(id, url);
      return;
    }
    if (this.faverites.has(id)) {
      !this.faverites.get(id)?.includes(url) && this.faverites.get(id)?.push(url); // 防止重复添加
    } else {
      this.faverites.set(id, [url]);
    }
  }

  setSD3Type(type: SD3Type) {
    this.sd3Type = type;
  }

  setSD3Model(model: SD3Model) {
    this.sd3Model = model;
  }
}

export const creationStore = {
  publicStore: new Creation(),
  reset: () => {
    creationStore[CreationType.MJ].reset();
    creationStore[CreationType.SD].reset();
    creationStore[CreationType.SD3].reset();
    creationStore[CreationType.E3].reset();
    creationStore[CreationType.GEN3].reset();
    creationStore[CreationType.SUNO].reset();
  },
  [CreationType.MJ]: new CreationOfType(CreationType.MJ),
  [CreationType.SD]: new CreationOfType(CreationType.SD, 21),
  [CreationType.SD3]: new CreationOfType(CreationType.SD3, 21),
  [CreationType.E3]: new CreationDallE3(),
  [CreationType.GEN3]: new CreationGen3(),
  [CreationType.Square]: new CreationSquare(),
  [CreationType.SUNO]: new CreationOfType(CreationType.SUNO, 21),
};