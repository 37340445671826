import { chatStore } from "@/store/chat";
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Icon, Image, Input, Text, useOutsideClick } from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs'
import { ScrollBarBox } from "@/components/ScrollBox";
import { _AI_DefaultModel } from "@/utils/common";
import { userStore } from "@/store/user";
import Loading from "@/components/Loading";
import { CModal } from "@/components/Modal";
import { CButton } from "@/components/Button";
import { uiStrore } from "@/store/ui";
import { saveAs } from 'file-saver'
import ReactDOMServer from "react-dom/server";
import Markdown from "@/components/Markdown";
import { htmlTemplate } from "@/utils/chat";
import { useMessage } from "@/hooks/useMessage";
import { WiseImage } from "@/components/Image";
import { agent_avatars } from "@/pages/agents/components/Nav";

import styles from './index.module.scss'

interface FixedEdit {
  x: number
  y: number
  info?: UseHistoryItem
}
export const ChatHistory: FC = observer(() => {
  const [isEdit, setIsEdit] = useState(false);
  const [checkIds, setCheckIds] = useState<string[]>([]);
  const [deleteLoading, serDeleteLoading] = useState(false);// 删除全部 
  const { t } = useTranslation();
  const [editFixedInfo, setEditFixedInfo] = useState<FixedEdit>({ x: 0, y: 0 })
  const opRef = useRef<HTMLDivElement>(null);
  const [isShowEditTitle, setIsShowEditTitle] = useState(false);
  const [editValue, setEditValue] = useState('');
  const [changeTitleLoading, setChangeTitleLoading] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [exportLoading, setExportLoading] = useState<Array<'html' | 'markdown' | 'pdf' | 'word'>>([]);

  const { openAlert, closeAlert, zoom = 1 } = uiStrore;
  const message = useMessage()
  const { userInfo } = userStore;
  const { histories, currentExpand, currentChat, isHideHistoryNav, aiChatInfo, modelInfo,
    setCurrentExpand, setCurrentChat, getChatInfo, resetDefaultChatInfo, deleteChatAction, getHistories,
    changeChatHistoryAction, setNeedReRender
  } = chatStore;

  useOutsideClick({
    ref: opRef,
    handler: e => {
      const close = (e.target as HTMLDivElement).classList.contains('more')
        || modalRef.current?.contains(e.target as HTMLElement);
      if (close) return;
      setEditFixedInfo({ x: 0, y: 0 })
    }
  })
  useEffect(() => {
    userInfo && chatStore.getHistories();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    userInfo && getChatInfo();
    // eslint-disable-next-line
  }, [currentChat])


  const editCheck = (key: string) => {
    const isIn = checkIds.some(id => id === key);
    if (isIn) {
      setCheckIds(checkIds.filter(id => id !== key));
    } else {
      setCheckIds(checkIds.concat(key))
    }
  }

  const deleteChat = async () => {
    if (deleteLoading || checkIds.length === 0) return;
    let allId: string[] = [];
    let pluginId: string[] = [];
    checkIds.forEach(key => {
      const ids = histories[key].children.map(chat => {
        if (chat.isPlugin) {
          pluginId.push(chat.conversationId || '')
        }
        return chat._id
      })

      allId = allId.concat(ids);

    })
    const currentIsDelete = currentChat?.chatId ?
      (currentChat.isPlugin ? pluginId.includes(currentChat.chatId) : allId.includes(currentChat.chatId))
      : false;
    serDeleteLoading(true);
    try {
      await deleteChatAction(allId);
      await getHistories();
      setCheckIds([]);
      setIsEdit(false);
      serDeleteLoading(false);
      if (currentIsDelete) {
        setCurrentChat();
        resetDefaultChatInfo();
        setNeedReRender();// 删除打开的列表重新渲染
      }
    } catch (err) {
      console.log(err);
      serDeleteLoading(false);
    }
  }

  const deleteAChat = async () => {
    if (!editFixedInfo.info) return;
    const { _id, conversationId } = editFixedInfo.info;
    try {
      await deleteChatAction([_id]);
      await getHistories();
      setEditFixedInfo({ x: 0, y: 0 });
      if (currentChat?.chatId === _id || (currentChat?.isPlugin && currentChat?.chatId === conversationId)) {
        setCurrentChat();
        resetDefaultChatInfo();
        setNeedReRender();// 删除打开的列表重新渲染
      }
      closeAlert();
    } catch (err) {
      console.log(err)
    }

  }

  const topChat = async () => {
    if (!editFixedInfo.info) return;
    const { top, _id } = editFixedInfo.info;
    try {
      await changeChatHistoryAction(_id, !top);
      const list = await getHistories();
      setEditFixedInfo({ x: 0, y: 0 })

      if (!list) return;
      const keys = Object.keys(list);
      for (let i = 0; i < keys.length; i++) {
        const openIndex = list[keys[i]].children.some(item => item._id === _id);
        if (openIndex) {
          setCurrentExpand(i);
          break;
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const changeTitle = async () => {
    if (!editFixedInfo.info) return;
    const { top, _id } = editFixedInfo.info;
    setChangeTitleLoading(true)
    try {
      await changeChatHistoryAction(_id, top, editValue);
      await getHistories();
      setEditFixedInfo({ x: 0, y: 0 });
      setIsShowEditTitle(false);
    } catch (err) {
      console.log(err)
    }
    setChangeTitleLoading(false)
  }
  // 导出对话
  const exportChat = (type: 'html' | 'markdown' | 'pdf' | 'word') => {
    const { info } = editFixedInfo;
    const chat = aiChatInfo.get(info?._id || '');
    if (exportLoading.includes(type)) return;
    setExportLoading([...exportLoading, type])
    if (type !== 'markdown') {
      // 使用定时器异步处理计算, 优先渲染loading
      setTimeout(() => {
        let userAvatar = userInfo?.avatar || '';
        if (userAvatar?.startsWith('/_next/') || userAvatar?.startsWith('/api/')) {
          userAvatar = (window.proxyApi || 'https://dev.wisetalker.tech') + userAvatar;
        }
        const dom = chat?.history.map(item => {
          const modelAvtar = modelInfo[item.modelName].avatar;
          // 对话头像
          const avatar = `<div class="img-content" style="background-color: #b3bfeb">
                <img src="${item.obj === 'Human' ? userAvatar : modelAvtar}" alt="" />
              </div>`;
          if (!item.value) {
            return '';
          }
          // 对话markdwon
          const components = ReactDOMServer.renderToString(<Markdown source={item.value} codeShowHeader={false} />)
          return `<div class="chat-item">
              ${type === 'word' ? '' : avatar}
              ${components}
            </div>`;
        }) || []
        const html = htmlTemplate.replace('{{CHAT_CONTENT}}', dom.join('\n'));
        switch (type) {
          case 'html':
            const blob = new Blob([html], { type: 'text/html' });
            saveAs(blob, `${info?.title}.html`);
            setExportLoading(exportLoading.filter(item => item !== type));
            break;
          case 'pdf':
            const pdf_blob = new Blob([html], { type: 'application/pdf' });
            saveAs(pdf_blob, `${info?.title}.pdf`);
            setExportLoading(exportLoading.filter(item => item !== type));
            break;
          case 'word':
            const word_blob = new Blob([html], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
            saveAs(word_blob, `${info?.title}.doc`);
            setExportLoading(exportLoading.filter(item => item !== type));
            break;
        }
      }, 0)
    } else {
      const markdownContent = chat?.history.map((item) => item.value).join('\n\n');
      const blob = new Blob([markdownContent || ''], { type: 'text/markdown' });
      saveAs(blob, `${info?.title}.md`);
      setExportLoading(exportLoading.filter(item => item !== type));
    }
  }

  const hasHistory = Object.keys(histories).length > 0

  return <Box className={`${styles.history} ${isHideHistoryNav ? styles.hide : ''}`}>
    <Box className={styles.addContainer}>
      <AddNewChat onClick={() => {
        resetDefaultChatInfo();
        setCurrentChat();
        setNeedReRender();
        message.success({ title: t('NewChatSuccess') })
      }} />
    </Box>
    {
      // userInfo && <Balance userInfo={userInfo} />
    }
    <Box className={styles.list}>
      <Box className={styles.start}>
        <Text className={styles.title}>{t('ChatHistory.chatList')}</Text>
        {
          isEdit ? <Box className={styles.operate}>
            {
              deleteLoading ? <Loading.Icon className={styles.loading} />
                : <Box className={styles.delete} onClick={deleteChat}>
                  <Icon className={styles.icon} as={require('@/assets/svg/delete.svg').ReactComponent} />
                  <Text>{t('delete')}</Text>
                </Box>
            }

            <Text className={styles.cancel} onClick={() => { setIsEdit(false); setCheckIds([]); }}>{t('cancel')}</Text>
          </Box> : <Box className={`${styles.editContainer}  ${!hasHistory ? styles.disable : ''}`}>
            <Icon as={require('@/assets/svg/edit.svg').ReactComponent} />
            <Text className={styles.edit} onClick={() => {
              setIsEdit(true);
              setCurrentExpand(-1); // 关闭所有
              setTimeout(() => {
                document.getElementById('listScroll')?.getElementsByClassName('ms-track')?.[0]?.classList.remove('ms-track-show')
              }, 30);
            }}>
              {t('edit')}
            </Text>
          </Box>
        }
      </Box>
      <ScrollBarBox barProps={{ id: 'listScroll' }} className={styles.chatList}>
        <Accordion allowToggle onChange={i => {
          setCurrentExpand(i as number);
          const listScroll = document.getElementById('listScroll');
          if (i === -1) {
            setTimeout(() => {
              listScroll?.getElementsByClassName('ms-track')?.[0]?.classList.remove('ms-track-show')
            }, 30);
          } else if ((i as number) > -1) {
            setTimeout(() => {
              const scrollTrack = listScroll?.getElementsByClassName('ms-track-box')?.[0];
              if (scrollTrack?.hasChildNodes()) {
                listScroll?.getElementsByClassName('ms-track')?.[0]?.classList.add('ms-track-show')
              } else {
                document.getElementById('listScroll')?.dispatchEvent(new Event('mouseenter'))
              }
            }, 10)
          }
        }} index={currentExpand}>
          {
            Object.keys(histories).map(key => {
              const editChecked = checkIds.includes(key);
              let cls = '';
              const avatar = histories[key].avatar;
              Object.keys(agent_avatars).forEach((key) => {
                if (agent_avatars[key as keyof typeof agent_avatars] === avatar) {
                  cls = styles.avatar;
                }
              });
              return <AccordionItem key={key}>
                {
                  ({ isExpanded }) => <>
                    <Box>
                      <AccordionButton className={`${styles.header} ${isExpanded ? styles.active : ''}`} onClick={e => {
                        if (isEdit) {
                          editCheck(key);
                          e.preventDefault();
                        }
                      }}>
                        <Box className={styles.headerContent}>
                          <Box className={`${styles.modelIcon} ${cls}`}>
                            {
                              histories[key].isPlugin && <Icon className={styles.modelIcon} as={require('@/assets/svg/plugin_icon.svg').ReactComponent} />
                            }
                            {
                              histories[key].modelId === _AI_DefaultModel && <Icon className={styles.modelIcon} as={require('@/assets/svg/ai_icon.svg').ReactComponent} />
                            }
                            {
                              histories[key].modelId !== _AI_DefaultModel && !histories[key].isPlugin && <WiseImage className={styles.modelIcon} src={histories[key].avatar || ''} />
                            }
                          </Box>
                          <Box className={styles.info}>
                            <Text className={styles.name}>
                              {t(histories[key].title)}
                            </Text>
                            <Text className={styles.count}>
                              {t('ChatHistory.count.before')}{histories[key].children.length}{t('ChatHistory.count.after')}
                            </Text>
                          </Box>
                        </Box>
                        {
                          isEdit ? <Box className={`${styles.check} ${editChecked || deleteLoading ? styles.checked : ''}`} onClick={e => {
                            e.preventDefault();
                            editCheck(key);
                          }}>
                            {
                              deleteLoading ? <Loading.Icon className={styles.icon} />
                                : editChecked && <Icon className={styles.icon} as={require('@/assets/svg/radio_primary.svg').ReactComponent} />
                            }

                          </Box>
                            : <Icon
                              className={`${styles.arrow} ${isExpanded ? styles.arrowExpanded : ''}`}
                              as={require('@/assets/svg/arrow_r.svg').ReactComponent}
                            />
                        }

                      </AccordionButton>
                    </Box>
                    <AccordionPanel>
                      {
                        histories[key].children.map(item => {
                          let title = '';
                          if (item.title.startsWith('|__') && item.title.endsWith('__|')) {
                            title = t("ContentMayContains") + ':' + item.title.replace('|__', '').replace('__|', '')?.split(',').map(str => t('riskContent.' + str)).join(',') + t('creation.TemporarilyUnavailable');
                          } else {
                            title = item.title;
                          }
                          const monthNum = dayjs(item.updateTime).month() + 1;
                          const isActive = currentChat?.chatId === item._id || (currentChat?.chatId === item.conversationId && item.conversationId);
                          return <Box className={`${styles.item} ${item.top ? styles.fixTop : ''} ${isActive ? styles.active : ''}`}
                            key={item._id}
                            onClick={() => {
                              if (item._id === currentChat?.chatId) return;
                              setCurrentChat({
                                isPlugin: item.isPlugin,
                                modelId: item.modelId,
                                chatId: item.isPlugin ? (item.conversationId || '') : item._id
                              })
                            }}
                          >
                            {
                              item.top && <Icon className={styles.top} as={require('@/assets/svg/top.svg').ReactComponent} />
                            }
                            <Box className={styles.time} bg={item.color}>
                              {
                                item.dayType === 'today' ? <Text className={styles.dayType}>{t('Td')}</Text>
                                  : item.dayType === 'yesterday' ? <Text className={styles.dayType}>{t('YTD')}</Text>
                                    : <>
                                      <Text className={styles.date}>{dayjs(item.updateTime).date()}</Text>
                                      <Text className={styles.month}>{t(`${monthNum}month`)}</Text>
                                    </>
                              }
                            </Box>
                            <Text className={styles.title}>
                              {title}
                            </Text>
                            <Text className={styles.hm}>
                              {dayjs(item.updateTime).format('HH:mm')}
                            </Text>
                            <Text className={styles.more} onClick={e => {
                              e.stopPropagation();
                              const x = Math.floor(e.nativeEvent.x / zoom) - 10;
                              if (e.nativeEvent.y + 404 > window.innerHeight) {
                                setEditFixedInfo({ x, y: Math.floor(e.nativeEvent.y / zoom) - 420, info: item })
                              } else {
                                setEditFixedInfo({ x, y: Math.floor(e.nativeEvent.y / zoom) + 15, info: item })
                              }
                            }}>···</Text>
                          </Box>
                        })
                      }
                    </AccordionPanel>
                  </>
                }
              </AccordionItem>
            })
          }
          {
            editFixedInfo.info && <Box ref={opRef} className={styles.moreOperation} top={editFixedInfo.y} left={editFixedInfo.x}>
              <Box className={styles.op} onClick={topChat}>{editFixedInfo.info.top ? t('Unpin') : t('Pin')}</Box>
              <Box className={styles.op} onClick={() => {
                openAlert({
                  title: t('tip'),
                  content: t('ConfirmDelChat'),
                  status: 'warning',
                  okBtn: <CButton theme='error' onClick={deleteAChat}>{t('delete')}</CButton>,
                })
              }}>{t('DelChat')}</Box>
              <Box className={styles.op} onClick={() => {
                let title = editFixedInfo.info?.title || '';
                if (title.startsWith('|__') && title.endsWith('__|')) {
                  title = t("ContentMayContains") + ':' + title.replace('|__', '').replace('__|', '')?.split(',').map(str => t('riskContent.' + str)).join(',') + t('creation.TemporarilyUnavailable');
                }
                setIsShowEditTitle(true);
                setEditValue(title)
              }}>{t('CTitle')}</Box>
              <Box
                className={styles.op}
                onClick={() => exportChat('html')}
                cursor={exportLoading.includes('html') ? 'not-allowed' : 'pointer'}
              >
                {exportLoading.includes('html') ? <Loading.Icon /> : ''}{t('ExportHtml')}
              </Box>
              <Box
                className={styles.op}
                onClick={() => exportChat('markdown')}
                cursor={exportLoading.includes('markdown') ? 'not-allowed' : 'pointer'}
              >
                {exportLoading.includes('markdown') ? <Loading.Icon /> : ''}{t('ExportMD')}
              </Box>
              <Box
                className={styles.op}
                onClick={() => exportChat('pdf')}
                cursor={exportLoading.includes('pdf') ? 'not-allowed' : 'pointer'}
              >
                {exportLoading.includes('pdf') ? <Loading.Icon /> : ''}{t('ExportPDF')}
              </Box>
              <Box
                className={styles.op}
                onClick={() => exportChat('word')}
                cursor={exportLoading.includes('word') ? 'not-allowed' : 'pointer'}
              >
                {exportLoading.includes('word') ? <Loading.Icon /> : ''}{t('ExportWord')}
              </Box>
            </Box>
          }
        </Accordion>
        {
          !hasHistory && <Box className={styles.noChat}>
            <Image src={require('@/assets/png/no_chat.png')} />
            <Text>{t('ChatHistory.no_chat')}</Text>
          </Box>
        }
      </ScrollBarBox>
    </Box>
    <CModal isOpen={isShowEditTitle} onClose={() => setIsShowEditTitle(false)}>
      <Box className={styles.editTitle} ref={modalRef}>
        <Box className={styles.title}>
          <Text>{t('EditChatTitle')}</Text>
          <Icon className={styles.icon} as={require('@/assets/svg/close_x.svg').ReactComponent} />
        </Box>
        <Input defaultValue={editValue} onChange={e => setEditValue(e.target.value)} />
        <Box className={styles.btns}>
          <CButton theme='outline' onClick={() => setIsShowEditTitle(false)}>{t('cancel')}</CButton>
          <CButton theme='primary' isLoading={changeTitleLoading} onClick={() => changeTitle()}>{t('confirm')}</CButton>
        </Box>
      </Box>
    </CModal>
  </Box >
})


interface AddNewChatProps {
  onClick?: () => void
}
const AddNewChat: FC<AddNewChatProps> = (props) => {
  const { onClick } = props;
  const { t } = useTranslation();

  return <div className={styles.addNewChat} onClick={onClick}>
    <Icon className={styles.svg} as={require('@/assets/svg/add_circle_primary.svg').ReactComponent} />
    {t('ChatHistory.newChat')}
  </div>
}
